import API from "../api/api";
import { TOKEN } from "../reducers/userReducer";

export const getResource = async (idResource?: string) => {
  const url = `/resource/${idResource}`;
  return await API.get(url);
};

export const getResources = async () => {
  const url = `/resources`;
  return await API.get(url);
};

export const getDataResourceList = async () => {
  const url = `/resources`;
  return await API.get(url);
};

export const getResourcesById = async (idResource: string) => {
  const url = `/resource/${idResource}`;

  return await API.get(url);
};

export const addResourceById = async (idResource: string, body: any) => {
  const url = `/resource/${idResource}`;

  return await API.post(url, body, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      "Content-Type": "application/json",
    },
  });
};

export const deleteResource = async (idResource: string) => {
  const url = `/resource/${idResource}`;

  return await API.delete(url, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      "Content-Type": "application/json",
    },
  });
};

export const addResourceFile = async (
  idResource: string,
  id_post: string,
  body: any
) => {
  const url = `/resource/${idResource}/file/${id_post}`;

  return await API.post(url, body, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteResourceFile = async (
  idResource: number,
  id_file: number
) => {
  const url = `/resource/${idResource}/file/${id_file}`;

  return await API.delete(url, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
    },
  });
};

export const getResourceFiles = async (idResource: string) => {
  const url = `/resource/${idResource}/files`;

  return await API.get(url);
};

export const updateResourceById = async (idResource: string, body: any) => {
  const url = `/resource/${idResource}`;

  return await API.put(url, body, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      "Content-Type": "application/json",
    },
  });
};

export const getResourceGroup = async (idGroup: string) => {
  const url = `/resource/group/${idGroup}`;

  return await API.get(url);
};

export const getResourceGroupsList = async () => {
  const url = "/resource/groups";

  return await API.get(url);
};

export const updateResourceGroup = async (idGroup: string, body: any) => {
  const url = `/resource/group/${idGroup}`;

  return await API.put(url, body, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      "Content-Type": "application/json",
    },
  });
};

export const deleteResourceGroup = async (idGroup: string) => {
  const url = `/resource/group/${idGroup}`;

  return await API.delete(url, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      "Content-Type": "application/json",
    },
  });
};

export const getResourcesOfGroup = async (idGroup: string) => {
  const url = `/resource/group/${idGroup}/resources`;

  return await API.get(url);
};

export const addResourceGroup = async (idGroup: string, body: any) => {
  const url = `/resource/group/${idGroup}`;

  return await API.post(url, body, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      "Content-Type": "application/json",
    },
  });
};
