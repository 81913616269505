import React, { useState, useEffect, useRef } from "react";
// import { Map, Marker, Overlay  } from "pigeon-maps"
// import smileHappyGreenTmp from '../../assets/images/smile-happy-green-tmp.png';

import faceNeutralImage from "../../assets/images/indicators-color/face-neutral.svg";

import ReactMapGL, {
  AttributionControl,
  Marker,
  StaticMap,
} from "react-map-gl";
import { v4 as uuidv4 } from "uuid";

import customMarkerTmp from "../../assets/images/custom-marker-tmp.png";
import "./MapCommunity.css";
//@ts-ignore
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
import "mapbox-gl/dist/mapbox-gl.css";
//@ts-ignore
//eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const attributionStyle = {
  right: 0,
  top: 0,
};

type Props = {
  points?: any[];
  defaultViewport?: any;
  onClickMarker?: (level: number, id: number) => void;
};

const START_VIEWPORT = {
  longitude: -122.45,
  latitude: 37.78,
  zoom: 8,
};

// mapboxgl.accessToken =
//   "pk.eyJ1IjoibWlzdGVyYW52aWwiLCJhIjoiY2t3b2oyNnBuMDFsMzJxcWhndTd3bnViZyJ9.7udLsQJnsQcxJcEuRrKCIw";
const MapCommunity: React.FC<Props> = ({
  points = [],
  defaultViewport = {
    ...START_VIEWPORT,
  },
  onClickMarker,
}) => {
  const [viewport, setViewport] = React.useState({
    ...defaultViewport,
  });

  const onClickMarkerFunction = (level: number, id: number, item: any) => {
    if (onClickMarker) onClickMarker(level, id);
  };

  return (
    <>
      {/* <div>{loading}</div>
      <div>{error}</div> */}
      <section className="browse-map-section">
        <ReactMapGL
          {...viewport}
          width="100%"
          height="100%"
          onViewportChange={setViewport}
          attributionControl={false}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        >
          {points.length > 0 && (
            <>
              {points.map((item) => (
                <Marker
                  key={uuidv4()}
                  latitude={item.lat}
                  longitude={item.lng}
                  offsetLeft={-22}
                  offsetTop={-64}
                >
                  <div
                    className="custom-marger-block"
                    onClick={() =>
                      onClickMarkerFunction(item.level, item.id, item)
                    }
                  >
                    <img
                      className="custom-marger-block-image"
                      src={item.image}
                      alt="faceNeutralImage"
                    />
                  </div>
                </Marker>
                // <Marker
                //   key={uuidv4()}
                //   latitude={item.lat}
                //   longitude={item.lng}
                //   offsetLeft={0}
                //   offsetTop={0}
                // >
                //   <img
                //     onClick={() => onClickMarkerFunction(item.level, item.id)}
                //     className="custom-marker"
                //     src={customMarkerTmp}
                //     alt="customMarkerTmp"
                //   />
                // </Marker>
              ))}
            </>
          )}
          {/* 
          <Marker
            latitude={37.78}
            longitude={-122.41}
            offsetLeft={-20}
            offsetTop={-10}
          >
            <img
              className="custom-marker"
              src={customMarkerTmp}
              alt="customMarkerTmp"
            />
          </Marker> */}

          <AttributionControl compact={true} style={attributionStyle} />
        </ReactMapGL>

        {/* <StaticMap
          mapboxApiAccessToken="pk.eyJ1IjoibWlzdGVyYW52aWwiLCJhIjoiY2t3b2oyNnBuMDFsMzJxcWhndTd3bnViZyJ9.7udLsQJnsQcxJcEuRrKCIw"
          width="100%"
          height="100%"
          latitude={37.78}
          longitude={-122.45}
          zoom={8}
        ></StaticMap> */}
      </section>
      {/* <Map height={500} width={500} defaultCenter={[50.879, 4.6997]} defaultZoom={11}>
                <Marker width={50} anchor={[50.879, 4.6997]} />
            </Map> */}
    </>
  );
};

export default MapCommunity;
