import React, {useEffect, useState} from "react";
import TitlePageTextTheme from "../../../components/titlePageTextTheme/titlePageTextTheme";
import GoBack from "../../../components/goBack/GoBack";
import {useTranslation} from "react-i18next";
import CommitmentCard from "../../../components/commitmentCard/CommitmentCard";
import IonLoadingPage from "../../../components/ionLoadingPage/IonLoadingPage";
import {middlewareGetCommunityActionPlan} from "../../../services/middleware/MiddlewareCommunity";
import {useParams} from "react-router-dom";
import {THEMES} from "../../../mocks/layout";
import {useHistory} from "react-router-dom";
import "./CommunityCommitment.css";
import { useDispatch } from "react-redux";
import {setHeaderActiveTab} from "../../../reducers/headerReducer";
import {useIonViewWillEnter} from "@ionic/react";

const PAGE_THEME = THEMES.GREEN;
const CommunityCommitment: React.FC = () => {
    const {t} = useTranslation("common");
    const [isLoading, setIsLoading] = useState(false);
    const [commitment, setCommitment] = useState<any>();
    const history = useHistory();
    const {idCommunity, idActionPlan} =
        useParams<{ idCommunity: string; idActionPlan: string }>();
    const [title, setTitle] = useState<string>("");

    const dispatch = useDispatch();

    useIonViewWillEnter(() => {
        dispatch(setHeaderActiveTab(0))
    }, []);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setIsLoading(true);
        const responce = await middlewareGetCommunityActionPlan(
            idCommunity,
            idActionPlan
        );
        if (responce.isError) {
            history.goBack();
        } else {
            setCommitment(responce);
        }
        setTitle(responce.title);
        setIsLoading(false);
    };


    return (

        <>
            <TitlePageTextTheme cssTitleScreenCommunity="resourse-title-page-text-green">
                <>
                    <div>
                        {/* <GoBack isGoBack text={`Tanzanian ${t("standards")}`} /> */}
                        <GoBack isGoBack text={t("actionPlan")}/>
                    </div>
                    {/* <div>{t("resourcesHeader")}</div> */}
                    <div>{title}</div>
                </>
            </TitlePageTextTheme>
            <section className="community-commitment-centered">
                <CommitmentCard
                    {...commitment}
                    headerText=""
                    theme={PAGE_THEME}
                    idCommunity={idCommunity}
                    idActionPlan={idActionPlan}/>
            </section>
            <IonLoadingPage isLoading={isLoading}/>
        </>

    );
};

export default CommunityCommitment;
