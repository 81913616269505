import React, {useEffect, useState} from "react";
import "./CommunityBrowseContext.css";
import TitlePageTextTheme from "../../../components/titlePageTextTheme/titlePageTextTheme";
import NewsWall from "../../../components/newsWall/NewsWall";
import TabComponent from "../../../components/tabsControl/TabsControl";
import { ThemesProvider } from "contexts/themesChartsContext";
import { PageTreeProvider } from "contexts/pageTreeContext";
import BrowseChartsContainer from "../../../components/browseCharts/container";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  NewsT,
  PaginationT,
} from "../../../types/types";
import CommunityDataHeader from "../../../components/communityDataHeader/CommunityDataHeader";
import {useDispatch} from "react-redux";
import { setHeaderActiveTab} from "../../../reducers/headerReducer";
import {useIonViewWillEnter} from "@ionic/react";
import {middlewareGetOlderUpdatesSummaryBrowse} from "../../../services/middleware/MiddlewareCommunity";
let isCommunityPage = false;

const CommunityBrowseContext: React.FC = () => {
  const { t }:any = useTranslation("common");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [news, setNews] = useState<NewsT[]>([]);
  const { levelURL, idURL } = useParams<{ levelURL: string; idURL: string }>();
  const [pagination, setPagination] = useState<PaginationT>({
    page:0,
    pages:0,
    pageLen: 10,
  });
  const [country, setCountry] = useState<string>("");
  const isShowBtnOlderUpdate = !!(pagination.pages && pagination.page < pagination.pages);


  useEffect(() => {
    async function processData () {
      const responce = await middlewareGetOlderUpdatesSummaryBrowse(
          idURL,
          (pagination.page + 1).toString(),
          pagination.pageLen.toString()
      );

      setPagination({
        pageLen: 10,
        page: pagination.page + 1,
        pages: responce.pages
      })

    }
    processData().catch(console.error)
  },[])


  const loadOlderUpdates = async () => {
    if(pagination.page < (pagination.pages||0)){
      const responce = await middlewareGetOlderUpdatesSummaryBrowse(
          idURL,
          (pagination.page + 1).toString(),
          pagination.pageLen.toString()
      );
      setPagination({
        ...pagination,
        page: pagination.page + 1,
      });
      const updatedNews = responce.data.map((item: any) => {
        return{
          ...item,
          img: item.photos[0] && item.photos[0].url,
          text: item.content,
        }
      })

      setNews([...news, ...updatedNews])
    }

  };

  const tabs =  ['updates', 'standards', 'sentiment', 'action']
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const dispatch = useDispatch();
  useIonViewWillEnter(() => {
    dispatch(setHeaderActiveTab(0))
  });

  return (
      <PageTreeProvider myCommunity={false}>
        <TitlePageTextTheme cssTitleScreenCommunity="css-props-community-browse-context">
          <CommunityDataHeader
              levelURL={levelURL}
              idURL={idURL}
              setNewsResponce={(updates) => setNews(updates)}
              isCommunityProps={(isCommunity) => (isCommunityPage = isCommunity)}
              setCountry={(countryParams: string) => setCountry(countryParams)}
              setActiveTab={setActiveTab}
          />
        </TitlePageTextTheme>
        <div className="body-bg-gray-image padding-bottom-top-container-post">
          <div className="browse-charts">
            <TabComponent tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab}/>
            <div className="main-browse-chart">
              { activeTab === 'updates' && <NewsWall
                  levelURL={levelURL}
                  noNews={t('noNews')}
                  news={news}
                  loadOlderUpdates={loadOlderUpdates}
                  isShowBtnLoadOlderUpdates={isShowBtnOlderUpdate}
                  isCommunity={isCommunityPage}
                  idCommunity={idURL}
                  country={country}
              />
              }
              { activeTab !== 'updates' &&
                  <ThemesProvider activeParentTab={activeTab} level={levelURL} id={idURL}>
                    <BrowseChartsContainer>
                    </BrowseChartsContainer>
                  </ThemesProvider>
              }
            </div>
          </div>
        </div>
      </PageTreeProvider>

  );
};

export default CommunityBrowseContext;
