import React, { useEffect, useState } from "react";
import TitlePageTextTheme from "../../../components/titlePageTextTheme/titlePageTextTheme";
import ListLinks from "../../../components/listLinks/ListLInks";
import { useTranslation } from "react-i18next";
import * as access from "../../../accessRghts/Access";
import {useHistory, useLocation} from "react-router-dom";
import { isUserGlobalAdmin } from "../../../helpers/functions";
import { MenuAccountI } from "../../../types/types";
import ContainerPosts from "../../../components/containerPosts/ContainerPosts";
import { setLogOut, COMMUNITY } from "../../../reducers/userReducer";
import {useDispatch, useSelector} from "react-redux";
import { middlewarGetAccountMe } from "../../../services/middleware/MiddlewateUser";
import * as accessFunction from "../../../helpers/functions";
import { useIonViewWillEnter } from "@ionic/react";

import "./AccountMenu.css";
import { setHeaderActiveTab} from "../../../reducers/headerReducer";
import {persistor} from "../../../reducers";

const TIMESTAMP = 'last_changes_timestamp';
const AccountMenu: React.FC = () => {
  const PAGE_THEME = useSelector((state:any)=> state.theme.theme)
  const user = useSelector((state:any)=> state.user)
  const isConnected = useSelector((state:any) => state.offline.isConnected);
  const { t } = useTranslation("common");
  const history = useHistory();
  const dispatch = useDispatch();
  const [accountMe, setAccountMe] = useState<any>({
    community: false,
  });

  const initialList = [
    {
      name: t("personalDetails"),
      description: t("updateYourAccountDetails"),
      access: true && isConnected,
      url: access.URL_ACCOUNT_DETAILS,
    },
    {
      name: t("communityManagement"),
      description: t("controlHowYourCommunity"),
      access: !!user.community && isConnected,
      url: "/account/community/" + user.community, // URL_ACCOUNT_COMMUNITY_MANAGE
    },
    {
      name: t("userManagement"),
      description: t("createAndRemoveAccounts"),
      access: isUserGlobalAdmin() && isConnected,
      url: access.URL_USERS_MANAGEMENT,
    },
    {
      name: t("countryManagement"),
      description: t("editTheBrowseNavigation"),
      access: isUserGlobalAdmin() && isConnected,
      url: access.URL_COUNTRY_MANAGEMENT,
    },
    {
      name: t("standardsManagement"),
      description: t("associateStandardsWithThemes"),
      access: isUserGlobalAdmin() && isConnected,
      url: access.URL_ACCOUNT_STANDARDS,
    },
    {
      name: t("feedback"),
      description: t("sendAnonymousFeedbck"),
      access: true && isConnected,
      url: access.URL_ACCOUNT_FEEDBACK,
    },
    {
      name: t("logOut"),
      description: "",
      access: true,
      url: access.URL_SIGNIN,
    },
  ];
  const [listMenu, setListMenu] = useState<MenuAccountI[] | []>(initialList);

  useEffect(() => {
    getAccountMeLocal();
    filterAccessLinks();
  }, [user, isConnected]);

  const filterAccessLinks = () => {
    const filteredLinks = initialList.filter((item: MenuAccountI) => item.access);
    setListMenu(filteredLinks);
  };

  const onClickItem = async(id: number) => {
    if (id === listMenu.length - 1) {
      if (window.confirm(t("confirmLogout"))) {
        dispatch(setLogOut());
        caches && caches.delete('dynamic_cache_sauti');
        localStorage.removeItem(TIMESTAMP);
        await persistor.purge();
        history.push(listMenu[id].url);

        window.location.reload();
      }
    } else {
      history.push(listMenu[id].url);
    }
  };

  const getTextuserRole = () => {
    if (accessFunction.isUserGlobalAdmin()) return t("inUnderAGlobalAdmi");
    else if (accessFunction.isUserIrelandAdmin())
      return t("inUnderAIrelandAdmi");
    else if (accessFunction.isUserTanzaniaAdmin())
      return t("inUnderATanzaniaAdmi");
    else if (accessFunction.isUserRegistration()) return t("inUnderABasicUser");
    return "";
  };

  const getAccountMeLocal = async () => {
    const responce = await middlewarGetAccountMe();
    setAccountMe(responce);
    filterAccessLinks();
  };

  useIonViewWillEnter(() => {
    dispatch(setHeaderActiveTab(3))
  }, []);

  return (
      <div>
        <TitlePageTextTheme
          theme={PAGE_THEME}
          cssTitleScreenCommunity="sing-in-title-page-text"
        >
          <div>My Account</div>
        </TitlePageTextTheme>
        <ContainerPosts theme={PAGE_THEME} text={getTextuserRole()}>
          <ListLinks
            titleCss="text-color-blue"
            listLinks={listMenu}
            onClick={(id) => onClickItem(id)}
          />
        </ContainerPosts>
      </div>
  );
};

export default AccountMenu;
