import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import { IonContent, IonPage } from "@ionic/react";
import { IonButton } from "@ionic/react";
import { IonIcon } from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";
import TitlePageTextTheme from "../components/titlePageTextTheme/titlePageTextTheme";
import WrapperOnTheForm from "../components/wrapperOnTheForm/WrapperOnTheForm";
import CustomInput from "../components/customInput/CustomInput";
import CustomTextAre from "../components/customTextAre/CustomTextAre";
import CustomCheckbox from "../components/customCheckbox/CustomCheckbox";
import { THEMES } from "../mocks/layout";
import ButtonResource from "../components/content/ButtonResource"
import CustomDropDown from "../components/customDropDown/CustomDropDown";
import fileImage from "../assets/images/file.png";
import {
    addResourceById,
    addResourceFile,

} from '../services/Resources';
import {
    getCountriesList,
    getFreeStandardsOfCountry
} from '../services/standards';
import './AddResourceGroupPage.css';
import "./EditResource.css";
import { withTranslation, WithTranslation } from "react-i18next";
import { Resource } from './Resource';

const THEME_PAGE = THEMES.BLUE;

type ContentPage = {

    en_title: string,
    en_summary: string,
    sw_title: string,
    sw_summary: string,
    en_content: string,
    sw_content: string
}


interface SetResources {
    (): void;
}

type Standard = {
    label: string,
    value: number
}

type Standarts = {
    country: string,
    arrayStandards: Standard[];
    id: number;
}

type AddNewResourceProps = {}

type AddNewResourceState = {
    contentPage: ContentPage,
    resource_group: number,
    isConfirmBox: boolean,
    attachFile: string | boolean,
    fileDescription: any[],
    uploadFileDescription: any[],
    addGroupFiles: JSX.Element[] | null,
    addFilesUpload: JSX.Element[] | null,
    linkRelated: boolean,
    choiceCountry: JSX.Element | null,
    standardsList: JSX.Element | null
};

  class AddNewResource extends React.Component<AddNewResourceProps & WithTranslation & RouteComponentProps<{}>,AddNewResourceState> {
    page_title: string;
    resource_group: number;
    arrayFiles: File[];
    arrayUploadFiles: any[];
    arrayRemoveFiles: any[];
    arrayFileDescription: string[];
    arrayUploadFileDescription: string[];
    filesOfResource: any[];
    arrayCountry: any[];
    arrayCountryStandards: Standarts[];
    linkStandard: number;

    constructor(props: any) {
		super(props);
        this.state = {
            contentPage: {
                en_title: '',
                en_summary: '',
                sw_title: '',
                sw_summary: '',
                en_content: '',
                sw_content: ''
            },
            resource_group: 0,
            isConfirmBox: false,
            attachFile: false ,
            fileDescription: [],
            uploadFileDescription: [],
            addGroupFiles: [],
            addFilesUpload: [],
            linkRelated: false,
            choiceCountry: null,
            standardsList: null
        };
        this.page_title = "";
        this.resource_group = 0;
        this.arrayFiles = [];
        this.arrayUploadFiles = [];
        this.arrayRemoveFiles = [];
        this.arrayFileDescription = [];
        this.filesOfResource = [];
        this.arrayUploadFileDescription = [];
        this.arrayCountry = [];
        this.arrayCountryStandards = [];
        this.linkStandard = 0;

        this.setContentPage = this.setContentPage.bind(this);
        this.createResource = this.createResource.bind(this);
        this.Exit = this.Exit.bind(this);
        this.backToResources = this.backToResources.bind(this);
        this.attachFile = this.attachFile.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.removeUploadFile = this.removeUploadFile.bind(this);
        this.setFileDescription = this.setFileDescription.bind(this);
        this.setUploadFileDescription = this.setUploadFileDescription.bind(this);
        this.setGroupFiles = this.setGroupFiles.bind(this);
        this.setCheckLinkRelated = this.setCheckLinkRelated.bind(this);
        this.getListOfCountries = this.getListOfCountries.bind(this);
        this.createChoiceCountry = this.createChoiceCountry.bind(this);
        this.selectedCountry = this.selectedCountry.bind(this);
        this.createStandardsList = this.createStandardsList.bind(this);
        this.addFilesToTheServer = this.addFilesToTheServer.bind(this);
    }

    componentDidMount() {
        var param = Object.create(this.props.match.params);
        this.resource_group = Number(param.idGroup);
  	}

    setContentPage(content:any) {
        this.setState({contentPage:content});
    }

    async setCheckLinkRelated(checkRelatedStandart: boolean, standard?: number) {
        if (checkRelatedStandart === false) {
            this.linkStandard = 0;
            this.setState({
                linkRelated:checkRelatedStandart,
                choiceCountry: null,
                standardsList: null
            });
        } else {
            standard?this.linkStandard = standard:this.linkStandard = 0;
            if (this.arrayCountryStandards.length === 0) {
                try {
                    await this.getListOfCountries();
                } catch (error: any) {
                    console.log(`getListOfCountries error status: ${error.response.status}`);
                }

            }
            const selectCountry:JSX.Element = this.createChoiceCountry(this.arrayCountryStandards);
            this.setState({
                linkRelated: true,
                choiceCountry: selectCountry
            });
        }

    }

    async getListOfCountries() {

        try {
            const {data} = await getCountriesList();
            data.forEach((item:any)=>{

                this.arrayCountryStandards.push({
                    country: item.name as string,
                    arrayStandards: [],
                    id: item.id
                });
            });

        } catch (error: any) {
            console.log(`getStandardsList error status: ${error.response.status}`);
        }
    }

    selectedCountry(option:any) {
       this.createStandardsList(option.value as string);
    }

    selectedStandards(option:any,country:string) {
        this.linkStandard = option.value;
    }

    async createStandardsList(country: string) {
        const arrayStandarts:Standard[] = [
            {
                label:'Sustainable agriculture',
                value: 1
            },
            {
                label:'Marked cattle grazing areas',
                value: 2
            },
            {
                label:'Protection of water resources',
                value: 3
            },
            {
                label:'Community based forest management',
                value: 4
            },
            {
                label:'Youth Participation',
                value: 5
            }
        ];

        const country_element:any = this.arrayCountryStandards.find((item:any)=>{
            return item.country === country;
        });
        if (country_element === 'undefined')
            return;

        if (country_element.arrayStandards.length === 0) {

            try {
                const {data} = await getFreeStandardsOfCountry(country);
                console.log(data);

                data.forEach((element:any) => {
                    country_element.arrayStandards.push({
                        label:element.name,
                        value:element.id
                    });
                });
                /*
                if (country_element.arrayStandards.length === 0) {
                    arrayStandarts.forEach((element:any) => {
                        country_element.arrayStandards.push({
                            label:element.label,
                            value:element.value
                        });
                    });
                }*/

            } catch (error: any) {
                console.log(`getStandardsOfCountry error status: ${error.response.status}`);
            }
        }
        const selectStandards:JSX.Element =
        this.createChoiceStandards(country_element.arrayStandards,country_element.country);
        this.setState({standardsList:selectStandards});
    }

    createChoiceStandards(data: any,country: string):JSX.Element {
        return(
            <div className="community-update-image-row">
                <div className="community-update-row" id="resource-dropdawn-standards-list">
                    <label className="community-update-label-in-row"></label>
                    <CustomDropDown
                        setValue={(option) => this.selectedStandards(option,country)}
                        options={data}
                        cssControl="cusom-input-props"
                  />
                </div>
            </div>
        );
    }

    createChoiceCountry(data: any):JSX.Element {
        const arrayCountry: string[] = data.map((item:any)=> {
            return item.country;
        });
        return(
            <div className="community-update-image-row">
                <div className="community-update-row">
                    <label className="community-update-label-in-row"></label>
                    <CustomDropDown
                        setValue={(option) => this.selectedCountry(option)}
                        options={arrayCountry}
                        cssControl="cusom-input-props"
                  />
                </div>
            </div>
        );
    }

    backToResources() {
        this.props.history.goBack();
    }

    Exit() {
        this.props.history.goBack();
    }

    attachFile(e: React.ChangeEvent<HTMLInputElement>) {

        const files = e.target.files;

        if (files && files.length > 0) {
            this.arrayFiles.push(files[0]);

            this.setGroupFiles(this.arrayFiles);
        }
    }

    getUploadFiles(arrayFiles: any):JSX.Element[] {
        const { t } = this.props;
        let uploadFiles: JSX.Element[] = arrayFiles.map((itemFile:any,index:number)=>{
            let start = itemFile.file.lastIndexOf('/');
            let url:string = itemFile.file.slice(0, start);
            let end = itemFile.file.lastIndexOf('_');
            let fileName:string = itemFile.file.slice(start+1,end);
            start = itemFile.file.lastIndexOf('.');
            let extFile: string = itemFile.file.slice(start);
            fileName += extFile;

            return (
                <div className="community-update-row" key={index}>
                    <label className="community-update-label-in-row"></label>
                    <div>
                        <div className="upperblock-file-row-community">
                            <div className="fileimage-img-name-file">
                                <img
                                    className="fileimage-community"
                                    src={fileImage}
                                    alt="fileImage"
                                />
                                <div className="name-file-community">
                                    {fileName}
                                </div>
                            </div>
                            <div className="remove-file-text" onClick={()=>{this.removeUploadFile(index);}}>
                                    {t("removeFile")}
                            </div>
                        </div>
                        <div className='cusom-input-props'/>
                    </div>
                </div>
            );
        });
        return uploadFiles;
    }

    setGroupFiles(Files: File[]) {
        const { t } = this.props;
        let groupFiles: JSX.Element[] = Files.map((itemFile:File,index)=>{

            return (
                <div className="community-update-row" key={index}>
                    <label className="community-update-label-in-row"></label>
                    <div>
                        <div className="upperblock-file-row-community">
                            <div className="fileimage-img-name-file">
                                <img
                                    className="fileimage-community"
                                    src={fileImage}
                                    alt="fileImage"
                                />
                                <div className="name-file-community">
                                    {itemFile.name}
                                </div>
                            </div>
                            <div className="remove-file-text" onClick={()=>{this.removeFile(index);}}>
                                    {t("removeFile")}
                            </div>
                        </div>
                        <div>
                            <CustomInput
                                cssInput="cusom-input-props"
                                id="englishTitle"
                                value={this.state.fileDescription[index]}
                                onChange={(inputText) => this.setFileDescription(inputText,index)}
                                placeholder={t("editedFileName")}
                            />
                        </div>
                    </div>
                </div>
            );
        });
        this.setState({addGroupFiles:groupFiles});
    }

    removeFile(index:number) {
        this.arrayFiles.splice(index,1);
        this.setGroupFiles(this.arrayFiles);
    }

    removeUploadFile(index:number) {
        this.arrayRemoveFiles.push(this.arrayUploadFiles[index]);
        this.arrayUploadFiles.splice(index,1);

        const uploadFiles:JSX.Element[] = this.getUploadFiles(this.arrayUploadFiles);
        this.setState({addFilesUpload:uploadFiles});
    }

    setFileDescription(text: string,index:number) {
        this.arrayFileDescription[index] = text;
        this.setState({fileDescription:this.arrayFileDescription});

    }

    setUploadFileDescription(text: string,index:number) {
        this.arrayUploadFileDescription[index] = text;
        this.setState({uploadFileDescription:this.arrayUploadFileDescription});
    }

    async createResource() {
        if (!((this.state.contentPage.en_title.length &&
               this.state.contentPage.en_summary.length &&
               this.state.contentPage.en_content) ||
              (this.state.contentPage.sw_title.length &&
               this.state.contentPage.sw_summary.length &&
               this.state.contentPage.sw_content)))
        return;
        try {
            const response = await addResourceById('1',  {
                ...this.state.contentPage,
                resource_group:this.resource_group,
                standard:this.linkStandard?this.linkStandard:null
            });

            await this.addFilesToTheServer(response);
            this.backToResources();
        }catch (error: any) {
            console.log(`error status: ${error.response.status}`);
        }

    }

    async addFilesToTheServer(response: any) {
        var formData = new FormData();
        this.arrayFiles.forEach(async (itemFile)=> {
            try {
                console.log('will add itemfile=>',itemFile);
                formData.append("file", itemFile);
                formData.append("resource", response.data.id.toString() );
                const data = await addResourceFile(response.data.id,'1', formData);
            }catch (error: any) {
                console.log(`error status addResourceFile: ${error.response.status}`);
            }
        });
    }

    render() {
        const { t } = this.props;
        this.page_title = t("addResource");

        const content:ContentPage = {...this.state.contentPage};

        const buttonAddUpdate:JSX.Element =
            <ButtonResource onClickHandler={this.createResource} text_color="white">{t("createResource")}</ButtonResource>;

        const buttonDelCancel:JSX.Element =
            <ButtonResource color="light" text_color='rgb(105, 113, 126)' onClickHandler={this.Exit} >{t("cancel")}</ButtonResource>;
        const add_resource_css: string = ' custom-tetxtarea-add-resource';

        return (
            <IonPage>
                <IonContent>

                    <TitlePageTextTheme
                        theme={THEME_PAGE}
                        cssTitleScreenCommunity="resourse-title-page-text-green"
                    >
                    <>
                        <div>
                        <IonButton color="light" id="back_to_resources_button" onClick={this.backToResources}>
                            <IonIcon slot="start" icon={chevronBackOutline} />
                            <span>{t("resources")}</span>
                        </IonButton>
                        </div>
                        <div>{this.page_title}</div>
                    </>
                    </TitlePageTextTheme>
                    <WrapperOnTheForm
                        title={t("engdetails")}
                        description={t("provideengverscontant")}
                    >
                    <div className="community-update-row">
                        <label
                            className="community-update-label-in-row"
                            htmlFor="englishTitle"
                        >
                        {t("title")}
                        </label>
                        <CustomInput
                            cssInput="cusom-input-props"
                            id="englishTitle"
                            value={this.state.contentPage.en_title}
                            onChange={
                                (inputText:string) => {
                                    this.setContentPage({...content,en_title:inputText});
                                }

                            }
                            isShowCountLetter
                        />
                    </div>
                    <div className="community-update-row">
                        <label
                            className="community-update-label-in-row"
                            htmlFor="englishUpdate"
                        >
                        {t("summary")}
                        </label>
                        <CustomTextAre
                            value={this.state.contentPage.en_summary}
                            id="englishUpdate"
                            isShowCountLetter
                            onChange={
                                (inputText:string) => {
                                    this.setContentPage({...content,en_summary:inputText});
                                }

                            }
                            cssTextAre={"cusom-input-props"+add_resource_css}
                        />
                    </div>

                    <div className="community-update-row">
                    <label
                        className="community-update-label-in-row"
                        htmlFor="englishUpdate"
                    >
                    {t("content")}
                    </label>
                    <CustomTextAre
                        value={this.state.contentPage.en_content}
                        id="englishUpdate"
                        isShowMarkdown
                        onChange={
                            (inputText:string) => {
                                this.setContentPage({...content,en_content:inputText});
                            }
                        }
                        cssTextAre="cusom-input-props"
                    />
                    </div>
                    </WrapperOnTheForm>
                    <WrapperOnTheForm
                        title={t("swahilidetails")}
                        description={t("availprovidetransl")}
                    >
                    <div className="community-update-row">
                        <label
                            className="community-update-label-in-row"
                            htmlFor="swahiliTitle"
                        >
                        {"Title"}
                        </label>
                        <CustomInput
                            cssInput="cusom-input-props"
                            id="swahiliTitle"
                            value={this.state.contentPage.sw_title}
                            onChange={
                                (inputText:string) => {
                                    this.setContentPage({...content,sw_title:inputText});
                                }

                            }
                        isShowCountLetter
                        />
                    </div>
                    <div className="community-update-row">
                        <label
                            className="community-update-label-in-row"
                            htmlFor="swahiliUpdate"
                        >
                        {t("summary")}
                        </label>
                        <CustomTextAre
                            value={this.state.contentPage.sw_summary}
                            id="swahiliUpdate"
                            isShowCountLetter
                            onChange={
                                (inputText:string) => {
                                    this.setContentPage({...content,sw_summary:inputText});
                                }

                            }
                            cssTextAre={"cusom-input-props"+add_resource_css}
                        />
                    </div>

                    <div className="community-update-row">
                        <label
                            className="community-update-label-in-row"
                            htmlFor="swahiliUpdate"
                        >
                        {t("content")}
                        </label>
                        <CustomTextAre
                            value={this.state.contentPage.sw_content}
                            id="swahiliUpdate"
                            isShowMarkdown
                            onChange={
                                (inputText:string) => {
                                    this.setContentPage({...content,sw_content:inputText});
                                }

                            }
                            cssTextAre="cusom-input-props"
                        />
                    </div>
                    </WrapperOnTheForm>

                    <WrapperOnTheForm
                        title={t("relatedFiles")}
                        description={t("addanyRelatFilesDownload")}
                    >
                        { this.state.addFilesUpload}
                        { this.state.addGroupFiles }
                        <div className="community-update-row">
                            <label className="community-update-label-in-row"></label>
                            <div>
                                <label>
                                    <div className="add-photo-btn-community size-btn-file">
                                    {t("attachFile")}
                                    </div>
                                    <input
                                    className="hidde-input-image-community"
                                    type="file"
                                    accept=".doc, .docx, .pdf"
                                    onChange={this.attachFile}
                                    />
                                </label>
                            </div>
                        </div>
                    </WrapperOnTheForm>

                    <WrapperOnTheForm
                        title={t("relatedStandard")}
                        description={t("specifyResRelatStand")}
                        >
                        <div className="community-update-image-row">
                            <div className="community-update-row">
                            <label className="community-update-label-in-row"></label>
                            <CustomCheckbox
                                id="checkbox-link"
                                textAfter={t("linkResourcetoStandard")}
                                checked={this.state.linkRelated}
                                onChange={(change) => {
                                    this.setCheckLinkRelated(change);

                                }}
                            />
                            </div>
                        </div>
                        {this.state.choiceCountry}
                        {this.state.standardsList}
                    </WrapperOnTheForm>

                    <div className='section-add-resource-button'>
                        <div className='resource-button-cancel-container'>
                        {buttonDelCancel}
                        </div>
                        <div className='resource-button-add-container'>
                        {buttonAddUpdate}
                        </div>
                    </div>
                </IonContent>
            </IonPage>
        );
    }
  }

  const decoratedComponent = withTranslation("common")(AddNewResource);
  export { decoratedComponent as AddNewResource };
