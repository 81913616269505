import { AnySourceImpl } from 'mapbox-gl';
import React from 'react';
import "./chart.css";

type LinearDiagramState = {
	canvas_el:JSX.Element|null
}
type LinePoint = {
	level: number,
	control_point: boolean,
	text?: boolean
};

type LinearDiagramProps = {

  state_santiments: {
  	evaluation:any,
  	linear_points:LinePoint[],
  	line_width:number,
  	line_color:string,
  	point_color:string,
  	background:string,
	textSize?: number
  }
};

class LinearDiagram extends React.Component<LinearDiagramProps,LinearDiagramState> {
  canvasRef:HTMLCanvasElement|null;
  container:any;
  set_canvas:boolean;

  constructor(props: any) {
    super(props);

    this.canvasRef = null;
    this.container = null;
    this.set_canvas = false;
    this.state = {canvas_el:null};
    this.checkDOM = this.checkDOM.bind(this);
    this.setCanvas = this.setCanvas.bind(this);
    this.drawLinear = this.drawLinear.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
  }

  handleMouseOver() {
  	if (!(this.container.clientWidth < 250 || (this.container.clientWidth >= 250 && this.container.clientHeight < 100)))
  		return;
  	if (this.canvasRef) {
		this.canvasRef.width = 250;
		this.canvasRef.height = 170;
		this.canvasRef.style.border = '1px solid blue';
		this.canvasRef.style.top = '-145px';
		this.canvasRef.style.left = '-10px';

		this.drawLinear(250,170,4,'white');
  	}
  }

  handleMouseOut() {
  	if (this.canvasRef) {
  		if (this.canvasRef.width === this.container.clientWidth && this.canvasRef.height === this.container.clientHeight)
  			return;
	  	this.canvasRef.width = this.container.clientWidth;
		this.canvasRef.height = this.container.clientHeight;
		this.canvasRef.style.border = 'none';
		this.canvasRef.style.top = '0';
		this.canvasRef.style.left = '0';

		this.drawLinear(this.container.clientWidth,this.container.clientHeight);
	}
  }

  componentDidUpdate(prevProps:LinearDiagramProps,prevState:LinearDiagramState):void {
  	if (this.canvasRef && !this.set_canvas) {
  		this.set_canvas = true;
  		this.drawLinear(this.container.clientWidth,this.container.clientHeight);
  	}
  }

  componentDidMount() {

  	this.checkDOM();
  }

  checkDOM() {
  	if (this.container===null)
  		return;
  	if (!this.container.clientWidth)
  		setTimeout (()=>{this.checkDOM();},1000);
  	else {
  		this.setCanvas(this.container.clientWidth,this.container.clientHeight);
  	}
  }

  setCanvas(width:number,height:number) {

  	var canvas_el:JSX.Element = <canvas ref={(element)=>{this.canvasRef=element as HTMLCanvasElement}} width={width} height={height}
  										/*onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut}*/	/>
  	this.setState({canvas_el:canvas_el});
  }

  drawLinear(width:number,height:number,line?:number,back?:string) {

  	if (this.canvasRef===null)
  		return;
  	const {state_santiments} = this.props;

  	var line_width:number = line?line:state_santiments.line_width; //установить через props
	var points:any[] = [];
	var radius_point:number = line_width;
	var count_points:number = state_santiments.linear_points.length;

	var points_distance:number = Math.round((width-(3*radius_point))/(count_points-1));
 	var offset:number = radius_point;
 	this.canvasRef.style.background = back?back:state_santiments.background;

 	const ctx = this.canvasRef.getContext('2d');

 	if (ctx) {
		ctx.beginPath();
		if (state_santiments.linear_points.length &&
			state_santiments.linear_points[0].control_point &&
			state_santiments.linear_points[0].text &&
			state_santiments.textSize) {
			let str: string = state_santiments.linear_points[0].level.toString()+"%   ";
			let metrics = ctx.measureText(str);
			offset += metrics.width;
		}
		if (state_santiments.linear_points.length &&
			state_santiments.linear_points[count_points-1].control_point &&
			state_santiments.linear_points[count_points-1].text &&
			state_santiments.textSize) {
			let str: string = state_santiments.linear_points[count_points-1].level.toString()+"%";
			let metrics = ctx.measureText(str);
			points_distance = Math.round(((width-metrics.width*3)-(3*radius_point))/(count_points-1));
		}
		ctx.strokeStyle = state_santiments.line_color; //установить через props
		ctx.lineWidth = line_width;

		var x:number = offset;
		var y:number = Math.round(height-offset);
		//ctx.moveTo(x,y);

		state_santiments.linear_points.forEach((sentiment,index)=> {
			y = Math.round(height-(sentiment.level*height/100));
			if (y === height)
				y = height - line_width;
			if (sentiment.control_point /* || (width >= 250 && height >= 100)*/)
			{
			/*	if (y === height)
					y = height - line_width;
				if (x > width)
					x = width;*/

				points.push({
					x:x,
					y:y,
					level:sentiment.level,
					text: sentiment.text?sentiment.text:false
				});
			}
			if (!index)
				ctx.moveTo(x,y);
			else
				ctx.lineTo(x,y);
			x += points_distance;

		});
		ctx.stroke();
		// control point
	//	if (width >= 250 && height >= 100)
	//		ctx.fillStyle = state_santiments.point_color;//установить через props
	//	else
			ctx.fillStyle = state_santiments.line_color;

		points.forEach ((point,index)=> {
			ctx.beginPath();
			if (!point.text) {
				ctx.arc(point.x, point.y, radius_point, 0, 2 * Math.PI);
				ctx.fill();
			}

			if (point.text === true && state_santiments.textSize) {
				var x:number = point.x;
				var y:number = point.y;
				var font_size:number = state_santiments.textSize;
				ctx.font = font_size+"px serif";
				var text_level:string = point.level.toString()+"%";
				var metrics = ctx.measureText(text_level);


			//	ctx.strokeStyle = 'black';

				if (!index) {
					x = 0;
				}
				if (index === points.length-1)
					y += font_size*1.5;
				/*
				else if (index===count_points-1)
					x -= radius_point + metrics.width;
				else {
					y += font_size;
					x -= radius_point;
				}*/
				ctx.fillText(text_level,x,y);
			}
		});
	}
  }

  render() {

  	return(<div className='canvas_container' ref={(div)=>{this.container=div as HTMLDivElement}}>{this.state.canvas_el}</div>);
  }
}
export default LinearDiagram
