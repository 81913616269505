import { THEMES } from "../mocks/layout";

const SET_THEME = "SET_THEME";
const DEFAULT_VALUE_THEME = THEMES.BLUE;
const ITEM_VALUE_IN_LOCALSTORAGE = "theme";

export interface ThemeState {
  activeTheme: string;
}

const initialState: ThemeState = {
  activeTheme: "blue",
};

export const setTheme = (theme: string) => ({
  type: SET_THEME,
  payload: theme,
});

export const getThemeFromLocalstorage = () => {
  return window.localStorage.getItem(ITEM_VALUE_IN_LOCALSTORAGE);
};

export const setThemeToLocalstorage = (theme: any) => {
  setTheme(theme);
  window.localStorage.setItem(ITEM_VALUE_IN_LOCALSTORAGE, theme);
};

export const setBlueThem = () => {
  setThemeToLocalstorage(THEMES.BLUE);
};

export const setGreenThem = () => {
  setThemeToLocalstorage(THEMES.GREEN);
};

export default function themeReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_THEME:
      return {
        ...state,
        activeTheme: action.payload,
      };
    default:
      return state;
  }
}
