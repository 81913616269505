import React, {ComponentProps} from 'react';
import { IonButton, IonIcon} from "@ionic/react";
import {
    chevronBackOutline,
    chevronForwardOutline,
} from "ionicons/icons"
import {
    getResourcesOfGroup,
    getResourceGroup,
    getResourceGroupsList
} from '../services/Resources';
import {
    isUserGlobalIrelandTanzaniaAdmin,
    isUserGlobalAdmin,
    isUserGlobalIrelandAdmin,
    isUserGlobalTanzaniaAdmin
} from "../helpers/functions";
import {THEMES} from "../mocks/layout";
import TitlePageTextTheme from "../components/titlePageTextTheme/titlePageTextTheme";
import ContainerPosts from "../components/containerPosts/ContainerPosts";
import ShallPost from "../components/shallPost/ShallPost";
import SimpleListOfLink from "../components/simpleListOfLink/SimpleListOfLink";
import IonLoadingPage from "../components/ionLoadingPage/IonLoadingPage";
import {withTranslation, WithTranslation} from "react-i18next";
import ButtonResource from "../components/content/ButtonResource";
import "./resources.css";
import {connect, useDispatch} from "react-redux";
import {setHeaderActiveTab} from "../reducers/headerReducer";

import { withIonLifeCycle } from '@ionic/react';

const THEME_PAGE = THEMES.BLUE;

type Resources = {
    title: string,
    text: string,
    id: number,
    url: string
}
type ResourcesState = {

    contentPage: JSX.Element | null,

};
type ResourceGroup = {
    en_summary: string,
    en_title: string,
    id: number,
    sw_summary: string,
    sw_title: string
}

class ResourcesOfGroup extends React.Component<ComponentProps<any>, ResourcesState> {
    array_resourses: Resources[];
    resourses_description: string;
    page_title: string;
    isLoading: boolean;
    resourceGroup: ResourceGroup;
    idResourceGroup: number;
    array_groups: Resources[];
    blockSeeAlso: JSX.Element | null;
    globalAdmin: boolean;
    levelCountrysAdmin: boolean;
    adminTanzania: boolean;
    adminIreland: boolean;


    constructor(props: any) {
        super(props);
        this.array_resourses = [];
        this.state = {contentPage: null};
        this.isLoading = true;
        this.resourses_description = "";
        this.page_title = "";
        this.resourceGroup = {
            en_summary: '',
            en_title: '',
            id: 0,
            sw_summary: '',
            sw_title: ''
        };
        this.idResourceGroup = 0;
        this.array_groups = [];
        this.blockSeeAlso = null;
        this.globalAdmin = false;
        this.levelCountrysAdmin = false;
        this.adminTanzania = false;
        this.adminIreland = false;
        this.getTitleOfResorsesList = this.getTitleOfResorsesList.bind(this);
        this.getResourcesOfGroup = this.getResourcesOfGroup.bind(this);
        this.setResources = this.setResources.bind(this);
        this.AddNewResource = this.AddNewResource.bind(this);
        this.editGroup = this.editGroup.bind(this);
        this.backToResources = this.backToResources.bind(this);
        this.updatePage = this.updatePage.bind(this);
        this.getOtherGroups = this.getOtherGroups.bind(this);
        this.makeBlockSeeAlso = this.makeBlockSeeAlso.bind(this);
        this.hanlderClickItemList = this.hanlderClickItemList.bind(this);
    }

    componentDidMount() {
        if (isUserGlobalAdmin())
            this.globalAdmin = true;
        else if (isUserGlobalIrelandTanzaniaAdmin())
            this.levelCountrysAdmin = true;
        else if (isUserGlobalIrelandAdmin())
            this.adminIreland = true;
        else if (isUserGlobalTanzaniaAdmin())
            this.adminTanzania = true;
        var param = Object.create(this.props.match.params);
        this.getResourcesOfGroup(param.idResourse);
        this.idResourceGroup = Number(param.idResourse);

        this.props.history.listen((location: any, action: any) => {
            if (location.pathname.indexOf('/resource/group/') !== -1 && action === 'POP')
                this.getResourcesOfGroup(`${this.idResourceGroup}`);
        });
    }

    AddNewResource() {
        this.props.history.push(`/resources/group/${this.idResourceGroup}/addresource`); //add 05-01-22
        /*
        const addNewResorcePage:JSX.Element =
        <AddResourceGroupPage setResources={()=>{this.updatePage();}} idResource={this.idResourceGroup} addNewResource={true} />
        this.setResources(addNewResorcePage);
        */
    }

    editGroup() {
        this.props.history.push(`/resources/group/${this.idResourceGroup}/editgroup`); //add 05-01-22
        /*
        const editGroupPage:JSX.Element =
        <AddResourceGroupPage setResources={()=>{this.updatePage();}} content={this.resourceGroup} idResource={this.idResourceGroup} />
        this.setResources(editGroupPage);
        */
    }

    updatePage() {
        this.getResourcesOfGroup(`${this.idResourceGroup}`);
    }

    backToResources() {
        this.props.history.goBack();
        //	this.props.history.push('/resources');
    }

    async getResourcesOfGroup(idResourse: string) {
        try {
            var {data} = await getResourcesOfGroup(idResourse);
            await this.getTitleOfResorsesList(Number(idResourse));

            this.array_resourses = data.map((item: any, index: number) => {
                return {
                    //title: item.en_title,
                    //text: item.en_summary,
                    title: item.title,
                    text: item.summary,
                    id: item.id,
                    //	url: `/resources/group/${this.idResourceGroup}/resource/${item.id}`
                    url: "/resource/" + item.id
                };

            });
            //list other groups
            await this.getOtherGroups()
                      .then(async (list_groups) => {
                          await this.makeListOtherGroups(list_groups)
                                    .then(async (other_groups) => {
                                        await this.makeBlockSeeAlso(other_groups)
                                                  .then((blockSeeAlso) => {
                                                      this.blockSeeAlso = blockSeeAlso;
                                                      this.isLoading = false;
                                                      this.setResources();
                                                  });
                                    });
                      });

        }
        catch (error: any) {
            console.log('error',error)
            this.isLoading = false;
            console.log(`getResourcesOfGroup error status: ${error.response.status}`);

        }
    }

    async hanlderClickItemList(id: number) {
        await this.getResourcesOfGroup(`${id}`);
        this.props.history.push(`/resource/group/${id}/resources`);
    }

    async makeBlockSeeAlso(other_groups: Resources[]): Promise<JSX.Element> {
        return await new Promise<JSX.Element>(async (resolve) => {
            const listOfResources: JSX.Element[] = other_groups.map((item, index) => {
                return (
                    <li key={index} onClick={() => {
                        //this.getResourcesOfGroup(`${item.id}`);
                        this.hanlderClickItemList(item.id);
                    }}
                    >
                        <div className='content-resource-summary-list_resources-name'>
                            {item.title}
                        </div>
                        <div className='content-resource-summary-list_resources-name-forward'>
                            <IonIcon
                                icon={chevronForwardOutline}
                                className="forward_arrow"
                            />
                        </div>
                    </li>
                );
            });

            resolve(
                <ul className='content-resource-summary-list_resources'>
                    {listOfResources}
                </ul>
            );
        });
    }

    async getOtherGroups(): Promise<Resources[]> {
        return await new Promise<Resources[]>(async (resolve) => {
            await getResourceGroupsList()
                .then(({data}) => {
                    const array_groups: Resources[] = data.map((item: any, index: number) => {
                        return {
                            //title: item.en_title,
                            //text: item.en_summary,
                            title: item.title,
                            text: item.summary,
                            id: item.id,
                            //	url: "/resources/group/" + item.id + "/resources",
                            url: "/resource/group/" + item.id + "/resources"
                        };
                    });

                    resolve(array_groups);
                })
                .catch((error: any) => {
                    console.log(`error status: ${error.response.status}`);
                });
        });
    }

    async makeListOtherGroups(list: Resources[]): Promise<Resources[]> {
        return await new Promise<Resources[]>(async (resolve) => {
            let array_other_groups: Resources[] = [];
            list.forEach((item: any) => {
                if (item.title != this.page_title)
                    array_other_groups.push({...item});
            });
            resolve(array_other_groups);
        });
    }

    async getTitleOfResorsesList(idGroup: number) {
        try {
            var {data} = await getResourceGroup(`${idGroup}`);
            //this.resourses_description = data.en_summary;
            //this.page_title = data.en_title;
            this.resourses_description = data.summary;
            this.page_title = data.title;
            this.resourceGroup = {...data};
            if (this.resourceGroup.en_summary === null)
                this.resourceGroup.en_summary = '';
            if (this.resourceGroup.en_title === null)
                this.resourceGroup.en_title = '';
            if (this.resourceGroup.sw_summary === null)
                this.resourceGroup.sw_summary = '';
            if (this.resourceGroup.sw_title === null)
                this.resourceGroup.sw_title = '';
            //	console.log('this.resourceGroup=>',this.resourceGroup);
        }
        catch (error: any) {
            console.log(`getTitleOfResorsesList error status: ${error.response.status}`);
            if (error.response.status == 404)
                this.props.history.goBack();
        }
    }
    ionViewWillEnter () {
        const {dispatch} = this.props;
        dispatch(setHeaderActiveTab(2))
    }

    setResources() {
        const {t} = this.props;

        const contentPage: JSX.Element =

            <>
                <TitlePageTextTheme
                    theme={THEME_PAGE}
                    cssTitleScreenCommunity="resourse-title-page-text-green"
                >
                    <>
                        <IonButton color="light" id="back_to_resources_button" onClick={this.backToResources}>
                            <IonIcon slot="start" icon={chevronBackOutline}/>
                            <span>{t("resources")}</span>
                        </IonButton>

                        <div>{this.page_title}</div>
                    </>
                </TitlePageTextTheme>
                <div className="wrap_groups">
                    <ContainerPosts
                        //class_section="align-browse-news-wall2"
                        class_section="wrap_groups-news-wall"
                        theme={THEME_PAGE}
                        text={this.resourses_description}
                    >
                        <section>
                            <ShallPost cssSectionWall="community-page-css-section-wall">
                                <SimpleListOfLink theme={THEME_PAGE} links={this.array_resourses}/>
                            </ShallPost>
                            {this.globalAdmin ?
                                <div className='align-browse-news-wall-buttons-container'>
                                    <div className='align-browse-news-wall-button-edit'>
                                        <ButtonResource text_color='white'
                                                        onClickHandler={this.editGroup}>{t("editGroup")}</ButtonResource>
                                    </div>
                                    <div>
                                        <ButtonResource text_color='white'
                                                        onClickHandler={this.AddNewResource}>{t("addNewResource")}</ButtonResource>
                                    </div>
                                </div> :
                                null}
                            <div className='align-browse-news-wall-block-see-also'>
                                <div className='content-resource-summary-other-list'>
                                    <div className='content-resource-summary-other-list-title'>
                                        {t("alsoSee")}
                                    </div>
                                    {this.blockSeeAlso}
                                </div>
                            </div>
                        </section>
                    </ContainerPosts>
                </div>
                <IonLoadingPage isLoading={this.isLoading}/>
            </>


        this.setState({contentPage: contentPage});
    }

    render() {

        return (
            <div>
                {this.state.contentPage}
            </div>
        );
    }

}

const decoratedComponent =connect()(withTranslation("common")(withIonLifeCycle(ResourcesOfGroup)));
export {decoratedComponent as ResourcesOfGroup};
