import React, { ComponentProps } from 'react';
import {RouteComponentProps} from 'react-router-dom';
import { IonContent, IonPage } from "@ionic/react";
import SmallHeader from "../../components/smallHeader/SmallHeader";
import TitlePageTextGreen from "../../components/titlePageTextGreen/TitlePageTextGreen";
import WrapperOnTheForm from "../../components/wrapperOnTheForm/WrapperOnTheForm";
import CustomInput from "../../components/customInput/CustomInput";
import CustomRadioButton from "../../components/customRadioButton/customRadioButton";
import CustomTextAre from "../../components/customTextAre/CustomTextAre";
import CustomDropDown from "../../components/customDropDown/CustomDropDown";
import ButtonResource from "../../components/content/ButtonResource"
import { withTranslation, WithTranslation } from "react-i18next";
import {
    getCopmlianceStandardAll, getStandardsOfCommunity,
} from "../../services/Community";
import offlineActions from "../../services/actions";
import { standardsTrendsUpdateThunk } from "../../reducers/thunks/standardsTrendsRecalculate";
import "./NewCompliance.css";
import "../AddResourceGroupPage.css";
import "../ActionPlan/AddCommitment.css";
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {setStandardsDetails} from "../../reducers/standardsReducer";
import {connect} from "react-redux";
import newCommunityId from "../../helpers/newCommunityId";

type Function = {
    label: string,
    value: string
}

export type OptionDateGathering = {
    label: string,
    value: number
}

type Standard = {
    id: number,
    name: string,
    tupe_value: string,
    comparsion: string,
    numeric_value: number,
    boolean_value: boolean,
    country: number,
    theme: number
};

type NewComplianceProps = {};

type NewComplianceState = {
    choiceFunction: string,
    choiceFunctionCompliant: string,
    contentPage: ContentPage,
    standard_value: number,
    standard_name: string,
    dateGathering: OptionDateGathering[]
};

export type FilesGathering = {
    community_gathering: number,
    file: string,
    file_name: string,
    id: number,
    url: string
};

export type CommunityGthering = {
    attendees: any,
    community: number,
    date: string,
    files: FilesGathering[],
    id: any
};

export type StandardCompliance = {
    boolean_value: boolean | null,
    comparison: string,
    id: number,
    name: string,
    numeric_value: number,
    type_value: string
};

export type ComplianceGathering = {
    boolean_actual: boolean | null,
    community_gathering: CommunityGthering ,
    compliance: boolean,
    id: number,
    numeric_actual: number| null,
    remarks: string,
    standard: StandardCompliance
    rate: number,
    state: any
};

export type UpdateGathering = {
    id: number,
    standard: number,
    community_gathering: any,
    numeric_actual: number | null,
    boolean_actual: boolean | null,
    compliance: boolean,
    remarks: string,
    state: string,
    rate: number
};

type ContentPage = {
    boolean_actual: boolean | null,
    compliance: boolean,
    numeric_actual: number | null,
    remarks: string,
    date: string
};

class OfflineNewCompliance extends React.Component
    <
        NewComplianceProps &
        WithTranslation &
        ComponentProps<any> &
        RouteComponentProps<{}>,
        NewComplianceState
        > {
    arrayFunction: Function[];
    arrayFunctionCompliant: Function[];
    idCommunity: number;
    idStandard: number;
    idUpdate: number;
    arrayGathering: UpdateGathering[];
    indexUpdateGathering: number;

    constructor(props: any) {
        super(props);
        this.state = {
            choiceFunction: "add",
            choiceFunctionCompliant: "compliant",
            standard_value: 0,
            standard_name: '',
            dateGathering: [],
            contentPage: {
                boolean_actual: null,
                compliance: true,
                numeric_actual: 0,
                remarks: "",
                date: moment(new Date()).format('YYYY-MM-DD')
            }
        };
        this.arrayFunction = [
            {
                label: "Add New",
                value: "add"
            },
            {
                label: "Edit Existing",
                value: "edit"
            }
        ];
        this.arrayFunctionCompliant = [
            {
                label: "Compliant",
                value: "compliant"
            },
            {
                label: "Not Compliant",
                value: "notcompliant"
            }
        ];
        this.arrayGathering = [];
        this.idCommunity = 0;
        this.idStandard = 0;
        this.idUpdate = 0;
        this.indexUpdateGathering = 0;
        this.saveChanges = this.saveChanges.bind(this);
        this.onChangeRadioButtons = this.onChangeRadioButtons.bind(this);
        this.onChangeRadioButtonsCompliant =  this.onChangeRadioButtonsCompliant.bind(this);
        this.setContentPage = this.setContentPage.bind(this);
        this.selectedDateGathering = this.selectedDateGathering.bind(this);
        this.getGatheringStandarts = this.getGatheringStandarts.bind(this);
        this.createComplianceGathering = this.createComplianceGathering.bind(this);
        this.getUpdateGatheringId = this.getUpdateGatheringId.bind(this);
    }

    async componentDidMount() {
        const param = Object.create(this.props.match.params);
        this.idCommunity = Number(param.idCommunity);
        this.idStandard = Number(param.idStandard);
        this.idUpdate = Number(param.idUpdate);

        this.props.history.listen(async(location: any, action: any) => {
            const name: string = "/updateCompliance/";
            const index = location.pathname.indexOf(name);

            if (index !== -1 && action === "PUSH") {
                this.idUpdate = Number(location.pathname.slice(index + name.length));
                if (this.idUpdate !== 0) {
                    try {
                        await this.getUpdateGatheringId();
                    } catch (error: any) {
                        console.log(`getUpdateGatheringId error status: ${error}`);
                    }
                }
            }
        });

        try {
            await this.getCommunityStandards(this.idCommunity);
            await this.getGatheringStandarts();
            if (this.idUpdate !== 0) {
                this.setState({choiceFunction:"edit"});
            }
        } catch (error: any) {
            console.log(`getStandardById error status: ${error}`);
        }
    }

    async getCommunityStandards(id: number) {
        try {
            const {data} = await getStandardsOfCommunity(id);
            data.standards.forEach((item:any)=>{
                if (item.id === this.idStandard) {
                    this.setState({
                        standard_value:item.numeric_value,
                        standard_name:item.name
                    })
                }
            });
        } catch (error: any) {
            console.log(`getStandardsOfCommunity error status: ${error}`);
        }
    }

    async getUpdateGatheringId() {
        try {
            const data:any = this.arrayGathering.find(el => el.id === this.idUpdate);
            if (!data) {
                return;
            }
            const content:ContentPage = {
                ...this.state.contentPage,
                boolean_actual: data.boolean_actual,
                compliance: data.compliance,
                numeric_actual: data.numeric_actual,
                remarks: data.remarks,
                date: data.community_gathering?.date ||this.state.contentPage.date
            };
            this.setContentPage(content);
            this.onChangeRadioButtonsCompliant(data.compliance?"compliant":"notcompliant");
        } catch (error: any) {
            console.log(`getCopmlianceStandard error status: ${error}`);
        }
    }

    async fetchGatheringsStandards() {
        try {
            const { data, headers }: any = await getCopmlianceStandardAll(this.idCommunity,this.idStandard);

            const reduxData = this.props.standardsDetails[this.idStandard];

            if (headers.cached && reduxData) {
                return;
            }

            this.props.dispatch(setStandardsDetails({
                id: this.idStandard,
                updates: data
            }));
        } catch (error: any) {
            console.log(`getCopmlianceStandardAll error status: ${error}`);
        }
    }

    async getGatheringStandarts() {
        try {
            await this.fetchGatheringsStandards();
            const data = this.props.standardsDetails[this.idStandard] && this.props.standardsDetails[this.idStandard].updates;
            this.createComplianceGathering(data);
        } catch (error: any) {
            console.log(`getCopmlianceStandardAll error status: ${error}`);
        }
    }

    setContentPage(content:any) {
        this.setState({contentPage:content});
    }

    createComplianceGathering(arrayGathering: ComplianceGathering[]) {
        const arrayDateGathering: OptionDateGathering[] = [];
        this.arrayGathering = [];

        arrayGathering.forEach((item,index)=>{
            this.arrayGathering.push({
                id: item.id,
                standard: item.standard.numeric_value,
                community_gathering: item.community_gathering.id,
                numeric_actual: item.numeric_actual,
                boolean_actual: item.boolean_actual,
                compliance: item.compliance,
                rate: item.compliance ? 100 : 0,
                remarks: item.remarks,
                state:""
            });
            arrayDateGathering.push({
                label: item.community_gathering.date,
                value: index
            });

            if (item.id === this.idUpdate)
                this.indexUpdateGathering = index;
        });
        this.setState({dateGathering:arrayDateGathering});
    }

    async saveChanges() {
        const data = this.props.standardsDetails[this.idStandard] && this.props.standardsDetails[this.idStandard].updates;

        if (this.state.choiceFunction === "add") {
            const { id: community_gathering_id } = newCommunityId(this.idCommunity, this.state.contentPage.date);
            let newGathering = {
                ...this.state.contentPage,
                id: community_gathering_id,
                rate: this.state.contentPage.compliance ? 100 : 0,
                community_gathering: community_gathering_id,
            };

            const standardsUpdate = {
                ...newGathering,
                community_gathering: {
                    date: newGathering.date,
                    id: newGathering.id
                },
                state: newGathering.compliance ? { Pass: 'Pass' }: { Fail: 'Fail' },
                standard: {
                    id: this.idStandard
                },
            };

            this.props.dispatch(setStandardsDetails({
                id: this.idStandard,
                updates: data.concat(standardsUpdate)
            }));
            this.props.dispatch(standardsTrendsUpdateThunk(standardsUpdate));
            try {
                await this.props.dispatch(offlineActions.postComplianceAction({
                    idCommunity: this.idCommunity,
                    idStandard: this.idStandard,
                    data: newGathering,
                }));

            } catch (error: any) {
                console.log(`postCopmlianceStandard error status: ${error}`);
            }

            this.arrayGathering.push({
                id: community_gathering_id,
                standard: this.state.standard_value,
                community_gathering: community_gathering_id,
                numeric_actual: this.state.contentPage.numeric_actual,
                boolean_actual: this.state.contentPage.boolean_actual,
                compliance: this.state.contentPage.compliance,
                rate: this.state.contentPage.compliance ? 100 : 0,
                remarks: this.state.contentPage.remarks,
                state:""
            });

            this.setState({
                dateGathering: this.state.dateGathering.concat({
                    label: this.state.contentPage.date,
                    value: this.arrayGathering.length-1
                })
            });
            this.props.history.goBack();
        } else if (this.arrayGathering.length) {
            const updateGathering: UpdateGathering = {
                id: this.arrayGathering[this.indexUpdateGathering].id,
                standard: this.state.standard_value,
                community_gathering: this.arrayGathering[this.indexUpdateGathering].community_gathering,
                numeric_actual: this.state.contentPage.numeric_actual,
                boolean_actual: this.state.contentPage.boolean_actual,
                compliance: this.state.contentPage.compliance,
                rate: this.state.contentPage.compliance ? 100 : 0,
                remarks: this.state.contentPage.remarks,
                state: ""
            };


            const dataGathering =  data[this.indexUpdateGathering];
            dataGathering.numeric_actual = updateGathering.numeric_actual;
            dataGathering.boolean_actual = updateGathering.boolean_actual;
            dataGathering.compliance = updateGathering.compliance;
            dataGathering.remarks = updateGathering.remarks;
            dataGathering.rate = updateGathering.rate;
            dataGathering.state = dataGathering.compliance ? { Pass: 'Pass' }: { Fail: 'Fail' };
            this.props.dispatch(setStandardsDetails({
                id: this.idStandard,
                updates: data
            }));
            this.props.dispatch(standardsTrendsUpdateThunk(dataGathering));
            try {
                await this.props.dispatch(offlineActions.updateComplianceAction(
                    {
                        idCommunity: this.idCommunity,
                        idStandard: this.idStandard,
                        idUpdate: updateGathering.id,
                        data: updateGathering,
                    }
                ));
            } catch (error: any) {
                console.log(`updateCopmlianceStandard error status: ${error}`);
            }
            this.arrayGathering[this.indexUpdateGathering] = {...updateGathering};
            this.props.history.goBack();
        }
    }

    createRadioGroup(
        arrayFunction: Function[],
        onChangeHadler: (inputText: string) => void,
        state_function: string,
        nameOfGroup: string
    ):JSX.Element {
        const { t } = this.props;
        const radios:JSX.Element[] = arrayFunction.map((item:Function,index:number)=>{
            return(
                <div key={index++} className='custom-input-radio-wrap'>
                    <CustomRadioButton
                        id={item.value}
                        value={item.value}
                        cssSize="custom-input-radio-size"
                        checked={state_function === item.value?true:false}
                        name={nameOfGroup}
                        onChange={
                            (inputText:string) => {
                                onChangeHadler(inputText);
                            }
                        }
                    />
                    <label
                        className="community-update-label-in-row"
                        htmlFor={item.value}
                    >
                        {t(item.label)}
                    </label>
                </div>
            );
        });
        return (
            <div className='compliance-radio-group cusom-input-props'>
                {radios}
            </div>
        );
    }

    async onChangeRadioButtons(value: string) {
        this.setState({choiceFunction: value});
    }

    onChangeRadioButtonsCompliant(value: string) {
        const content:ContentPage = {...this.state.contentPage, compliance: value === "compliant"};
        this.setContentPage(content);
        this.setState({choiceFunctionCompliant: value});
    }

    selectedDateGathering(option: any) {
        this.indexUpdateGathering = option.value;
        const gathUpdate: UpdateGathering = this.arrayGathering[option.value];
        const content:ContentPage = {
            boolean_actual: gathUpdate.boolean_actual,
            compliance: gathUpdate.compliance,
            numeric_actual: gathUpdate.numeric_actual,
            remarks: gathUpdate.remarks,
            date: option.label
        };
        this.setContentPage(content);
        const compliance: string = gathUpdate.compliance?"compliant":"notcompliant";
        this.setState({choiceFunctionCompliant: compliance});
    }

    render() {
        const { t } = this.props;
        const add_resource_css: string = ' custom-tetxtarea-add-commitment';
        const content:ContentPage = {...this.state.contentPage};

        const buttonSave: JSX.Element = (
            <ButtonResource id="saveCompliance" onClickHandler={this.saveChanges}
                            color="light"
                            text_color="rgb(105, 113, 126)"
            >
                {t("save")}
            </ButtonResource>
        );

        const group_radio:JSX.Element = this.createRadioGroup(
            this.arrayFunction,
            this.onChangeRadioButtons,
            this.state.choiceFunction,
            "addGroup"
        );

        const group_radio_compliant:JSX.Element = this.createRadioGroup(
            this.arrayFunctionCompliant,
            this.onChangeRadioButtonsCompliant,
            this.state.choiceFunctionCompliant,
            "compliantGroup"
        );
        return(
            <IonPage>
                <IonContent>
                    <SmallHeader
                        rightLink={`/community/${this.idCommunity}/standard/${this.idStandard}`}
                        rightText={t("complianceUpdate")}
                    />
                    <TitlePageTextGreen cssTitleScreenCommunity="compliance-update-title-page">
                        <div className="community-update-header-text">
                            {this.state.standard_name}
                        </div>
                    </TitlePageTextGreen>
                    <WrapperOnTheForm
                        title={t("communityGathering")}
                        description={t("makeSureThisUpdate")}
                    >
                        <div className="community-update-row">
                            <div className='wrap-compliance-update-label'>
                                <label
                                    className="compliance-update-label-in-row"
                                    htmlFor="Date"
                                >
                                    {t("date")}
                                </label>
                            </div>
                            {group_radio}
                        </div>

                        <div className="community-update-row">
                            {
                                this.state.choiceFunction === "add"?
                                    <DatePicker className={"custom-date-picker update-picker"}
                                                value={this.state.contentPage.date}

                                                onChange={(date:Date) =>  this.setContentPage({...content,date:date.toLocaleDateString('en-CA')})}
                                                popperClassName="some-custom-class"
                                                popperPlacement="top-end"
                                                popperModifiers={[
                                                    {
                                                        name: "offset",
                                                        options: {
                                                            offset: [5, 10],
                                                        },
                                                    },
                                                    {
                                                        name: "preventOverflow",
                                                        options: {
                                                            rootBoundary: "viewport",
                                                            tether: false,
                                                            altAxis: true,
                                                        },
                                                    },
                                                ]}
                                    />
                                    :
                                    <CustomDropDown
                                        setValue={(option) => this.selectedDateGathering(option)}
                                        cssControl="cusom-input-props"
                                        options={this.state.dateGathering as any[]}
                                        defaultOption={
                                            this.idUpdate !== 0?
                                                this.state.dateGathering[this.indexUpdateGathering] as any:''
                                        }
                                    />
                            }
                        </div>
                    </WrapperOnTheForm>
                    <WrapperOnTheForm
                        title={t("compliance")}
                        description={t("addYourGroupsReport")}
                    >
                        <div className="community-update-row">
                            <div className='wrap-compliance-update-label'>
                                <label
                                    className="community-update-label-in-row"
                                    htmlFor="englishStandard"
                                >
                                    {t("standard")}
                                </label>
                            </div>
                            <div className='wrap-compliance-update-label'>
                                <div className='cusom-input-props standard-compliance-number'>
                                    {this.state.standard_value}
                                </div>
                            </div>
                        </div>
                        <div className="community-update-row">
                            <div className='compliance-line' />
                        </div>

                        <div className="community-update-row">
                            <label
                                className="community-update-label-in-row"
                                htmlFor="englishActual"
                            >
                                {t("actual")}
                            </label>
                            <CustomInput
                                cssInput="cusom-input-props"
                                id="englishActual"
                                value={this.state.contentPage.numeric_actual !== null?this.state.contentPage.numeric_actual.toString():"0"}
                                onChange={
                                    (inputText:string) => {
                                        this.setContentPage({...content,numeric_actual:Number(inputText)});
                                    }
                                }
                            />
                        </div>
                        <div className="community-update-row">
                            {group_radio_compliant}
                        </div>

                        <div className="community-update-row">
                            <label
                                className="community-update-label-in-row"
                                htmlFor="englishRemarks"
                            >
                                {t("remarks")}
                            </label>
                            <CustomTextAre
                                value={this.state.contentPage.remarks}
                                id="englishRemarks"
                                isShowCountLetter
                                onChange={
                                    (inputText:string) => {
                                        this.setContentPage({...content,remarks:inputText});
                                    }
                                }
                                cssTextAre={"cusom-input-props" + add_resource_css}
                            />
                        </div>
                    </WrapperOnTheForm>
                    <div className='section-compliance-buttons compliance-button-control'>
                        {buttonSave}
                    </div>
                </IonContent>
            </IonPage>
        );
    }
}
const mapState = (state: any) => ({ standardsDetails: state.standards.standardsDetails, standardsUpdatesDetails: state.standards.standardsUpdatesDetails })
const decoratedComponent =connect(mapState)(withTranslation("common")(OfflineNewCompliance));
export { decoratedComponent as OfflineNewCompliance };
