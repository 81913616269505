import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import { IonContent, IonPage } from "@ionic/react";
import { IonButton } from "@ionic/react";
import { IonIcon } from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";
import TitlePageTextTheme from "../components/titlePageTextTheme/titlePageTextTheme";
import WrapperOnTheForm from "../components/wrapperOnTheForm/WrapperOnTheForm";
import CustomInput from "../components/customInput/CustomInput";
import CustomTextAre from "../components/customTextAre/CustomTextAre";
import { THEMES } from "../mocks/layout";
import ButtonResource from "../components/content/ButtonResource"
import {
    getResourceGroupsList,
    addResourceGroup,   
} from '../services/Resources';
import './AddResourceGroupPage.css'
import { withTranslation, WithTranslation } from "react-i18next";

const THEME_PAGE = THEMES.BLUE;

type ContentPage = {
  
    en_title: string, 
    en_summary: string,
    sw_title: string, 
    sw_summary: string,
}

type AddResourceGroupPageProps = {
    
}

type AddResourceGroupPageState = {
    contentPage: ContentPage,
    resource_group: number,
};
  
  class AddResourceGroupPage extends React.Component<AddResourceGroupPageProps & WithTranslation & RouteComponentProps<{}>,AddResourceGroupPageState> {
    page_title: string;
    resource_group: number;   

    constructor(props: any) {
		super(props);
        this.state = {
            contentPage: {
                en_title: '', 
                en_summary: '',              
                sw_title: '', 
                sw_summary: '',               
            },          
            resource_group: 0,             
        };
        this.page_title = "";
        this.resource_group = 0;       
        this.setContentPage = this.setContentPage.bind(this);
        this.AddGroup = this.AddGroup.bind(this);
        this.Exit = this.Exit.bind(this);
        this.backToResources = this.backToResources.bind(this);       
    }

    async componentDidMount() {
        try {             
            const { data } = await getResourceGroupsList();
            this.resource_group = data.length;              
        }catch(error: any) {
               
            console.log(`error status: ${error.response.status}`);
        }		
  	}
    
    setContentPage(content:any) {
        this.setState({contentPage:content});              
    }

    backToResources() {       
        this.props.history.goBack();
    }

    Exit() {
        this.props.history.goBack();
    }

    async AddGroup() {
        if (!((this.state.contentPage.en_title.length && 
               this.state.contentPage.en_summary.length) ||
              (this.state.contentPage.sw_title.length && 
               this.state.contentPage.sw_summary.length)))
               return;
       
        await addResourceGroup(`${this.resource_group}`, {
            ...this.state.contentPage
        })
        .then(()=>{
            this.props.history.goBack();
        })
        .catch((error: any)=>{
            console.log(`error status: ${error.response.status}`);
        });       
    }
    
    render() {
        const { t } = this.props;
        this.page_title = t("addnewresgroup");

        const content:ContentPage = {...this.state.contentPage};
        const buttonAddUpdate:JSX.Element =          
            <ButtonResource 
                onClickHandler={this.AddGroup} 
                text_color="white"
            >
                {t("creategroup")}
            </ButtonResource>;
           
        const buttonDelCancel:JSX.Element =             
            <ButtonResource 
                color="light" 
                text_color='rgb(105, 113, 126)' 
                onClickHandler={this.Exit} 
            >
                {t("cancel")}
            </ButtonResource>;         

        return (
            <IonPage>                
                <IonContent>
                   
                    <TitlePageTextTheme
                        theme={THEME_PAGE}
                        cssTitleScreenCommunity="resourse-title-page-text-green"
                    >
                    <>
                        <div>
                        <IonButton color="light" 
                            id="back_to_resources_button" 
                            onClick={this.backToResources}
                        >
                            <IonIcon slot="start" icon={chevronBackOutline} />
                            <span>{t("resources")}</span>
                        </IonButton>
                        </div>
                        <div>{this.page_title}</div>
                    </>			  				   	   	            						    
                    </TitlePageTextTheme>
                    <WrapperOnTheForm
                        title={t("engdetails")}
                        description={t("provideengverscontant")}
                    >
                    <div className="community-update-row">
                        <label
                            className="community-update-label-in-row"
                            htmlFor="englishTitle"
                        >
                        {t("title")}
                        </label>
                        <CustomInput
                            cssInput="cusom-input-props"
                            id="englishTitle"
                            value={this.state.contentPage.en_title}
                            onChange={
                                (inputText:string) => {
                                    this.setContentPage({...content,en_title:inputText});
                                }
                                
                            }
                            isShowCountLetter
                        />
                    </div>
                    <div className="community-update-row">
                        <label
                            className="community-update-label-in-row"
                            htmlFor="englishUpdate"
                        >
                        {t("summary")}
                        </label>
                        <CustomTextAre
                            value={this.state.contentPage.en_summary}
                            id="englishUpdate"
                            isShowCountLetter
                            onChange={
                                (inputText:string) => {
                                    this.setContentPage({...content,en_summary:inputText});  
                                }
                                
                            }
                            cssTextAre={"cusom-input-props"}
                        />
                    </div>                
                    </WrapperOnTheForm>
                    <WrapperOnTheForm
                        title={t("swahilidetails")}
                        description={t("availprovidetransl")}
                    >
                    <div className="community-update-row">
                        <label
                            className="community-update-label-in-row"
                            htmlFor="swahiliTitle"
                        >
                        {"Title"}
                        </label>
                        <CustomInput
                            cssInput="cusom-input-props"
                            id="swahiliTitle"
                            value={this.state.contentPage.sw_title}
                            onChange={
                                (inputText:string) => {
                                    this.setContentPage({...content,sw_title:inputText});
                                }
                                
                            }
                        isShowCountLetter
                        />
                    </div>
                    <div className="community-update-row">
                        <label
                            className="community-update-label-in-row"
                            htmlFor="swahiliUpdate"
                        >
                        {t("summary")}
                        </label>
                        <CustomTextAre
                            value={this.state.contentPage.sw_summary}
                            id="swahiliUpdate"
                            isShowCountLetter
                            onChange={
                                (inputText:string) => {
                                    this.setContentPage({...content,sw_summary:inputText});
                                }
                                
                            }
                            cssTextAre={"cusom-input-props"}
                        />
                    </div>           
                    </WrapperOnTheForm>                                                                     
                    <div className='section-add-resource-button'>
                        <div className='resource-button-cancel-container'>  
                        {buttonDelCancel}                                                  
                        </div>
                        <div className='resource-button-add-container'>
                        {buttonAddUpdate}                                                                                                            
                        </div>
                    </div>
                </IonContent>               
            </IonPage>
        );
    }
  }

  const decoratedComponent = withTranslation("common")(AddResourceGroupPage);
  export { decoratedComponent as AddResourceGroupPage };