import {createAction} from "redux-actions";

const middlewareResolver: any = {
};

const generatorAsync = (name:string, resolver: any) => {
    middlewareResolver[name] = resolver;
    return createAction(name,
        (
            argsObj: any
        ) => argsObj);
};

export const middlewareCheck = (store: any) => (next: any) => (action: any) => {
    if (!middlewareResolver[action.type]) {
        return next(action);
    }
    return middlewareResolver[action.type](action.payload);
}

export default generatorAsync;