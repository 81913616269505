import React, { Children } from 'react';
import { withTranslation, WithTranslation } from "react-i18next";
import ButtonResource from "./ButtonResource"
import "./ConfirmBox.css"
import "./animate.css"

interface actionRender {
    (state: string): void;
}

type ConfirmBoxProps = {
    children: React.ReactNode;
    actionRender: actionRender;
    effectIn?: string;
    effectOut?: string;
    effectOutDel?: string;
    actionButtonText?:string | null;
}

type ConfirmBoxState = {
    class_box: string;
}

class ConfirmBox extends React.Component<ConfirmBoxProps & WithTranslation,ConfirmBoxState> {
    isCloseState: string;
    class_box: string;

    constructor(props: any) {
		super(props);
        this.isCloseState = '';
        this.class_box = !this.props.effectIn?
        'confirm-box':
        'confirm-box animate__animated animate__'+this.props.effectIn;
        this.state = {class_box:this.class_box};
        this.clickButton = this.clickButton.bind(this);
        this.AnimationEnd = this.AnimationEnd.bind(this);
    }

    AnimationEnd() { 
        if (this.isCloseState != '') {
            
            this.props.actionRender(this.isCloseState);
        }           
    }

    clickButton(event: any) {
        this.isCloseState = event.target.textContent;
        
        if (this.props.effectOutDel && this.isCloseState !== "Cancel")
            this.setState({class_box:'confirm-box animate__animated animate__'+this.props.effectOutDel});
        else
        if (this.props.effectOut)
            this.setState({class_box:'confirm-box animate__animated animate__'+this.props.effectOut});
        else
            this.props.actionRender(this.isCloseState);       
    }

    render() {
        const { t } = this.props;      
        let css_action:string = this.props.actionButtonText?" confirm-box-subcontainer-button-delete-custom":"";
    
        return(
            <div className='modal-confirm-box'>
                <div className='confirm-box-container'>
                    <div className='confirm-box-banner'>
                        <div className='confirm-box-banner-name'>
                            {t("confirmDuplication")}
                        </div>
                    </div>
                </div>
                <div className={this.state.class_box} onAnimationEnd={this.AnimationEnd}>
                    <div className='confirm-box-subcontainer'>
                        <div className='confirm-box-subcontainer-mess-but'>
                            <div className='confirm-box-subcontainer-message'>
                                <div className='confirm-box-subcontainer-message-1'>
                                    <div className='confirm-box-subcontainer-message-1-up'>
                                        {this.props.children}
                                    </div>
                                    <div className='confirm-box-subcontainer-message-1-down'>                                  
                                        {t("cannotBeUndone")}
                                    </div>
                                </div>

                            </div>
                            <div className='confirm-box-subcontainer-buttons'>
                                <div className='confirm-box-subcontainer-buttons-content'>
                                   <div className='confirm-box-subcontainer-button-cancel'>
                                        <ButtonResource color="light" text_color='rgb(107, 114, 128)' onClickHandler={this.clickButton} >
                                            {t("cancel")}
                                        </ButtonResource>  
                                   </div>
                                   <div className={'confirm-box-subcontainer-button-delete' + css_action}>
                                        <ButtonResource onClickHandler={this.clickButton} text_color="white">
                                            {this.props.actionButtonText?t(this.props.actionButtonText):t("delete")}
                                        </ButtonResource>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const decoratedComponent = withTranslation("common")(ConfirmBox);
export { decoratedComponent as ConfirmBox };