import React, { useEffect } from "react";
import TitlePageTextTheme from "../../../components/titlePageTextTheme/titlePageTextTheme";
import GoBack from "../../../components/goBack/GoBack";
import {useTranslation} from "react-i18next";
import CommitmentCard from "../../../components/commitmentCard/CommitmentCard";
import {useHistory, useParams} from "react-router-dom";
import {THEMES} from "../../../mocks/layout";
import "./CommunityCommitment.css";
import {useDispatch, useSelector} from "react-redux";
import {setHeaderActiveTab} from "../../../reducers/headerReducer";
import {useIonViewWillEnter} from "@ionic/react";
import {AppState} from "../../../reducers";
import {middlewareGetCommunityActionPlan} from "../../../services/middleware/MiddlewareCommunity";
import {setActionPlanDescription} from "../../../reducers/actionPlansReducer";
import ArrowLeftAndRight from "../../../components/arrowLeftAndRight/ArrowLeftAndRight";

const PAGE_THEME = THEMES.GREEN;
const OfflineCommunityCommitment: React.FC = () => {
    const {t} = useTranslation("common");
    const history = useHistory();
    const {idCommunity, idActionPlan} =
        useParams<{ idCommunity: string; idActionPlan: string }>();
    const commitment = useSelector((state: AppState) => state.actionPlans.actionPlansDescription[idActionPlan]) || { def: true };
    const actionPlans = useSelector((state: AppState) => state.actionPlans.actionPlansData&& state.actionPlans.actionPlansData.action_plans);
    const title = commitment.title || '';

    const moveOtherSentiment = (moveRight: boolean) => {
        if (actionPlans&&actionPlans.length > 1) {
            const findIndex = actionPlans.findIndex(
                (item: any) => item.id === +idActionPlan
            );
            if (moveRight) {
                if (actionPlans[findIndex + 1]) {
                    history.push(
                        `/community/${idCommunity}/action_plan/${
                            actionPlans[findIndex + 1].id
                        }`
                    );
                } else {
                    history.push(
                        `/community/${idCommunity}/action_plan/${actionPlans[0].id}`
                    );
                }
            } else {
                if (actionPlans[findIndex - 1]) {
                    history.push(
                        `/community/${idCommunity}/action_plan/${
                            actionPlans[findIndex - 1].id
                        }`
                    );
                } else {
                    history.push(
                        `/community/${idCommunity}/action_plan/${
                            actionPlans[actionPlans.length - 1].id
                        }`
                    );
                }
            }
        }
    };


    const dispatch = useDispatch();

    const fetchData = async () => {
        const responce = await middlewareGetCommunityActionPlan(
            idCommunity,
            idActionPlan
        );

        if (responce.cached && !commitment.def) {
            return;
        }

        dispatch(setActionPlanDescription(responce))
    }

    useEffect(() => {
        fetchData();
    }, []);

    useIonViewWillEnter(() => {
        dispatch(setHeaderActiveTab(1))
    }, []);

    return (
        <>

            <div className="community-gathering-compliance-title-page-wrap">
                <div className="community-gathering-compliance-title-page-wrap-upper">
                    <GoBack urlMove={`/community/${idCommunity}/ListActionPlans`} text={"My Community Action PLans"}/>
                    <div className="community-sentiment-arrow-left-and-right">
                        <ArrowLeftAndRight
                            onClick={(direct) => moveOtherSentiment(direct)}/>
                    </div>
                </div>
                <div className="community-gathering-compliance-title-page-wrap-down">
                    <div className="community-gathering-compliance-title-page-wrap-down-name-standard">
                        {title}
                    </div>
                </div>
            </div>
            <section className="community-commitment-centered">
                <CommitmentCard
                    {...commitment}
                    headerText=""
                    theme={PAGE_THEME}
                    idCommunity={idCommunity}
                    idActionPlan={idActionPlan}/>
            </section>
        </>

    );
};

export default OfflineCommunityCommitment;
