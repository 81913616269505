import React, {useState, useEffect} from "react";
import "./CommunitySentiment.css";
import {useTranslation} from "react-i18next";
import IonLoadingPage from "../../../components/ionLoadingPage/IonLoadingPage";
import TitlePageTextTheme from "../../../components/titlePageTextTheme/titlePageTextTheme";
import GoBack from "../../../components/goBack/GoBack";
import StatusList from "../../../components/statusList/StatusList";
import ListLinkStatus from "../../../components/listLinkStatus/ListLinkStatus";
import {StandardStatusT} from "../../../types/types";
import {middlewareGetCommunitySentiment} from "../../../services/middleware/MiddlewareCommunity";
import {useHistory, useParams} from "react-router-dom";
import {
    ALL,

} from "../../../mocks/layout";
import {getObjectsValue} from "../../../helpers/functions";
import faceSorrowfulImage from "../../../assets/images/indicators-color/face-sorrowful.svg";
import faceJubilantImage from "../../../assets/images/indicators-color/face-jubilant.svg";
import facePositiveImage from "../../../assets/images/indicators-color/face-positive.svg";
import faceNegativeImage from "../../../assets/images/indicators-color/face-negative.svg";
import faceNeutralImage from "../../../assets/images/indicators-color/face-neutral.svg";
import faceSorrowfulImageNoColor from "../../../assets/images/indicators/face-sorrowful.svg";
import faceJubilantImageNoColor from "../../../assets/images/indicators/face-jubilant.svg";
import facePositiveImageNoColor from "../../../assets/images/indicators/face-positive.svg";
import faceNegativeImageNoColor from "../../../assets/images/indicators/face-negative.svg";
import faceNeutralImageNoColor from "../../../assets/images/indicators/face-neutral.svg";
import CustomButtonNavLink from "../../../components/customButton/customButtonNavLink/CustomButtonNavLink";
import {THEMES} from "../../../mocks/layout";
import {
    getAccessUserToCommunity,
} from "../../../helpers/functions";
import {AppState} from "../../../reducers";
import {useDispatch, useSelector} from "react-redux";
import { setHeaderActiveTab } from "reducers/headerReducer";
import {useIonViewWillEnter} from "@ionic/react";
import CommunityChart from "../../../components/communityCharts/communityChart";
import {setSentiments} from "../../../reducers/sentimentsReducer";

let statusArray: any = [
    {
        text: ALL,
    },
];

let chartDiagram = {
    type: 2,
    downText: "",
    optionSentiment: {
        barWidth: 37,
        community_sentiment: {
            badly: 30,
            unsatisfactory: 40,
            satisfactory: 50,
            good: 60,
            excelent: 70,
        },
        commitments: 0,
        level: 0,
        type_chart: 2,
        section_name: "", // t("communitySentiment")
        backColor: "#3ea551",
        colorBar: "white",
        backgroundColorSvg: "#3ea551",
    },
};
const PAGE_THEME = THEMES.GREEN;

const OfflineCommunitySentiment: React.FC = () => {
    const {t} = useTranslation("common");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [statusIndex, setStatusIndex] = useState(0);
    const [listLinks, setListLinks] = useState<StandardStatusT[]>([]);
    const [allListLinks, setAllListLinks] = useState<StandardStatusT[]>([]);
    const {idCommunity} = useParams<{ idCommunity: string }>();
    const [isShowCreateBtn, setIsShowCreateBtn] = useState<boolean>(false);
    const lastLevel = useSelector((state: AppState) => state.community.lastLevel);
    const lastId = useSelector((state: AppState) => state.community.lastId);
    const sentimentsData = useSelector((state: AppState) => state.sentiments.sentimentsData);
    const [chartData, setChartData] = useState({});
    const [chartInfo, setChartInfo]= useState({completed:0,active:0})
    const userCommunity = useSelector((state: AppState) => state.user.community);

    const history = useHistory();
    const dispatch = useDispatch();

    useIonViewWillEnter(() => {
        dispatch(setHeaderActiveTab(1))
    }, []);

    useEffect(() => {
        statusArray = [
            {
                text: ALL,
            },
            {
                text: t("veryGood"),
                name: 'very good',
                image: faceJubilantImageNoColor,
            },
            {
                text: t("good"),
                name: 'good',
                image: facePositiveImageNoColor,
            },
            {
                text: t("ok"),
                name: 'ok',
                image: faceNeutralImageNoColor,
            },
            {
                text: t("bad"),
                name: 'bad',
                image: faceNegativeImageNoColor,
            },
            {
                text: t("veryBad"),
                name: 'very bad',
                image: faceSorrowfulImageNoColor,
            },
        ];

        fetchSentiment();
    }, [idCommunity]);

    useEffect(() => {
        const responce = sentimentsData;
        chartDiagram.optionSentiment.community_sentiment =
            generationCommunitySentiment(responce.rate_all);
        if (responce.state) {
            chartDiagram.downText = responce.state[Object.keys(responce.state)[0]];
        } else {
            chartDiagram.downText = "";
        }
        const completedSentiments =  responce.sentiments?.filter((sentiment:any) => sentiment&&sentiment.rate>0).length
        setChartInfo({completed:completedSentiments, active: responce.sentiments?.length})
        setChartData(responce)
        setSentimentList(responce.sentiments);
    }, [sentimentsData]);

    const fetchSentiment = async () => {
        const responceIsUser = await getAccessUserToCommunity(idCommunity); // getAccessUserToCommunity
        setIsShowCreateBtn(responceIsUser);
        const responce = await middlewareGetCommunitySentiment(idCommunity);
        if (responce.isError) {
            history.goBack();
        }
        if (responce.cached && !sentimentsData.def) {
            return;
        }
        dispatch(setSentiments(responce));
    };

    const generationCommunitySentiment = (array: number[] = [0,0,0,0,0]) => {
        let objectSentiment = {
            badly: 0,
            unsatisfactory: 0,
            satisfactory: 0,
            good: 0,
            excelent: 0,
        };

        if(array&&array.length){
            array = normalizationNumberArray(array);
            objectSentiment.badly = array[0];
            objectSentiment.unsatisfactory = array[1];
            objectSentiment.satisfactory = array[2];
            objectSentiment.good = array[3];
            objectSentiment.excelent = array[4];
        }

        return objectSentiment;
    };

    const normalizationNumberArray = (array: number[]) => {
        const maxNumber = Math.max(...array);
        const normalizeArrayNumber = array.map((item) => {
            return (item * 100) / maxNumber;
        });
        return normalizeArrayNumber;
    };

    const onClickFilterBtn = (id: number) => {
        if (!id) {
            setListLinks(allListLinks);
        } else {
            const newList = allListLinks.filter((item) => item.state.toLowerCase() === statusArray[id].name);
            setListLinks(newList);
        }
        setStatusIndex(id);
    };

    const setSentimentList = async (sentiments: any[]) => {
        const listSentiment: any = await sentiments?.map((item: any) => {
            return {
                id: item.id,
                name: item.name,
                nameCss: item.state ? getColorText(getObjectsValue(item.state)) : "",
                description: item.description,
                rate: item.rate,
                state: item.state ? getObjectsValue(item.state) : "",
                img: item.state ? getNormalImage(getObjectsValue(item.state)) : "",
            };
        });
        setAllListLinks(listSentiment);
        setListLinks(listSentiment);
    };

    const getNormalImage = (state: string) => {
        switch (state.toLowerCase()) {
            case "Very Good".toLowerCase():
                return faceJubilantImage;
            case "Good".toLowerCase():
                return facePositiveImage;
            case "Ok".toLowerCase():
                return faceNeutralImage;
            case "Bad".toLowerCase():
                return faceNegativeImage;
            case "Very Bad".toLowerCase():
                return faceSorrowfulImage;
        }
    };

    const getColorText = (state: string) => {
        switch (state.toLowerCase()) {
            case "Very Good".toLowerCase():
                return "state-text-color-jubilant";
            case "Good".toLowerCase():
                return "state-text-color-positive";
            case "Ok".toLowerCase():
                return "state-text-color-neutral";
            case "Bad".toLowerCase():
                return "state-text-color-negative";
            case "Very Bad".toLowerCase():
                return "state-text-color-sorrowful";
            default:
                return "";
        }
    };

    const onClickListItem = (id: number) => {
        history.push(`/community/${idCommunity}/sentiments/${id}`);
    };

    const LINK_TO_CREATE_NEW_SENTIMENT = `/community/${idCommunity}/sentiments/new/perfomance`;
    const LINK_TO_BACK = `/community/my`;
    return (

        <>
            <div className={"list-action-plans-title-page-wrap" }>
                <div
                    className={"list-action-plans-title-left-block" }
                >
                    <div className="display-flex">
                        <GoBack urlMove={LINK_TO_BACK} text={"My Community"}/>
                    </div>
                    <div
                        className={"list-action-plans-title-left-block-name"}
                    >
                        {t("communitySentiment")}
                    </div>
                </div>
                <CommunityChart
                    data={chartData }
                    info={chartInfo}
                    showSentiment={true}
                />
            </div>
            <section className="community-stabdards-align">
                <>
                    <div className="align-status-list-media">
                        <StatusList
                            list={statusArray}
                            active={statusIndex}
                            onClick={onClickFilterBtn}/>
                    </div>
                    {!listLinks.length && (
                        <section className="list-links-status-section">
                            <div className="sentiments-not-found-centered">
                                {t("sentimentsNotFound")}
                            </div>
                            {isShowCreateBtn && (
                                <div
                                    className="community-sentiment-create-btn community-sentiment-create-btn-margin-in-empty-list">
                                    <CustomButtonNavLink
                                        to={LINK_TO_CREATE_NEW_SENTIMENT}
                                        theme={PAGE_THEME}
                                        text={t("createNew")}/>
                                </div>
                            )}
                        </section>
                    )}
                    {!!listLinks.length && (
                        <div>
                            <ListLinkStatus
                                listLinks={listLinks}
                                onClick={onClickListItem}/>
                            {isShowCreateBtn && (
                                <div className="community-sentiment-create-btn">
                                    <CustomButtonNavLink
                                        to={LINK_TO_CREATE_NEW_SENTIMENT}
                                        theme={PAGE_THEME}
                                        text={t("createNew")}/>
                                </div>
                            )}
                        </div>
                    )}
                </>
            </section>
            <IonLoadingPage isLoading={isLoading}/>
        </>

    );
};

export default OfflineCommunitySentiment;
