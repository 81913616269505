import React from "react";
import "./TitlePageTextTheme.css";
import { getTitlePageTextStyle } from "../../theme/CombineThemes";
import { THEMES } from "../../mocks/layout";

type Props = {
  children: React.ReactNode;
  cssTitleScreenCommunity?: string;
  theme?: string;
};

const TitlePageTextTheme: React.FC<Props> = ({
  children,
  cssTitleScreenCommunity,
  theme = THEMES.GREEN,
}) => {
  const titleTheme = () => {
    return getTitlePageTextStyle(theme);
  };

  return (
    <>
      <div
        className={`title-screen-community-theme ${
          cssTitleScreenCommunity && cssTitleScreenCommunity
        } ${titleTheme()}`}
      >
        {children}
      </div>
    </>
  );
};

export default TitlePageTextTheme;
