import React, {useState} from "react";
import "./communityChart.css";
import {IonImg, IonItem, IonLabel} from "@ionic/react";
import currentInactiveIcon from "../../assets/images/charts-control/current-community-inactive.svg";
import currentIcon from "../../assets/images/charts-control/current-community.svg";
import trendIcon from "../../assets/images/charts-control/trend-community.svg";
import trendInactiveIcon from "../../assets/images/charts-control/trend-community-inactive.svg";
import PieChartComponent from "../chartsComponents/pieChart";
import LinearDiagramChartComponent from "../chartsComponents/lineDiagram";
import TinyBarChartComponent from "../chartsComponents/tinyBarChart";

const CommunityChart: React.FC<any> = ({
    data,
    info,
    showSentiment
}) => {
    const [showHistory, setHistory] = useState(false);

    const plainInfo = (info.completed||0)+'/'+(info.active||0)

    let trend_direction
    if (data&&data.trend_direction) {
        let keys = Object.keys(data.trend_direction);
        let key: string = keys[0];
        trend_direction = data.trend_direction[key];
    } else {
        trend_direction = "";
    }

    return (
        <div className={"list-action-plans-title-right-block "} >
            <div className={"list-action-plans-title-right-block-conteiner-chart" } >
                {/*Chart*/}
                <div className={"list-action-plans-title-right-block-conteiner-chart-wrap" } >
                    {!showHistory&&!showSentiment ?
                        data&&data.rate?<PieChartComponent color={"white"} backgroundColor={"rgb(57, 150, 74)"} data={data.rate||0} />:<></>
                            :
                        data&&data.rate_all&&!showHistory?< TinyBarChartComponent color={"white"} data={data} />:<></>
                     }
                    {showHistory&&data&&data.trend_all?<LinearDiagramChartComponent data={data} showSentiment={showSentiment} color={"white"} sentimentColor={"white"} />:<></>}
                </div>
                {/*End Chart*/}
                <div className="list-action-plans-title-right-block-conteiner-chart-line"/>
                <div className="items-baseline list-action-plans-title-right-block-conteiner-chart-info list-action-plans-title-right-block-conteiner-chart-info-arch-current"  >
                    <div className="list-action-plans-title-right-block-conteiner-chart-info-text">
                        {!showHistory?plainInfo:trend_direction}
                    </div>
                    <div className="flex">
                        <IonItem className={"community-charts-controls-item" +(!showHistory ? ' active':'')}  onClick={() => setHistory(false)}>
                            <IonImg src={showHistory?currentInactiveIcon:currentIcon} />
                        </IonItem>
                        <IonItem className={"community-charts-controls-item" +(showHistory ? ' active':'')} onClick={() => setHistory(true)}>
                            <IonImg src={showHistory?trendIcon:trendInactiveIcon} />
                        </IonItem>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommunityChart;
