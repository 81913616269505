import React, { useState, useEffect } from "react";
import { IonContent, IonPage } from "@ionic/react";
import "./CommunitySentimentId.css";
import SmallHeader from "../../../components/smallHeader/SmallHeader";
import TitlePageTextTheme from "../../../components/titlePageTextTheme/titlePageTextTheme";
import WrapperOnTheForm from "../../../components/wrapperOnTheForm/WrapperOnTheForm";
import { useTranslation } from "react-i18next";
import CustomRadioButton from "../../../components/customRadioButton/customRadioButton";
import {
    CommunityMeasureT,
    CommunityGatheringT,
    CropDownOptionsT,
    ScoreCards,
} from "../../../types/types";
import CustomDropDown from "../../../components/customDropDown/CustomDropDown";
import CustomTextAre from "../../../components/customTextAre/CustomTextAre";
import FaceInput from "../../../components/faceInput/FaceInput";
import { useParams, useHistory } from "react-router-dom";
import faceSorrowfulImage from "../../../assets/images/indicators-color/face-sorrowful.svg";
import faceJubilantImage from "../../../assets/images/indicators-color/face-jubilant.svg";
import facePositiveImage from "../../../assets/images/indicators-color/face-positive.svg";
import faceNegativeImage from "../../../assets/images/indicators-color/face-negative.svg";
import faceNeutralImage from "../../../assets/images/indicators-color/face-neutral.svg";
import {
    middlewareGetCommunitySentimentIdUpdates,
    middlewareGetCommunityId,
} from "../../../services/middleware/MiddlewareCommunity";
import CustomButton from "../../../components/customButton/CustomButton";
import { THEMES } from "../../../mocks/layout";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../reducers";
import {setSentimentsDetails} from "../../../reducers/sentimentsReducer";
import DatePicker from "react-datepicker";
import offlineActions from "../../../services/actions";
import idHelper from "../../../helpers/newCommunityId";
import {sentimentUpdateDetailsThunk} from "../../../reducers/thunks/sentimentsThunksRecalculate";

const PAGE_THEME = THEMES.GREEN;
const SHOW_FIRST_ITEM_ID = 1;
const CommunitySentimentId: React.FC = () => {
    const { t } = useTranslation("common");
    const [defaultOption, setDefaultOption] = useState<CropDownOptionsT>();
    const [emptyScoreCard, setEmptyScoreCard] = useState<ScoreCards>({
        id: 0,
        rating_very_bad: 0,
        rating_bad: 0,
        rating_ok: 0,
        rating_good: 0,
        rating_very_good: 0,
        remarks: "",
        proposals: "",
        respondent_type: "", // set type
    });
    const [responcenTypes, setResponcenTypes] = useState<string[]>([]);
    const [currentScoreCards, setCurrentScoreCards] = useState<ScoreCards[]>([]);
    const [optionsDate, setOptionsDate] = useState([
        {
            label: "Label 1",
            value: "Value 1",
        },
        {
            label: "Label 2",
            value: "Value 2",
        },
        {
            label: "Label 3",
            value: "Value 3",
        },
    ]);
    const { idCommunity, idSentiment } =
        useParams<{ idCommunity: string; idSentiment: string }>();
    const history = useHistory();
    const [communityMeasure, setCommunityMeasure] = useState<CommunityMeasureT>({
        id: -1,
        isAddNewUser: false,
        date: new Date().toLocaleDateString('en-CA'),
    });
    const SentimentGatherings = useSelector((state: AppState) =>  state.sentiments.sentimentsDetails[idSentiment])

    const communityGartherings = SentimentGatherings.updates;
    const dispatch = useDispatch()

    useEffect(() => {
        getCommunityMeasure();
    }, []);

    useEffect(() => {
        prepareData();
    }, [SentimentGatherings]);

    const getCommunityMeasure = async () => {
        // get community by id

        const responceCommunityId = await middlewareGetCommunityId(idCommunity);
        if (responceCommunityId.isError) {
            history.goBack();
        } else {
            setResponcenTypes(responceCommunityId.respondent_types);
        }

        const responceSentimentIdUpdates =
            await middlewareGetCommunitySentimentIdUpdates(idCommunity, idSentiment);

        if (responceSentimentIdUpdates.cached && SentimentGatherings) {
            return;
        }

        dispatch(setSentimentsDetails({
            id:idSentiment,
            updates:responceSentimentIdUpdates.community_gatherings
        }))
    };
    const prepareData = async () => {
        if (!SentimentGatherings) {
            history.goBack();
        } else {
            await generationDropDownOptions(
                communityGartherings,
                responcenTypes
            );
        }

    };

    const generationDropDownOptions = async (
        paramsGatherings: any = [],
        types: string[]
    ) => {
        const newOptionsValue: CropDownOptionsT[] = await paramsGatherings.map(
            (item: any) => {
                return {
                    label: item.date,
                    value: item.id,
                };
            }
        );
        // second value
        const localDefaultOptionsValue =
            paramsGatherings[paramsGatherings.length - 1];

        if (localDefaultOptionsValue) {
            setDefaultOption({
                label: localDefaultOptionsValue.date,
                value: localDefaultOptionsValue.id,
            });
        } else {
            setDefaultOption({
                label: "",
                value: "0",
            });
        }

        setOptionsDate(newOptionsValue);
        setScoreCards(+idSentiment, paramsGatherings, types);
    };

    const setScoreCards = (
        id: number,
        localCommunityGartherings: any = communityGartherings,
        types: string[] = responcenTypes
    ) => {
        const localDefaultOptionsValue = localCommunityGartherings.find(
            (item: any) => item.id === id
        );

        if (localDefaultOptionsValue) {
            setCommunityMeasure({
                ...communityMeasure,
                date: localDefaultOptionsValue.date,
                id: localDefaultOptionsValue.id,
                isAddNewUser: false,
            });
        } else {
            if (localCommunityGartherings.length) {
                setCommunityMeasure({
                    ...communityMeasure,
                    date: new Date().toLocaleDateString('en-CA'),
                    id: localCommunityGartherings[0].id,
                    isAddNewUser: false,
                });
            } else {
                setCommunityMeasure({
                    ...communityMeasure,
                    date: new Date().toLocaleDateString('en-CA'),
                    id: 0,
                    isAddNewUser: true,
                });
            }
        }

        /// change tmpTypes to types
        const tmpTypes = types; // ["BOYS", "GIRLS", "MAN"];

        let scoreCards: any = [];
        tmpTypes.forEach((itemType: string) => {
            let findElement;
            try {
                findElement = localDefaultOptionsValue.score_cards.find(
                    (item: any) => item.respondent_type === itemType
                );
            } catch (e) {
                findElement = null;
            }
            if (findElement) {
                scoreCards.push(findElement);
            } else {
                const newElement = {
                    ...emptyScoreCard,
                    respondent_type: itemType,
                };
                scoreCards.push(newElement);
            }
        });
        setCurrentScoreCards(scoreCards); // localDefaultOptionsValue.score_cards
    };

    const changeDateGathering = (gatheringDate: any) => {
        setScoreCards(gatheringDate.value);
    };

    const clearForms = () => {
        const newScoreCards = responcenTypes.map((item: string, index: number) => {
            return {
                ...emptyScoreCard,
                respondent_type: item,
                id: index,
            };
        });
        setCurrentScoreCards(newScoreCards);
    };

    const changeRadionToNewDate = (isAddNew: boolean) => {
        clearForms();
        if (isAddNew) {
            setCommunityMeasure({
                id: -1,
                date: new Date().toLocaleDateString('en-CA'),
                isAddNewUser: true,
            });
        } else {
            setScoreCards(SHOW_FIRST_ITEM_ID);
        }
    };

    const saveBtn = async () => {

        if (communityMeasure.isAddNewUser) {
            // add

            const { id } = idHelper(idCommunity, communityMeasure.date);
            const sendDate = {
                id,
                sentiment: idSentiment,
                date: communityMeasure.date,
                score_cards: currentScoreCards,
            };

            dispatch(offlineActions.postSentimentUpdateAction({
                idCommunity,
                idSentiment,
                dataObject:sendDate
            }));

            dispatch(sentimentUpdateDetailsThunk(sendDate))

        } else {
            // edit
            const idUpdate = communityMeasure.id;
            const sendDate = {
                id: idUpdate,
                sentiment: idSentiment,
                date: communityMeasure.date ,
                score_cards: currentScoreCards,
            };

            dispatch(offlineActions.putSentimentUpdateAction({
                idCommunity,
                idSentiment,
                idUpdate,
                dataObject:sendDate
            }))

            dispatch(sentimentUpdateDetailsThunk(sendDate))

        }

        history.goBack()
    };

    const generationTitleText = (word: string) => {
        return (
            t(word.toLocaleLowerCase()).charAt(0).toUpperCase() +
            t(word.toLocaleLowerCase()).slice(1).toLocaleLowerCase()
        );
    };

    const generationText = (word: string) => {
        return (
            t("addTheScorecardInformationFromThe") +
            " " +
            t(word.toLocaleLowerCase()).toLocaleLowerCase() +
            " " +
            t("reportingGroup")
        );
    };

    return (
        <IonPage>
            <IonContent>
                <SmallHeader isGoBack rightText={t("scorecard")} />
                <TitlePageTextTheme>
                    <div className="community-update-header-text">
                        {t("newMeasureWithATypicalNameLength")}
                    </div>
                </TitlePageTextTheme>
                <WrapperOnTheForm
                    title={t("communityGathering")}
                    description={t("makeSureThisUpdate")}
                >
                    <div className="community-update-row margin-top-label-unset">
                        <label
                            className="community-update-label-in-row"
                            htmlFor="englishTitle"
                        >
                            {t("date")}
                        </label>
                        <div className="community-measure-radio-block cusom-input-props">
                            <label className="community-measure-radio-block margin-right-custom-radion-btn">
                                <CustomRadioButton
                                    id={"1"}
                                    value={"1"}
                                    cssSize="custom-input-radio-size"
                                    checked={communityMeasure.isAddNewUser}
                                    onChange={(inputText: string) => changeRadionToNewDate(true)}
                                />
                                <div>{t("addNew")}</div>
                            </label>
                            <label className="community-measure-radio-block">
                                <CustomRadioButton
                                    id={"2"}
                                    value={"2"}
                                    cssSize="custom-input-radio-size"
                                    checked={!communityMeasure.isAddNewUser}
                                    onChange={(inputText: string) => changeRadionToNewDate(false)}
                                />
                                <div>{t("useExisting")}</div>
                            </label>
                        </div>
                    </div>
                    <div className="community-update-row">
                        <label className="community-update-label-in-row"></label>
                        {communityMeasure.isAddNewUser && (
                            <DatePicker className={"custom-date-picker update-picker"}
                                        value={ communityMeasure.date || new Date().toLocaleDateString('en-CA')}
                                        onChange={(date:Date) =>  setCommunityMeasure({...communityMeasure,date:date.toLocaleDateString('en-CA')})}
                                        popperClassName="some-custom-class"
                                        popperPlacement="top-end"
                                        popperModifiers={[
                                            {
                                                name: "offset",
                                                options: {
                                                    offset: [5, 10],
                                                },
                                            },
                                            {
                                                name: "preventOverflow",
                                                options: {
                                                    rootBoundary: "viewport",
                                                    tether: false,
                                                    altAxis: true,
                                                },
                                            },
                                        ]}
                            />

                        )}
                        {!communityMeasure.isAddNewUser && (
                            <CustomDropDown
                                setValue={(option) => changeDateGathering(option)}
                                defaultOption={defaultOption}
                                options={optionsDate}
                                cssControl="cusom-input-props"
                            />
                        )}
                    </div>
                </WrapperOnTheForm>
                {currentScoreCards.map((item: ScoreCards, index: number) => (
                    <WrapperOnTheForm
                        title={generationTitleText(item.respondent_type)}
                        description={generationText(item.respondent_type)}
                        key={item.respondent_type + "-" + index}
                    >
                        <div className="community-update-row community-measure-face-input-align-block">
                            <label
                                className="community-update-label-in-row"
                                htmlFor="swahiliUpdate"
                            >
                                {t("scorecardRatings")}
                            </label>
                            <div className="cusom-input-props display-flex-align-face-blocks face-input-margin-between">
                                <FaceInput
                                    image={faceSorrowfulImage}
                                    value={item.rating_very_bad}
                                    onChange={(count) => {
                                        let newScoreCards = [...currentScoreCards];
                                        newScoreCards[index] = {
                                            ...currentScoreCards[index],
                                            rating_very_bad: count,
                                        };
                                        setCurrentScoreCards(newScoreCards);
                                    }}
                                />
                                <FaceInput
                                    image={faceNegativeImage}
                                    value={item.rating_bad}
                                    onChange={(count) => {
                                        let newScoreCards = [...currentScoreCards];
                                        newScoreCards[index] = {
                                            ...currentScoreCards[index],
                                            rating_bad: count,
                                        };
                                        setCurrentScoreCards(newScoreCards);
                                    }}
                                />
                                <FaceInput
                                    image={faceNeutralImage}
                                    value={item.rating_ok}
                                    onChange={(count) => {
                                        let newScoreCards = [...currentScoreCards];
                                        newScoreCards[index] = {
                                            ...currentScoreCards[index],
                                            rating_ok: count,
                                        };
                                        setCurrentScoreCards(newScoreCards);
                                    }}
                                />
                                <FaceInput
                                    image={facePositiveImage}
                                    value={item.rating_good}
                                    onChange={(count) => {
                                        let newScoreCards = [...currentScoreCards];
                                        newScoreCards[index] = {
                                            ...currentScoreCards[index],
                                            rating_good: count,
                                        };
                                        setCurrentScoreCards(newScoreCards);
                                    }}
                                />
                                <FaceInput
                                    image={faceJubilantImage}
                                    value={item.rating_very_good}
                                    onChange={(count) => {
                                        let newScoreCards = [...currentScoreCards];
                                        newScoreCards[index] = {
                                            ...currentScoreCards[index],
                                            rating_very_good: count,
                                        };
                                        setCurrentScoreCards(newScoreCards);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="community-update-row">
                            <label
                                className="community-update-label-in-row"
                                htmlFor="swahiliUpdate"
                            >
                                {t("remarks")}
                            </label>
                            <CustomTextAre
                                value={item.remarks}
                                id="swahiliUpdate"
                                isShowCountLetter
                                onChange={(inputText) => {
                                    let newScoreCards = [...currentScoreCards];
                                    newScoreCards[index] = {
                                        ...currentScoreCards[index],
                                        remarks: inputText,
                                    };
                                    setCurrentScoreCards(newScoreCards);
                                }}
                                cssTextAre="cusom-input-props"
                            />
                        </div>
                        <div className="community-update-row">
                            <label
                                className="community-update-label-in-row"
                                htmlFor="swahiliUpdate"
                            >
                                {t("proposals")}
                            </label>
                            <CustomTextAre
                                value={item.proposals}
                                id="swahiliUpdate"
                                isShowCountLetter
                                onChange={(inputText) => {
                                    let newScoreCards = [...currentScoreCards];
                                    newScoreCards[index] = {
                                        ...currentScoreCards[index],
                                        proposals: inputText,
                                    };
                                    setCurrentScoreCards(newScoreCards);
                                }}
                                cssTextAre="cusom-input-props"
                            />
                        </div>
                    </WrapperOnTheForm>
                ))}
                <div className="container-post-update-btn margin-bottom-in-tablet">
                    <div></div>
                    <div className="community-sentiment-id-container-btn">
                        <CustomButton
                            theme={PAGE_THEME}
                            onClick={saveBtn}
                            text={t("save")}
                        />
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default CommunitySentimentId;
