import moment from "moment";

const uid = function(){
    return +(Date.now().toString().slice(6) + Math.floor(Math.random() * 90 + 10));
}

const idHelper = function (communityId: any, date?: string, moduleName = 'gathering') {
    const currentDate = date || moment(new Date()).format("YYYY-MM-DD");

    let id;

    if (moduleName === 'gathering') {
        id = +('' + communityId + parseInt(currentDate.replaceAll('-', '').slice(2)));
    } else {
        id = uid();
    }

    return {
        id,
        date: currentDate,
    };
}
export default idHelper;