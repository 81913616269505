import React from 'react';
import "./content.css";
import MakeChart from './MakeChart'
import ChartDescription from './ChartDescription'
import { withTranslation, WithTranslation } from "react-i18next";
import PieChartComponent from "../../components/chartsComponents/pieChart";

type StandartsComplienceProps = {
  country:string,
  progress:number,
  children: JSX.Element
  state_santiments: {
    evaluation:any,
    linear_points:number[]
  }
}
type StandartsComplienceState = {
  ring: JSX.Element|null;
};

class StandartsComplience extends React.Component<WithTranslation & StandartsComplienceProps,StandartsComplienceState> {
  setOption: {
    type: number,
    colorInRing: string,
    colorRing: string,
    colorProgress: string,
    widthRing: number,
    percent: number,
    fontSizeText: string,
    colorText: string
  }
  constructor(props: any) {
    super(props);
    this.setOption = {
      type: 1,/*1-circle,2-столбик*/
      colorInRing: 'white',
      colorRing: 'rgb(209, 213, 219)',
      colorProgress: 'blue',
      widthRing: 0,
      percent: this.props.progress,
      fontSizeText: '',
      colorText: 'blue'
    };
    this.state = {ring:null};
    this.onResize = this.onResize.bind(this);
    this.setSizeRing = this.setSizeRing.bind(this);
  }

  onResize(event:any) {
    this.setSizeRing();
  }

  componentDidMount() {
    window.addEventListener("resize", this.onResize);
    this.setSizeRing();

  }
  setSizeRing() {
    var ring:JSX.Element = <MakeChart setOption={this.setOption}/>;
    if (this.props.state_santiments.evaluation != null) {

        const keys:string[] = Object.keys(this.props.state_santiments.evaluation);

        if (keys.length != 0) {

          if (keys[0].toLowerCase() === 'improving'||
              keys[0].toLowerCase() === 'accelerating' ||
              keys[0].toLowerCase() === 'good') {

              this.setOption.colorText = 'rgb(62, 165, 81)';
              this.setOption.colorProgress = 'rgb(62, 165, 81)';
          }else
          if (keys[0].toLowerCase() === 'stalled') {

              this.setOption.colorText = 'rgb(231, 181, 0)';
              this.setOption.colorProgress = 'rgb(231, 181, 0)';
          }
          else if (keys[0].toLowerCase() === 'declining' ||
                   keys[0].toLowerCase() === 'bad' ||
                   keys[0].toLowerCase() === 'poor' ) {

            this.setOption.colorText = 'rgb(219, 128, 46)';
            this.setOption.colorProgress = 'rgb(219, 128, 46)';

          }
        }
    }

    if (window.outerWidth <= 790 && this.setOption.widthRing!=20) {
      this.setOption.widthRing = 20;
      this.setOption.fontSizeText = '18px';
      this.setState({ring:ring});
    }else {

      if (window.outerWidth > 790) {
        this.setOption.widthRing = 25;

        if (window.outerWidth <= 1366 && this.setOption.fontSizeText != '20px') {
          this.setOption.fontSizeText = '20px';
          this.setState({ring:ring});
        }
        else if (window.outerWidth > 1366 && this.setOption.fontSizeText != '24px') {
          this.setOption.fontSizeText = '24px';
          this.setState({ring:ring});
        }

      }
    }
  }


  render() {
    var descript_el:JSX.Element|null = null;
    const { t } = this.props;

    if (!this.props.progress)
      descript_el = <ChartDescription wrap_container="subcontainer_linear_compliance">
                      <div className="decript_commit"><span className="str_align">{t("notYetEvaluated")}</span></div>
                    </ChartDescription>;
    else
    if (this.props.state_santiments.evaluation != null || this.props.state_santiments.linear_points.length)
      descript_el = <ChartDescription state_santiments={this.props.state_santiments} wrap_container="subcontainer_linear_compliance"/>

    return(
      <div className='complience_block1'>
        <div className="name_of_block_compliance">
          <div className="name_of_block1">
          {this.props.children}
          </div>
        </div>
        <div className="coniainer_linear_compliance">
          {descript_el}
        </div>
        <div className="wrap_chart">
          <div className="ring_container">
            <PieChartComponent color={this.setOption.colorProgress} data={this.props.progress||0}></PieChartComponent>
          </div>
          <div className="ring_container_compliance">
            {this.state.ring}
          </div>
        </div>
      </div>
    );
  }
}
const decoratedComponent = withTranslation("common")(StandartsComplience);
export { decoratedComponent as StandartsComplience };
