import React, {useEffect, useState} from "react";
import TitlePageTextTheme from "../../../components/titlePageTextTheme/titlePageTextTheme";
import GoBack from "../../../components/goBack/GoBack";
import {useTranslation} from "react-i18next";
import {middlewareGetCommunityActionPlanUpdates} from "../../../services/middleware/MiddlewareCommunity";
import {useParams} from "react-router-dom";
import {THEMES} from "../../../mocks/layout";
import {useHistory} from "react-router-dom";
import WrapperOnTheForm from "../../../components/wrapperOnTheForm/WrapperOnTheForm";
import CustomRadioButton from "../../../components/customRadioButton/customRadioButton";
import CustomDropDown from "../../../components/customDropDown/CustomDropDown";
import {getObjectsValue, normalizeText} from "../../../helpers/functions";
import {ActionPLanUpdateT} from "../../../types/types";
import { getActionPlanInfo} from "../../../services/actionPlan";
import CustomButton from "../../../components/customButton/CustomButton";
import { setHeaderActiveTab } from "reducers/headerReducer";
import {useDispatch, useSelector} from "react-redux";
import {useIonViewWillEnter} from "@ionic/react";
import {AppState} from "../../../reducers";
import { setActionPlanDetails} from "../../../reducers/actionPlansReducer";
import DatePicker from "react-datepicker";
import offlineActions from "../../../services/actions";
import idHelper from "../../../helpers/newCommunityId";
import {actionPlanUpdateDetailsThunk} from "../../../reducers/thunks/actionPlansThunksRecalculate";
import NewCommunityId from "../../../helpers/newCommunityId";

const FIRST_START_ELEMENT_ID = 0;
const PAGE_THEME = THEMES.GREEN;
const OfflineCommunityActionPlanUpdate: React.FC = () => {
    const {t} = useTranslation("common");
    const history = useHistory();
    const {idCommunity: idCommunityParam, idActionPlan: idActionPlanParam} =
        useParams<{ idCommunity: string; idActionPlan: string }>();

    const idActionPlan = +idActionPlanParam;
    const idCommunity = +idCommunityParam;
    const actionPlanUpdates = useSelector((state: AppState) =>  state.actionPlans.actionPlansDetails[idActionPlan]);
    const [actionPlanInfo, setActionPlanInfo] = useState<any>({data:{status:[]}});
    const [actionPlanUpdate, setActionPlanUpdate] = useState<ActionPLanUpdateT>({
        id: 0,
        isAddNew: false,
        newDate: new Date().toLocaleDateString('en-CA'),
        listDate: [],
        status: "",
        listStatus: [],
        activeDate: {
            label: "",
            value: "",
        },
    });

    const gatherings = actionPlanUpdates && actionPlanUpdates.updates || [];

    const dispatch = useDispatch();

    useIonViewWillEnter(() => {
        dispatch(setHeaderActiveTab(1))
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        prepareData();
    }, [actionPlanInfo, actionPlanUpdates]);

    const fetchActionPlanInfo = async () => {
        try {
            const data = await getActionPlanInfo();
            setActionPlanInfo(data);
        } catch (error: any) {
            console.log(`getCopmlianceStandardAll error status: ${error}`);
        }
    }
    const fetchActionPlanDetails = async () => {
        try {
            const { data, headers }: any = await middlewareGetCommunityActionPlanUpdates(idCommunityParam, idActionPlanParam);

            if (headers.cached && actionPlanUpdates) {
                return;
            }

            data.sort((a: { id: number; }, b: { id: number; }) => b.id - a.id);

            dispatch(setActionPlanDetails({
                id:idActionPlan,
                updates:data
            }));
        } catch (error: any) {
            console.log(`getCopmlianceStandardAll error status: ${error}`);
        }
    }

    const fetchData = async () => {
        try {
            await Promise.all([fetchActionPlanInfo(), fetchActionPlanDetails()]);
        }
        catch (error) {
            history.goBack();
        }
    };

    const prepareData =  () => {
        let newStatus = "";
        let newListOfStatus = [];
        let newListDate: any = [];
        let newId: number = 0;

        newListDate = gatherings.map((item: any) => {
            return {
                label: item.community_gathering.date,
                value: item.community_gathering.id,
            };
        })
        newStatus =
            gatherings.length > 0
                ? normalizeText(gatherings[FIRST_START_ELEMENT_ID].status)
                : "";
        newId = gatherings.length > 0 ? gatherings[FIRST_START_ELEMENT_ID].id : 0;
        newListOfStatus = actionPlanInfo.data.status.map((item: any) => {
            return {
                label: getObjectsValue(item),
                value: getObjectsValue(item),
            };
        });


        setActionPlanUpdate({
            ...actionPlanUpdate,
            listStatus: newListOfStatus,
            status: newStatus,
            listDate: newListDate,
            id: newId,
            activeDate: newListDate[FIRST_START_ELEMENT_ID],
        });

    };

    const setDateByIndex = (index: number, newActiveDate: any) => {
        const newStatus = normalizeText(actionPlanUpdates.updates[index].status);
        const newId = actionPlanUpdates.updates[index].id;

        setActionPlanUpdate({
            ...actionPlanUpdate,
            status: newStatus,
            id: newId,
            activeDate: newActiveDate,
        });
    };

    const handlerDateDropDown = (option: any) => {
        const findIndex = actionPlanUpdate.listDate.findIndex(
            (item: any) => item.value === option.value
        );
        setDateByIndex(findIndex, option);
    };

    const setStatus = (option: any) => {
        setActionPlanUpdate({
            ...actionPlanUpdate,
            status: option.value,
        });
    };

    const saveBtn = async () => {
        if (actionPlanUpdate.isAddNew)
        {
            const data = {
                date: actionPlanUpdate.newDate,
                status: actionPlanUpdate.status.toUpperCase(),
            };
            const { id } = idHelper(idCommunity, actionPlanUpdate.newDate);
            const { id:updateId } = NewCommunityId(idCommunity, actionPlanUpdate.newDate, 'updateId');

            const newGathering = {
                action_plan : idActionPlan,
                id:updateId,
                status: actionPlanUpdate.status.toUpperCase(),
                community_gathering:{
                    id,
                    date:actionPlanUpdate.newDate,
                    attendees: null ,
                    community: idCommunity,
                }
            };

            gatherings.push(newGathering);

            dispatch(setActionPlanDetails({
                id:idActionPlan,
                updates: [...gatherings]
            }))

            dispatch(offlineActions.updateNewActionPlanAction(
                {
                    idCommunity,
                    idPlan:idActionPlan,
                    data
                }
            ))
            dispatch(actionPlanUpdateDetailsThunk(newGathering))
            history.goBack()
        }
        else {
            const sendDate = {
                id: actionPlanUpdate.id,
                status: actionPlanUpdate.status.toUpperCase(),
                community_gathering: actionPlanUpdate.activeDate.value,
                action_plan: idActionPlan,
            };

            const hasGathering = gatherings.find((el: any)=>el.id ===  actionPlanUpdate.id);
            hasGathering.status = actionPlanUpdate.status.toUpperCase();

            dispatch(setActionPlanDetails({
                id:idActionPlan,
                updates: [...gatherings]
            }))

            dispatch(offlineActions.updateExitedActionPlanAction(
                {
                    idCommunity,
                    idPlan:idActionPlan,
                    idUpdate:actionPlanUpdate.id,
                    data:sendDate
                }
            ))
            dispatch(actionPlanUpdateDetailsThunk(hasGathering))
            history.goBack()

        }
    };

    return (

        <>
            <TitlePageTextTheme cssTitleScreenCommunity="resourse-title-page-text-green">
                <>
                    <div>
                        <GoBack isGoBack text={t("exit")}/>
                    </div>
                    <div className="community-update-header-text">{t("resourcesHeader")}</div>
                </>
            </TitlePageTextTheme><WrapperOnTheForm
            title={t("communityGathering")}
            description={t("makeSureThisUpdate")}
        >
            <div className="community-update-row margin-top-label-unset">
                <label
                    className="community-update-label-in-row"
                    htmlFor="englishTitle"
                >
                    {t("date")}
                </label>
                <div className="community-measure-radio-block cusom-input-props">
                    <label className="community-measure-radio-block margin-right-custom-radion-btn">
                        <CustomRadioButton
                            id={"1"}
                            value={"1"}
                            cssSize="custom-input-radio-size"
                            checked={actionPlanUpdate.isAddNew}
                            onChange={(inputText: string) => setActionPlanUpdate({
                                ...actionPlanUpdate, isAddNew: true
                            })}/>
                        <div>{t("addNew")}</div>
                    </label>
                    <label className="community-measure-radio-block">
                        <CustomRadioButton
                            id={"2"}
                            value={"2"}
                            cssSize="custom-input-radio-size"
                            checked={!actionPlanUpdate.isAddNew}
                            onChange={(inputText: string) => setActionPlanUpdate({
                                ...actionPlanUpdate,
                                isAddNew: false,
                            })}/>
                        <div>{t("editExisting")}</div>
                    </label>
                </div>
            </div>
            <div className="community-update-row">
                <label className="community-update-label-in-row"></label>
                {actionPlanUpdate.isAddNew && (
                    <DatePicker className={"custom-date-picker update-picker"}
                                value={ actionPlanUpdate.newDate}
                                onChange={(date:Date) =>  setActionPlanUpdate({...actionPlanUpdate,newDate:date.toLocaleDateString('en-CA')})}
                                popperClassName="some-custom-class"
                                popperPlacement="top-end"
                                popperModifiers={[
                                    {
                                        name: "offset",
                                        options: {
                                            offset: [5, 10],
                                        },
                                    },
                                    {
                                        name: "preventOverflow",
                                        options: {
                                            rootBoundary: "viewport",
                                            tether: false,
                                            altAxis: true,
                                        },
                                    },
                                ]}
                    />
                )}
                {!actionPlanUpdate.isAddNew && (
                    <CustomDropDown
                        setValue={(option) => handlerDateDropDown(option)}
                        defaultOption={actionPlanUpdate.activeDate}
                        options={actionPlanUpdate.listDate}
                        cssControl="cusom-input-props"/>
                )}
            </div>
        </WrapperOnTheForm><WrapperOnTheForm
            title={t("currentStatus")}
            description={t("ifTheCommitmentHasBeen")}
        >
            <div className="community-update-row">
                <label className="community-update-label-in-row">
                    {t("status")}
                </label>
                <CustomDropDown
                    setValue={(option) => setStatus(option)}
                    defaultOption={actionPlanUpdate.status}
                    options={actionPlanUpdate.listStatus}
                    cssControl="cusom-input-props"/>
            </div>
        </WrapperOnTheForm>
            <div className="container-post-update-btn margin-bottom-in-tablet">
                <div></div>
                <div className="community-sentiment-id-container-btn">
                    <CustomButton
                        theme={PAGE_THEME}
                        text={t("save")}
                        onClick={saveBtn}/>
                </div>
            </div>
        </>

    );
};

export default OfflineCommunityActionPlanUpdate;
