export interface ActionPlansState {
    actionPlansData: any;
    actionPlansDetails: any
    actionPlansDescription: any
}

export const initialState: ActionPlansState = {
    actionPlansData: {
        trend: [],
        rate: 0,
        trend_all: [],
        trendDirection: { 'Good': 'Good'},
        action_plans: [],
        def: true
    },
    actionPlansDetails:{},
    actionPlansDescription: {},
};

const SET_ACTION_PLANS = "SET_ACTION_PLANS";
const SET_ACTION_PLANS_DETAILS = "SET_ACTION_PLANS_DETAILS";
const SET_ACTION_PLANS_DESCRIPTION = "SET_ACTION_PLANS_DESCRIPTION";
const SET_ACTION_PLANS_DETAILS_DELETE = "SET_ACTION_PLANS_DETAILS_DELETE";
const SET_ACTION_PLANS_DESCRIPTION_DELETE = "SET_ACTION_PLANS_DESCRIPTION_DELETE";

export default function sentimentsReducer(state = initialState, action: any) {
    switch (action.type) {
        case SET_ACTION_PLANS:
            return {
                ...state,
                actionPlansData: {
                    ...action.payload
                },
            };
        case SET_ACTION_PLANS_DETAILS:
            return {
                ...state,
                actionPlansDetails: {
                    ...state.actionPlansDetails,
                    [action.payload.id]: action.payload
                },
            };
        case SET_ACTION_PLANS_DESCRIPTION:
            return {
                ...state,
                actionPlansDescription: {
                    ...state.actionPlansDescription,
                    [action.payload.id]: action.payload
                },
            };
        case SET_ACTION_PLANS_DETAILS_DELETE:
            const actionPlansDetails = state.actionPlansDetails;

            delete actionPlansDetails[action.payload.id];
            return {
                ...state,
                actionPlansDetails: actionPlansDetails,
            };
        case SET_ACTION_PLANS_DESCRIPTION_DELETE:
            const actionPlansDescription = state.actionPlansDescription;

            delete actionPlansDescription[action.payload.id];
            return {
                ...state,
                actionPlansDescription: actionPlansDescription,
            };
        default:
            return state;
    }
}

export const setActionPlans = (actionPlans: any) => ({
    type: SET_ACTION_PLANS,
    payload: actionPlans,
});
export const setActionPlanDetails = (actionPlansDetails: any) => ({
    type: SET_ACTION_PLANS_DETAILS,
    payload: actionPlansDetails,
});
export const setActionPlanDescription = (actionPlanDescription: any) => ({
    type: SET_ACTION_PLANS_DESCRIPTION,
    payload: actionPlanDescription,
});
export const setActionPlansDescriptionDelete = (actionPlanDetails: any) => ({
    type: SET_ACTION_PLANS_DESCRIPTION_DELETE,
    payload: actionPlanDetails,
});
export const setActionPlanDetailsDelete = (actionPlanDetails: any) => ({
    type: SET_ACTION_PLANS_DETAILS_DELETE,
    payload: actionPlanDetails,
});
