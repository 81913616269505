import { markActionsOffline } from 'redux-offline-queue'
import generatorAsync from './generatorAsync'

import {
    postCopmlianceStandard, updateCopmlianceStandard,
} from "services/Community";
import {
    addActionPlan,
    updateActionPlanById,
    deleteActionPlanById
} from "services/actionPlan";
import {middlewatePutActionPlanIdUpdateId, middlewatePostActionPlanIdUpdateNew} from "../middleware/MiddlewareActionPlan";
import {
    middlewarePostCommunitySentimentIdUpdates,
    middlewarePostPerformanceMeasure,
    middlewarePutCommunitySentimentIdUpdates,
    middlewarePutPerformanceMeasure,
    middlewarePostCommunityGatheringsGetOffline
} from "../middleware/MiddlewareCommunity";

/*Standarts*/
const postComplianceAction = generatorAsync('postComplianceStandard',async (
    argsObj: any
) => {
    const {idCommunity, idStandard, data} = argsObj;

    const response = await postCopmlianceStandard(idCommunity,
        idStandard,
        data);
    return response.data;
});

const updateComplianceAction: any = generatorAsync('updateComplianceStandard',
     async (
        argsObj: any
    ) => {
        const {
            idCommunity,
            idStandard,
            idUpdate,
            data
        } = argsObj;

        const response = await updateCopmlianceStandard(
            idCommunity,
            idStandard,
            idUpdate,
            data
        );
        return response.data;
    });
/* END Standarts*/


/* Action PLans*/
const editActionPlanAction = generatorAsync('updateActionPlanById',async (
    argsObj: any
) => {
    const {
        idCommunity,
        idPlan,
        data
    } = argsObj;

    const response = await updateActionPlanById(
        idCommunity,
        idPlan,
        data
    );
    return response.data;
});
const addActionPlanAction = generatorAsync('addActionPlanAction',async (
    argsObj: any
) => {
    const {
        idCommunity,
        idPlan,
        data
    } = argsObj;

    const response = await addActionPlan(
        idCommunity,
        idPlan,
        data
    );
    return response.data;
});
const addActionPlanGatheringAction = generatorAsync('addActionPlanGatheringAction',async (
    argsObj: any
) => {
    const {
        idCommunity,
        date,
        id,
    } = argsObj;

    const response = await middlewarePostCommunityGatheringsGetOffline(
        idCommunity,
        date,
        id
    );
    return response.data;
});

const updateExitedActionPlanAction = generatorAsync('middlewatePutActionPlanIdUpdateId',async (
    argsObj: any
) => {
    const {
        idCommunity,
        idPlan,
        idUpdate,
        data
    } = argsObj;

    const response = await middlewatePutActionPlanIdUpdateId(
        idCommunity,
        idPlan,
        idUpdate,
        data
    );
    return response.data;
});

const updateNewActionPlanAction = generatorAsync('middlewatePostActionPlanIdUpdateNew',async (
    argsObj: any
) => {
    const {
        idCommunity,
        idPlan,
        data
    } = argsObj;


    const response = await middlewatePostActionPlanIdUpdateNew(
        idCommunity,
        idPlan,
        data
    );
    return response.data;
});

const removeActionPlanAction = generatorAsync('deleteActionPlanById',async (
    argsObj: any
) => {
    const {
        idCommunity,
        idPlan,
    } = argsObj;


    const response = await deleteActionPlanById(
        idCommunity,
        idPlan,
    );
    return response.data;
});

/* END Action PLans*/



/* Sentiments*/
const postSentimentPerformanceAction = generatorAsync('middlewarePostPerformanceMeasure',async (
    argsObj: any
) => {


    const response = await middlewarePostPerformanceMeasure(
        argsObj
    );
    return response.data;
});

const putSentimentPerformanceAction = generatorAsync('middlewarePutPerformanceMeasure',async (
    argsObj: any
) => {
    const {
        id,
        objectData
    } = argsObj;


    const response = await middlewarePutPerformanceMeasure(
        id,
        objectData
    );
    return response.data;
});

const postSentimentUpdateAction = generatorAsync('middlewarePostCommunitySentimentIdUpdates',async (
    argsObj: any
) => {
    const {
        idCommunity,
        idSentiment,
        dataObject
    } = argsObj;


    const response = await middlewarePostCommunitySentimentIdUpdates(
        idCommunity,
        idSentiment,
        dataObject
    );
    return response.data;
});

const putSentimentUpdateAction = generatorAsync('middlewarePutCommunitySentimentIdUpdates',async (
    argsObj: any
) => {
    const {
        idCommunity,
        idSentiment,
        idUpdate,
        dataObject
    } = argsObj;


    const response = await middlewarePutCommunitySentimentIdUpdates(
        idCommunity,
        idSentiment,
        idUpdate,
        dataObject
    );
    return response.data;
});

/* END Sentiments*/


const Creators = {
    updateComplianceAction,
    postComplianceAction,


    editActionPlanAction,
    updateExitedActionPlanAction,
    updateNewActionPlanAction,
    addActionPlanAction,
    addActionPlanGatheringAction,
    removeActionPlanAction,


    postSentimentPerformanceAction,
    putSentimentPerformanceAction,
    postSentimentUpdateAction,
    putSentimentUpdateAction,

}

markActionsOffline(Creators, Object.keys(Creators))

export default Creators;
