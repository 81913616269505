import React from "react";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { IonIcon } from "@ionic/react";

import "./IconTabs.css";

const IconTabs: React.FC<any> = (props: any) => {
  const history = useHistory();
  const NAV_LINKS: any[] = props.NAV_LINKS;
  const activeTab: any = props.activeTab;

  return (
    <>
      <section className="footer-tabs-section-tab">
        <div className="footer-tabs-height footer-tabs-fixed footer-tabs-custom-section">
          {NAV_LINKS.map((item, index) => {
            return (
              <NavLink
                key={index}
                to={item.link}
                className={`footer-tabs-link-block ${
                    activeTab === index && `green-text-active-nav`
                }`}
              >
                <IonIcon
                  className="footer-tabs-img"
                  icon={item.icon}
                  color={activeTab === index ? 'green': ''}
                  size="small"
                />
                <div
                  className={`footer-tabs-link-text`}
                >
                  {item.text}
                </div>
              </NavLink>
            );
          })}
        </div>
        {/* <div className="footer-tabs-height"></div> */}
      </section>
    </>
  );
};

export default IconTabs;
