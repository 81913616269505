import React from "react";
import { THEMES } from "../../mocks/layout";
import "./CustomButton.css";

type Props = {
  theme?: string;
  onClick?: () => void;
  additionalClasses?:string;
  text: string;
};

const CustomButton: React.FC<Props> = ({ theme, onClick, text, additionalClasses }) => {
  return (
    <div
      onClick={onClick}
      className={`custom-button ${!theme && "none-theme-custom-button"} ${
        theme === THEMES.GREEN && "green-theme-custom-button"
      } ${theme === THEMES.BLUE && "blue-theme-custom-button"} ${additionalClasses}`}
    >
      {text}
    </div>
  );
};

export default CustomButton;
