import React from "react";
import { IonButton } from "@ionic/react";
import "./content.css";

type Props = {
    children: React.ReactNode;
    onClickHandler: (event?:any) => void;
    color?: string;
    text_color?: string;
    id?: string;
    active?:boolean;
};

const ButtonResource: React.FC<Props> = ({
    children,
    onClickHandler,
    color,
    text_color,
    id,
    active,
  }) => {
    const color_but:string = color?color:'';
    const id_button:string = id?id:"resource_button";

    return (
        <IonButton className={active?'community-btn active':'community-btn'} color={color_but} id={id_button} onClick={onClickHandler}>
            <span className="resource_button_text">{children}</span>
        </IonButton>
    );
  };

export default ButtonResource;
