import React from "react";
import dateImage from "../../assets/images/forms/date.svg";
import "./CustomDatePicker.css";

type Props = {
  value: string;
  id?: string;
  cssInput?: string;
  placeholder?: string;
  onChange: (inputText: string) => void;
};

const CustomDatePicker: React.FC<Props> = ({
  onChange,
  value,
  id,
  cssInput,
  placeholder,
}) => {
  const hendlerOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    const inputValue = e.currentTarget.value;
    onChange(inputValue);
  };

  return (
    <section className="section-custom-input date-picker-section">
      <div>
        <input
          id={id}
          value={value}
          onChange={hendlerOnChange}
          className={`custom-input ${cssInput && cssInput}`}
          type="date"
          placeholder={placeholder}
        />
      </div>
      <div className="date-picker-img-block">
        <img className="date-picker-img " src={dateImage} alt="dateImage" />
      </div>
    </section>
  );
};

export default CustomDatePicker;
