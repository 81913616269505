import React from "react";
import {Bar, BarChart, LabelList, ResponsiveContainer} from "recharts";
import emojisArr from "./emojis";

type Props = {
    color: string,
    data:{
        state?:string,
        rate_all?:number[],
        rate?: number
    },
    options?:any
}

const prepareTinyBarChartData = ( array: number[]) => {
    let arrayToPrepare

    if(array.length>=1){
        arrayToPrepare = array
        if(array.length<5){
            const fillArrayCount =5-array.length
            for (let i = 0; i < (fillArrayCount); i++) {
                arrayToPrepare.push(0)
            }
        }
    } else {
        arrayToPrepare = [0,0,0,0,0]
    }


    return arrayToPrepare.map((element,index) => {
        const value = element>1?Math.round(element):0;
        const fillValue = arrayToPrepare.length>1? value*0.2: 99
        return {
            value,
            fillValue
        }
    })
}

const BrowseChartsContainer: React.FC<Props> = ({data, color, options}) => {
    const height  = (options&&options.height)??'75%'
    const chartData:any[] = prepareTinyBarChartData(data.rate_all||[])

    const renderEmoji = (props:any) => {
        const {x,y,index,width} = props;
        const calcWidth = width*1.2
        const isSmall = width < 25
        const k = isSmall ? 2: 1.5
        const iconSize = isSmall ? 16 : 21.5
        const posX = width >=iconSize ? x+((width-iconSize)/3) : x-((iconSize-width)/3)

        return (
            <svg
                x={posX}
                y={y-28.5}
                width={calcWidth}
                height={calcWidth}
                viewBox={`0 0 ${calcWidth*k} ${calcWidth*k}`}
                strokeWidth="1"
            >
                <g fill={color} stroke={color}>
                    { emojisArr[index]}
                </g>
            </svg>
        );

    }

    return (
        <ResponsiveContainer width={"100%"} height={height}>
            <BarChart width={200} height={160} data={chartData} >
                <Bar stackId="chart" dataKey="value" fill={color} minPointSize={1} isAnimationActive={false}
                >
                    <LabelList dataKey="value" content={renderEmoji} position="top"/>
                </Bar>
                <Bar stackId="chart" dataKey={"fillValue"} fill={"transparent"}/>
            </BarChart>
        </ResponsiveContainer >

    )

};

export default BrowseChartsContainer;
