import API from "../api/api";
import { TOKEN } from "../reducers/userReducer";

export const getDataHomePage = async () => {
  const url = `/summary/data/home`;
  return await API.get(url);
};

export const getUpdatesHomePage = async (page:number) => {
  const url = `/updates?page=${page}&page_len=10`;
  return await API.get(url);
};
