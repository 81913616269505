import { store, persistorPromise } from './reducers';
import axios from "axios";
import {TOKEN} from "./reducers/userReducer";
import saveByChunk from "./helpers/saveByChunk";

const TIMESTAMP = 'last_changes_timestamp';

const prepareUrl = function (urlString: string) {
    return `${process.env.REACT_APP_API}${urlString}`;
}
const getByUrlIfNoCached = async function (urlString: string, force?: boolean) {

    const url = prepareUrl(urlString);
    let cacheMatch: any = false;
    if (!force && caches) {
        cacheMatch = await caches.match(url);
    }

    if (!cacheMatch) {
        return axios.get(url, {
            headers: {
                Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
            },
        }).then(({ data }) => data);
    }

    let cacheMatchResult

    try {
        cacheMatchResult = cacheMatch.json()
    } catch (err) {
        console.error(`Cache error`, url , err);
        cacheMatchResult = {}
    }


    return cacheMatchResult;
}

const prepareCacheState = async (withInterval?: boolean) => {

    await persistorPromise;

    try {
        // const tasks = [getResourceGroupsList(), getDataHomePage(), getUpdatesHomePage(1), getAccountMe()];
        const tasks = [
            getByUrlIfNoCached('/resource/groups'),
            getByUrlIfNoCached('/summary/data/home'),
            getByUrlIfNoCached('/action_plan/info'),
            getByUrlIfNoCached('/summary/data/level/0/id/3'),
            getByUrlIfNoCached('/summary/data/level/0/id/2'),
            getByUrlIfNoCached('/updates?page=1&page_len=10'),
            getByUrlIfNoCached('/themes'),
            getByUrlIfNoCached('/account/me')];

        const state: any = store.getState();


        const idCommunity = state.user && state.user.community;

        const updatesCommunityWorker = async () => {
            const idCommunity = state.user && state.user.community;

            if (!idCommunity) {
               localStorage.removeItem(TIMESTAMP);
                return;
            }


            const lastTimestamp = localStorage.getItem(TIMESTAMP) || '0';

            const {
                standards = [],
                standard_updates = [],
                performance_measure = [],
                performance_measure_updates = [],
                action_plans = [],
                action_plans_updates = [],
                timestamp: timestampData
            } = await getByUrlIfNoCached(`/community/${idCommunity}/last_changes?timestamp=${lastTimestamp}`, true);

            const timestamp = timestampData && `${timestampData}`.split('.')[0];
            if (!timestamp || lastTimestamp === timestamp) {
                return;
            }

            localStorage.setItem(TIMESTAMP, timestamp);

            const innerTasks = [];

            if (standards.length) {
                innerTasks.push(getByUrlIfNoCached(`/community/${idCommunity}/standards`), true);
                innerTasks.push(getByUrlIfNoCached(`/community/${idCommunity}/standard_trends`), true);
            }

            innerTasks.push(saveByChunk(async (standardId: number) =>
                    Promise.allSettled([
                        getByUrlIfNoCached(`/community/${idCommunity}/standard/${standardId}/updates`, true)
                    ])
                , standards, 2));

            if (action_plans.length) {
                innerTasks.push(getByUrlIfNoCached(`/community/${idCommunity}/action_plans`), true)
                innerTasks.push(getByUrlIfNoCached(`/community/${idCommunity}/action_plan_trends`), true)
            }


            innerTasks.push(saveByChunk(async (actionPlanId: number) =>
                    Promise.allSettled([
                        getByUrlIfNoCached(`/community/${idCommunity}/action_plan/${actionPlanId}/updates`, true),
                        getByUrlIfNoCached(`/community/${idCommunity}/action_plan/${actionPlanId}`, true)
                    ])
                , action_plans, 2));

            if (performance_measure.length) {
                innerTasks.push(getByUrlIfNoCached(`/community/${idCommunity}/sentiments`), true)
                innerTasks.push(getByUrlIfNoCached(`/community/${idCommunity}/sentiment_trends`), true)
            }

            innerTasks.push(saveByChunk(async (performance_measureId: number) =>
                    Promise.allSettled([
                        getByUrlIfNoCached(`/community/${idCommunity}/sentiment/${performance_measureId}/updates`, true),
                        getByUrlIfNoCached(`/community/${idCommunity}/sentiment/${performance_measureId}`, true),
                        getByUrlIfNoCached(`/performance_measure/${performance_measureId}`, true),
                    ])
                , performance_measure, 2));

            if (standards.length || standard_updates.length || action_plans.length || action_plans_updates.length || performance_measure.length || performance_measure_updates.length) {
                innerTasks.push(getByUrlIfNoCached(`/summary/data/level/4/id/${idCommunity}`), true);
            }

            await Promise.allSettled(innerTasks);
        }

        if (withInterval) {
            setInterval(updatesCommunityWorker, 30000);
        }
        if (idCommunity) {
            const asyncCommunityPhotos = async function () {
                const photos = (await getByUrlIfNoCached(`/community/${idCommunity}/photos`)) || [];
                const photoCommunity = photos[0];

                if (photoCommunity) {
                    tasks.push(fetch(photoCommunity.url, {
                        mode: 'no-cors'
                    }));
                }
            }

            tasks.push(
                asyncCommunityPhotos(),
                updatesCommunityWorker(),
                getByUrlIfNoCached( `/summary/data/level/4/id/${idCommunity}`),
                getByUrlIfNoCached(`/community/${idCommunity}/sentiments`),
                getByUrlIfNoCached(`/community/${idCommunity}`),
                getByUrlIfNoCached(`/community/${idCommunity}/photos`));
        }
        await Promise.allSettled([tasks]);
    } catch (err) {
        console.log(err);
    }
};

export default prepareCacheState;
