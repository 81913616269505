import React, {ComponentProps} from "react";
import {  IonIcon ,IonFabButton} from "@ionic/react";
import {
  arrowForward,
  arrowBack
} from "ionicons/icons";
import ButtonResource from "../../components/content/ButtonResource";
import CustomDropDown from "../../components/customDropDown/CustomDropDown";
import { withTranslation } from "react-i18next";
import moment from 'moment';
import {
  getCommunityId ,
  getCopmlianceStandardAll,
  getStandardsOfCommunity,
  getCopmlianceStandardUpdates
} from  "../../services/Community";
import {
  setStandardsDetails
} from  "../../reducers/standardsReducer";
import {
	isUserGlobalIrelandTanzaniaAdmin,
	isUserGlobalAdmin,
	isUserGlobalIrelandAdmin,
	isUserGlobalTanzaniaAdmin,
  getAccessUserToCommunity
  } from "../../helpers/functions";
import "../resource.css";
import "./UpdateStandardGathering.css";
import compliant_icon from "../../assets/images/indicators/compliant.svg";
import notcompliant_icon from "../../assets/images/indicators/notcompliant.svg";
import notrated_icon from "../../assets/images/indicators-color/not_rated.svg";
import GoBack from "../../components/goBack/GoBack";

import { withIonLifeCycle } from '@ionic/react';

import {
  OptionDateGathering,
  ComplianceGathering,
  UpdateGathering
} from "../Compliance/NewCompliance";
import {connect} from "react-redux";
import {setHeaderActiveTab} from "../../reducers/headerReducer";
import Axios from "axios";
import fileDownload from "js-file-download";
import fileImage from "../../assets/images/file-download.svg";


type UpdateStandardsGathering = {
  id: number,
  date: string,
  standard: number,
  community_gathering: number,
  numeric_actual: number | null,
  boolean_actual: boolean | null,
  compliance: boolean,
  remarks: string,
  state: string
};


type ContentPage = {
  standard: number,
  compliance: boolean,
  numeric_actual: number | null,
  remarks: string,
};

type UpdateStandardGatheringState = {
  community_name: string,
  contentStandart: string,
  summary_standard: string,
  standard_name: string
  dateGathering: OptionDateGathering[],
  contentPage: ContentPage,
  isCompliance:boolean,
};

class OfflineUpdateStandardGathering extends React.Component<
    ComponentProps<any>,
  UpdateStandardGatheringState
> {
  idCommunity: number;
  idStandard: number;
  idUpdateCurrent: number;
  arrayGathering: UpdateGathering[];
  indexUpdateGathering: number;
  listNavigate: number[];
  filesStandart: any[];
  currentIndexListNavigate: number;
  globalAdmin: boolean;
	levelCountrysAdmin: boolean;
	adminTanzania: boolean;
	adminIreland: boolean;
  userAccess: boolean;

  constructor(props: any) {
    super(props);
    this.state = {
      community_name: "",
      standard_name: "" ,
      summary_standard: '',
      contentStandart: '',
      dateGathering: [],
      contentPage: {
        standard: 0,
        compliance: true,
        numeric_actual: 0,
        remarks: '',
      },
      isCompliance: true,
    };
    this.idCommunity = 0;
    this.idStandard = 0;
    this.arrayGathering = [];
    this.indexUpdateGathering = 0;
    this.idUpdateCurrent = 0;
    this.listNavigate = [];
    this.filesStandart = [];
    this.currentIndexListNavigate = 0;
    this.globalAdmin = false;
    this.levelCountrysAdmin = false;
    this.adminTanzania = false;
    this.adminIreland = false;
    this.userAccess = false;
    this.getCommunity = this.getCommunity.bind(this);
    this.setComplianceGathering = this.setComplianceGathering.bind(this);
    this.setDetailsResource = this.setDetailsResource.bind(this);
    this.updateRating = this.updateRating.bind(this);
    this.getGatheringStandarts = this.getGatheringStandarts.bind(this);
    this.fetchGatheringsStandards = this.fetchGatheringsStandards.bind(this);
    this.createComplianceGathering = this.createComplianceGathering.bind(this);
    this.selectedDateGathering = this.selectedDateGathering.bind(this);
    this.setContentPage = this.setContentPage.bind(this);
    this.setCurrentGathering = this.setCurrentGathering.bind(this);
    this.selectedCommunityGroups = this.selectedCommunityGroups.bind(this);
    this.getCommunityStandards = this.getCommunityStandards.bind(this);
    this.createNavigateButtons = this.createNavigateButtons.bind(this);
    this.createListNavigate = this.createListNavigate.bind(this);
    this.getAll = this.getAll.bind(this);
    this.getNextGatheringStandard = this.getNextGatheringStandard.bind(this);
    this.getPrevGatheringStandard = this.getPrevGatheringStandard.bind(this);
    this.getLastUpdate = this.getLastUpdate.bind(this);
  }

  async componentDidMount() {
    if (isUserGlobalAdmin())
      this.globalAdmin = true;
    else if (isUserGlobalIrelandTanzaniaAdmin())
      this.levelCountrysAdmin = true;
    else if (isUserGlobalIrelandAdmin())
      this.adminIreland = true;
    else if (isUserGlobalTanzaniaAdmin())
      this.adminTanzania = true;

    const param = Object.create(this.props.match.params);
    this.idCommunity = Number(param.idCommunity);
    this.idStandard = Number(param.idStandard);
    this.userAccess = await getAccessUserToCommunity(`${this.idCommunity}`);
    this.props.history.listen(async(location: any, action: any) => {
      if (location.pathname.indexOf("UpdateStandardGathering") !==-1)  {
          try {
            await this.getGatheringStandarts();
          } catch (error: any) {
            console.log(`getGatheringStandarts error status: ${error}`);
          }
      }
    });

    try {
        await this.getCommunity(this.idCommunity);
        await this.getAll();
    } catch (error: any) {
        console.log(`getStandardById error status: ${error}`);
    }
  }

  async getAll() {
    try {
      await this.getGatheringStandarts();
      await this.getCommunityStandards(this.idCommunity);
    } catch (error: any) {
      console.log(`getAll error status: ${error}`);
    }
  }

  async getCommunityStandards(id: number) {
    try {
      const {data} = await getStandardsOfCommunity(id);
      this.createListNavigate(data);
    } catch (error: any) {
      console.log(`getStandardsOfCommunity error status: ${error}`);
    }
  }

  createListNavigate(listStandards: any) {
    this.listNavigate = listStandards.standards.map((item:any,index:number)=>{
      if (item.id === this.idStandard) {
          this.currentIndexListNavigate = index;
          const state: any = {
              standard_name:item.name,
              summary_standard: '',
              contentStandart: '',
          };

          if (item.resource && item.resource.id) {
              const resource = item.resource;
              state.summary_standard = resource.en_summary || resource.sw_summary;
              state.contentStandart = resource.en_content || resource.sw_content;
          }
          this.setState(state)
      }

      return item.id as number;
    });
  }

  async getCommunity(id: number) {
    try {
      const {data} = await getCommunityId(`${id}`);
      this.setState ({
        community_name:data.name + ' Compliance'
      });
    } catch (error: any) {
      console.log(`getCommunityId error status: ${error}`);
    }
  }

  async getGatheringStandarts() {
    try {
        await this.fetchGatheringsStandards();
    } catch (error: any) {
        console.log(`getCopmlianceStandardAll error status: ${error}`);
    }
  }

  componentWillReceiveProps(nextProps:Readonly<React.ComponentProps<any>>, nextContext:any) {
      const data = nextProps.standardsDetails[this.idStandard] && nextProps.standardsDetails[this.idStandard].updates;

      if (data) {
          let last_date: string | null = this.getLastUpdate(data.map((el: any) => el.community_gathering));
          this.createComplianceGathering(data,last_date);
      }

  }

  async fetchGatheringsStandards() {
    try {
        const { data, headers }: any = await getCopmlianceStandardAll(this.idCommunity,this.idStandard);

        const reduxData = this.props.standardsDetails[this.idStandard];

        if (headers.cached && reduxData) {
          return;
        }

        this.props.dispatch(setStandardsDetails({
          id: this.idStandard,
          updates: data
        }));
    } catch (error: any) {
        console.log(`getCopmlianceStandardAll error status: ${error}`);
    }
  }

  getLastUpdate(arrayUpdates:UpdateStandardsGathering[]):string | null {
    if (arrayUpdates.length !== 0)
      return arrayUpdates[arrayUpdates.length-1].date;
    return null;
  }

  createComplianceGathering(arrayGathering: ComplianceGathering[],last_date: string | null) {
    const arrayDateGathering: OptionDateGathering[] = [];
    let indexCurrent: number = 0;
    if (arrayGathering.length > this.arrayGathering.length) {
      indexCurrent = arrayGathering.length-1;
    }
    else
      indexCurrent = this.indexUpdateGathering;
    this.arrayGathering = [];

    arrayGathering.forEach((item,index)=>{
        if (item.standard.id === this.idStandard) {
            let keys = Object.keys(item.state);
            let state_key: string = keys[0];
            if (last_date !== null) {
                if (item.community_gathering.date === last_date)
                    this.indexUpdateGathering = index;
            };
            this.arrayGathering.push({
                id: item.id,
                standard: item.standard.numeric_value,
                community_gathering: item.community_gathering.id,
                numeric_actual: item.numeric_actual,
                boolean_actual: item.boolean_actual,
                rate: item.rate,
                compliance: item.compliance,
                remarks: item.remarks,
                state: item.state[state_key]
            });

            arrayDateGathering.push({
                label: moment(item.community_gathering.date,'YYYY-MM-DD').format('MMMM Do YYYY'),
                value: index
            });
        }
    });
    this.setState({dateGathering:arrayDateGathering});
    const content:ContentPage = {
      ...this.state.contentPage,
    };

    if (this.arrayGathering.length !== 0) {
        this.indexUpdateGathering = arrayGathering.length-1;
        this.idUpdateCurrent =  this.arrayGathering[this.indexUpdateGathering].id;
        content.compliance = this.arrayGathering[this.indexUpdateGathering].compliance;
        content.numeric_actual = this.arrayGathering[this.indexUpdateGathering].numeric_actual=== null?null:
        this.arrayGathering[this.indexUpdateGathering].numeric_actual;
        content.remarks = this.arrayGathering[this.indexUpdateGathering].remarks;
        content.standard = this.arrayGathering[this.indexUpdateGathering].standard;
    }
    this.setContentPage(content);
  }

  setContentPage(content:any) {
    this.setState({contentPage:content});
  }

  setCurrentGathering(index: number) {
    this.idUpdateCurrent = this.arrayGathering[index].id;
    this.indexUpdateGathering = index;
    this.setContentPage ({
      ...this.state.contentPage,
      compliance: this.arrayGathering[index].compliance,
      numeric_actual: this.arrayGathering[index].numeric_actual,
      remarks: this.arrayGathering[index].remarks,
      standard: this.arrayGathering[index].standard
    });
  }

  getPrevGatheringStandard() {
    if (this.currentIndexListNavigate !== 0) {
        this.idStandard = this.listNavigate[--this.currentIndexListNavigate];
        this.getAll();
    }
  }

  getNextGatheringStandard() {
    if (this.currentIndexListNavigate !== this.listNavigate.length-1) {
        this.idStandard = this.listNavigate[++this.currentIndexListNavigate];
        this.getAll();
    }
  }

  selectedDateGathering(option: any) {
    this.setCurrentGathering(option.value);
  }

  backToResources() {
    this.props.history.goBack();
  }

  setComplianceGathering() {
    this.setState({isCompliance: true})

    const index: number = this.arrayGathering.findIndex((item)=>{
      return item.compliance;
    });
    if (index !== -1)
      this.setCurrentGathering(index);
  }

  setDetailsResource() {
    this.setState({isCompliance: false})
  }

  selectedCommunityGroups(option: any) {

  }

  createNavigateButtons(wrap_css: string):JSX.Element {
    return(
      <div className={wrap_css}>
        <IonFabButton
          className="community-gathering-compliance-navigate_button"
          onClick={this.getPrevGatheringStandard}
        >
          <IonIcon icon={arrowBack} />
        </IonFabButton>
        <IonFabButton
          className="community-gathering-compliance-navigate_button"
          onClick={this.getNextGatheringStandard}
        >
          <IonIcon icon={arrowForward} />
        </IonFabButton>
      </div>
    );
  }

  updateRating() {
    this.props.history.push(`/community/${this.idCommunity}/standard/${this.idStandard}/updateCompliance/${this.idUpdateCurrent}`);
  }

  downloadFile(url: string, file: string) {
    Axios.get(url, {
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, file);
    });
  }

  makeBlockFiles(): JSX.Element {
    const len = this.filesStandart.length;
    const block_files: JSX.Element[] = this.filesStandart.map(
        (itemFile: any, index: number) => {
          let sufix: string = index === len - 1 ? "-last" : "";

          let start = itemFile.file.lastIndexOf("/");
          let url: string = itemFile.file.slice(0, start);
          let end = itemFile.file.lastIndexOf("_");
          let fileName: string = itemFile.file.slice(start + 1, end);
          start = itemFile.file.lastIndexOf(".");
          let extFile: string = itemFile.file.slice(start);
          fileName += extFile;

          return (
              <div
                  key={index}
                  className={
                      "content-resource-summary-detail-subcontainer-downloads-files-row" +
                      sufix
                  }
              >
                <div className="content-resource-summary-detail-subcontainer-downloads-files-col-img">
                  <img src={fileImage} alt="img"/>
                </div>
                <div className="content-resource-summary-detail-subcontainer-downloads-files-col-file">
              <span
                  onClick={() => {
                    this.downloadFile(itemFile.file, fileName);
                  }}
              >
                {fileName}
              </span>
                </div>
              </div>
          );
        }
    );
    return (
        <div className="content-resource-summary-detail-subcontainer-downloads-files">
          {block_files}
        </div>
    );
  }

  formatingContent(text: string) {
    let content: JSX.Element[] = [];
    let start: number = 0;
    let key = 0;

    while (start < text.length) {
      let end: number = text.indexOf("\n", start);
      if (end !== -1) {
        content.push(
            <span key={key++}>
            {text.slice(start, end)}
              <br/>
          </span>
        );
      } else {
        content.push(<span key={key++}>{text.slice(start)}</span>);
        break;
      }
      start = end + 1;
    }
    return (
        <div className="content-resource-summary-detail-subcontainer-text">
          {content}
        </div>
    );
  }

    ionViewWillEnter () {
        const {dispatch} = this.props;
        dispatch(setHeaderActiveTab(1))
    }

  render() {
    const { t } = this.props;

    const add_css: string = !this.state.contentPage.compliance?
    " community-gathering-compliance-add":
    "";

    const block_files: JSX.Element = this.makeBlockFiles();
    const content: JSX.Element = this.formatingContent(
        this.state.contentStandart
    );

    return (
        <>
          <div className="community-gathering-compliance-title-page-wrap">
            <div className="community-gathering-compliance-title-page-wrap-upper">
              <GoBack isGoBack text={"My Community Standards"}/>
              {this.createNavigateButtons("community-gathering-compliance-title-page-wrap-upper-controls")}
            </div>
            <div className="community-gathering-compliance-title-page-wrap-down">
              <div className="community-gathering-compliance-title-page-wrap-down-name-standard">
                {this.state.standard_name}
              </div>
              {this.createNavigateButtons("community-gathering-compliance-title-page-wrap-down-controls")}
            </div>
          </div>
          <div className="wrap-content-resource">
            <div className="content-resource">
              <div className='control-resource-gathering'>
                <ButtonResource
                    id="button-compliance-gathering"
                    text_color="white"
                    onClickHandler={this.setComplianceGathering}
                    active={this.state.isCompliance}
                >
                  {t("compliance")}
                </ButtonResource>
                <ButtonResource
                    id="button-details-gathering"
                    text_color="rgb(107, 114, 128)"
                    onClickHandler={this.setDetailsResource}
                    active={!this.state.isCompliance}
                >
                  {t("details")}
                </ButtonResource>
              </div>
              <div className="content-resource-summary">
                <div className={"content-resource-summary-detail" + add_css}>
                  {!this.state.contentPage.compliance ?
                      <div className="community-gathering-compliance-wrap-select-groups">
                        <CustomDropDown
                            setValue={(option) => this.selectedCommunityGroups(option)}
                            cssControl="community-gathering-compliance-groups-select-dropdown"
                            options={[]}
                            defaultOption="All Galway Groups"/>
                      </div> :
                      null}

                  <div className="content-resource-summary-detail-subcontainer">
                    {this.state.isCompliance?
                        <>
                    <div className="community-gathering-compliance-head">
                      <div className="community-gathering-compliance-head-block-icon">
                        {(this.arrayGathering.length !== 0 &&
                            this.arrayGathering[this.indexUpdateGathering].state === "Not rated" ||
                            this.arrayGathering.length === 0) ?
                            <img src={notrated_icon} alt="notrated_img"/> :
                            (this.arrayGathering.length !== 0 &&
                                this.arrayGathering[this.indexUpdateGathering].state === "Fail") ?
                                <img src={notcompliant_icon} alt="notcompliant_img"/> :
                                this.state.contentPage.compliance ?
                                    <img src={compliant_icon} alt="compliant_img"/> :
                                    <img src={notcompliant_icon} alt="notcompliant_img"/>}
                      </div>
                      <div className="community-gathering-compliance-head-block-text">
                        {(this.arrayGathering.length !== 0 &&
                            this.arrayGathering[this.indexUpdateGathering].state === "Not rated" ||
                            this.arrayGathering.length === 0) ?
                            <span className="community-gathering-compliance-head-block-text-notrated">
                                                {t("notRated")}
                                            </span> :
                            (this.arrayGathering.length !== 0 &&
                                this.arrayGathering[this.indexUpdateGathering].state === "Fail") ?
                                <span className="community-gathering-compliance-head-block-text-notcompliant">
                                                    {t("fail")}
                                                </span> :
                                this.state.contentPage.compliance ?
                                    <span className="community-gathering-compliance-head-block-text-compliant">
                                                        {t("compliant")}
                                                    </span> :
                                    <span className="community-gathering-compliance-head-block-text-notcompliant">
                                                        {t("notCompliant")}
                                                    </span>}
                      </div>
                    </div>
                    {(this.arrayGathering.length === 0 ||
                        (this.arrayGathering.length === 1 &&
                            (this.arrayGathering[this.indexUpdateGathering].state === "Not realated" ||
                                this.arrayGathering[this.indexUpdateGathering].state === "Fail"))) ?
                        null :
                        <div>
                          <div className="community-gathering-compliance-line"/>
                          <div className="community-gathering-compliance-summary-resource">
                            {this.state.summary_standard}
                          </div>
                          <div className="community-gathering-compliance-line"/>
                          <div className="community-gathering-compliance-date">
                            <div className="community-gathering-compliance-date-name">
                              {t("date")}
                            </div>
                            <div className="community-gathering-compliance-date-select">
                              <CustomDropDown
                                  setValue={(option) => this.selectedDateGathering(option)}
                                  cssControl="community-gathering-compliance-date-select-dropdown"
                                  options={this.state.dateGathering as any[]}
                                  defaultOption={this.state.dateGathering.length !== 0 ?
                                      this.state.dateGathering[this.indexUpdateGathering] as any : ''}/>
                            </div>
                          </div>
                        </div>}
                    <div className="community-gathering-compliance-line"/>
                    <div className="community-gathering-compliance-standard-section">
                      <div className="community-gathering-compliance-standard-section-block-etalon">
                        <div className="community-gathering-compliance-standard-section-block-etalon-name">
                          {t("standard")}
                        </div>
                        <div className="community-gathering-compliance-standard-section-block-etalon-value">
                          {this.state.contentPage.standard}
                        </div>
                      </div>
                      {this.state.contentPage.numeric_actual !== null ?
                          <div className="community-gathering-compliance-standard-section-block-actual">
                            <div className="community-gathering-compliance-standard-section-block-actual-name">
                              {t("actual")}
                            </div>
                            <div className="community-gathering-compliance-standard-section-block-actual-value">
                              {this.state.contentPage.numeric_actual}
                            </div>
                          </div> :
                          null}
                    </div>
                    {this.state.contentPage.remarks !== "" ?
                        <div>
                          <div className="community-gathering-compliance-line"/>
                          <div className="community-gathering-compliance-remarks-section">
                            <div className="community-gathering-compliance-remarks-section-name">
                              {t("remarks")}
                            </div>
                            <div className="community-gathering-compliance-remarks-section-text">
                              {this.state.contentPage.remarks}
                            </div>
                          </div>
                        </div> :
                        null}
                    {this.userAccess
                        ?
                        <div>
                          <div className="community-gathering-compliance-line"/>
                          <div className="community-gathering-compliance-button-section">
                            <ButtonResource
                                id="community-gathering-button-update-rating"
                                text_color="white"
                                onClickHandler={this.updateRating}
                            >
                              {t("updateRating")}
                            </ButtonResource>
                          </div>
                        </div> :
                        null}
                      </>
                        :
                        <>
                          <div className="community-gathering-compliance-summary-resource">
                            {this.state.summary_standard}
                          </div>
                          <div className="content-resource-summary-detail-line"/>
                          <div className="content-resource-summary-detail-subcontainer-name">
                            {t("summary")}
                          </div>
                          {content}
                          <div className="content-resource-summary-detail-line"/>
                          <div className="content-resource-summary-detail-subcontainer-downloads">
                            {t("downloads")}
                          </div>
                          {block_files}
                        </>

                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
    );
  }
}

const mapState = (state: any) => ({ standardsDetails: state.standards.standardsDetails })
const decoratedComponent =connect(mapState)( withTranslation("common")(withIonLifeCycle(OfflineUpdateStandardGathering)));
export { decoratedComponent as OfflineUpdateStandardGathering };
