import { useCallback } from 'react';

import { usePageTreeContext } from './provider';
import { getSummaryDataBrowse } from 'services/Community';
import {useDispatch, useSelector} from "react-redux";
import {AppState, store} from "../../reducers";

import { setCommunityPageTree, setCommunityMyPageTree } from "reducers/communityReducer";


export default () => {
  const {
    isFetching,
    setIsFetching,
    pageChildren,
    myCommunity,
  } = usePageTreeContext();

  const pageTree = useSelector((state: AppState) => myCommunity ? state.community.myPageTree : state.community.pageTree);

  const dispatch = useDispatch();

  const fetchPageTree = async (level: any, id: any) => {
    setIsFetching(true);
    const pageTreeRes: any = await getSummaryDataBrowse(level, id);

    const { community: { myPageTree, pageTree } }: any = store.getState();
    const pageTreeDef = myCommunity ? myPageTree.def : pageTree.def;
    setIsFetching(false);

    if (pageTreeRes.headers.cached && !pageTreeDef) {
        return;
    }

    const communityReducer = myCommunity ? setCommunityMyPageTree :setCommunityPageTree;
    await dispatch(communityReducer({...pageTreeRes.data}));
  };

  return {
    isFetching,
    fetchPageTree,
    pageTree,
    pageChildren,
  };
};
