import {
  putActionPlanIdUpdateId,
  postActionPlanIdUpdateNew,
} from "../actionPlan";

import { createErrorApi } from "../../helpers/helperApi";
import { PerformanceMeasureT } from "../../types/types";

export const middlewatePutActionPlanIdUpdateId = async (
  idCommunity: string,
  idPlan: string,
  idUpdate: any,
  body: any
) => {
  try {
    const { data } = await putActionPlanIdUpdateId(
      idCommunity,
      idPlan,
      idUpdate,
      body
    );
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewatePostActionPlanIdUpdateNew = async (
  idCommunity: string,
  idPlan: string,
  body: any
) => {
  try {
    const { data } = await postActionPlanIdUpdateNew(idCommunity, idPlan, body);
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};
