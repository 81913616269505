import React from "react";
import "./content.css";
import { chevronForwardOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import { NavLink } from "react-router-dom";

interface IProps {
  country_name: string;
  link: string;
}

// text-decoration-none
const HeaderCharts: React.FC<IProps> = (props: IProps) => {
  return (
    <NavLink to={props.link} className="text-decoration-none">
      <div className="header_charts">
        <div className="country_name1">{props.country_name}</div>
        <div className="header_charts_arrow">
          <IonIcon icon={chevronForwardOutline} />
        </div>
      </div>
    </NavLink>
  );
};

export default HeaderCharts;
