const SET_COMMUNITY_BROWSE = "SET_COMMUNITY_BROWSE";
const SET_COMMUNITY_BROWSE_WITH_SUBSTITUTE = "SET_COMMUNITY_BROWSE_WITH_SUBSTITUTE";
const SET_COMMUNITY_PAGE_TREE = "SET_COMMUNITY_PAGE_TREE";
const SET_COMMUNITY_MY_PAGE_TREE = "SET_COMMUNITY_MY_PAGE_TREE";
const SET_IS_REFRESH_PAGE = "SET_IS_REFRESH_PAGE";
const SET_LEVEL_AND_ID = "SET_LEVEL_AND_ID";

export interface CommunityState {
  communityBrowse: any;
  isRefreshPage: boolean;
  lastLevel: string;
  lastId: string;
  pageTree: any;
  myPageTree: any;
}

const initialState: CommunityState = {
  communityBrowse: '',
  lastLevel: "",
  lastId: "",
  pageTree: {
    def: true
  },
  myPageTree:  {
    def: true,
  },
  isRefreshPage: false,
};

export default function communityReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_COMMUNITY_BROWSE:
      return {
        ...state,
        communityBrowse: {
          ...action.payload
        },
      };
    case SET_COMMUNITY_PAGE_TREE:
      return {
        ...state,
        pageTree: {
          ...action.payload
        },
      };
    case SET_COMMUNITY_MY_PAGE_TREE:
      return {
        ...state,
        myPageTree:  {
            ...action.payload
        },
      };
    case SET_COMMUNITY_BROWSE_WITH_SUBSTITUTE:
      return {
        ...state,
        communityBrowse:  {
          ...action.payload
        },
      };
    case SET_IS_REFRESH_PAGE:
      return {
        ...state,
        isRefreshPage: action.payload,
      };
    case SET_LEVEL_AND_ID:
      const { level, id } = action.payload;
      return {
        ...state,
        lastLevel: level,
        lastId: id,
      };
    default:
      return state;
  }
}

export const setCommunityBrowse = (communityBrowse: any) => ({
  type: SET_COMMUNITY_BROWSE,
  payload: communityBrowse,
});

export const setCommunityBrowseWithSub = (communityBrowse: any) => ({
  type: SET_COMMUNITY_BROWSE_WITH_SUBSTITUTE,
  payload: communityBrowse,
});

export const setIsRefreshPage = (isRefresh: boolean) => ({
  type: SET_IS_REFRESH_PAGE,
  payload: isRefresh,
});
export const setCommunityPageTree = (payload: any) => ({
  type: SET_COMMUNITY_PAGE_TREE,
  payload,
});
export const setCommunityMyPageTree = (payload: any) => ({
  type: SET_COMMUNITY_MY_PAGE_TREE,
  payload,
});

export const setLevelAndId = (levelAndId: any) => ({
  type: SET_LEVEL_AND_ID,
  payload: levelAndId,
});
