import React from "react";
import shareImage from "../../assets/images/share.png";
import "./ShareSocial.css";
import { useTranslation } from "react-i18next";
import ModalWindow from "../modalWindow/ModalWindow";

import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share";

type Props = {
  url?: string;
  socialIconSize?: number;
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const ShareSocial: React.FC<Props> = ({
  url = process.env.REACT_APP_HOST,
  socialIconSize = 32,
}) => {
  const { t } = useTranslation("common");
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const hanldeerClick = async () => {
    setIsOpen(true);
  };

  const shareUrl = url
    ? url + window.location.pathname
    : "http://46.101.238.233/";
  return (
    <>
      <ModalWindow
        isOpen={modalIsOpen}
        onHandlerOpen={(eOpen) => setIsOpen(eOpen)}
        // contentLabel="Example Modal"
      >
        <div className="container-social-row">
          <div className="social-btn-row">
            <TelegramShareButton url={shareUrl} className="social-block-width">
              <div className="social-icon-text-row">
                <TelegramIcon size={socialIconSize} round />{" "}
                <div className="social-text">Telegram</div>
              </div>
            </TelegramShareButton>
          </div>

          <div className="social-btn-row">
            <FacebookShareButton url={shareUrl} className="social-block-width">
              <div className="social-icon-text-row">
                <FacebookIcon size={socialIconSize} round />{" "}
                <div className="social-text">Facebook</div>
              </div>
            </FacebookShareButton>
          </div>

          <div className="social-btn-row">
            <ViberShareButton url={shareUrl} className="social-block-width">
              <div className="social-icon-text-row">
                <ViberIcon size={socialIconSize} round />{" "}
                <div className="social-text">Viber</div>
              </div>
            </ViberShareButton>
          </div>

          <div className="social-btn-row">
            <TwitterShareButton url={shareUrl} className="social-block-width">
              <div className="social-icon-text-row">
                <TwitterIcon size={socialIconSize} round />{" "}
                <div className="social-text">Twitter</div>
              </div>
            </TwitterShareButton>
          </div>

          <div className="social-btn-row">
            <EmailShareButton url={shareUrl} className="social-block-width">
              <div className="social-icon-text-row">
                <EmailIcon size={socialIconSize} round />{" "}
                <div className="social-text">Email</div>
              </div>
            </EmailShareButton>
          </div>

          <div className="social-btn-row">
            <LinkedinShareButton url={shareUrl} className="social-block-width">
              <div className="social-icon-text-row">
                <LinkedinIcon size={socialIconSize} round />{" "}
                <div className="social-text">Linkedin</div>
              </div>
            </LinkedinShareButton>
          </div>

          <div className="social-btn-row">
            <RedditShareButton url={shareUrl} className="social-block-width">
              <div className="social-icon-text-row">
                <RedditIcon size={socialIconSize} round />{" "}
                <div className="social-text">Reddit</div>
              </div>
            </RedditShareButton>
          </div>
        </div>
      </ModalWindow>

      <div className="share-social" onClick={hanldeerClick}>
        <img className="social-share-image" src={shareImage} alt="shareImage" />
        <div>{t("share")}</div>
      </div>
    </>
  );
};

export default ShareSocial;
