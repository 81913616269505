import API from "../api/api";
import {IGetStatsResponse} from "../models";

export const getStats = async () => {
    const url = `/stats`;
    let response: IGetStatsResponse = await API.get(url);

    const stats = response.data || [];

    return stats;
};
