const CHUNK_SIZE: number = 5000;

function _chunkArray(myArray: [], chunk_size: number) {
    let index = 0;
    const arrayLength = myArray.length;
    const tempArray = [];

    for (index = 0; index < arrayLength; index += chunk_size) {
        const myChunk = myArray.slice(index, index + chunk_size);
        // Do something if you want with the group
        tempArray.push(myChunk);
    }

    return tempArray;
}

export default async function saveByChunk (saveFunc: any, big_arr: [], chunk_size_set: number) {
    const big_arr_chunked = _chunkArray(big_arr, chunk_size_set || CHUNK_SIZE);

    let result: any[] = [];

    for (const arr_chunk of big_arr_chunked) {
        const partialResult = await Promise.all(arr_chunk.map(saveFunc));

        result = result.concat(partialResult);
    }

    return result;
};