import React from "react";
import {RouteComponentProps} from "react-router-dom";
import {THEMES} from "../../mocks/layout";
import {withTranslation, WithTranslation} from "react-i18next";
import GoBack from "../../components/goBack/GoBack";
import CustomDropDown from "../../components/customDropDown/CustomDropDown";
import {
    getStandardsList,
    getStandardsThemes
} from "../../services/standards";
import {getThemes} from "../../services/themes";
import "../resource.css";
import "../UserManagement/UserManagement.css";
import "./Standards.css";


const THEME_PAGE = THEMES.BLUE;

type StandardsProps = {};

type StandardsState = {
    StadardsThemes: JSX.Element[] | null
};

type Standard = {
    boolean_value: any | null,
    comparison: string,
    country: number,
    id: number,
    name: string,
    numeric_value: number,
    theme: number,
    type_value: string
};

type Theme = {
    id: number,
    name: string,
    set: boolean
};

type AssignStandard = {
    country: string,
    theme: number,
    standard: number
}

type StandardTheme = {
    id_standard: number,
    name_standard: string,
    theme: Theme[];
};

type CountryStandards = {
    country: string,
    standardsThemes: StandardTheme[]
};

type OptionTheme = {
    label: string,
    value: number
};

class Standards extends React.Component<StandardsProps & WithTranslation & RouteComponentProps<{}>,
    StandardsState> {
    arrayCountryStandards: CountryStandards[];
    listStandards: Standard[];

    constructor(props: any) {
        super(props);
        this.state = {
            StadardsThemes: null
        };
        this.arrayCountryStandards = [];
        this.listStandards = [];
        this.getStandards = this.getStandards.bind(this);
        this.getStandardsThemes = this.getStandardsThemes.bind(this);
        this.selectThemes = this.selectThemes.bind(this);
        this.getThemes = this.getThemes.bind(this);
        this.makeCountriesStandards = this.makeCountriesStandards.bind(this);
        this.findCountry = this.findCountry.bind(this);
        this.getNameStandard = this.getNameStandard.bind(this);
        this.getNameTheme = this.getNameTheme.bind(this);
        this.makeStandardsThemes = this.makeStandardsThemes.bind(this);
        this.setTheme = this.setTheme.bind(this);
    }

    async componentDidMount() {
        try {
            await this.getStandards();
            const arrayAssign: AssignStandard[] = await this.getStandardsThemes();
            const arrayThemes: Theme[] = await this.getThemes();
            console.log("arrayAssign", arrayAssign, "arrayThemes", arrayThemes);

            this.makeCountriesStandards(arrayAssign, arrayThemes);
            this.setState({StadardsThemes: this.makeStandardsThemes()});
        }
        catch (error: any) {
            console.log(`error status: ${error}`);
        }
    }

    async getThemes(): Promise<Theme[]> {
        try {
            const {data} = await getThemes();
            return [...data];
        }
        catch (error: any) {
            console.log(`getThemes error status: ${error}`);
        }
        return [];
    }

    async getStandards() {
        try {
            const {data} = await getStandardsList();
            console.log("standards list", data);
            this.listStandards = [...data];
        }
        catch (error: any) {
            console.log(`getStandardsList error status: ${error}`);
        }
    }

    async getStandardsThemes(): Promise<AssignStandard[]> {
        try {
            const {data} = await getStandardsThemes();
            return [...data];
        }
        catch (error: any) {
            console.log(`getStandardsThemes error status: ${error}`);
        }
        return [];
    }

    makeStandardsThemes(): JSX.Element[] {
      return this.arrayCountryStandards.map((item, index) => {

            let standardsList: JSX.Element[] = item.standardsThemes.map((item, j) => {
                let optionTheme: OptionTheme[] = item.theme.map((theme) => {
                    return ({
                            label: theme.name,
                            value: theme.id
                        }
                    );
                });
                return (
                    <div key={j + 100}>
                        <div className="community-gathering-compliance-line"/>
                        <div className="standards-content-block-standard-link">
                            <div className="standards-content-wrap-row">
                                <div className="standards-content-standard-name">
                                    {item.name_standard}
                                </div>
                                <CustomDropDown
                                    setValue={(option) => this.selectThemes(option)}
                                    options={optionTheme as any[]}
                                    cssControl="standards-content-dropdownlist-themes"
                                    defaultOption={this.getTheme(item.theme) as any}
                                />
                            </div>
                        </div>
                    </div>
                );
            });
            return (
                <div key={index}>
                    <div className="standards-content-country">
                        {item.country}
                    </div>
                    {standardsList}
                </div>
            );
        });
    }

    getTheme(arrayThemes: Theme[]): OptionTheme | string {
        for (let j = 0; j <= arrayThemes.length - 1; j++) {
            if (arrayThemes[j].set)
                return {
                    label: arrayThemes[j].name,
                    value: arrayThemes[j].id
                }
        }
        return "";
    }

    setTheme(id: number, arrayThemes: Theme[]): Theme[] {
        return arrayThemes.map((theme) => {
            return ({
                    id: theme.id,
                    name: theme.name,
                    set: theme.id === id
                }
            );
        });
    }

    makeCountriesStandards(arrayAssign: AssignStandard[], arrayThemes: Theme[]) {
        arrayAssign.forEach((item) => {
            let countryStandards: CountryStandards | undefined;
            if ((countryStandards = this.findCountry(item.country)) === undefined) {

                this.arrayCountryStandards.push({
                    country: item.country,
                    standardsThemes: [{
                        id_standard: item.standard,
                        name_standard: this.getNameStandard(item.standard),//get from standards
                        theme: this.setTheme(item.theme, arrayThemes)
                    }]
                });
            } else {//есть ли такой стандарт
                let find_standard: number = item.standard;
                let find_theme: number = item.theme;
                let standardTheme: StandardTheme | undefined;

                if ((standardTheme = countryStandards.standardsThemes.find((item) => {
                        return item.id_standard === find_standard;
                    })
                ) === undefined) {
                    countryStandards.standardsThemes.push({
                        id_standard: find_standard,
                        name_standard: this.getNameStandard(find_standard),
                        theme: this.setTheme(find_theme, arrayThemes)
                    });
                }
            }
        });
        console.log("this.arrayCountryStandards", this.arrayCountryStandards);
    }

    findCountry(country: string): CountryStandards | undefined {
        return this.arrayCountryStandards.find((item) => {
            return item.country === country;
        });
    }

    getNameStandard(id: number): string {
        const standard: Standard | undefined = this.listStandards.find((item) => {
            return item.id === id;
        });
        return (
            standard !== undefined ?
                standard.name :
                ""
        );
    }

    getNameTheme(id: number, arrayThemes: Theme[]): string {
        const theme: Theme | undefined = arrayThemes.find((item) => {
            return item.id === id;
        });
        return (
            theme !== undefined ?
                theme.name :
                ""
        );
    }

    backToResources() {
        this.props.history.goBack();
    }

    selectThemes(option: any) {

    }


    render() {
        const {t} = this.props;

        return (

            <>
                <div className="community-management-title-wrap">
                    <div className="community-management-title-wrap-content">
                        <div className="display-flex">
                            <GoBack isGoBack text={t("myAccount")}/>

                        </div>
                        <div className="community-management-title-wrap-content-name">
                            {t("standardsThemes")}
                        </div>
                    </div>
                </div>
                <div className="wrap-content-resource">
                    <div className="content-resource community-management-content-resource">
                        <div className='description_resource community-management-content-description'>
                            {t("assignThemesToEach")}
                        </div>
                        <div className="content-resource-summary community-management-content-summary">
                            <div className="content-resource-summary-detail">
                                <div className="content-resource-summary-detail-subcontainer">
                                    {this.state.StadardsThemes}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        );
    }
}

const decoratedComponent = withTranslation("common")(Standards);
export {decoratedComponent as Standards};
