import React from "react";
import { IonButton } from "@ionic/react";
import "./style.css";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

type Props = {
  setActiveTab: Function;
  activeTab: string;
  tabs: string[];
  theme?: string;
}

const MenuBottomChartControls: React.FC<Props> = ({ tabs, activeTab, setActiveTab, theme }) => {
  const { t } = useTranslation("common");
  const activeColor = theme==='blue'?'primary':'success'

  return (
    <div className= {tabs.length <= 1 ? " chart_controls1 hidden" : "chart_controls1"}>
        {tabs.map((tabEl) =>
             <IonButton key={uuidv4()} color={tabEl === activeTab ? activeColor : 'light'} id={tabEl} onClick={() => setActiveTab(tabEl)}>
                <span className={(tabEl === activeTab ? '' : ' text_color') + ' button_text'}>{t(tabEl)}</span>
            </IonButton>
    ) }

    </div>
  );
};

export default MenuBottomChartControls;
