import React from "react";
import { THEMES } from "../../../mocks/layout";
import { NavLink } from "react-router-dom";
import "./../CustomButton.css";

type Props = {
  theme?: string;
  to?: string;
  text: string;
};

const CustomButtonNavLink: React.FC<Props> = ({ theme, text, to = "/" }) => {
  return (
    <NavLink
      to={to}
      className={`custom-button ${!theme && "none-theme-custom-button"} ${
        theme === THEMES.GREEN && "green-theme-custom-button"
      } ${theme === THEMES.BLUE && "blue-theme-custom-button"}`}
    >
      {text}
    </NavLink>
  );
};

export default CustomButtonNavLink;
