import React, {ComponentProps} from "react";
import {IonIcon} from "@ionic/react";
import {  chevronForwardOutline } from "ionicons/icons";
import moment from "moment";
import ButtonResource from "../../components/content/ButtonResource";
import CustomDropDown from "../../components/customDropDown/CustomDropDown";
import {WithTranslation, withTranslation} from "react-i18next";
import { getCommunityId } from "../../services/Community";
import { getAccessUserToCommunity } from "../../helpers/functions";
import GoBack from "../../components/goBack/GoBack";
import "./ListActionPlans.css";
import "../resource.css";
import "../UpdateStandardGathering/UpdateStandardGathering.css";
import { setHeaderActiveTab} from "../../reducers/headerReducer";
import {connect} from "react-redux";
import CommunityChart from '../../components/communityCharts/communityChart'
import {middlewareGetCommunityActionPlans} from "../../services/middleware/MiddlewareCommunity";
import {setActionPlans} from "../../reducers/actionPlansReducer";
import { withIonLifeCycle } from '@ionic/react';


type ActionPlan = {
    community_gathering: number;
    due_date: string;
    en_resources: string;
    en_sub_activities: string;
    en_title: string;
    id: number;
    monitored_by: string;
    resources: string;
    responsible_party: string;
    responsible_party_text: string;
    responsible_party_type: string;
    status: string;
    status_text: string;
    sub_activities: string;
    sw_resources: string;
    sw_sub_activities: string;
    sw_title: string;
    theme: number;
    title: string;
};


type ActionPlanRate = {
    action_plans: ActionPlan[];
    rate: number;
    trend: number[];
    trend_direction: any;
    trend_all: any[];
    def?: boolean;
};

type PlanProgress = {
    COMPLETED: number;
    ACTIVE: number;
};


type ListActionPlansState = {
    community_name: string;
    actionPlanList: JSX.Element[] | null;
};
type NewComplianceProps = {
    actionPlans: any;
};

class OfflineListActionPlans extends React.Component<
    NewComplianceProps &
    WithTranslation &ComponentProps<any>,
    ListActionPlansState
    > {
    idCommunity: number;
    actionPlan: ActionPlanRate | null;
    status: string;
    trend_direction: string;
    progress_plan: PlanProgress | null;
    userAccess: boolean;
    actionPlanArray :ActionPlan[]

    constructor(props: any) {
        super(props);
        this.state = {
            community_name: "Action Plan",
            actionPlanList: null,
        };
        this.progress_plan = null;
        this.idCommunity = 0;
        this.actionPlanArray = [];
        this.actionPlan = null;
        this.status = "all";
        this.trend_direction = "";
        this.userAccess = false;
        this.setActionPlanList = this.setActionPlanList.bind(this);
        this.selectedCommunityGroups = this.selectedCommunityGroups.bind(this);
        this.getPlans = this.getPlans.bind(this);
        this.createListPlans = this.createListPlans.bind(this);
        this.selectLinkPlan = this.selectLinkPlan.bind(this);
        this.addNewCommitment = this.addNewCommitment.bind(this);
    }

    componentWillReceiveProps(nextProps:Readonly<NewComplianceProps & WithTranslation & React.ComponentProps<any>>, nextContext:any) {
        this.actionPlan = nextProps.actionPlans;
        this.actionPlanArray = this.actionPlan?.action_plans||[]
        this.createListPlans();
    }

    async componentDidMount() {
        const param = Object.create(this.props.match.params);
        this.idCommunity = Number(param.idCommunity);
        this.userAccess = await getAccessUserToCommunity(`${this.idCommunity}`);
        this.status = "all"

        try {
            this.idCommunity = Number(param.idCommunity);
            await this.getPlans(this.idCommunity);
        } catch (error: any) {
            console.log(`error status: ${error}`);
        }
    }

    async getPlans(id: number) {
        try {
            const data = await middlewareGetCommunityActionPlans(id);

            if (data.cached && this.props.actionPlans && !this.props.actionPlans.def) {
                return;
            }

            this.props.dispatch(setActionPlans(data))
        }
        catch (error: any) {
            console.log(`getActionPlans error status: ${error}`);
        }
    }

    selectLinkPlan(index: number) {
        this.props.history.push(
            `/community/${this.idCommunity}/action_plan/${this.actionPlan?.action_plans[index].id}`
        );
    }

    createListPlans() {
        const actionPlan = this.actionPlan || {trend_all:[]}

        const trend_all: any[] = actionPlan.trend_all;
        this.progress_plan = trend_all?.length ? trend_all[trend_all.length - 1]:[];

        let arrayList: JSX.Element[] = this.actionPlanArray.map(
            (itemPlan, index) => {
                let css_state =
                    "list-action-plans-content-link-row-date-state-" +
                    itemPlan.status_text.toLowerCase();
                return (
                    <div key={index}>
                        {index ? (
                            <div className="community-gathering-compliance-line" />
                        ) : null}
                        <div
                            className="list-action-plans-block-standard-link"
                            onClick={() => this.selectLinkPlan(index)}
                        >
                            <div className="list-action-plans-content-link">
                                <div className="list-action-plans-content-link-row-standard">
                                    {itemPlan.title}
                                </div>
                                <div className="list-action-plans-content-link-row-date">
                                    <div
                                        className={
                                            "list-action-plans-content-link-row-date-state " +
                                            css_state
                                        }
                                    >
                                        {itemPlan.status_text}
                                    </div>
                                    <div className="list-action-plans-content-link-row-date-due">
                                        {"Due: "}{" "}
                                        {moment(itemPlan.due_date, "YYYY-MM-DD").format("DD.MM.YY")}
                                    </div>
                                </div>
                            </div>
                            <div className="list-action-plans-block-forward-icon">
                                <IonIcon icon={chevronForwardOutline} />
                            </div>
                        </div>
                    </div>
                );
            }
        );
        this.setState({ actionPlanList: arrayList });
    }

    addNewCommitment() {
        this.props.history.push(`/community/${this.idCommunity}/action_plan`);
    }

    setActionPlanList(status:string) {
        this.status = status
        const actionPlans = this.props.actionPlans.action_plans
        this.actionPlanArray =  status === 'all' ? actionPlans : status=== 'archive'? actionPlans.filter((el: { status: string; })=>el.status ==="CANCELLED") : actionPlans.filter((el: { status: string; })=>  el.status !== 'COMPLETED'&&el.status !== 'CANCELLED')
        this.createListPlans()
    }
    async ionViewWillEnter () {
        const {dispatch} = this.props;
        dispatch(setHeaderActiveTab(1))
        this.idCommunity = Number(this.props.match.params.idCommunity);
        await this.getPlans(this.idCommunity);
    }

    selectedCommunityGroups(option: any) {}

    render() {
        const { t } = this.props;

        return (

            <>
                <div className={"list-action-plans-title-page-wrap" }>
                    <div
                        className={"list-action-plans-title-left-block" }
                    >
                        <div className="display-flex">
                            <GoBack urlMove={`/community/my`} text={"My Community"}/>
                        </div>
                        <div
                            className={"list-action-plans-title-left-block-name"}
                        >
                            {t("actionPlan")}
                        </div>
                    </div>
                    <CommunityChart
                        data={ this.actionPlan}
                        info={{completed:this.progress_plan?.COMPLETED, active:this.progress_plan?.ACTIVE}}
                        showSentiment={false}
                    />
                </div>
                <div className="wrap-content-resource">
                    <div className="content-resource">
                        <div className="control-resource-gathering">
                            <ButtonResource
                                id="list-action-plans-button-all"
                                text_color="white"
                                onClickHandler={()=>this.setActionPlanList('all')}
                                active={this.status === 'all'}
                            >
                                {t("all")}
                            </ButtonResource>
                            <ButtonResource
                                id="list-action-plans-button-current"
                                text_color="rgb(107, 114, 128)"
                                onClickHandler={()=>this.setActionPlanList('current')}
                                active={this.status === 'current'}
                            >
                                {t("current")}
                            </ButtonResource>
                            <ButtonResource
                                id="list-action-plans-button-archive"
                                text_color="rgb(107, 114, 128)"
                                onClickHandler={()=>this.setActionPlanList('archive')}
                                active={this.status === 'archive'}
                            >
                                {t("archive")}
                            </ButtonResource>
                        </div>
                        <div className="content-resource-summary">
                            <div className={"content-resource-summary-detail community-gathering-compliance-add"}>
                                <div className="community-gathering-compliance-wrap-select-groups">
                                    <CustomDropDown
                                        setValue={(option) => this.selectedCommunityGroups(option)}
                                        cssControl="community-gathering-compliance-groups-select-dropdown"
                                        options={[]}
                                        defaultOption="All Galway Groups"/>
                                </div>
                                <div className="content-resource-summary-detail-subcontainer list-action-plans-content">
                                    {this.state.actionPlanList}
                                    {this.userAccess ? (
                                        <div>
                                            <div className="community-gathering-compliance-line"/>
                                            <div className="community-gathering-compliance-button-section">
                                                <ButtonResource
                                                    id="list-action-plans-content-button-add-commitment"
                                                    text_color="white"
                                                    onClickHandler={this.addNewCommitment}
                                                >
                                                    {t("addNewCommitment")}
                                                </ButtonResource>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        );
    }
}

const mapState = (state: any) => ({ actionPlans: state.actionPlans.actionPlansData })
const decoratedComponent = connect(mapState)(withTranslation("common")(withIonLifeCycle(OfflineListActionPlans)));
export { decoratedComponent as OfflineListActionPlans };
