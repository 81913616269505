import React, {useCallback, useEffect, useState} from "react";
import { IonItem, IonLabel, IonImg } from "@ionic/react";
import "./style.css";
import trendIcon from "../../assets/images/charts-control/trend.svg";
import trendInactiveIcon from "../../assets/images/charts-control/trend-inactive.svg";
import currentIcon from "../../assets/images/charts-control/current.svg";
import currentInactiveIcon from "../../assets/images/charts-control/current-inactive.svg";
import {ThemeChartType} from "../types"

import { useTranslation } from "react-i18next";
import { useThemes } from 'contexts/themesChartsContext';
import ChartComponent from 'components/browseCharts/chartComponent';
import IonLoadingPage from "components/ionLoadingPage/IonLoadingPage";
import { useHistory } from "react-router-dom";
import {ITheme} from "../../models";
import {getThemes} from "../../services/Theme";

const mapTabToChart: any = {
    'standards': 'standards',
    'action': 'ListActionPlans',
    'sentiment': 'sentiments'
};
const mapCommunityTabToChart: any = {
    'standards': 'standards',
    'action': 'action_plan',
    'sentiment': 'sentiments'
};

const BrowseChartsContainer: React.FC = () => {
  const {
      themes,
      activeTheme,
      setActiveThemeId,
      themeCharts,
      isLoading,
      activeParentTab,
      id: parentId,
      level
  } = useThemes();

  const { t } = useTranslation("common");
    const history = useHistory();

    const openChartLink = async (
        idParams: string | undefined
    ) => {
        const id = idParams || "0";
        const url = level === '4' ? `/community/${parentId}/${mapCommunityTabToChart[activeParentTab]}/${id}` : `/community/${id}/${mapTabToChart[activeParentTab]}`;
        history.push(url);
    };

    const [showHistory, setHistory] = useState(false);
    const onClick = async (tabId: any) => {
        setActiveThemeId(tabId);
    };

  return (
    <div className="content_container_chart">
        <div className="themes_controls1">
            {
                themes.map((tabEl, index) =>
                    <div className={`theme_button ${ activeTheme && activeTheme.name === tabEl.name ? 'active': ''}`}
                         key={`theme` + tabEl.id}
                         onClick={() => onClick(tabEl.id)}
                    >
                        <span className={' button_text'} title={t(tabEl.name) as string}>{t(tabEl.name)}</span>
                    </div>
                )
            }
        </div>

        {   <div className="charts_place_container">
                <select className="themes_mobile_controls"
                    // @ts-ignore
                        selected={ activeTheme && activeTheme.id || 0 }
                        onChange={(e: any) => {
                            onClick(+e.target.value)
                        }}>
                    {
                        themes.map((tabEl, index) =>
                            <option
                                key={`mobileTheme` + tabEl.id} value={tabEl.id}
                            >
                                {t(tabEl.name)}
                            </option>
                        )
                    }
                </select>
                <div className="charts_place card">
                <div className="charts_container">
                    { themeCharts.length && themeCharts.map((themeChart:any) =>
                        <ChartComponent
                            openChartLink={openChartLink}
                            showHistory={showHistory}
                            showSentiment={activeParentTab === 'sentiment'}
                            chartName={themeChart.name}
                            chartId={themeChart.id}
                            chart={themeChart.data}
                            key={`themeChart${themeChart.id}`}
                        />
                    )  || <div className="charts_container justify-centered">
                        <IonLabel>No Data</IonLabel>
                    </div>}
                </div>
                <div className="divider"/>
                <div className={"charts_controls"}>
					<IonItem className={"charts-controls-item"}  onClick={() => setHistory(false)}>
						<IonImg src={showHistory?currentInactiveIcon:currentIcon} />
						<IonLabel className={`chart-controls-label ${showHistory?'':'active'}`}>Current</IonLabel>
					</IonItem>
					<IonItem className={"charts-controls-item"} onClick={() => setHistory(true)}>
						<IonImg src={showHistory?trendIcon:trendInactiveIcon} />
						<IonLabel className={`chart-controls-label ${showHistory?'active':''}`}>Trend</IonLabel>
					</IonItem>
                </div>

                <IonLoadingPage isLoading={isLoading}/>
            </div>

            </div>

        }


    </div>
  );
};

export default BrowseChartsContainer;
