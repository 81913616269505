import React from "react";
import {  PieChart, Pie, Cell, Label , ResponsiveContainer } from "recharts";

type Props = {
    data:number
    options?:any
    color: string,
    backgroundColor?:string
}

const preparePieChartData:(value: number, color: string, backgroundColor: string) => [{ color: string; value: number }, { color: string; value: number }] = (value:number, color: string, backgroundColor:string) => {
    const diffValue = 100 - value
    return [
        {
            value,
            color,
        },
        {
            value:diffValue,
            color: backgroundColor
        }
    ]
}

const BrowseChartsContainer: React.FC<Props> = ({ color, data, backgroundColor, options}) => {
    const chartData = preparePieChartData(data, color, backgroundColor||"#E5E7EB");

    return (
        <ResponsiveContainer className={"pieChartContainer"} width={"100%"} height={"100%"} >
            <PieChart >
                <Pie
                    data={chartData}
                    startAngle={225}
                    endAngle={-45}
                    isAnimationActive={false}
                    innerRadius="60%"
                    outerRadius="100%"
                    dataKey="value"
                    stroke={"transparent"}
                >
                    {chartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={chartData[index].color} />
                    ))}
                    <Label className={options&&options.browseCharts?"chart-text":"text-xl"} value={`${Number.isInteger(data)?data:data.toFixed(2)}%`} position="center" fill={color} />
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
};

export default BrowseChartsContainer;
