import API from "../api/api";
import {IGetThemesResponse} from "../models";

export const getThemes = async () => {
  const url = `/themes`;
  let response: IGetThemesResponse = await API.get(url);

  const themes = response.data || [];

  return themes;
};
