import React from "react";
import { useHistory, Link } from "react-router-dom";
import arrowGreenLeftImage from "../../assets/images/arrow-green-left.png";
import { THEMES } from "../../mocks/layout";
import { getTextColor } from "../../theme/CombineThemes";
import "./GoBack.css";

type Props = {
  isGoBack?: boolean;
  text?: string;
  theme?: string;
  urlMove?: string;
  eventAfterClick?: () => void;
};

const GoBack: React.FC<Props> = ({
  isGoBack = false,
  text = "Exit",
  theme = THEMES.GREEN,
  urlMove,
  eventAfterClick,
}) => {
  const history = useHistory();

  const textColor = () => {
    return getTextColor(theme);
  };

  const handlerBtn = () => {

    if (isGoBack) history.goBack();
    else {
      if (urlMove) history.push(urlMove);
      else history.push("/");
    }
    if (eventAfterClick) eventAfterClick();
  };

  return (
    <>
      <div
        className={`small-header-exit-btn ${textColor()}`}
        onClick={handlerBtn}
      >
        <img
          className="arrow-left-green-image-in-small-header"
          src={arrowGreenLeftImage}
          alt="arrowGreenLeftImage"
        />
        {text}
      </div>
    </>
  );
};

export default GoBack;
