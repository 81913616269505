export const excellent: JSX.Element = (
    <>
        <path
            d="M20,36A16,16,0,1,1,36,20,16.01833,16.01833,0,0,1,20,36ZM20,6A14,14,0,1,0,34,20,14.01572,14.01572,0,0,0,20,6Z"/>
        <path
            d="M20,30a7.15683,7.15683,0,0,1-6.98047-5.80371A1,1,0,0,1,14,23H26a1,1,0,0,1,.98047,1.19629A7.15683,7.15683,0,0,1,20,30Zm-4.59766-5a5.02982,5.02982,0,0,0,9.20411,0Z"/>
        <circle cx="25.5" cy="17.5" r="1.5"/>
        <circle cx="14.5" cy="17.5" r="1.5"/>
    </>
);
export const good: JSX.Element = (
    <g>
        <path d="M20,36A16,16,0,1,1,36,20,16.01833,16.01833,0,0,1,20,36ZM20,6A14,14,0,1,0,34,20,14.01572,14.01572,0,0,0,20,6Z" />
        <path d="M20,29a7.74227,7.74227,0,0,1-6.44287-3.44336,1.00025,1.00025,0,0,1,1.66211-1.11328,5.74818,5.74818,0,0,0,9.56152,0,1.00025,1.00025,0,0,1,1.66211,1.11328A7.74227,7.74227,0,0,1,20,29Z" />
        <circle cx="25.5" cy="17.5" r="1.5" />
        <circle cx="14.5" cy="17.5" r="1.5" />
    </g>
);
export const satisfactory: JSX.Element = (
    <g >
        <path d="M20,36A16,16,0,1,1,36,20,16.01833,16.01833,0,0,1,20,36ZM20,6A14,14,0,1,0,34,20,14.01572,14.01572,0,0,0,20,6Z" />
        <circle cx="25.5" cy="17.5" r="1.5" />
        <circle cx="14.5" cy="17.5" r="1.5" />
        <line x1="14" y1="25" x2="26" y2="25" strokeWidth={4} />
    </g>

);
export const unsatisfactory: JSX.Element = (
    <g>
        <path d="M20,36A16,16,0,1,1,36,20,16.01833,16.01833,0,0,1,20,36ZM20,6A14,14,0,1,0,34,20,14.01572,14.01572,0,0,0,20,6Z" />
        <path d="M25.61279,29a.9985.9985,0,0,1-.832-.44336,5.74818,5.74818,0,0,0-9.56152,0,1.00025,1.00025,0,0,1-1.66211-1.11328,7.74931,7.74931,0,0,1,12.88574,0A1.00022,1.00022,0,0,1,25.61279,29Z" />
        <circle cx="25.5" cy="17.5" r="1.5" />
        <circle cx="14.5" cy="17.5" r="1.5" />
    </g>
);
export const badly: JSX.Element = (
    <g>
        <path d="M20,36A16,16,0,1,1,36,20,16.01833,16.01833,0,0,1,20,36ZM20,6A14,14,0,1,0,34,20,14.01572,14.01572,0,0,0,20,6Z" />
        <path d="M26.332,21.44531a1.03785,1.03785,0,0,0-1.66406,0,15.74845,15.74845,0,0,0-1.9906,4.25659,5.48578,5.48578,0,0,0-7.24964,1.74146,1.00025,1.00025,0,0,0,1.66211,1.11328,3.49861,3.49861,0,0,1,5.82032,0,1.025,1.025,0,0,0,.0625.083l.34472.41406c.00421.00507.01074.00653.01508.01154.01684.01757.02869.03857.046.05584A3,3,0,0,0,28.5,27C28.5,24.76172,26.55371,21.77832,26.332,21.44531ZM26.20654,27.707a1.019,1.019,0,0,1-1.40234.007l-.0354-.0426A.98488.98488,0,0,1,24.5,27a7.702,7.702,0,0,1,1-3.0127A7.702,7.702,0,0,1,26.5,27,.98992.98992,0,0,1,26.20654,27.707Z" />
        <circle cx="25.5" cy="17.5" r="1.5" />
        <circle cx="14.5" cy="17.5" r="1.5" />
    </g>
);

export default [
    badly,
    unsatisfactory,
    satisfactory,
    good,
    excellent,
];
