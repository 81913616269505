import React, {ComponentProps} from "react";
import {THEMES} from "../mocks/layout";
import ButtonResource from "../components/content/ButtonResource";
import TitlePageTextTheme from "../components/titlePageTextTheme/titlePageTextTheme";
import ContainerPosts from "../components/containerPosts/ContainerPosts";
import ShallPost from "../components/shallPost/ShallPost";
import SimpleListOfLink from "../components/simpleListOfLink/SimpleListOfLink";
import {getResourceGroupsList,} from '../services/Resources';
import {
    isUserGlobalAdmin,
    isUserGlobalIrelandAdmin,
    isUserGlobalIrelandTanzaniaAdmin,
    isUserGlobalTanzaniaAdmin
} from "../helpers/functions";
import IonLoadingPage from "../components/ionLoadingPage/IonLoadingPage";
import {withTranslation} from "react-i18next";
import "./resources.css";
import {setHeaderActiveTab} from "../reducers/headerReducer";
import { withIonLifeCycle } from '@ionic/react';
import {connect} from "react-redux";

const THEME_PAGE = THEMES.BLUE;

type ResourceGroup = {
    title: string;
    text: string;
    id: number;
    url: string;
};
type ResourcesState = {
    resourceGroup: JSX.Element | null;
};

type ResourcesProps = {};

class Resources extends React.Component<ComponentProps<any>,ResourcesState> {
    array_groups: ResourceGroup[];
    groups_description: string;
    isLoading: boolean;
    globalAdmin: boolean;
    levelCountrysAdmin: boolean;
    adminTanzania: boolean;
    adminIreland: boolean;

    constructor(props: any) {
        super(props);
        this.array_groups = [];
        this.state = {resourceGroup: null};
        this.isLoading = false;
        this.groups_description = "";
        this.globalAdmin = false;
        this.levelCountrysAdmin = false;
        this.adminTanzania = false;
        this.adminIreland = false;
        this.getGroupsList = this.getGroupsList.bind(this);
        this.setResourceGroup = this.setResourceGroup.bind(this);
        this.showResourcesOfGroup = this.showResourcesOfGroup.bind(this);
        this.AddGroup = this.AddGroup.bind(this);
    }

    componentDidMount() {
        if (isUserGlobalAdmin())
            this.globalAdmin = true;
        else if (isUserGlobalIrelandTanzaniaAdmin())
            this.levelCountrysAdmin = true;
        else if (isUserGlobalIrelandAdmin())
            this.adminIreland = true;
        else if (isUserGlobalTanzaniaAdmin())
            this.adminTanzania = true;
        this.getGroupsList();

        this.props.history.listen((location: any, action: any) => {
            if (location.pathname === "/resources") this.getGroupsList();

        });
    }

    ionViewWillEnter () {
        const {dispatch} = this.props;
        dispatch(setHeaderActiveTab(2))
    }

    getGroupsList = async () => {
        try {
            var {data} = await getResourceGroupsList();
            this.array_groups = data.map((item: any, index: number) => {
                return {
                    // title: item.en_title,
                    // text: item.en_summary,
                    title: item.title,
                    text: item.summary,
                    id: item.id,
                    url: "/resource/group/" + item.id + "/resources",
                };
            });
            this.isLoading = false;
            this.setResourceGroup();
        }
        catch (error: any) {
            this.isLoading = false;
            console.log(
                `end of reading getResourceGroup =>error status: ${error.response.status}`
            );
        }
    };

    showResourcesOfGroup(indexLink: number) {
        console.log("selected", this.array_groups[indexLink].title);
    }

    setResourceGroup() {
        const {t} = this.props;
        this.groups_description = t("allAboutSAUTIYouth");

        const resourceGroup: JSX.Element = (
            <div className="wrap_groups">
                <ContainerPosts
                    //  class_section="align-browse-news-wall2"
                    class_section="wrap_groups-news-wall"
                    theme={THEME_PAGE}
                    text={this.groups_description}
                >
                    <section>
                        <ShallPost cssSectionWall="community-page-css-section-wall">
                            <SimpleListOfLink theme={THEME_PAGE} links={this.array_groups}/>
                        </ShallPost>
                        {
                            this.globalAdmin ?
                                <div className="add_group_button_container">
                                    <ButtonResource text_color="white" onClickHandler={this.AddGroup}>
                                        {t("addnewgroup")}
                                    </ButtonResource>
                                </div> :
                                null
                        }
                    </section>
                </ContainerPosts>
            </div>
        );
        this.setState({resourceGroup: resourceGroup});
    }

    AddGroup() {
        this.props.history.push("/resources/addgroup");
    }

    render() {
        const {t} = this.props;

        return (
            <>
                <TitlePageTextTheme
                    theme={THEME_PAGE}
                    cssTitleScreenCommunity="resourse-title-page-text-green"
                >
                    <>
                        <div>{t("resources")}</div>
                    </>
                </TitlePageTextTheme>
                {this.state.resourceGroup}
                <IonLoadingPage isLoading={this.isLoading}/>
            </>
        );
    }
}

//export default Resources
const decoratedComponent = connect()(withTranslation("common")(withIonLifeCycle(Resources)));
export { decoratedComponent as Resources };
