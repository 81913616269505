import React from 'react';
import "./content.css";
import MakeChart from './MakeChart'
import ChartDescription from './ChartDescription'
import PieChartComponent from "../chartsComponents/pieChart";

type ActionPlanProps = {
  isData: boolean;
  country:string,
  description:string,
  progress:number,
  children: JSX.Element,
  commitments:number,
  state_santiments: {
    evaluation:any,
    linear_points:number[]
  }
};
type ActionPlanState = {
  ring: JSX.Element|null;
};

class ActionPlan extends React.Component<ActionPlanProps,ActionPlanState> {
  setOption: {
    type: number,
    colorInRing: string,
      colorRing: string,
      colorProgress: string,
      widthRing: number,
      percent: number,
      fontSizeText: string,
      colorText: string
  }
  constructor(props: any) {
    super(props);

    this.setOption = {
      type: 1,/*1-circle,2-столбик*/
      colorInRing: 'white',
        colorRing: 'rgb(209, 213, 219)',
        colorProgress: 'blue',
        widthRing: 0,
        percent: this.props.progress,
        fontSizeText: '',
        colorText: 'blue'
    };
    this.state = {ring:null};
    this.onResize = this.onResize.bind(this);
    this.setSizeRing = this.setSizeRing.bind(this);
  }

  onResize(event:any) {
    this.setSizeRing();
  }

  componentDidMount() {
    window.addEventListener("resize", this.onResize);
    this.setSizeRing();

  }

  componentDidUpdate(
    prevProps: ActionPlanProps,
    prevState: ActionPlanState
  ): void {

  }

  setSizeRing() {
    var ring:JSX.Element = <MakeChart setOption={this.setOption}/>;
    if (this.props.state_santiments.evaluation != null) {
        const keys:string[] = Object.keys(this.props.state_santiments.evaluation);

        if (keys.length != 0) {
            if (keys[0].toLowerCase() === 'improving'||
                keys[0].toLowerCase() === 'accelerating' ||
                keys[0].toLowerCase() === 'good') {

                this.setOption.colorText = 'rgb(62, 165, 81)';
                this.setOption.colorProgress = 'rgb(62, 165, 81)';
          }else
          if (keys[0].toLowerCase() === 'stalled') {

              this.setOption.colorText = 'rgb(231, 181, 0)';
              this.setOption.colorProgress = 'rgb(231, 181, 0)';
        }
        else if (keys[0].toLowerCase() === 'declining'||
                 keys[0].toLowerCase() === 'bad'||
                 keys[0].toLowerCase() === 'poor' ) {

          this.setOption.colorText = 'rgb(219, 128, 46)';
          this.setOption.colorProgress = 'rgb(219, 128, 46)';

        }
      }
    }

    if (window.outerWidth <= 790 && this.setOption.widthRing!=20) {
      this.setOption.widthRing = 20;
      this.setOption.fontSizeText = '18px';
      this.setState({ring:ring});
    }else {
      if (window.outerWidth > 790) {
            this.setOption.widthRing = 25;

            if (window.outerWidth <= 1366 && this.setOption.fontSizeText != '20px') {
              this.setOption.fontSizeText = '20px';
              this.setState({ring:ring});
            }
            else if (window.outerWidth > 1366 && this.setOption.fontSizeText != '24px') {
              this.setOption.fontSizeText = '24px';
              this.setState({ring:ring});
            }
        }
    }
  }

  render() {

      var descript_el:JSX.Element|null = null;
      if (this.props.commitments)
      descript_el = <ChartDescription commitments={this.props.commitments+' Commitments'} wrap_container="subcontainer_linear_action"/>

    else {
      if (this.props.state_santiments.evaluation != null || this.props.state_santiments.linear_points.length)
        descript_el = <ChartDescription state_santiments={this.props.state_santiments} wrap_container="subcontainer_linear_action"/>

    }

      if (this.props.isData) {
        return (
          <div className='action_block_full1'>
            <div className="name_of_block_action">
              <div className="name_of_block1">
              {this.props.children}
              </div>
            </div>
            <div className="container_linear_action">
              {descript_el}
            </div>
            <div className="wrap_chart">
              <div className="ring_container">
                  <PieChartComponent color={this.setOption.colorProgress} data={this.props.progress||0}></PieChartComponent>
              </div>
              <div className="ring_container_action">
              {this.state.ring}
              </div>
            </div>
          </div>
        );
    }else {

      return (
          <div className='action_block_short'>
            <div className="name_of_block_description">{this.props.children}</div>
            <div className="description_commit">{this.props.description}</div>
          </div>
      );
    }


  }
}

export default ActionPlan;
