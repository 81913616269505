import React from "react";
import "./ArrowLeftAndRight.css";
import chevronleftImage from "../../assets/images/functional/arrow-left.svg";
import chevronRightImage from "../../assets/images/functional/arrow-right.svg";

type Props = {
  onClick: (direct: boolean) => void;
};

const ArrowLeftAndRight: React.FC<Props> = ({ onClick }) => {
  return (
    <section className="arrow-left-and-righ-section">
      <div
        className="arrow-left-and-righ-block arrow-left-and-righ-margin-right"
        onClick={() => onClick(false)}
      >
        <img
          className="arrow-left-and-right-image"
          src={chevronleftImage}
          alt="chevronleftImage"
        />
      </div>
      <div className="arrow-left-and-righ-block" onClick={() => onClick(true)}>
        <img
          className="arrow-left-and-right-image"
          src={chevronRightImage}
          alt="chevronRightImage"
        />
      </div>
    </section>
  );
};

export default ArrowLeftAndRight;
