import React, { useState } from "react";
import "./ListLinkStatus.css";
import crossImage from "../../assets/images/indicators/cross.svg";
import pendingImage from "../../assets/images/indicators/pending.svg";
import tickImage from "../../assets/images/indicators/tick.svg";
import chevronRightImage from "../../assets/images/functional/chevron-right.svg";
import menuImage from "../../assets/images/functional/menu.svg";
import { StandardStatusT } from "../../types/types";
import { v4 as uuidv4 } from "uuid";

const FAIL = "Fail";
const PASS = "Pass";
const NOT_RATED = "Not rated";

type Props = {
  listLinks: StandardStatusT[];
  headerText?: string;
  onClick?: (id: number) => void;
};

const ListLinkStatus: React.FC<Props> = ({
  listLinks,
  headerText,
  onClick,
}) => {
  const onHandler = (id: number) => {
    if (onClick) onClick(id);
  };
  return (
    <div className="list-links-status-component-container">
      <section className="list-links-status-section">
        <div className="list-links-status-section-header-text">
          {headerText && (
            <>
              <div className="status-header-text">
                <div>{headerText}</div>
                <img
                  className="list-status-header-menu-image"
                  src={menuImage}
                  alt="menuImage"
                />
              </div>
            </>
          )}
        </div>
        {listLinks.length > 0 && (
          <>
            {listLinks.map((item, index) => {
              return (
                <div
                  className="list-status-block"
                  key={uuidv4()}
                  onClick={() => onHandler(item.id)}
                >
                  <div className="list-status-img-text">
                    {item.img && (
                      <img
                        className="list-link-status-image"
                        src={item.img}
                        alt="pendingImage"
                      />
                    )}
                    <div
                      className={`${
                        !item.description &&
                        `align-text-status-list-header-text-link`
                      }`}
                    >
                      <div
                        className={`list-status-align-vertical list-status-text-normal ${
                          item.nameCss && item.nameCss
                        }`}
                      >
                        {item.name}
                      </div>
                      {item.description && (
                        <div className="list-status-description-text">
                          {item.description}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="list-status-align-vertical">
                    <img
                      className="list-status-chevron-right-image"
                      src={chevronRightImage}
                      alt="chevronRightImage"
                    />
                  </div>
                </div>
              );
            })}
          </>
        )}
      </section>
    </div>
  );
};

export default ListLinkStatus;
