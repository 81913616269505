export interface StandardsState {
  standardsData: any;
  standardsDetails: any
}

export const initialState: StandardsState = {
  standardsData: {
    trend: [],
    rate: 0,
    trend_all: [],
    trendDirection: { 'Good': 'Good'},
    standards: [],
    def: true
  },
  standardsDetails: {},
};

const SET_STANDARDS = "SET_STANDARDS";
const SET_STANDARDS_DETAILS = "SET_STANDARDS_DETAILS";

export default function standardsReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_STANDARDS:
      return {
        ...state,
        standardsData: {
          ...action.payload
        },
      };
    case SET_STANDARDS_DETAILS:
      return {
        ...state,
        standardsDetails: {
          ...state.standardsDetails,
          [action.payload.id]: action.payload
        },
      };
    default:
      return state;
  }
}

export const setStandards = (standards: any) => ({
  type: SET_STANDARDS,
  payload: standards,
});
export const setStandardsDetails = (standardsDetails: any) => ({
  type: SET_STANDARDS_DETAILS,
  payload: standardsDetails,
});