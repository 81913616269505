var view_data:any[]= 
[{
	 country:"Ireland",
	 link:'/community/browse/level/0/id/2',
	 section_charts:[
	 	{
		section_name:"Standards Compliance",
	 	type_chart:1,
	 	community_sentiment:{},
	 	commitments:0,
	 	level:0, 
	 	state_santiments: {
  			evaluation:null, 
  			linear_points:[]
  		}
  	},{
	 	section_name:"Community Sentiment",
	 	type_chart:2,
	 	//community_sentiment:{"badly":0,"unsatisfactory":0,"satisfactory":0,"good":0,"excelent":0},
	 	community_sentiment:{},
	 	commitments:0,
	 	level:0,
	 	state_santiments: {
  			evaluation:null,
  			linear_points:[]
  		}
	 },{
	 	section_name:"Action Plan Progress",
	 	type_chart:1, 
	 	community_sentiment:{},
	 	commitments:0,
	 	level:0,
	 	state_santiments: {
  			evaluation:null,
  			linear_points:[]
  		}
	 }]
},
{
	country:"Tanzania",
	link:'/community/browse/level/0/id/3',
 	section_charts:[
	{
	 	section_name:"Standards Compliance",
	 	type_chart:1, 
	 	community_sentiment:{},
	 	commitments:0,
	 	level:0,
	 	state_santiments: {
  			evaluation:null,
  			linear_points:[]
  		}
	 },{
	 	section_name:"Community Sentiment",
	 	type_chart:2, 
	 //	community_sentiment:{"badly":0,"unsatisfactory":0,"satisfactory":0,"good":0,"excelent":0},
	 	community_sentiment:{},
	 	commitments:0,
	 	level:0,
	 	state_santiments: {
  			evaluation:null,
  			linear_points:[]
  		}
	 },{
	 	section_name:"Action Plan Progress",
	 	type_chart:1, 
	 	community_sentiment:{},
	 	commitments:0,
	 	level:0,
	 	state_santiments: {
  			evaluation:null,
  			linear_points:[]
  		}
	 }]

}];
export default view_data;