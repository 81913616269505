import React from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "../customButton/CustomButton";
import { THEMES } from "../../mocks/layout";
import Moment from "react-moment";
import menuImage from "../../assets/images/functional/menu.svg";
import { LANG, DEFAULT_LANG } from "../../lang";
import "./WrapperCard.css";

const PAGE_THEME = THEMES.GREEN;
const PAGE_LANG = window.localStorage.getItem(LANG) || DEFAULT_LANG;

type Props = {
  headerText?: string;
  theme?: string;
  children: React.ReactNode;
};

const WrapperCard: React.FC<Props> = ({
  headerText,
  theme = PAGE_THEME,
  children,
}) => {
  return (
    <>
      <div className="community-commitment-shell-with-text ">
        {headerText && (
          <div className="commitment-city-header-card">
            {headerText}
            <img
              className="wrapper-card-menu-image"
              src={menuImage}
              alt="menuImage"
            />
          </div>
        )}
        <div
          className={`community-commitment-shell-border ${
            headerText && "shell-top-none-radius"
          }`}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default WrapperCard;
