import { getResource, getResources } from "../Resources";
import { createErrorApi } from "../../helpers/helperApi";

export const middlewareGetResource = async (iResource?: string) => {
  try {
    const { data } = await getResource(iResource);
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewareGetResources = async () => {
  try {
    const { data } = await getResources();
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};
