import {setSentimentsTrends} from 'reducers/communityTrendsReducer'
import recalculateTrends from 'helpers/offline/recalculateTrends'
import {setCommunityMyPageTree} from "../communityReducer";
import {
    setSentimentsDescriptions,
    setSentimentsDetails,
    setSentiments
} from "../sentimentsReducer";
import {AppState} from "../index";
import _, {set} from 'lodash';

const LEVELS = [17, 42, 58, 83];

const LEVELS_NAMES = ['Very Bad', 'Bad', 'Ok', 'Good', 'Very Good'];
const makeState = (trend: any) => {
    const levels = LEVELS.filter(level => trend >= level);
    const levelIndex = levels.length - 1 < 0 ? levels.length : levels.length - 1;

    const levelName = LEVELS_NAMES[levelIndex];

    return { [levelName]: levelName };

}

const normalizeVals = (sumVals: {}) => {
    const entries = Object.entries(sumVals);
    const sum = entries.reduce((acc, el:any) => acc + el[1], 0);

    const res: any = {};

    entries.forEach(([key, value]: any)=>{
        res[key] = (value/sum) * 100
    });

    return res;

};
const weights: {[key: string]: number} = {
    rating_very_bad:-3,
    rating_bad:-1,
    rating_ok:0,
    rating_good:1,
    rating_very_good:3
};
const findRate = (sentimentsValuesRes:any) => {
    const entries = Object.entries(sentimentsValuesRes).filter(el => Object.keys(weights).includes(el[0]));
    const sum = entries.reduce((acc: any, el: any) => acc + el[1], 0);
    const sumScalar = sum ? entries.map(([key, el]: any) => (el/sum)*weights[key]).reduce((acc, el) => acc + el, 0): 0;
    return +(((sumScalar + 3) / 6) * 100).toFixed(2);
}

const findRateOfSentiments = (sentimentsValuesArr: any[]) => {
    const sentimentsValuesRes: any = {
        rating_very_bad:0,
        rating_bad:0,
        rating_ok:0,
        rating_good:0,
        rating_very_good:0
    };

    for (const sentimentsValues of sentimentsValuesArr) {
        for ( const sentimentsKey of Object.keys(sentimentsValuesRes)) {
            sentimentsValuesRes[sentimentsKey] += sentimentsValues[sentimentsKey];
        }
    }

    const rate = findRate(sentimentsValuesRes);

    return {
        rate,
        sentiments: sentimentsValuesRes,
        normalizedVals: normalizeVals(sentimentsValuesRes),
        state: makeState(rate)
    };
}

const updateTrendsPageTree = (myPageTreeData: any, newTrends: any) => {
    myPageTreeData['sentiments'] = newTrends;

    myPageTreeData.all_rate_list[1] = newTrends.rate;
    myPageTreeData.rate = myPageTreeData.all_rate_list.reduce((acc: number, el: number) => acc + el,0)/myPageTreeData.all_rate_list.length;

}
const updateTrendsInList = (listData: any, newTrends: any) => {
    listData.trend = newTrends.trend;
    listData.rate = newTrends.rate;
    listData.trend_direction = newTrends.trend_direction;
    listData.trend_direction = newTrends.trend_direction;
    listData.state = newTrends.state;

}

export const sentimentUpdateDescriptionThunk = function (sentiment: any) {
    return (dispatch: any, getState: () => AppState) => {
        const sentiment_id = sentiment.id;
        sentiment.name = sentiment.en_name;
        sentiment.description = sentiment.en_description;

        const { communityTrends: { sentiments }, sentiments: { sentimentsData, sentimentsDescriptions }  } = getState()

        // ------ update trends list ------

        const { trends } = sentiments;

        const sentimentTrend = trends.find((trend: any) => trend.id === sentiment_id);

        if (sentimentTrend) {

            sentimentTrend.theme_id = sentiment.theme;
            sentimentTrend.name = sentiment.name;

            dispatch(setSentimentsTrends(sentiments));
        }

        // ------ update trends general numbers ------

        //SKIP

        // ------ update list ------

        const { sentiments: sentimentsListData } = sentimentsData;

        const sentimentListDataItemIndex = sentimentsListData.findIndex((el: any) => el.id === sentiment_id)

        if (~sentimentListDataItemIndex) {
            sentimentsListData[sentimentListDataItemIndex] = {
                ...sentimentsListData[sentimentListDataItemIndex],
                ...sentiment
            }
        } else {
            sentimentsListData.push({
                id: sentiment_id,
                current:[],
                trend_all:[],
                trend:[],
                trend_state:[],
                rate_all:[],
                rate:0,
                state:{Ok:"Ok"},
                trend_1scale:[],
                performance_measure:sentiment
            })
        }

        dispatch(setSentiments(sentimentsData));

        // ------ update descriptions ------

        let sentimentDescription = sentimentsDescriptions[sentiment_id];

        if (!sentimentDescription) {
            dispatch(setSentimentsDetails({
                id: sentiment.id,
                updates: []
            }));
        }

        if (sentimentDescription) {
            sentimentDescription.performance_measure = {
                ...sentiment
            };
        } else {
            sentimentDescription = {
                id: sentiment_id,
                current:[],
                trend_all:[],
                trend:[],
                trend_state:[],
                rate_all:[],
                rate:0,
                state:{Ok:"Ok"},
                trend_1scale:[],
                performance_measure:sentiment
            };
        }


        dispatch(setSentimentsDescriptions(sentimentDescription));

        // ------ update updates ------
        // SKIP
    }
}

export const sentimentUpdateDetailsThunk = function (sentimentUpdate: any) {
    return (dispatch: any, getState: any) => {

        const community_gathering_date = sentimentUpdate.date;
        const community_gathering_id = sentimentUpdate.id;
        const { rate: update_rate, normalizedVals, sentiments, state: sentimentState } = findRateOfSentiments(sentimentUpdate.score_cards);
        const sentiment_id = +sentimentUpdate.sentiment;
        const sentimentValues = Object.values(sentiments);

        const { communityTrends: { sentiments: sentimentsTrends }, community: { myPageTree }, sentiments: { sentimentsData, sentimentsDescriptions, sentimentsDetails }  } = getState()

        // ------ update details ------

        const sDetails = sentimentsDetails[sentiment_id];

        if (!sDetails) {
            return;
        }

        const sentimentDetailsUpdates = sDetails.updates;


        const existingUpdateIndex = sentimentDetailsUpdates.findIndex((el: any) => el.id === community_gathering_id);


        if (!~existingUpdateIndex) {
            sentimentDetailsUpdates.push({
                ...sentimentUpdate,

            });
        } else {
            sentimentDetailsUpdates[existingUpdateIndex] = {
                ...sentimentDetailsUpdates[existingUpdateIndex],
                ...sentimentUpdate
            }
        }
        dispatch(setSentimentsDetails(sDetails));

        // ------ update trends list ------

        const { trends } = sentimentsTrends;

        const trend = trends.find((trend: any) => trend.id === sentiment_id);

        if (trend) {
            let trendUpdateIndex = trend.dates.findIndex((date: string) => date === community_gathering_date);

            if (!~trendUpdateIndex) {
                const olderDates = trend.dates.filter((el: string) => el < community_gathering_date);

                trendUpdateIndex = olderDates.length;
                trend.dates.splice(trendUpdateIndex, 0, community_gathering_date);
                trend.trend.splice(trendUpdateIndex, 0, update_rate);
                trend.trend_all.splice(trendUpdateIndex, 0, sentimentValues);
            } else {
                trend.trend[trendUpdateIndex] = update_rate;
                trend.trend_all[trendUpdateIndex] = sentimentValues;
            }

            dispatch(setSentimentsTrends(sentimentsTrends));
        }



        // ------ update trends general numbers ------

        const newTrends = recalculateTrends(trends);

        const { data: myPageTreeData } = myPageTree;

        updateTrendsPageTree(myPageTreeData, newTrends);

        dispatch(setCommunityMyPageTree(myPageTree));

        // ------ update list ------

        const { sentiments: listData } = sentimentsData;

        const listDataItem = listData.find((el: any) => el.id == sentiment_id)

        if (listDataItem) {
            listDataItem.rate = update_rate;
            listDataItem.state = sentimentState;
        }

        updateTrendsInList(sentimentsData, newTrends);

        dispatch(setSentiments(sentimentsData));

        // ------ update description ------

        const sentimentDescription = sentimentsDescriptions[sentiment_id];
        const sentimentDetails = sentimentsDetails[sentiment_id];

        if (sentimentDetails) {
            let affectedGathering = sentimentDescription.current.find((el: any) => el.community_gathering.id === community_gathering_id);

            if (!affectedGathering) {
                affectedGathering = {
                    community_gathering: {
                        date: community_gathering_date,
                        id: community_gathering_id,
                    }
                };
                sentimentDescription.current.push(affectedGathering);
            }

            if (affectedGathering) {
                sentimentUpdate.score_cards.forEach((scoreCard: any) => {
                    let respondent = affectedGathering[scoreCard.respondent_type];

                    if (!respondent) {
                        respondent = {};
                        affectedGathering[scoreCard.respondent_type] = respondent;
                    }


                    respondent.values = [
                        scoreCard.rating_very_bad,
                        scoreCard.rating_bad,
                        scoreCard.rating_ok,
                        scoreCard.rating_good,
                        scoreCard.rating_very_good
                    ];
                    respondent.rate = findRate(scoreCard);
                    respondent.remarks = scoreCard.remarks;
                    respondent.proposals = scoreCard.proposals;
                    respondent.state = makeState(respondent.rate);
                });
            }
            sentimentDescription.state = sentimentState;
            sentimentDescription.rate = update_rate;

            dispatch(setSentimentsDescriptions(sentimentDescription));

            dispatch(setSentiments(sentimentsData));

            if (!trend) {
                trends.push({
                    id: sentiment_id,
                    theme_id: sentimentDescription.proposals && sentimentDescription.proposals.theme || 6,
                    trend: [update_rate],
                    trend_all: [sentimentValues],
                    dates: [community_gathering_date]
                });

                dispatch(setSentimentsTrends(sentimentsTrends));
            }
        }




    }
}

//export {};
