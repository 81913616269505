import {createAction} from "redux-actions";

const SET_TRENDS = "SET_TRENDS";
const SET_STANDARDS_TRENDS = "SET_STANDARDS_TRENDS";
const SET_ACTION_PLANS_TRENDS = "SET_ACTION_PLANS_TRENDS";
const SET_SENTIMENTS_TRENDS = "SET_SENTIMENTS_TRENDS";

export interface CommunityTrendsState {
  standards: any;
  sentiments: any;
  action_plans: any;
  def?: boolean;
}

const initialState: CommunityTrendsState = {
  standards: {
    trends:[],
  },
  sentiments:{
    trends:[],
  },
  action_plans: {
    trends:[],
  },
  def: true,
};

export default function communityTrendsReducer(inputState = initialState, action: any) {
  const { def, ...state} = inputState;
  switch (action.type) {
    case SET_TRENDS:
      return {
        ...action.payload
      };
    case SET_STANDARDS_TRENDS:
      return {
        ...state,
        standards: action.payload,
      };
    case SET_SENTIMENTS_TRENDS:
      return {
        ...state,
        sentiments: action.payload,
      };
    case SET_ACTION_PLANS_TRENDS:
      return {
        ...state,
        action_plans: action.payload,
      };
    default:
      return inputState;
  }
}

export const setTrends = createAction(SET_TRENDS);
export const setStandardsTrends = createAction(SET_STANDARDS_TRENDS);
export const setSentimentsTrends = createAction(SET_SENTIMENTS_TRENDS);
export const setActionPlansTrends = createAction(SET_ACTION_PLANS_TRENDS);
