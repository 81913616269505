import {
  getCommunityUpdates,
  getCommunityUpdatePost,
  getCommunityUpdatePostImages,
  deleteCommunityUpdatePhoto,
  postCommunityUpdatePhoto,
  putCommunityUpdatePhoto,
  getCommunityGatheringFiles,
  deleteCommunityGathingFile,
  postCommunityGathingFile,
  getSummaryDataBrowse,
  getCommunityActionPlan,
  getCommunityActionPlanUpdates,
  putCommunityUpdate,
  putCommunityGathering,
  getStandards,
  getOlderUpdatesSummaryBrowse,
  getPerformanceMeasure,
  putPerformanceMeasure,
  getCommunitySentiment,
  getCommunitySentimentId,
  getCommunityId,
  getCommunitySentimentIdUpdates,
  putCommunitySentimentIdUpdates,
  postCommunitySentimentIdUpdates,
  getCommunityActionPlanUpdateId,
  getCommunityPhotos,
  postCommunityUpdate,
  getThemes,
  postPerformanceMeasure,
  getCommunityGatherings,
  postCommunityGatheringsGetOffline,
  postCommunityGatheringsGet,
  getTrendCommunityChartsByType,

} from "../Community";
import { createErrorApi } from "../../helpers/helperApi";
import { PerformanceMeasureT } from "../../types/types";
import {getActionPlans} from "../actionPlan";

export const middlewareGetCommunityUpdates = async (idCommunity?: string) => {
  try {
    const { data } = await getCommunityUpdates(idCommunity);
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewareGetCommunityUpdatePost = async (
  idCommunity: string,
  idPost: string
) => {
  try {
    const { data } = await getCommunityUpdatePost(idCommunity, idPost);
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewateGetCommunityUpdatePostImages = async (
  idCommunity: string,
  idPost: string
) => {
  try {
    const { data } = await getCommunityUpdatePostImages(idCommunity, idPost);
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewareDeleteCommunityUpdatePhoto = async (
  idCommunity: string,
  idPost: string,
  idDelete: number
) => {
  try {
    const { data } = await deleteCommunityUpdatePhoto(
      idCommunity,
      idPost,
      idDelete
    );
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewarePostCommunityUpdatePhoto = async (
  idCommunity: string,
  idUpdate: string,
  idPost: number,
  formData: FormData
) => {
  try {
    const { data } = await postCommunityUpdatePhoto(
      idCommunity,
      idUpdate,
      (idPost = 1), // hardcode in backend
      formData
    );
    return data;
  } catch (error: any) {
    //return error;
    return createErrorApi(error);
  }
};

export const middlewarePutCommunityUpdatePhoto = async (
  idCommunity: string,
  idUpdate: string,
  idPhoto: number,
  formData: FormData
) => {
  try {
    const { data } = await putCommunityUpdatePhoto(
      idCommunity,
      idUpdate,
      idPhoto,
      formData
    );
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewareGetCommunityGatheringFiles = async (
  idCommunity: string,
  idGathering: number
) => {
  try {
    const { data } = await getCommunityGatheringFiles(idCommunity, idGathering);
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewarePostCommunityGathingFile = async (
  idCommunity: string,
  idGathering: number,
  formData: FormData
) => {
  try {
    const { data } = await postCommunityGathingFile(
      idCommunity,
      idGathering,
      formData
    );
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewareDeleteCommunityGathingFile = async (
  idCommunity: string,
  idGathering: number,
  idFile: number
) => {
  try {
    const { data } = await deleteCommunityGathingFile(
      idCommunity,
      idGathering,
      idFile
    );
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewareGetSummaryDataBrowse = async (
  level: string,
  id: string
) => {
  try {
    const { data } = await getSummaryDataBrowse(level, id);
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewareGetCommunityActionPlan = async (
  idCommunity: string,
  idActionPlan: string
) => {
  try {
    const { data, headers } = await getCommunityActionPlan(idCommunity, idActionPlan);

    data.cached = headers.cached;
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewareGetCommunityActionPlanUpdates = async (
  idCommunity: string,
  idActionPlan: string
) => {
  try {
    const { data, headers } = await getCommunityActionPlanUpdates(
      idCommunity,
      idActionPlan
    );
    return { data, headers };
  } catch (error: any) {
    return createErrorApi(error);
  }
};
export const middlewareGetCommunityActionPlanUpdatesOffline = async (
    idCommunity: string,
    idActionPlan: string
) => {
  try {
    const { data, headers } = await getCommunityActionPlanUpdates(
        idCommunity,
        idActionPlan
    );
    return { data, headers };
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewareGetCommunityActionPlanUpdateId = async (
  idCommunity: string,
  idActionPlan: string,
  idAtionPlanId: string
) => {
  try {
    const { data } = await getCommunityActionPlanUpdateId(
      idCommunity,
      idActionPlan,
      idAtionPlanId
    );
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewarePutCommunityUpdate = async (
  idCommunity: string,
  idActionPlan: string,
  dataResponce: any
) => {
  try {
    const { data } = await putCommunityUpdate(
      idCommunity,
      idActionPlan,
      dataResponce
    );
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewarePutCommunityGathering = async (
  idCommunity: string,
  idGathering: number,
  dataProps: any
) => {
  try {
    const { data } = await putCommunityGathering(
      idCommunity,
      idGathering,
      dataProps
    );
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewareGetStandards = async (idCommunity: string) => {
  try {
    const { data, headers } = await getStandards(idCommunity);

    data.cached = headers.cached;
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewareGetOlderUpdatesSummaryBrowse = async (
  idCommunity: string,
  page: string,
  pageLen?: string
) => {
  try {
    const { data } = await getOlderUpdatesSummaryBrowse(
      idCommunity,
      page,
      pageLen || "10"
    );
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewareGetPerformanceMeasure = async (id: string) => {
  try {
    const { data } = await getPerformanceMeasure(id);
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewarePutPerformanceMeasure = async (
  id: string,
  objectData: PerformanceMeasureT
) => {
  try {
    const { data } = await putPerformanceMeasure(id, objectData);
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewareGetCommunitySentiment = async (id: string) => {
  try {
    const { data, headers } = await getCommunitySentiment(id);
    data.cached = headers.cached;

    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewareGetCommunitySentimentId = async (
  id: string,
  idSentiment: string
) => {
  try {
    const { data, headers } = await getCommunitySentimentId(id, idSentiment);

    data.cached = headers.cached;

    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewareGetCommunitySentimentIdUpdates = async (
  id: string,
  idSentiment: string
) => {
  try {
    const { data, headers } = await getCommunitySentimentIdUpdates(id, idSentiment);

    data.cached = headers.cached;

    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewareGetCommunityId = async (id: string) => {
  try {
    const { data } = await getCommunityId(id);
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewarePutCommunitySentimentIdUpdates = async (
  idCommunity: string,
  idSentiment: string,
  idUpdate: number,
  dataObject: any
) => {
  try {
    const { data } = await putCommunitySentimentIdUpdates(
      idCommunity,
      idSentiment,
      idUpdate,
      dataObject
    );
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewarePostCommunitySentimentIdUpdates = async (
  idCommunity: string,
  idSentiment: string,
  dataObject: any
) => {
  try {
    const { data } = await postCommunitySentimentIdUpdates(
      idCommunity,
      idSentiment,
      dataObject
    );
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewareGetCommunityPhotos = async (idCommunity: string) => {
  try {
    const { data } = await getCommunityPhotos(idCommunity);
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewarePostCommunityUpdate = async (
  idCommunity: string,
  dataPost: any
) => {
  try {
    const { data } = await postCommunityUpdate(idCommunity, dataPost);
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewareGetThemes = async () => {
  try {
    const { data } = await getThemes();
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewarePostPerformanceMeasure = async (
  objectData: PerformanceMeasureT
) => {
  try {
    const { data } = await postPerformanceMeasure(objectData);
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewareGetCommunityGatherings = async (idCommunity: string) => {
  try {
    const { data } = await getCommunityGatherings(idCommunity);
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewarePostCommunityGatheringsGetOffline = async (
  idCommunity: string,
  date: string,
  id: number
) => {
  try {
    const { data } = await postCommunityGatheringsGetOffline(idCommunity, date, id);
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewarePostCommunityGatheringsGet = async (
    idCommunity: string,
    date: string
) => {
  try {
    const { data } = await postCommunityGatheringsGet(idCommunity, date);
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewareGetCommunityActionPlans = async (idCommunity: number) => {
  try {
    const { data, headers } = await getActionPlans(idCommunity);
    data.cached = headers.cached;

    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};
export const middlewareGetCommunityTrends = async (idCommunity: string, type: string) => {
  try {
    const { data, headers } = await getTrendCommunityChartsByType(idCommunity, type);

    return {
      data,
      cached: headers.cached
    };
  } catch (error: any) {
    return createErrorApi(error);
  }
};
