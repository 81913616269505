import React from "react";
import { LineChart, Line, ResponsiveContainer, Legend, YAxis} from "recharts";
import emojisArr from "./emojis";

type Props = {
    data:any,
    showSentiment:boolean,
    color: string,
    sentimentColor?:string,
}


const prepareLinearDiagramStandartData = (array: any)=>{
    return array.map((value: any) => {
        return {
            value
        }
    })
}

const prepareLinearDiagramSentimentData = (array: any)=>{
    array = Array.isArray(array)&&array.length ? array : []
    return array.map((value: any) => {
        const vals = value.map((val: number) => Math.max(val, 1));

        return ['a', 'b', 'c', 'd', 'e'].reduce((acc: any, letter: string, index: number) => {
            acc[letter] = vals[index];
            return acc;
        }, {});
    })
}

const LinearDiagramContainer: React.FC<Props> = ({ data, showSentiment, color,sentimentColor }) => {

    const chartData =showSentiment&&data.rate_all? prepareLinearDiagramSentimentData(data.trend_all): prepareLinearDiagramStandartData(data.trend)

    const renderEmoji = (data:any, index: number) => {
        const { color } = data;

        return (
            <li key={index}>
                <svg
                    width={24}
                    height={24}
                    viewBox="0 0 40 36"
                    strokeWidth="1"
                >
                    <g fill={color} stroke={color}>
                        { emojisArr[index]}
                    </g>
                </svg>
            </li>
        );

    }

    const renderLegend = (props:any) => {
        const { payload } = props;
        return (
            <ul style={{display: 'flex', 'flexDirection': 'column', margin:0, "paddingLeft":15}}>
                {
                    payload.map(renderEmoji)
                }
            </ul>
        );

    }

    const standartLine = (
        <Line type="monotone" isAnimationActive={false} dot={false}  dataKey="value"  stroke={color} />
    )

    const sentimentLine = (
        <>
            <Legend iconType="diamond" layout="vertical" content={renderLegend} verticalAlign="bottom" align="right" />
            <Line type="monotone" isAnimationActive={false} dot={false}  dataKey="a" stroke={sentimentColor||"#C72501"} />
            <Line type="monotone" isAnimationActive={false} dot={false} dataKey="b" stroke={sentimentColor||"#DB802E"} />
            <Line type="monotone" isAnimationActive={false} dot={false}  dataKey="c" stroke={sentimentColor||"#E7B500"}  />
            <Line type="monotone" isAnimationActive={false} dot={false}  dataKey="d" stroke={sentimentColor||"#3EA551"}  />
            <Line type="monotone" isAnimationActive={false} dot={false} dataKey="e" stroke={sentimentColor||"#057147"}  />
        </>
    )

    return (
        <ResponsiveContainer height={"75%"} >
            <LineChart width={200} height={160} data={chartData}>
                <YAxis hide={true} domain={[0, 100]}/>
                { showSentiment? sentimentLine : standartLine }
            </LineChart>
        </ResponsiveContainer >


    );
};

export default LinearDiagramContainer;
