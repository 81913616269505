import React from "react";
import "./NoBookmarkBlock.css";
import { useTranslation } from "react-i18next";
import bookmarkOffImage from "../../assets/images/bookmark-off.svg";

const NoBookmarkBlock: React.FC = () => {
  const { t } = useTranslation("common");
  return (
    <section className="no-bookmark-block-section">
      <div className="centered-flex-no-bookmark">
        <div className="container-for-bookmark-off-image-no-bookmark">
          <img
            className="bookmark-off-image-no-bookmark"
            src={bookmarkOffImage}
            alt="bookmarkOffImage"
          />
        </div>
      </div>
      <div className="align-text-center-size-no-bookmark">
        {t("youCanSetAShortcut")}
      </div>
      <div className="horizontal-line-no-bookmark"></div>
      <div className="align-text-center-size-no-bookmark">
        {t("ifYouAreARegistered")}
      </div>
    </section>
  );
};

export default NoBookmarkBlock;
