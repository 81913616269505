import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { IonContent, IonPage } from "@ionic/react";
import SmallHeader from "../../components/smallHeader/SmallHeader";
import WrapperOnTheForm from "../../components/wrapperOnTheForm/WrapperOnTheForm";
import CustomInput from "../../components/customInput/CustomInput";
import CustomRadioButton from "../../components/customRadioButton/customRadioButton";
import CustomTextAre from "../../components/customTextAre/CustomTextAre";
import CustomDropDown from "../../components/customDropDown/CustomDropDown";
import ButtonResource from "../../components/content/ButtonResource";
import { withTranslation, WithTranslation } from "react-i18next";
import { getThemes } from "../../services/themes";
import {  getActionPlanInfo } from "../../services/actionPlan";
import TitlePageTextTheme from "../../components/titlePageTextTheme/titlePageTextTheme";
import IonLoadingPage from "../../components/ionLoadingPage/IonLoadingPage";
import idHelper from "../../helpers/newCommunityId";
import moment from "moment";
import "../AddResourceGroupPage.css";
import "./AddCommitment.css";
import DatePicker from "react-datepicker";
import offlineActions from "../../services/actions";
import {connect} from "react-redux";
import newCommunityId from "../../helpers/newCommunityId";
import {actionPlanAddThunk} from "../../reducers/thunks/actionPlansThunksRecalculate";

type AddCommitmentProps = {
    dispatch: any
};

type AddCommitmentState = {
    contentPage: ContentPage;
    themesOption: OptionTheme[];
};
type Theme = {
    id: number;
    name: string;
};
type OptionTheme = {
    label: string;
    value: number;
};
type ContentPage = {
    en_title: string;
    en_resources: string;
    en_sub_activities: string;
    sw_title: string;
    sw_resources: string;
    sw_sub_activities: string;
    due_date: string;
    responsible_party: string;
    responsible_party_type: string;
    monitored_by: string;
    title?: string;

    community_gathering: number;
    community_gathering_date: string;
    community: number;
    status: string;
    // status: number | null,
    // community_gathering: number | null,
    theme: number;
};

class OfflineAddCommitment extends React.Component<
    AddCommitmentProps & WithTranslation & RouteComponentProps<{}>,
    AddCommitmentState
    > {
    themes: Theme[] | null;
    idCommunity: number;
    idPlan: number;
    arrayPartyType: any[];

    constructor(props: any) {
        super(props);
        this.state = {
            contentPage: {
                en_title: "",
                en_resources: "",
                en_sub_activities: "",
                sw_title: "",
                sw_resources: "",
                sw_sub_activities: "",
                due_date: moment(new Date()).format("YYYY-MM-DD"),
                responsible_party: "",
                responsible_party_type: "SERVICE_USER",
                monitored_by: "",
                community: 0,
                status: "PENDING",
                community_gathering: 15,
                community_gathering_date: '',

                // status: "",
                // community_gathering: 0,
                //  status: null,
                //  community_gathering: null,
                theme: 1,
            },
            themesOption: [],
        };
        this.themes = null;
        this.idCommunity = 0;
        this.idPlan = 1;
        this.arrayPartyType = [];
        this.setContentPage = this.setContentPage.bind(this);
        this.createRadioGroup = this.createRadioGroup.bind(this);
        this.onChangeRadioButtons = this.onChangeRadioButtons.bind(this);
        this.selectedTheme = this.selectedTheme.bind(this);
        this.createOptionThemes = this.createOptionThemes.bind(this);
        this.backToPrevoius = this.backToPrevoius.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.getActionInfo = this.getActionInfo.bind(this);
    }

    async componentDidMount() {
        const param = Object.create(this.props.match.params);
        this.idCommunity = Number(param.idCommunity);
        await this.getActionInfo();
        this.state.contentPage.community = this.idCommunity;

        try {
            const { data } = await getThemes();
            this.themes = [...data];
            this.createOptionThemes(this.themes);
        } catch (error: any) {
            console.log(`getThemes error status: ${error}`);
        }
    }

    async getActionInfo() {
        try {
            const { data } = await getActionPlanInfo();
            this.arrayPartyType = [...data.responsible_party_type];
            if (this.arrayPartyType.length != 0) {
                const keys = Object.keys(this.arrayPartyType[0]);
                const type_party: string = keys[0];
                this.onChangeRadioButtons(type_party);
            }
        } catch (error: any) {
            console.log(`getActionInfo error status: ${error}`);
        }
    }

    createOptionThemes(arrayThemes: Theme[]) {
        const themesOption: OptionTheme[] = arrayThemes.map((item) => {
            return {
                label: item.name,
                value: item.id,
            };
        });
        this.setState({ themesOption: themesOption });
    }

    setContentPage(content: any) {
        this.setState({ contentPage: content });
    }

    onChangeRadioButtons(value: string) {
        const content: ContentPage = {
            ...this.state.contentPage,
            responsible_party_type: value,
        };
        this.setContentPage(content);
    }

    selectedTheme(option: any) {
        this.setContentPage({ ...this.state.contentPage, theme: option.value });
    }

    backToPrevoius() {
        this.props.history.goBack();
    }

    async saveChanges() {

        if (this.validationDate()) {
            const { id: actionPlanId } = newCommunityId(this.idCommunity, this.state.contentPage.community_gathering_date, 'actionPlan');

            const { id, date: currentDate } = idHelper(this.idCommunity);

            this.state.contentPage.community_gathering = id;
            this.state.contentPage.community_gathering_date = currentDate;

            const body = {
                id: actionPlanId,
                title: this.state.contentPage.en_title,
                ...this.state.contentPage,
            }
            await this.props.dispatch(actionPlanAddThunk(body));
            try {


                await this.props.dispatch(offlineActions.addActionPlanGatheringAction({date: currentDate, idCommunity: this.idCommunity.toString(), id }));

                await this.props.dispatch(offlineActions.addActionPlanAction(
                    {
                        idCommunity: this.idCommunity,
                        idPlan: this.idPlan,
                        data: body,
                    }
                ));
            } catch (error: any) {
                console.log(`addActionPlan error status: ${error}`);
            }


            this.props.history.goBack();
        } else {
            console.log("No valid data");
        }
    }

    validationDate() {
        const {
            en_title,
            en_resources,
            en_sub_activities,
            sw_title,
            sw_resources,
            sw_sub_activities,
            responsible_party,
            monitored_by,
        } = this.state.contentPage;
        if (en_title.length < 1) {
            alert("English 'Title' is to short");
            return false;
        }
        if (en_resources.length < 1) {
            alert("English 'Resources' is to short");
            return false;
        }
        if (en_sub_activities.length < 1) {
            alert("English 'Sub-activities' is to short");
            return false;
        }

        // if (sw_title.length < 1) {
        //   alert("Swahili  'Title' is to short");
        //   return false;
        // }
        // if (sw_resources.length < 1) {
        //   alert("Swahili  'Resources' is to short");
        //   return false;
        // }
        // if (sw_sub_activities.length < 1) {
        //   alert("Swahili  'Sub-activities' is to short");
        //   return false;
        // }

        if (responsible_party.length < 1) {
            alert("Swahili  'Responsible party' is to short");
            return false;
        }
        if (monitored_by.length < 1) {
            alert("Swahili  'Monitored by' is to short");
            return false;
        }
        return true;
    }

    createRadioGroup(
        arrayParty: any[],
        onChangeHadler: (inputText: string) => void
    ): JSX.Element[] {
        const { t } = this.props;
        return arrayParty.map((item: any, index: number) => {
            let keys = Object.keys(item);
            let party_type: string = keys[0];
            return (
                <div
                    key={index++}
                    className="community-update-row custom-input-radio-row"
                >
                    <div className="custom-input-radio-wrap cusom-input-props">
                        <CustomRadioButton
                            id={party_type}
                            value={party_type}
                            cssSize="custom-input-radio-size"
                            checked={
                                this.state.contentPage.responsible_party_type === party_type
                                    ? true
                                    : false
                            }
                            onChange={(inputText: string) => {
                                onChangeHadler(inputText);
                            }}
                        />
                        <label
                            className="community-update-label-in-row"
                            htmlFor={party_type}
                        >
                            {t(item[party_type])}
                        </label>
                    </div>
                </div>
            );
        });
    }

    render() {
        const { t } = this.props;
        const content: ContentPage = { ...this.state.contentPage };
        const add_resource_css: string = " custom-tetxtarea-add-commitment";
        const group_radio: JSX.Element[] = this.createRadioGroup(
            this.arrayPartyType,
            this.onChangeRadioButtons
        );
        const buttonSave: JSX.Element = (
            <ButtonResource
                id="saveCommitment"
                onClickHandler={this.saveChanges}
                text_color="white"
            >
                {t("save")}
            </ButtonResource>
        );

        const buttonCancel: JSX.Element = (
            <ButtonResource
                id="cancelCommitment"
                color="light"
                text_color="rgb(105, 113, 126)"
                onClickHandler={this.backToPrevoius}
            >
                {t("cancel")}
            </ButtonResource>
        );

        return (
            <IonPage>
                <IonContent>
                    <SmallHeader rightText={t("addNew")} />
                    <TitlePageTextTheme>
                        <div className="community-update-header-text">
                            {t("individualActionPlanCommitment")}
                        </div>
                    </TitlePageTextTheme>
                    <WrapperOnTheForm
                        title={t("engdetails")}
                        description={t("provideAClearTitleAndOptionally")}
                    >
                        <div className="community-update-row">
                            <label
                                className="community-update-label-in-row"
                                htmlFor="englishTitle"
                            >
                                {t("title")}
                            </label>
                            <CustomInput
                                cssInput="cusom-input-props"
                                id="englishTitle"
                                value={this.state.contentPage.en_title}
                                onChange={(inputText: string) => {
                                    this.setContentPage({ ...content, en_title: inputText });
                                }}
                                isShowCountLetter
                            />
                        </div>
                        <div className="community-update-row">
                            <label
                                className="community-update-label-in-row"
                                htmlFor="englishResource"
                            >
                                {t("resources")}
                            </label>
                            <CustomTextAre
                                value={this.state.contentPage.en_resources}
                                id="englishResource"
                                isShowCountLetter
                                onChange={(inputText: string) => {
                                    this.setContentPage({ ...content, en_resources: inputText });
                                }}
                                cssTextAre={"cusom-input-props" + add_resource_css}
                            />
                        </div>
                        <div className="community-update-row">
                            <label
                                className="community-update-label-in-row"
                                htmlFor="englishActivitis"
                            >
                                {t("subaAtivities")}
                            </label>
                            <CustomTextAre
                                value={this.state.contentPage.en_sub_activities}
                                id="englishActivitis"
                                isShowMarkdown
                                onChange={(inputText: string) => {
                                    this.setContentPage({
                                        ...content,
                                        en_sub_activities: inputText,
                                    });
                                }}
                                cssTextAre={"cusom-input-props" + add_resource_css}
                            />
                        </div>
                    </WrapperOnTheForm>

                    <WrapperOnTheForm
                        title={t("swahiliTranslation")}
                        description={t("optionalIfAvailable")}
                    >
                        <div className="community-update-row">
                            <label
                                className="community-update-label-in-row"
                                htmlFor="swTitle"
                            >
                                {t("title")}
                            </label>
                            <CustomInput
                                cssInput="cusom-input-props"
                                id="swTitle"
                                value={this.state.contentPage.sw_title}
                                onChange={(inputText: string) => {
                                    this.setContentPage({ ...content, sw_title: inputText });
                                }}
                                isShowCountLetter
                            />
                        </div>
                        <div className="community-update-row">
                            <label
                                className="community-update-label-in-row"
                                htmlFor="swResources"
                            >
                                {t("resources")}
                            </label>
                            <CustomTextAre
                                value={this.state.contentPage.sw_resources}
                                id="swResources"
                                isShowCountLetter
                                onChange={(inputText: string) => {
                                    this.setContentPage({ ...content, sw_resources: inputText });
                                }}
                                cssTextAre={"cusom-input-props" + add_resource_css}
                            />
                        </div>
                        <div className="community-update-row">
                            <label
                                className="community-update-label-in-row"
                                htmlFor="swActivitis"
                            >
                                {t("subaAtivities")}
                            </label>
                            <CustomTextAre
                                value={this.state.contentPage.sw_sub_activities}
                                id="swActivitis"
                                isShowMarkdown
                                onChange={(inputText: string) => {
                                    this.setContentPage({
                                        ...content,
                                        sw_sub_activities: inputText,
                                    });
                                }}
                                cssTextAre={"cusom-input-props" + add_resource_css}
                            />
                        </div>
                    </WrapperOnTheForm>

                    <WrapperOnTheForm
                        title={t("attributes")}
                        description={t("specifyCommitmentDueCompleted")}
                    >
                        <div className="community-update-row">
                            <label
                                className="community-update-label-in-row"
                                htmlFor="englishDate"
                            >
                                {t("dueDate")}
                            </label>
                            <section className="section-custom-input">
                                <div>
                                    <DatePicker className={"custom-date-picker update-picker"}
                                                value={ this.state.contentPage.due_date}
                                                onChange={(date:Date) =>  this.setContentPage({...content,due_date:date.toLocaleDateString('en-CA')})}
                                                popperClassName="some-custom-class"
                                                popperPlacement="top-end"
                                                popperModifiers={[
                                                    {
                                                        name: "offset",
                                                        options: {
                                                            offset: [5, 10],
                                                        },
                                                    },
                                                    {
                                                        name: "preventOverflow",
                                                        options: {
                                                            rootBoundary: "viewport",
                                                            tether: false,
                                                            altAxis: true,
                                                        },
                                                    },
                                                ]}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="community-update-row">
                            <label
                                className="community-update-label-in-row"
                                htmlFor="englishParty"
                            >
                                {t("responsibleParty")}
                            </label>
                            <CustomInput
                                cssInput="cusom-input-props"
                                id="englishParty"
                                value={this.state.contentPage.responsible_party}
                                onChange={(inputText: string) => {
                                    this.setContentPage({
                                        ...content,
                                        responsible_party: inputText,
                                    });
                                }}
                            />
                        </div>
                        {group_radio}

                        <div className="community-update-row">
                            <label
                                className="community-update-label-in-row"
                                htmlFor="englishMonitor"
                            >
                                {t("monitoredBy")}
                            </label>
                            <CustomInput
                                cssInput="cusom-input-props"
                                id="englishMonitor"
                                value={this.state.contentPage.monitored_by}
                                onChange={(inputText: string) => {
                                    this.setContentPage({ ...content, monitored_by: inputText });
                                }}
                            />
                        </div>
                    </WrapperOnTheForm>

                    <WrapperOnTheForm
                        title={t("categorisation")}
                        description={t("toHelpUsCompareSentimentFrom")}
                    >
                        <div className="community-update-row">
                            <label
                                className="community-update-label-in-row"
                                htmlFor="englishTheme"
                            >
                                {t("theme")}
                            </label>
                            <CustomDropDown
                                setValue={(option) => this.selectedTheme(option)}
                                cssControl="cusom-input-props"
                                options={this.state.themesOption as any[]}
                            />
                        </div>
                    </WrapperOnTheForm>
                    <div className="section-add-resource-button commitment-button-control">
                        <div className="resource-button-cancel-container">
                            {buttonCancel}
                        </div>
                        <div className="resource-button-add-container">{buttonSave}</div>
                    </div>
                    <IonLoadingPage isLoading={false} />
                </IonContent>
            </IonPage>
        );
    }
}
const decoratedComponent = connect()(withTranslation("common")(OfflineAddCommitment));
export { decoratedComponent as OfflineAddCommitment };
