
export interface SentimentsState {
    sentimentsData: any;
    sentimentsDetails: any
    sentimentsDescriptions: any
}

export const initialState: SentimentsState = {
    sentimentsData:{
        trend: [],
        rate: 0,
        trend_all: [],
        rate_all: [],
        trendDirection: { 'Good': 'Good'},
        sentiments: [],
        def: true
    },
    sentimentsDetails:{},
    sentimentsDescriptions:{},
};
const SET_SENTIMENTS = "SET_SENTIMENTS";
const SET_SENTIMENTS_DETAILS = "SET_SENTIMENTS_DETAILS";
const SET_SENTIMENTS_DESCRIPTIONS = "SET_SENTIMENTS_DESCRIPTIONS";

export default function sentimentsReducer(state = initialState, action: any) {
    switch (action.type) {
        case SET_SENTIMENTS:
            return {
                ...state,
                sentimentsData: {
                    ...action.payload
                },
            };
        case SET_SENTIMENTS_DETAILS:
            return {
                ...state,
                sentimentsDetails: {
                    ...state.sentimentsDetails,
                    [action.payload.id]: action.payload
                },
            };
        case SET_SENTIMENTS_DESCRIPTIONS:
            return {
                ...state,
                sentimentsDescriptions: {
                    ...state.sentimentsDescriptions,
                    [action.payload.id]: action.payload
                },
            };
        default:
            return state;
    }
}

export const setSentiments = (sentiments: any) => ({
    type: SET_SENTIMENTS,
    payload: sentiments,
});
export const setSentimentsDetails = (sentimentsDetails: any) => ({
    type: SET_SENTIMENTS_DETAILS,
    payload: sentimentsDetails,
});
export const setSentimentsDescriptions = (sentimentsDescriptions: any) => ({
    type: SET_SENTIMENTS_DESCRIPTIONS,
    payload: sentimentsDescriptions,
});
