import React, { useEffect, useState } from "react";
import { IonContent, IonPage } from "@ionic/react";
import "./PerformanceMeasure.css";
import IonLoadingPage from "../../../components/ionLoadingPage/IonLoadingPage";
import { useTranslation } from "react-i18next";
import TitlePageTextTheme from "../../../components/titlePageTextTheme/titlePageTextTheme";
import WrapperOnTheForm from "../../../components/wrapperOnTheForm/WrapperOnTheForm";
import CustomInput from "../../../components/customInput/CustomInput";
import CustomTextAre from "../../../components/customTextAre/CustomTextAre";
import { PerformanceMeasureT } from "../../../types/types";
import { THEMES } from "../../../mocks/layout";
import { useHistory, useParams } from "react-router-dom";
import CustomButton from "../../../components/customButton/CustomButton";
import { middlewareGetThemes } from "../../../services/middleware/MiddlewareTheme";
import CustomDropDown from "../../../components/customDropDown/CustomDropDown";
import SmallHeader from "../../../components/smallHeader/SmallHeader";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../reducers";
import offlineActions from "../../../services/actions";
import {setSentimentsDescriptions, setSentimentsDetails} from "../../../reducers/sentimentsReducer";
import newCommunityId from "../../../helpers/newCommunityId";
import {middlewareGetPerformanceMeasure} from "../../../services/middleware/MiddlewareCommunity";
import {sentimentUpdateDescriptionThunk} from "../../../reducers/thunks/sentimentsThunksRecalculate";

const URL_PARAMS_NEW = "new";
const PAGE_THEME = THEMES.GREEN;
const PerformanceMeasure: React.FC = () => {
    const { t } = useTranslation("common");
    const [perfomanceMeasure, setPerfomanceMeasure] =
        useState<PerformanceMeasureT>({
            id: 0,
            en_name: "",
            en_description: "",
            sw_name: "",
            sw_description: "",
            theme: 1,
            community: 0,
        });
    const [isLoading, setIsLoading] = useState(false);
    const { idPerfomanceMeasure, idCommunity } =
        useParams<{ idPerfomanceMeasure: string; idCommunity: string }>();

    const [optionsThemes, setOptionsThemes] = useState([]);

    const SentimentDescription = useSelector((state: AppState) =>  state.sentiments.sentimentsDescriptions[idPerfomanceMeasure])
    const defaultValueDropDown = SentimentDescription && optionsThemes.find((item: any) => item.value === SentimentDescription.performance_measure.theme);
    const history = useHistory();
    const dispatch = useDispatch()

    useEffect(() => {
        getThemes();
        if (checkNewPerfomance()) {
            ////Edit
            getDataPerfomanceMeasure();
            prepareData()
        } else {
            setPerfomanceMeasure({ ...perfomanceMeasure, community: +idCommunity });
        }
    }, []);



    const prepareData = async () => {

        if (!SentimentDescription) {
            history.goBack()
        } else {
            setPerfomanceMeasure(SentimentDescription.performance_measure);
        }
    };


    const checkNewPerfomance = () => {
        return idPerfomanceMeasure !== URL_PARAMS_NEW ? true : false;
    };

    const getDataPerfomanceMeasure = async () => {
        const responce = await middlewareGetPerformanceMeasure(idPerfomanceMeasure);

        if(responce.cached && SentimentDescription) {
            return
        }

        dispatch(setSentimentsDescriptions({
            ...SentimentDescription,
            performance_measure:responce
        }))

    };

    const getThemes = async () => {
        const responce:any = await middlewareGetThemes();
        const optionsThemesLocal = responce.map((item: any) => {
            return {
                label: item.name,
                value: item.id,
            };
        });
        setOptionsThemes(optionsThemesLocal);
        return optionsThemesLocal;
    };

    const addScorecard = async () => {

        if (!checkNewPerfomance()) {
            const {id} = newCommunityId(idCommunity,'','sentiment' )
            perfomanceMeasure.id = id

            // dispatch(setSentimentsDescriptions({
            //     id,
            //     current:[],
            //     trend_all:[],
            //     trend:[],
            //     trend_state:[],
            //     rate_all:[],
            //     rate:0,
            //     state:{Ok:"Ok"},
            //     trend_1scale:[],
            //     performance_measure:perfomanceMeasure
            // }))

            dispatch(offlineActions.postSentimentPerformanceAction(perfomanceMeasure));

            dispatch(sentimentUpdateDescriptionThunk(perfomanceMeasure));

            //TODO Update ADD thunk

        } else {
            dispatch(offlineActions.putSentimentPerformanceAction({
                id:idPerfomanceMeasure,
                objectData:perfomanceMeasure
            }))

            dispatch(sentimentUpdateDescriptionThunk(perfomanceMeasure));
            //TODO Update EDIT  thunk
        }

        history.goBack()
    };

    const changeValueDate = (option: any) => {
        const { value } = option;
        setPerfomanceMeasure({
            ...perfomanceMeasure,
            theme: value,
        });
    };

    const textBtn = checkNewPerfomance() ? t("save") : t("addScorecard");

    return (
        <IonPage>
            <IonContent>
                <SmallHeader isGoBack rightText={t("scorecard")} />
                <TitlePageTextTheme cssTitleScreenCommunity="align-bottom-text-my-community">
                    <div>{t("performanceMeasure")}</div>
                </TitlePageTextTheme>{" "}
                <WrapperOnTheForm
                    title={t("details")}
                    description={t("provideAClearNameAndConciseDescription")}
                >
                    <div className="community-update-row">
                        <label
                            className="community-update-label-in-row"
                            htmlFor="englishName"
                        >
                            {t("name")}
                        </label>
                        <CustomInput
                            cssInput="cusom-input-props"
                            id="englishName"
                            value={perfomanceMeasure.en_name}
                            onChange={(inputText) =>
                                setPerfomanceMeasure({
                                    ...perfomanceMeasure,
                                    en_name: inputText,
                                })
                            }
                            isShowCountLetter
                        />
                    </div>
                    <div className="community-update-row">
                        <label
                            className="community-update-label-in-row"
                            htmlFor="englishUpdate"
                        >
                            {t("update")}
                        </label>
                        <CustomTextAre
                            value={perfomanceMeasure.en_description}
                            id="englishUpdate"
                            isShowCountLetter
                            onChange={(inputText) =>
                                setPerfomanceMeasure({
                                    ...perfomanceMeasure,
                                    en_description: inputText,
                                })
                            }
                            cssTextAre="cusom-input-props"
                        />
                    </div>
                </WrapperOnTheForm>
                <WrapperOnTheForm
                    title={t("swahiliTranslation")}
                    description={t("optionalIfAvailable")}
                >
                    <div className="community-update-row">
                        <label
                            className="community-update-label-in-row"
                            htmlFor="swahiliTitle"
                        >
                            {t("name")}
                        </label>
                        <CustomInput
                            cssInput="cusom-input-props"
                            id="swahiliTitle"
                            value={perfomanceMeasure.sw_name}
                            onChange={(inputText) =>
                                setPerfomanceMeasure({
                                    ...perfomanceMeasure,
                                    sw_name: inputText,
                                })
                            }
                            isShowCountLetter
                        />
                    </div>
                    <div className="community-update-row">
                        <label
                            className="community-update-label-in-row"
                            htmlFor="swahiliUpdate"
                        >
                            {t("update")}
                        </label>
                        <CustomTextAre
                            value={perfomanceMeasure.sw_description}
                            id="swahiliUpdate"
                            isShowCountLetter
                            onChange={(inputText) =>
                                setPerfomanceMeasure({
                                    ...perfomanceMeasure,
                                    sw_description: inputText,
                                })
                            }
                            cssTextAre="cusom-input-props"
                        />
                    </div>
                </WrapperOnTheForm>
                <WrapperOnTheForm
                    title={t("categorisation")}
                    description={t("toHelpUsCompareSentimentFrom")}
                >
                    <div className="community-update-row">
                        <label
                            className="community-update-label-in-row"
                            htmlFor="swahiliTitle"
                        >
                            {t("theme")}
                        </label>
                        <CustomDropDown
                            setValue={(option) => changeValueDate(option)}
                            defaultOption={defaultValueDropDown}
                            options={optionsThemes}
                            cssControl="cusom-input-props"
                        />
                    </div>
                </WrapperOnTheForm>
                <div className="container-post-update-btn margin-bottom-in-tablet">
                    <div></div>
                    <div>
                        <CustomButton
                            theme={PAGE_THEME}
                            onClick={addScorecard}
                            text={textBtn}
                        />
                    </div>
                </div>
                <IonLoadingPage isLoading={isLoading} />
            </IonContent>
        </IonPage>
    );
};

export default PerformanceMeasure;
