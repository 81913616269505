import "./content.css";
import { ChartContent } from "./ChartContent";
import { useTranslation } from "react-i18next";
import TitlePageTextTheme from "../../components/titlePageTextTheme/titlePageTextTheme";
interface IProps {
  updateHome: boolean;
}

const ContentAll: React.FC<IProps> = (props: IProps) => {
  const { t } = useTranslation("common");

  return (
    <div className="content_container">
      <div className="name_content">
        <TitlePageTextTheme
          theme="blue"
          cssTitleScreenCommunity="sing-in-title-page-text"
        >
          <div className="container_banner">
            <div className="logotip_block"></div>
            <div className="name_page1">
              <span>{t("SAUTIYouth")}</span>
            </div>
            <div className="container_descript_logo1">
              <div className="block_description1">
                <span>{t("africanAndEuropeanYouth")}</span>
              </div>
              <div className="block_logo1">
                <div className="info_block">
                  <div className="info">i</div>
                </div>
              </div>
            </div>
          </div>
        </TitlePageTextTheme>
      </div>
      <ChartContent updateHome={props.updateHome} />
    </div>
  );
};

export default ContentAll;
