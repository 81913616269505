import { combineReducers } from "redux";
import { createStore, applyMiddleware } from "redux";
import { middlewareCheck } from "services/actions/generatorAsync";
import { reducer as offline,
  offlineMiddleware,
} from "redux-offline-queue";
import userReducer from "./userReducer";
import communityReducer from "./communityReducer";
import themeReducer from "./themeReducer";
import headerReducer from "./headerReducer";
import standardsReducer , {StandardsState}from "./standardsReducer";
import sentimentsReducer, { SentimentsState } from "./sentimentsReducer";
import actionPlansReducer, {ActionPlansState} from "./actionPlansReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { UserState } from "./userReducer";
import { CommunityState } from "./communityReducer";
import communityTrendsReducer, { CommunityTrendsState } from "./communityTrendsReducer";

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

export interface AppState {
  user: UserState;
  community: CommunityState;
  communityTrends: CommunityTrendsState;
  header: any;
  standards: StandardsState;
  sentiments: SentimentsState;
  actionPlans: ActionPlansState;
}

const rootReducer: any = combineReducers({
  user: userReducer,
  theme: themeReducer,
  community: communityReducer,
  communityTrends: communityTrendsReducer,
  header: headerReducer,
  standards: standardsReducer,
  sentiments: sentimentsReducer,
  actionPlans: actionPlansReducer,
  offline,
});

const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
};

const pReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  pReducer,
  composeWithDevTools(applyMiddleware(...[
    //@ts-ignore
      offlineMiddleware(),
      middlewareCheck,
      thunk]))
);

export const persistor = persistStore(store);

export const persistorPromise = new Promise((resolve) => {
    const unsubscribe = persistor.subscribe(()=> {
        let { bootstrapped } = persistor.getState()
        if (bootstrapped) {
            resolve(true);
            unsubscribe();
        }
    });
});
