import {setActionPlansTrends} from 'reducers/communityTrendsReducer'
import recalculateTrends from 'helpers/offline/recalculateTrends'
import {setCommunityMyPageTree} from "../communityReducer";
import {
    setActionPlanDescription,
    setActionPlanDetails,
    setActionPlanDetailsDelete,
    setActionPlans, setActionPlansDescriptionDelete
} from "../actionPlansReducer";
import {AppState} from "../index";
import _ from 'lodash';

const statusMapToRate: any = {
    'ONGOING': 0,
    'PENDING': 0,
    'COMPLETED': 100,
};
function capitalizeFirstLetter(stringEl: string) {
    return stringEl.charAt(0).toUpperCase() + stringEl.slice(1).toLowerCase();
}
const updateTrendsPageTree = (myPageTreeData: any, newTrends: any) => {
    myPageTreeData['action-plans'] = newTrends;

    myPageTreeData.all_rate_list[2] = newTrends.rate;
    myPageTreeData.rate = myPageTreeData.all_rate_list.reduce((acc: number, el: number) => acc + el,0)/myPageTreeData.all_rate_list.length;

}
const updateTrendsInList = (listData: any, newTrends: any) => {
    listData.trend = newTrends.trend;
    listData.rate = newTrends.rate;
    listData.trend_direction = newTrends.trend_direction;
    listData.trend_direction = newTrends.trend_direction;
    listData.state = newTrends.state;

}

export const actionPlanAddThunk = function (newActionPlan: any) {
    return (dispatch: any, getState: () => AppState) => {
        const action_plan_id = newActionPlan.id;
        const status = 'PENDING';
        const rate = statusMapToRate[status];
        const { communityTrends: { action_plans }, community: { myPageTree }, actionPlans: { actionPlansData }  } = getState()
        newActionPlan.status_text = 'Pending';
        // ------ update trends list ------


        const { trends } = action_plans;

        const actionPlanTrend = {
            id: action_plan_id,
            dates: [newActionPlan.community_gathering_date],
            trend: [rate],
        }

        trends.push(actionPlanTrend);

        dispatch(setActionPlansTrends(action_plans));

        // ------ update trends general numbers ------

        const newTrends = recalculateTrends(trends);

        const { data: myPageTreeData } = myPageTree;

        updateTrendsPageTree(myPageTreeData, newTrends);

        dispatch(setCommunityMyPageTree(myPageTree));

        // ------ update list ------

        const { action_plans: actionPlansListData } = actionPlansData;

        const standartsListDataItem = {
          ...newActionPlan,
        };

        actionPlansListData.push(standartsListDataItem)

        updateTrendsInList(actionPlansData, newTrends);

        dispatch(setActionPlans(actionPlansData));

        // ------ update descriptions ------

        dispatch(setActionPlanDescription(newActionPlan));

        // ------ update updates ------

        dispatch(setActionPlanDetails({
            id: newActionPlan.id,
            updates: [{
                id: newActionPlan.community_gathering,
                action_plan: newActionPlan.id,
                community_gathering: {
                    date: newActionPlan.community_gathering_date,
                    id: newActionPlan.community_gathering,
                    community: newActionPlan.community
                },
                status
            }]
        }));
    }
}

export const actionPlanUpdateDescriptionThunk = function (actionPlan: any) {
    return (dispatch: any, getState: () => AppState) => {
        const action_plan_id = actionPlan.id;

        const { communityTrends: { action_plans }, actionPlans: { actionPlansData }  } = getState()

        // ------ update trends list ------

        const { trends } = action_plans;


        const actionPlanTrend = trends.find((trend: any) => trend.id === action_plan_id);

        if (actionPlanTrend) {

            actionPlanTrend.theme_id = actionPlan.theme_id;
            actionPlanTrend.name = actionPlan.title;

            dispatch(setActionPlansTrends(action_plans));
        }


        // ------ update trends general numbers ------

        //SKIP

        // ------ update list ------

        const { action_plans: actionPlansListData } = actionPlansData;

        const actionPlanListDataItemIndex = actionPlansListData.findIndex((el: any) => el.id === action_plan_id)

        if (~actionPlanListDataItemIndex) {
            actionPlansListData[actionPlanListDataItemIndex] = {
                ...actionPlan
            }
        }

        dispatch(setActionPlans(actionPlansData));

        // ------ update descriptions ------

        dispatch(setActionPlanDescription(actionPlan));

        // ------ update updates ------
        // SKIP
    }
}

export const actionPlanUpdateDetailsThunk = function (actionPlanUpdate: any) {
    return (dispatch: any, getState: any) => {

        const community_gathering_date = actionPlanUpdate.community_gathering.date;
        const update_rate = statusMapToRate[actionPlanUpdate.status];
        const action_plan_id = actionPlanUpdate.action_plan;

        const { communityTrends: { action_plans }, community: { myPageTree }, actionPlans: { actionPlansData, actionPlansDescription, actionPlansDetails }  } = getState()

        // ------ update trends list ------

        const { trends } = action_plans;

        const trend = trends.find((trend: any) => trend.id === action_plan_id);

        if (!trend) {
            return;
        }

        let trendUpdateIndex = trend.dates.findIndex((date: string) => date === community_gathering_date);

        if (!~trendUpdateIndex) {
            const olderDates = trend.dates.filter((el: string) => el < community_gathering_date);

            trendUpdateIndex = olderDates.length;
            trend.dates.splice(trendUpdateIndex, 0, community_gathering_date);
            trend.trend.splice(trendUpdateIndex, 0, update_rate);
        } else {
            trend.trend[trendUpdateIndex] = update_rate;
        }

        dispatch(setActionPlansTrends(action_plans));

        // ------ update trends general numbers ------

        const newTrends = recalculateTrends(trends);

        const { data: myPageTreeData } = myPageTree;

        updateTrendsPageTree(myPageTreeData, newTrends);

        dispatch(setCommunityMyPageTree(myPageTree));

        // ------ update list ------

        const { action_plans: listData } = actionPlansData;

        const listDataItem = listData.find((el: any) => el.id === action_plan_id) || {};

        listDataItem.rate = update_rate;
        listDataItem.state = actionPlanUpdate.state;

        updateTrendsInList(actionPlansData, newTrends);

        dispatch(setActionPlans(actionPlansData));

        // ------ update description ------

        const actionPlanDescription = actionPlansDescription[action_plan_id];
        const actionPlanDetails = actionPlansDetails[action_plan_id];

        if (!actionPlanDetails) {
            return;
        }

        const lastDateGatheringStatus = _.sortBy(actionPlanDetails.updates, (update: any) => update.community_gathering.date);

        if (lastDateGatheringStatus[lastDateGatheringStatus.length -1]) {
            const status = lastDateGatheringStatus[lastDateGatheringStatus.length -1].status;
            actionPlanDescription.status = status;

            const status_text = capitalizeFirstLetter(status);
            actionPlanDescription.status_text = status_text;

            dispatch(setActionPlanDescription(actionPlanDescription));

            listDataItem.status = status;
            listDataItem.status_text = status_text;

            dispatch(setActionPlans(actionPlansData));
        }
    }
}

export const actionPlanDeleteThunk = function (actionPlan: any) {
    return (dispatch: any, getState: any) => {
        const action_plan_id = actionPlan.id;

        const { communityTrends: { action_plans }, community: { myPageTree }, actionPlans: { actionPlansData, actionPlansDescription, actionPlansDetails }  } = getState()

        // ------ update trends list ------

        const { trends } = action_plans;

        trends.filter((trend: any) => trend.id !== action_plan_id);

        dispatch(setActionPlansTrends(action_plans));

        // ------ update trends general numbers ------

        const newTrends = recalculateTrends(trends);

        const { data: myPageTreeData } = myPageTree;

        updateTrendsPageTree(myPageTreeData, newTrends);

        dispatch(setCommunityMyPageTree(myPageTree));

        // ------ update list ------

        const { action_plans: listData } = actionPlansData;

        const listDataNew = listData.filter((el: any) => el.id !== action_plan_id)

        actionPlansData.action_plans = listDataNew;

        updateTrendsInList(actionPlansData, newTrends);

        dispatch(setActionPlans(actionPlansData));

        // ------ update details ------

        dispatch(setActionPlanDetailsDelete(actionPlan));

        // ------ update description ------

        dispatch(setActionPlansDescriptionDelete(actionPlan));

    }
}
//export {};
