import React from "react";
import "./Favorite.css";
import { bookmark } from "ionicons/icons";
import bookmarkOffImage from "../../assets/images/bookmark-off.svg";
import bookmarkOnImage from "../../assets/images/bookmark-on.svg";

type Props = {
  onClick: () => void;
  isCheckBookmark?: boolean;
};

const Favorite: React.FC<Props> = ({ onClick, isCheckBookmark }) => {
  return (
    <section className="favorite-bookmark-section" onClick={onClick}>
      {isCheckBookmark && (
        <img
          className="favorite-bookmark-image"
          src={bookmarkOnImage}
          alt="bookmark"
        />
      )}
      {!isCheckBookmark && (
        <img
          className="favorite-bookmark-image"
          src={bookmarkOffImage}
          alt="bookmark"
        />
      )}
    </section>
  );
};

export default Favorite;
