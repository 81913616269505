import React from "react";
import "./CommunityPost.css";
import ShareSocial from "../shareSocial/ShareSocial";
import { FileT } from "../../types/types";
import fileDownloadImage from "../../assets/images/file-download.png";
import imageArrowLeft from "../../assets/images/image-arrow-left.png";
import imageArrowRight from "../../assets/images/image-arrow-right.png";
import { THEMES } from "../../mocks/layout";
import { getTextColor } from "../../theme/CombineThemes";
import { useTranslation } from "react-i18next";
import CustomButtonNavLink from "../customButton/customButtonNavLink/CustomButtonNavLink";
import { isUserGlobalAdmin } from "../../helpers/functions";

type Props = {
  image?: string;
  date?: string;
  text?: React.ReactNode;
  files?: FileT[];
  attendees?: string[];
  attendeeText?: string;
  isEditPost?: boolean;
  isArrowImage?: boolean;
  title?: string;
  isShare?: boolean;
  downBtn?: React.ReactNode;
  theme?: string;
  idUpdate?: string;
  idCommunity?: string;
  changeImage?: (direct: number, imageUrl: string) => void;
};

const CommunityPost: React.FC<Props> = ({
  image,
  date,
  text,
  files,
  attendees,
  attendeeText,
  isEditPost = false,
  isArrowImage = true,
  title,
  isShare = true,
  downBtn,
  theme = THEMES.GREEN,
  changeImage,
  idUpdate,
  idCommunity,
}) => {
  const { t } = useTranslation("common");
  const textColor = () => {
    return getTextColor(theme);
  };

  const changePhoto = (direct: number, imageUrl: string) => {
    if (changeImage) changeImage(direct, imageUrl);
  };

  const IS_SHOW_EDIT_UPDATE_BTN = isUserGlobalAdmin();
  const NAV_LINK_TO_EDIT_UPDATE = `/community/${idCommunity}/update/${idUpdate}`;
  return (
    <>
      {isEditPost && (
        <div className="edit-post-in-wall-block">
          <div className="new-post-in-wall-btn">{t("editPost")}</div>
        </div>
      )}
      <div>
        {title && <div className="community-post-title-text">{title}</div>}
        {image && (
          <>
            <div>
              <img className="post-image" src={image} alt="africaTmpImage" />
            </div>
            {isArrowImage && (
              <div className="section-with-image-arrow">
                <div
                  className="block-image-arrow-side image-arrow-margin-right "
                  onClick={() => changePhoto(-1, image)}
                >
                  <img
                    className="image-arrow-side"
                    src={imageArrowLeft}
                    alt="imageArrowLeft"
                  />
                </div>
                <div
                  className="block-image-arrow-side"
                  onClick={() => changePhoto(1, image)}
                >
                  <img
                    className="image-arrow-side"
                    src={imageArrowRight}
                    alt="imageArrowRight"
                  />
                </div>
              </div>
            )}
          </>
        )}
        <div className="post-child-text">
          {text}
          {/* Lusanga has joined SAUTI-Youth. Check back for updates (or some other piece of boilerplate copy). */}
        </div>
        {date && <div className="post-date">{date}</div>}
        <div className="margin-bottom-42px">
          <div className="community-post-line" />
        </div>
        {files && files.length > 0 && (
          <>
            <div className="community-post-download-text">{t("downloads")}</div>
            {files.map((file, index) => {
              return (
                <div key={index}>
                  <div className="file-row-post">
                    <img src={fileDownloadImage} alt="fileDownloadImage" />
                    {/* <div className="file-download-image"></div> */}
                    <div className={`community-post-file-name ${textColor()}`}>
                      {file.name}
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="margin-bottom-42px">
              <div className="community-post-line" />
            </div>
          </>
        )}
        {attendees && (
          <>
            <div className="attendees-text">{t("attendees")}</div>
            <div className="attendees-names">
              {attendees.map((attendee, index) => {
                if (attendee === attendees[attendees.length - 1])
                  return (
                    <div className="attendee-name" key={index}>
                      {attendee}
                    </div>
                  );
                else
                  return (
                    <div className="attendee-name" key={index}>
                      {attendeeText},
                    </div>
                  );
              })}
            </div>
            <div className="margin-bottom-24px">
              <div className="community-post-line" />
            </div>
          </>
        )}
        {attendeeText && (
          <>
            <div className="attendees-text">{t("attendees")}</div>
            <div className="attendees-names">
              <div className="attendee-name">{attendeeText}</div>
            </div>
            <div className="margin-bottom-24px">
              <div className="community-post-line" />
            </div>
          </>
        )}
        <div className="commitment-card-row-btns">
          {IS_SHOW_EDIT_UPDATE_BTN && (
            <CustomButtonNavLink
              to={NAV_LINK_TO_EDIT_UPDATE}
              theme={theme}
              text={t("editUpdate")}
            />
          )}
        </div>
        {isShare && <ShareSocial />}
        {downBtn && (
          <div className="edit-post-in-wall-block-down">{downBtn}</div>
        )}
      </div>
    </>
  );
};

export default CommunityPost;
