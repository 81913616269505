import React, {useEffect, useState} from "react";
import TitlePageTextTheme from "../../../components/titlePageTextTheme/titlePageTextTheme";
import ContainerPosts from "../../../components/containerPosts/ContainerPosts";
import ShallPost from "../../../components/shallPost/ShallPost";
import "./AccountDetails.css";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import CustomInputWithError from "../../../components/customInput/customInputWithError/СustomInputWithError";
import CustomDropDown from "../../../components/customDropDown/CustomDropDown";
import CustomRadioButton from "../../../components/customRadioButton/customRadioButton";
import CustomButton from "../../../components/customButton/CustomButton";
import GoBack from "../../../components/goBack/GoBack";
import {
    middlewarGetAccountMe,
    middlewarPutAccountMe,
    middlewarPutAvatar,
    middlewarPostAccountSetPasswordMe,
} from "../../../services/middleware/MiddlewateUser";
import {selfDelete} from "../../../services/User"
import {EMAIL, setAvatar, setLogOut, setUsername} from "../../../reducers/userReducer";
import {useDispatch, useSelector} from 'react-redux';
import {AccountMeI} from "../../../types/types";
import { setHeaderActiveTab} from "../../../reducers/headerReducer";
import {useIonViewWillEnter} from "@ionic/react";
import {persistor} from "../../../reducers";

const AccountDetails: React.FC = () => {
    const history = useHistory();
    const {t} = useTranslation("common");
    const THEME_PAGE = useSelector((state:any) => state.theme.theme)
    const email = useSelector((state:any) => state.user.email)

    const [errors, setErrors] = useState({
        isErrorPassword: false,
        textErrorPassword: "",
        isErrorPasswordRep: false,
        textErrorPasswordRep: "",
    });
    const [account, setAccount] = useState<AccountMeI>({
        first_name: "",
        language: "",
        last_name: "",
        launch_screen: "",
        launch_screen_text: "",
        type_user: "",
        password: "",
        passwrodRep: "",
        community: "",
        avatar: "",
        id: ""
    });
    const optionsLang = [
        {
            label: t("english"),
            value: "EN",
        },
        {
            label: t("swahili"),
            value: "SW",
        },
    ];

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const responce = await middlewarGetAccountMe();
        if (responce.isError) {
            history.goBack();
        } else {
            setAccount({...account, ...responce});
        }
    };

    const createNormalOptionLang = (lang: string) => {
        const option = optionsLang.find((item: any) => item.value === lang);
        return option;
    };

    const workWithPassword = async () => {
        if (account.password.length > 0) {
            if (account.password != account.passwrodRep) {
                setErrors({
                    isErrorPassword: false,
                    textErrorPassword: t("passwordsMustBeTheSame"),
                    isErrorPasswordRep: true,
                    textErrorPasswordRep: t("passwordsMustBeTheSame"),
                });
            } else if (account.password.length < 10) {
                setErrors({
                    isErrorPassword: true,
                    textErrorPassword: t("passwordMustBeAt"),
                    isErrorPasswordRep: false,
                    textErrorPasswordRep: "",
                });
            } else if (account.passwrodRep.length < 10) {
                setErrors({
                    isErrorPassword: false,
                    textErrorPassword: "",
                    isErrorPasswordRep: true,
                    textErrorPasswordRep: t("passwordMustBeAt"),
                });
            } else {
                setErrors({
                    isErrorPassword: false,
                    textErrorPassword: "",
                    isErrorPasswordRep: false,
                    textErrorPasswordRep: "",
                });
                await middlewarPostAccountSetPasswordMe(account.password);
            }
        } else {
            setErrors({
                isErrorPassword: false,
                textErrorPassword: "",
                isErrorPasswordRep: false,
                textErrorPasswordRep: "",
            });
        }
    };

    const [showInfo, setShowInfo] = useState(false);
    const [selectedFile, setSelectedFile] = useState()
    const [preview, setPreview] = useState(account.avatar);
    const dispatch = useDispatch();

    const infoHandler = function () {
        setShowInfo(!showInfo)
    }

    const save = async () => {
        await saveAvatar()
        const responce = await middlewarPutAccountMe(account);
        workWithPassword();
        dispatch(setUsername(account.first_name))
        alert(t("yourDataHasBeenUpdated"));

        await persistor.flush();
        window.location.reload();
    };

    const saveAvatar = async () => {
        if (!selectedFile) {
            return
        }
        const formData = new FormData();
        // Update the formData object
        formData.append(
            'avatar', selectedFile
        );

        await middlewarPutAvatar(account.id, formData);
        dispatch(setAvatar(preview))
    }

    useEffect(() => {
        if (!selectedFile) {
            setPreview(account.avatar)
            return
        }

        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile, account])

    const onSelectFile = (e: any) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined)
            return
        }

        // I've kept this example simple by using the first image instead of multiple
        setSelectedFile(e.target.files[0])
    }

    const deleteAccount = async () => {
        if (window.confirm("Do you want to delete this account?")) {
            await selfDelete()
            dispatch(setLogOut());
            caches && caches.delete('dynamic_cache_sauti');
            await persistor.purge();
            window.location.reload();
        }
    }
    useIonViewWillEnter(() => {
        dispatch(setHeaderActiveTab(3))
    }, []);

    return (
        <>
          <TitlePageTextTheme
            theme={THEME_PAGE}
            cssTitleScreenCommunity="resourse-title-page-text-green"
          >
            <>
                <GoBack theme={THEME_PAGE} isGoBack text={t("myAccount")}/>
                <div>{t("personalDetails")}</div>
            </>
         </TitlePageTextTheme>
            <ContainerPosts theme={THEME_PAGE} text="">
                <ShallPost cssSectionWall="community-page-css-section-wall sign-in-css-section-wall">
                    <div className="account-country-manamenet-country-text flex flex-row justify-between items-center ">
                        <span>{showInfo ? 'Personal Info' : t("updateYourAccountDetails")}</span>
                        <CustomButton
                            theme={THEME_PAGE}
                            text={t("gdpr")}
                            onClick={() => infoHandler()}
                            additionalClasses={showInfo ? '' : 'inactive'}/>
                    </div>

                    {!showInfo ?
                        <div>
                            <section className="vertical-line vertical-line-top-bottom"></section>
                            <section>
                                <div className={"flex flex-col justify-center items-center gap-4"}>
                                    <div className={"w-32 h-32 "}>
                                        <img src={preview} className={"w-32 h-32 object-cover rounded-full"}
                                             style={{backgroundColor: "#ccc"}}/>
                                    </div>
                                    <div>
                                        <input id="file"
                                               type="file"
                                               name="some"
                                               onChange={onSelectFile}
                                               className={"hidden"}/>
                                        <label htmlFor="file">
                                            <CustomButton
                                                theme={THEME_PAGE}
                                                text={"Upload Avatar"}/>
                                        </label>
                                    </div>
                                </div>
                            </section>
                            <section className="vertical-line vertical-line-top-bottom"></section>
                            <section>
                                <div className="row-account-details row-margin-bottom-account-details">
                                    <div className="row-account-details-left">{t("name")}</div>
                                    <div className="row-account-details-right">
                                        <div className="row-first-name-block">
                                            <CustomInputWithError
                                                cssInput="cusom-small-input-props"
                                                id="first_name"
                                                value={account.first_name}
                                                onChange={(inputText) => {
                                                    setAccount({...account, first_name: inputText});
                                                }}/>
                                        </div>
                                        <div>
                                            <CustomInputWithError
                                                cssInput="cusom-small-input-props"
                                                id="last_name"
                                                value={account.last_name}
                                                onChange={(inputText) => setAccount({
                                                    ...account, last_name: inputText
                                                })}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-account-details">
                                    <div className="row-account-details-left">{t("email")}</div>
                                    <div className="row-account-details-right">
                                        <CustomInputWithError
                                            cssInput="cusom-large-input-props"
                                            id="email"
                                            value={email || ""}
                                            onChange={() => console.log()}/>
                                    </div>
                                </div>
                            </section>
                            <section className="vertical-line vertical-line-top-bottom"></section>
                            <section>
                                <div className="row-account-details row-margin-bottom-account-details-password">
                                    <div className="row-account-details-left">
                                        {t("newPassword")}
                                    </div>
                                    <div className="row-account-details-right">
                                        <CustomInputWithError
                                            cssInput="cusom-large-input-props"
                                            id="password"
                                            type="password"
                                            value={account.password}
                                            isError={errors.isErrorPassword}
                                            textError={errors.textErrorPassword}
                                            onChange={(inputText) => setAccount({...account, password: inputText})}/>
                                    </div>
                                </div>
                                <div className="row-account-details">
                                    <div className="row-account-details-left"></div>
                                    <div className="row-account-details-right">
                                        <CustomInputWithError
                                            cssInput="cusom-large-input-props"
                                            id="passwordRep"
                                            value={account.passwrodRep}
                                            isError={errors.isErrorPasswordRep}
                                            textError={errors.textErrorPasswordRep}
                                            type="password"
                                            onChange={(inputText) => setAccount({...account, passwrodRep: inputText})}/>
                                    </div>
                                </div>
                            </section>
                            <section className="vertical-line vertical-line-top-bottom"></section>
                            <section>
                                <div className="row-account-details row-margin-bottom-account-details">
                                    <div className="row-account-details-left">{t("language")}</div>
                                    <div className="row-account-details-right">
                                        <CustomDropDown
                                            setValue={(option: any) => setAccount({...account, language: option.value})}
                                            defaultOption={createNormalOptionLang(account.language)}
                                            options={optionsLang}
                                            cssControl="cusom-large-input-props"/>
                                    </div>
                                </div>
                                <div className="row-account-details">
                                    <div className="row-account-details-left">
                                        {t("lauchScreen")}
                                    </div>
                                    <div className="row-account-details-right">
                                        <div className="cusom-large-input-props row-account-details-right">
                                            <label
                                                className="community-measure-radio-block margin-right-custom-radion-btn row-first-name-block">
                                                <CustomRadioButton
                                                    id={"1"}
                                                    value={"1"}
                                                    cssSize="custom-input-radio-size"
                                                    checked={account.launch_screen === "MY" ? true : false}
                                                    onChange={() => setAccount({...account, launch_screen: "MY"})}/>
                                                <div>{t("myCommunity")}</div>
                                            </label>
                                            <label
                                                className="community-measure-radio-block margin-right-custom-radion-btn">
                                                <CustomRadioButton
                                                    id={"2"}
                                                    value={"2"}
                                                    cssSize="custom-input-radio-size"
                                                    checked={account.launch_screen === "OV" ? true : false}
                                                    onChange={() => setAccount({...account, launch_screen: "OV"})}/>
                                                <div>{t("sautiOverview")}</div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="vertical-line vertical-line-top-bottom"></section>
                            <div className="flex justify-between margin-bottom-in-tablet">
                                <div className="down-button-country-management">
                                    <CustomButton
                                        onClick={deleteAccount}
                                        text={"Delete Account"}/>
                                </div>

                                <div className="down-button-country-management">
                                    <CustomButton
                                        theme={THEME_PAGE}
                                        onClick={save}
                                        text={t("saveChanges")}/>
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            <section className="vertical-line vertical-line-top-bottom"></section>
                            <div className="flex flex-row ">
                                <span className="w-32 info-label"> {"Avatar :"} </span>
                                <div className="ml-4 p-2 ">
                                    <img src={preview} className={"w-32 h-32 object-cover rounded-full"}
                                         style={{backgroundColor: "#ccc"}}/>
                                </div>
                            </div>
                            <div className="flex flex-row ">
                                <span className="w-32 info-label"> {"First Name :"} </span>
                                <span className="ml-4"> {account.first_name} </span>
                            </div>
                            <div className="flex flex-row ">
                                <span className="w-32 info-label"> {"Last Name :"}  </span>
                                <span className="ml-4"> {account.last_name} </span>
                            </div>
                            <div className="flex flex-row ">
                                <span className="w-32 info-label"> {"Email :"} </span>
                                <span className="ml-4"> {email || ""} </span>
                            </div>
                            <div className="flex flex-row ">
                                <span className="w-32 info-label"> {"Language :"} </span>
                                <span className="ml-4"> {account.language} </span>
                            </div>
                            <div className="flex flex-row ">
                                <span className="w-32 info-label"> {"Launch Screen :"} </span>
                                <span className="ml-4"> {account.launch_screen_text} </span>
                            </div>
                            <div className="flex flex-row ">
                                <span className="w-32 info-label"> {"Community :"} </span>
                                <span className="ml-4"> {account.community} </span>
                            </div>
                        </div>}
                </ShallPost>
            </ContainerPosts>
        </>
    );
};

export default AccountDetails;
