import { IonButton } from "@ionic/react";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import "./content.css";
import "./ContentAboutUs.css";
import "../footer/Footer.css";
import { useTranslation } from "react-i18next";

const ContentAboutUs: React.FC = () => {
  const { t } = useTranslation("common");

  return (
    <div className="content_container">
      <div className="name_content">
        <div className="banner_control_about_us">
          <IonButton color="light" id="back_to_resources_button">
            <IonIcon slot="start" icon={chevronBackOutline} />
            <span>{t("resources")}</span>
          </IonButton>
        </div>
        <div className="banner_name_about_us">{t("aboutUs")}</div>
      </div>
      <div className="content_about_us">
        <div className="description_about_us">{t("learnHowWereHelping")}</div>
        <div className="container_functions_about_us">
          <div className="container_detail_about_us">
            <div className="subcontainer_detail_about_us">
              <div className="detail_about_us_section_what_we_do">
                <div className="what_we_do_description_menu">
                  <div className="what_we_do_menu">{t("whatWeDo")}</div>
                  <div className="what_we_do_description">
                    {t("aCommunityLedApproach")}
                  </div>
                </div>
                <div className="what_we_do_forward_container">
                  <IonIcon
                    icon={chevronForwardOutline}
                    className="forward_arrow"
                  />
                </div>
              </div>
              <div className="detail_about_us_section_who_we_are">
                <div className="who_we_are_description_menu">
                  <div className="who_we_are_menu">{t("whoweAre")}</div>
                  <div className="who_we_are_description">
                    {t("theTeamBehindSAUTI")}
                  </div>
                </div>
                <div className="who_we_are_forward_container">
                  <IonIcon
                    icon={chevronForwardOutline}
                    className="forward_arrow"
                  />
                </div>
              </div>
              <div className="detail_about_us_section_cva">
                <div className="cva_description_menu">
                  <div className="cva_menu">{t("theCVAProcess")}</div>
                  <div className="cva_description">
                    {t("GuidesAndTemplates")}
                  </div>
                </div>
                <div className="cva_forward_container">
                  <IonIcon
                    icon={chevronForwardOutline}
                    className="forward_arrow"
                  />
                </div>
              </div>
              <div className="detail_about_us_section_conditions">
                <div className="conditions_description_menu">
                  <div className="conditions_menu">
                    {t("terms")} & {t("conditions")}
                  </div>
                </div>
                <div className="conditions_forward_container">
                  <IonIcon
                    icon={chevronForwardOutline}
                    className="forward_arrow"
                  />
                </div>
              </div>
              <div className="detail_about_us_section_buttons">
                <div className="detail_about_us_buttons">
                  <IonButton color="light" id="edit_button">
                    <span>{t("editGroup")}</span>
                  </IonButton>
                  <IonButton color="light" id="add_button">
                    <span>{t("addNewResource")}</span>
                  </IonButton>
                </div>
              </div>
            </div>
          </div>
          <div className="container_standarts_about_us">
            <div className="subcontainer_standarts_about_us">
              <div className="standarts_about_us_decsription">
                {t("alsoSee")}
              </div>
              <div className="standarts_about_us_irish">
                <div className="standarts_about_us_irish_menu">
                  <span>{t("irishStandards")}</span>
                </div>
                <div className="standarts_about_us_irish_forward">
                  <IonIcon
                    icon={chevronForwardOutline}
                    className="forward_arrow"
                  />
                </div>
              </div>
              <div className="standarts_about_us_tanzanian">
                <div className="standarts_about_us_tanzanian_menu">
                  <span>{t("tanzanianStandards")}</span>
                </div>
                <div className="standarts_about_us_tanzanian_forward">
                  <IonIcon
                    icon={chevronForwardOutline}
                    className="forward_arrow"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default ContentAboutUs;
