import React, {useEffect, useState} from "react";
import TitlePageTextTheme from "../../components/titlePageTextTheme/titlePageTextTheme";
import {useTranslation} from "react-i18next";
import ShallPost from "../../components/shallPost/ShallPost";
import GoBack from "../../components/goBack/GoBack";
import CustomCheckbox from "../../components/customCheckbox/CustomCheckbox";
import CustomButton from "../../components/customButton/CustomButton";
import IonLoadingPage from "../../components/ionLoadingPage/IonLoadingPage";
import {CountryManagementI, CountryI} from "../../types/types";
import {
    middlewarGetCountryTanzania,
    middlewarGetCountryIreland,
    middlewarPutCountryIreland,
    middlewarPutCountryTanzania,
} from "../../services/middleware/MiddlewateUser";
import "./CountyManagement.css";
import {useDispatch, useSelector} from "react-redux";
import {setHeaderActiveTab} from "../../reducers/headerReducer";
import {useIonViewWillEnter} from "@ionic/react";



const CountyManagement: React.FC = () => {
    const {t} = useTranslation("common");
    const [testCheck, setTestCheck] = useState(false);
    const [countries, setCountries] = useState<CountryManagementI>();
    const [countriesCancel, setCountryCancel] = useState<CountryManagementI>();
    const [isLoading, setIsLoadind] = useState<boolean>(false);
    const [countryCheckBox, setCountryCheckBox] = useState({
        tanzania: {
            level_0_name: "Country",
            level_0_active: true,
        },
        ireland: {
            level_0_name: "Country",
            level_0_active: true,
        },
    });
    const THEME_PAGE = useSelector((state:any) => state.theme.theme )
    const dispatch = useDispatch();

    useIonViewWillEnter(() => {
        dispatch(setHeaderActiveTab(3))
    }, []);

    useEffect(() => {
        setData();
    }, []);

    const setData = async () => {
        setIsLoadind(true);
        const tanzania: CountryI = await middlewarGetCountryTanzania();
        const ireland: CountryI = await middlewarGetCountryIreland();
        setCountries({
            tanzania: tanzania,
            ireland: ireland,
        });
        setCountryCancel({
            tanzania: tanzania,
            ireland: ireland,
        });
        setIsLoadind(false);
    };

    const save = async () => {
        if (countries) {
            await middlewarPutCountryIreland(countries.ireland);
            await middlewarPutCountryTanzania(countries.tanzania);
        }
    };

    const cancel = () => {
        setCountries(countriesCancel);
    };

    return (

        <>
            <TitlePageTextTheme
                theme={THEME_PAGE}
                cssTitleScreenCommunity="resourse-title-page-text-green"
            >
                <>
                    <GoBack theme={THEME_PAGE} isGoBack text={t("myAccount")}/>
                    <div>{t("countryManagement")}</div>
                </>
            </TitlePageTextTheme>
            <section className="align-section-community-page">
                <ShallPost cssSectionWall="community-page-css-section-wall">
                    <section className="text-align-center account-country-manamenet-title">
                        Determine which levels of the geographic hiearachy are used to
                        navigate
                    </section>
                    <section className="vertical-line vertical-line-top-bottom"></section>
                    {countries?.ireland && (
                        <section className="row-item-country-management">
                            <div className="account-country-manamenet-country-text">
                                {countries?.ireland.name}
                            </div>
                            <div className="list-checkbox-countrys">
                                <div>
                                    <CustomCheckbox
                                        id="checkbox-link-ireland-level-0-name"
                                        textAfter={countryCheckBox.ireland.level_0_name}
                                        cssContainerProps="unset-width"
                                        checked={countryCheckBox.ireland.level_0_active}
                                        onChange={(change) => {
                                            // const newIreland: any = {
                                            //   ...countryCheckBox.ireland,
                                            //   level_0_active: change,
                                            // };
                                            // setCountryCheckBox({
                                            //   ...countryCheckBox,
                                            //   ireland: newIreland,
                                            // });
                                        }}/>
                                </div>
                                <div>
                                    <CustomCheckbox
                                        id="checkbox-link-ireland-level-1-name"
                                        textAfter={countries?.ireland.level_1_name}
                                        cssContainerProps="unset-width"
                                        checked={countries?.ireland.level_1_active}
                                        onChange={(change) => {
                                            const newIreland: any = {
                                                ...countries.ireland,
                                                level_1_active: change,
                                            };
                                            setCountries({...countries, ireland: newIreland});
                                        }}/>
                                </div>
                                <div>
                                    <CustomCheckbox
                                        id="checkbox-link-ireland-level-2-name"
                                        textAfter={countries?.ireland.level_2_name}
                                        cssContainerProps="unset-width"
                                        checked={countries?.ireland.level_2_active}
                                        onChange={(change) => {
                                            const newIreland: any = {
                                                ...countries.ireland,
                                                level_2_active: change,
                                            };
                                            setCountries({...countries, ireland: newIreland});
                                        }}/>
                                </div>
                                <div>
                                    <CustomCheckbox
                                        id="checkbox-link-ireland-level-3-name"
                                        textAfter={countries?.ireland.level_3_name}
                                        cssContainerProps="unset-width"
                                        checked={countries?.ireland.level_3_active}
                                        onChange={(change) => {
                                            const newIreland: any = {
                                                ...countries.ireland,
                                                level_3_active: change,
                                            };
                                            setCountries({...countries, ireland: newIreland});
                                        }}/>
                                </div>
                            </div>
                        </section>
                    )}
                    <section className="vertical-line vertical-line-top-bottom"></section>
                    {countries?.tanzania && (
                        <section className="row-item-country-management">
                            <div className="account-country-manamenet-country-text">
                                {countries.tanzania.name}
                            </div>
                            <div className="list-checkbox-countrys">
                                <div>
                                    <CustomCheckbox
                                        id="checkbox-link-tanzania-level-0-name"
                                        textAfter={countryCheckBox.tanzania.level_0_name}
                                        cssContainerProps="unset-width"
                                        checked={countryCheckBox.tanzania.level_0_active}
                                        onChange={(change) => {
                                            // const newTanzania: any = {
                                            //   ...countryCheckBox.tanzania,
                                            //   level_0_active: change,
                                            // };
                                            // setCountryCheckBox({
                                            //   ...countryCheckBox,
                                            //   tanzania: newTanzania,
                                            // });
                                        }}/>
                                </div>
                                <div>
                                    <CustomCheckbox
                                        id="checkbox-link-tanzania-level-1-name"
                                        textAfter={countries?.tanzania.level_1_name}
                                        cssContainerProps="unset-width"
                                        checked={countries?.tanzania.level_1_active}
                                        onChange={(change) => {
                                            const newTanzania: any = {
                                                ...countries.tanzania,
                                                level_1_active: change,
                                            };
                                            setCountries({...countries, tanzania: newTanzania});
                                        }}/>
                                </div>
                                <div>
                                    <CustomCheckbox
                                        id="checkbox-link-tanzania-level-2-name"
                                        textAfter={countries?.tanzania.level_2_name}
                                        cssContainerProps="unset-width"
                                        checked={countries?.tanzania.level_2_active}
                                        onChange={(change) => {
                                            const newTanzania: any = {
                                                ...countries.tanzania,
                                                level_2_active: change,
                                            };
                                            setCountries({...countries, tanzania: newTanzania});
                                        }}/>
                                </div>
                                <div>
                                    <CustomCheckbox
                                        id="checkbox-link-tanzania-level-3-name"
                                        textAfter={countries?.tanzania.level_3_name}
                                        cssContainerProps="unset-width"
                                        checked={countries?.tanzania.level_3_active}
                                        onChange={(change) => {
                                            const newTanzania: any = {
                                                ...countries.tanzania,
                                                level_3_active: change,
                                            };
                                            setCountries({...countries, tanzania: newTanzania});
                                        }}/>
                                </div>
                            </div>
                        </section>
                    )}
                    <div className="container-post-update-btn margin-bottom-in-tablet">
                        <div></div>
                        <div className="down-button-country-management">
                            <CustomButton onClick={cancel} text={t("cancel")}/>
                            <CustomButton
                                theme={THEME_PAGE}
                                onClick={save}
                                text={t("save")}/>
                        </div>
                    </div>
                </ShallPost>
            </section>
            <IonLoadingPage isLoading={isLoading}/>
        </>

    );
};

export default CountyManagement;
