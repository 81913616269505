import React from "react";
import "./style.css";
import PieChartComponent from "../../components/chartsComponents/pieChart";
import TinyBarChartComponent from "../../components/chartsComponents/tinyBarChart";
import LinearDiagramChartComponent from "../../components/chartsComponents/lineDiagram";

const COLORS:any = {
    "Very bad":"#DB802E",
    "Bad":"#DB802E",
    "Ok":"#E7B500",
    "Good":"#3EA551",
    "Very good":"#3EA551"
};


type Props = {
    chartName: string
    chartId: string
    chart:any
    showSentiment: boolean
    showHistory?: boolean
    openChartLink: Function
}
const normalizationNumberArray = (array: number[]) => {
    const maxNumber = Math.max(...array);
    const normalizeArrayNumber = array.map((item) => {
        return (item * 100) / maxNumber;
    });
    return normalizeArrayNumber;
};
const generationLinearPoints = (array: number[]) => {
    return array.map((item, index) => {
        return {
            level: item,
            control_point: index + 1 === array.length ? true : false,
            text: true,
        };
    });
};
const generationCommunitySentiment = (array: number[]) => {
    array = normalizationNumberArray(array);
    let objectSentiment = {
        badly: 0,
        unsatisfactory: 0,
        satisfactory: 0,
        good: 0,
        excelent: 0,
    };
    objectSentiment.badly = array[0];
    objectSentiment.unsatisfactory = array[1];
    objectSentiment.satisfactory = array[2];
    objectSentiment.good = array[3];
    objectSentiment.excelent = array[4];
    return objectSentiment;
};
const getColorFromValue = (value: number) => {
    let color
    if(value < 50){
        color = "#E7B500"
        if(value < 25){
            color = "#DB802E"
        }
    } else {
        color = "#3EA551"
    }

    return color
}

const BrowseChartsContainer: React.FC<Props> = ({ openChartLink, chart, chartId, chartName, showSentiment, showHistory }) => {
    let renderOption
    const color = (chart && chart.state && COLORS[Object.keys(chart.state)[0]])||'#6B7280';

    if (showSentiment&&chart.rate_all) {
        renderOption = < TinyBarChartComponent color={color} data={chart} />
    } else {
        renderOption = <PieChartComponent color={color} data={chart.rate||0} options={{browseCharts:true}}/>
    }
    if (showHistory) {
        renderOption =  <LinearDiagramChartComponent data={chart} showSentiment={showSentiment} color={color} />
    }


    return (
        <div className="chart_contaiter" onClick={() => openChartLink(chartId)}>
            <div className="chart_item">
                {
                    renderOption
                }
            </div>
            <div className="chartNameContainer">
                <div
                    className="name-chart-browse"
                    style={{color}}
                >
                    {chartName}
                </div>
                <div className="tooltip">
                    {chartName}
                </div>
            </div>
        </div>
    );
};

export default BrowseChartsContainer;
