import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en.json";
import sw from "./sw.json";

export const DEFAULT_LANG = "en";
export const LANG = "lang";
export const languages = ["en", "sw"];
export const languagesObjects = [
  {
    text: "English",
    value: "english",
    abbreviation: "en",
  },
  {
    text: "Swahili",
    value: "swahili",
    abbreviation: "sw",
  },
];

i18n.use(initReactI18next).init({
  interpolation: { escapeValue: false },
  resources: {
    en: {
      common: en,
    },
    sw: {
      common: sw,
    },
  },
  lng: localStorage.getItem(LANG) || DEFAULT_LANG,
});

export default i18n;
