import React, { useState } from "react";
import { IonLoading } from "@ionic/react";

type Props = {
  isLoading: boolean;
  loadingText?: string;
};

const IonLoadingPage: React.FC<Props> = ({
  isLoading,
  loadingText = "Please wait...",
}) => {
  return (
    <>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={isLoading}
        // onDidDismiss={() => setShowLoading(false)}
        message={loadingText}
        // duration={5000}
      />
    </>
  );
};

export default IonLoadingPage;
