import React from 'react';
import "./customRadioButton.css"

type CustomRadioButtonProps = {   
    value: string;
    id?: string;
    cssSize?: string;
    cssRadio?: string;   
    checked: boolean;
    onChange: (inputText: string) => void;
    name?:string;
};

type CustomRadioButtonState = {};

class CustomRadioButton extends React.Component
<
    CustomRadioButtonProps,
    CustomRadioButtonState
> {
    constructor(props: any) {
		super(props);
        this.handlerOnChange = this.handlerOnChange.bind(this);
    }

    handlerOnChange(e: React.FormEvent<HTMLInputElement>) {
        const inputValue = e.currentTarget.value;
       
        this.props.onChange(inputValue);
    }

    render () {
        const default_class = this.props.cssSize?"custom-input-radio":"";
        
        return(
            <div className={`wrap-radio ${this.props.cssSize && this.props.cssSize}`}>
                <input
                    id={this.props.id && this.props.id}
                    value={this.props.value}
                    onChange={this.handlerOnChange}
                    className={`${default_class} ${this.props.cssRadio && this.props.cssRadio}`}
                    type="radio"                     
                    checked={this.props.checked}
                    name={this.props.name?this.props.name:"radio"}
                />
            </div>
        );
    }
}
export default CustomRadioButton;