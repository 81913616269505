import {
  IonItem,
  IonLabel,
  IonIcon,
} from "@ionic/react";

import { globeOutline, person, book, location } from "ionicons/icons";
import {NavLink} from "react-router-dom";
import LogoImage from "../../assets/images/logo.png";
import "./Header.css";
import IconTabs from "../iconTabs/IconTabs";
import { getTextColor } from "../../theme/CombineThemes";
import { useTranslation } from "react-i18next";
import {URL_ACCOUNT, URL_SIGNIN} from "../../accessRghts/Access";
import {useSelector} from "react-redux";

const Header: React.FC = () => {

  const activeTheme = useSelector((state:any) => state.theme.activeTheme)
  const activeTab = useSelector((state:any) => state.header.activeTab)

  const email = useSelector((state:any) => state.user.email);
  const first_name = useSelector((state:any) => state.user.first_name);
  const avatar = useSelector((state:any) => state.user.avatar);
  const role = useSelector((state:any) => state.user.role);

  const { t } = useTranslation("common");

  const NAV_LINKS = [
    { text: t("browse"), link: "/browse", icon: globeOutline },
    { text: t("community"), link: "/community/my", icon: location },
    { text: t("resources"), link: "/resources", icon: book },
    { text: first_name|| email || t("logIn"), link: role==='not_registered_user'?URL_SIGNIN:URL_ACCOUNT, img:avatar, icon: person },
  ];
  const textColor = () => {
    return getTextColor(activeTheme);
  };

  return (
    <>
      <div className="head_container">
        <div className="logo_container">
          <NavLink to="/">
            <img
              className="logo-image-header"
              src={LogoImage}
              alt="LogoImage"
            />
          </NavLink>
        </div>
        <div className="container_menu">
          {NAV_LINKS.map((item, index) => {
            var class_item: string;

            index === NAV_LINKS.length - 1
              ? (class_item = "last_menu")
              : (class_item = "");
            return (
              <IonItem
                key={index}
                className={class_item}
                routerLink={item.link}
                lines="none"
              >
                { item.img ?
                    <img  src={item.img} className={"w-6 h-6 object-cover rounded-full mr-2"} />
                    :
                    <IonIcon className="menu_icon" icon={item.icon} size="small" />
                }
                <IonLabel
                  className={`hidden-in-mobile ${
                    activeTab === index && textColor()
                  }`}
                >
                  {item.text}
                </IonLabel>
              </IonItem>
            );
          })}
        </div>
      </div>
      <IconTabs NAV_LINKS={NAV_LINKS} activeTab={activeTab}/>
    </>
  );
};

export default Header;
