import * as access from "../accessRghts/Access";
import { ROLE } from "../reducers/userReducer";
import { store } from '../reducers';
import { middlewareGetCommunityId } from "../services/middleware/MiddlewareCommunity";
import { COMMUNITY } from "../reducers/userReducer";

const getSliceUser = (param: string) => {
  const state: any = store.getState();

  return state.user[param];
}
export const getObjectsValue = (object: any, index: number = 0) => {
  return object[Object.keys(object)[index]];
};

export const normalizeText = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLocaleLowerCase();
};

export const replaceRouteParams = (route: string, params: any) => {
  if (!params) return route.replace(/\?[^\/]+$/, "");

  // pathToRegexp can probably help here, but moving swiftly on...
  for (let key in params) {
    route = route.replace(new RegExp(`:${key}`), params[key]);
    route = route.replace(
      new RegExp(`([?&]${key})\\??`),
      `$1=${encodeURIComponent(params[key])}`
    );
  }

  // replace any optionals that didnt get set, and trailing question marks / ampersands
  route = route.replace(/\/?:[^\?:\/]*(\?(?=\/))?/g, "");
  route = route.replace(/([?&])[^\/=]*(?:&|$)/g, "$1");

  route = route.replace(/\?$/g, "");
  route = route.replace(/\&$/g, "");
  route = route.replace(/\?(?=\/)/g, "");

  return route;
};

export const isCheckConvertUrlEqual = (route: string, appRouter: string) => {
  const routeSplit = route.split("/");
  const appRouteSplit = appRouter.split("/");
  let arrResult: any = [];
  if (routeSplit.length === appRouteSplit.length) {
    for (let i = 1; i < routeSplit.length; i++) {
      if (appRouteSplit[i].charAt(0) === ":") {
        arrResult.push(routeSplit[i]);
      } else if (routeSplit[i] === appRouteSplit[i]) {
        arrResult.push(routeSplit[i]);
      } else {
        arrResult.push(false);
      }
    }
  } else {
    return false;
  }

  const isHaveFalse = arrResult.every((item: any) => item);
  if (isHaveFalse) {
    return true;
  } else {
    return false;
  }
};

export const isUserRegistration = () => {
  // is user registration
  const USER_ROLE = getSliceUser(ROLE);

  if (!USER_ROLE) {
    return false;
  }

  if (USER_ROLE === access.ROLE_NOT_REGISTERED_USER) {
    return false;
  }

  return true;
};

export const isUserGlobalAdmin = () => {
  // global
  const USER_ROLE = getSliceUser(ROLE);
  return USER_ROLE === access.ROLE_GLOBAL_USER ? true : false;
};

export const isUserGlobalIrelandTanzaniaAdmin = () => {
  // global tanzania ireland
  const USER_ROLE = getSliceUser(ROLE);
  if (
    USER_ROLE === access.ROLE_GLOBAL_USER ||
    USER_ROLE === access.ROLE_ADMIN_IRELAND ||
    USER_ROLE === access.ROLE_ADMIN_TANZANIA
  ) {
    return true;
  } else {
    return false;
  }
};

export const isUserGlobalIrelandAdmin = () => {
  // global ireland
  const USER_ROLE = getSliceUser(ROLE);
  if (
    USER_ROLE === access.ROLE_GLOBAL_USER ||
    USER_ROLE === access.ROLE_ADMIN_IRELAND
  ) {
    return true;
  } else {
    return false;
  }
};

export const isUserGlobalTanzaniaAdmin = () => {
  // global tanzania
  const USER_ROLE = getSliceUser(ROLE);
  if (
    USER_ROLE === access.ROLE_GLOBAL_USER ||
    USER_ROLE === access.ROLE_ADMIN_TANZANIA
  ) {
    return true;
  } else {
    return false;
  }
};

export const isUserIrelandAdmin = () => {
  // global ireland
  const USER_ROLE = getSliceUser(ROLE);
  if (USER_ROLE === access.ROLE_ADMIN_IRELAND) {
    return true;
  } else {
    return false;
  }
};

export const isUserTanzaniaAdmin = () => {
  // global tanzania
  const USER_ROLE = getSliceUser(ROLE);
  if (USER_ROLE === access.ROLE_ADMIN_TANZANIA) {
    return true;
  } else {
    return false;
  }
};

export const isUserAdminCountryByCommunity = async (idCommunity: string) => {
  const responce = await middlewareGetCommunityId(idCommunity);
  if (isUserGlobalAdmin()) {
    return true;
  }

  if (responce.isError) {
    return false;
  } else {
    const { country } = responce;
    const USER_ROLE = getSliceUser(ROLE);
    return country === USER_ROLE ? true : false;
  }
};

export const getAccessUserToCommunity = async (idCommunity: string) => {
  if (isUserGlobalAdmin()) {
    return true;
  }
  const localCommunity = getSliceUser(COMMUNITY);
  if (localCommunity) {
    if (+idCommunity === +localCommunity) {
      return true;
    }
  }

  const responce = await middlewareGetCommunityId(idCommunity);
  if (responce.isError) {
    return false;
  } else {
    const { country } = responce;
    const USER_ROLE = getSliceUser(ROLE);
    return country === USER_ROLE;
  }
};

export const getAccessAdminsToCommunity = async (idCommunity: string) => {
  if (isUserGlobalAdmin()) {
    return true;
  }
  const responce = await middlewareGetCommunityId(idCommunity);
  if (responce.isError) {
    return false;
  } else {
    const { country } = responce;
    const USER_ROLE = getSliceUser(ROLE);
    return country === USER_ROLE;
  }
};
