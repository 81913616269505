import React, { useState } from "react";
import "./СustomInputWithError.css";
import notValidatedImage from "../../../assets/images/forms/not-validated.svg";

type Props = {
  isShowCountLetter?: boolean;
  value: string;
  id?: string;
  cssInput?: string;
  cssCounterLetter?: string;
  placeholder?: string;
  onChange: (inputText: string) => void;
  type?: string;
  isError?: boolean;
  textError?: string;
};

const СustomInputWithError: React.FC<Props> = ({
  isShowCountLetter = false,
  onChange,
  value,
  id,
  cssInput,
  cssCounterLetter,
  placeholder,
  textError,
  type = "text",
  isError = false,
}) => {
  const [countLetter, setCountLetter] = useState(0);
  const [maxLetter] = useState(80);

  const hendlerOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    const inputValue = e.currentTarget.value;
    if (inputValue.length <= maxLetter) {
      setCountLetter(inputValue.length);
      onChange(inputValue);
    }
  };

  const isShowTextError = isError && textError ? true : false;
  return (
    <section className="section-custom-input section-custom-input-with-error">
      <div>
        <input
          id={id}
          value={value}
          onChange={hendlerOnChange}
          className={`custom-input ${cssInput && cssInput} ${
            isError && "form-input-error"
          }`}
          type={type}
          placeholder={placeholder}
        />
      </div>
      {isShowCountLetter && (
        <div
          className={`custom-input-count-letter ${
            cssCounterLetter && cssCounterLetter
          }`}
        >
          {countLetter} / {maxLetter}
        </div>
      )}
      {isError && (
        <img
          className="not-validated-input-image"
          src={notValidatedImage}
          alt="notValidatedImage"
        />
      )}
      {isShowTextError && (
        <div className="custom-input-with-error-message">{textError}</div>
      )}
    </section>
  );
};

export default СustomInputWithError;
