import { getThemeFromLocalstorage } from "../reducers/themeReducer";
import { THEMES } from "../mocks/layout";
import "./blue.css";
import "./green.css";

const THEME = getThemeFromLocalstorage();

export const getTextColor = (theme: string) => {
  if (theme === THEMES.BLUE) return "text-color-blue";
  else if (theme === THEMES.GREEN) return "text-color-green";
  else return "text-color-default";
};

export const getTitlePageTextStyle = (theme: string) => {
  if (theme === THEMES.BLUE) return "title-page-text-style-blue";
  else if (theme === THEMES.GREEN) return "title-page-text-style-green";
  else return "title-page-text-style-blue-default";
};
