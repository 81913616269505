import React from 'react';
import "./content.css";
import MakeChart from './MakeChart'
import ChartDescription from './ChartDescription'
import TinyBarChartComponent from "../chartsComponents/tinyBarChart";

type CommunitySentimentProps = {
	isData: boolean,
	sentiments:{badly:number,unsatisfactory:number,satisfactory:number,good:number,excelent:number},
	country:string,
	description:string,
	children: JSX.Element,
	state_santiments: {
		evaluation:any,
  		linear_points:number[]
	},
	rate_all:any
};
type CommunitySentimentState = {
  bar: JSX.Element|null;
};
class CommunitySentiment extends React.Component<CommunitySentimentProps,CommunitySentimentState> {
	setOption: {
		type: number,
		optionSentiment:{
			barWidth: number,
			community_sentiment: any,
			backColor: string,
			colorBar: string
		}
	}
	constructor(props: any) {
		super(props);
		this.state = {bar:null};
		this.setOption = {
			type: 2,
			optionSentiment: {
				barWidth: 0,
				community_sentiment:this.props.sentiments,
				backColor: 'white',
				colorBar:'blue'//default
			}
		}
		this.onResize = this.onResize.bind(this);
		this.setSizeBar = this.setSizeBar.bind(this);
	}

	onResize(event:any) {
		this.setSizeBar();
	}

	componentDidMount() {
		window.addEventListener("resize", this.onResize);
		this.setSizeBar();

	}

	setSizeBar() {
		var bar:JSX.Element = <MakeChart setOption={this.setOption}/>;
		if (this.props.state_santiments.evaluation != null) {

			const keys:string[] = Object.keys(this.props.state_santiments.evaluation);
			if (keys.length != 0) {

				if (keys[0].toLowerCase() === 'improving' ||
					keys[0].toLowerCase() === 'accelerating' ||
					keys[0].toLowerCase() === 'good') {

					this.setOption.optionSentiment.colorBar = 'rgb(62, 165, 81)';
				}else
  				if (keys[0].toLowerCase() === 'stalled') {

  					this.setOption.optionSentiment.colorBar = 'rgb(231, 181, 0)';
  				}
				else if (keys[0].toLowerCase() === 'declining' ||
						 keys[0].toLowerCase() === 'bad'||
						 keys[0].toLowerCase() === 'poor' ) {

					this.setOption.optionSentiment.colorBar = 'rgb(219, 128, 46)';

				}
			}
		}

		if (window.outerWidth <= 790 && this.setOption.optionSentiment.barWidth!=20) {
			this.setOption.optionSentiment.barWidth = 20;
			this.setState({bar:bar});
		}else {
			if (window.outerWidth > 790 && this.setOption.optionSentiment.barWidth!=24) {
				this.setOption.optionSentiment.barWidth = 24;
				this.setState({bar:bar});
		    }
		}
	}

	render() {
		if (this.props.isData) {

			var descript_el:JSX.Element|null = null;
			var linear_points:number[] = [];

		  	descript_el = <ChartDescription state_santiments={this.props.state_santiments} wrap_container="subcontainer_linear_sentiments"/>

		  	return(
		  		<div className='sentiment_block_full1'>
		  			<div className="name_of_block_sentiments">
		  				<div className="name_of_block1">
		  				{this.props.children}
		  				</div>
		  			</div>
  					<div className="container_linear_sentiments">
	  					{descript_el}
  					</div>
  					<div className="wrap_chart">
  						<div className="bar_container">
  						{/*{this.state.bar}*/}
							< TinyBarChartComponent color={this.setOption.optionSentiment.colorBar} data={this.props} options={{height:'100%'}}/>
  						</div>
  					</div>
		  		</div>
		  	);
		}else

		return(
			<div className='sentiment_block_short'>
				<div className="name_of_block_description">{this.props.children}</div>
			  	<div className="description_commit">{this.props.description}</div>
			</div>
		);
	}
}

export default CommunitySentiment;
