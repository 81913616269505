import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import { IonContent, IonPage } from "@ionic/react";
import SmallHeader from "../../components/smallHeader/SmallHeader";
import WrapperOnTheForm from "../../components/wrapperOnTheForm/WrapperOnTheForm";
import CustomInput from "../../components/customInput/CustomInput";
import CustomRadioButton from "../../components/customRadioButton/customRadioButton";
import CustomTextAre from "../../components/customTextAre/CustomTextAre";
import CustomDropDown from "../../components/customDropDown/CustomDropDown";
import ButtonResource from "../../components/content/ButtonResource"
import { withTranslation, WithTranslation } from "react-i18next";
import { getThemes } from "../../services/themes";
import {ConfirmBox} from "../../components/content/ConfirmBox";
import TitlePageTextTheme from "../../components/titlePageTextTheme/titlePageTextTheme";
import {
    getActionPlanInfo,
    getActionPlanById,
    updateActionPlanById,
    deleteActionPlanById
} from "../../services/actionPlan";
import moment from 'moment';
import "../AddResourceGroupPage.css";
import "./AddCommitment.css";
import DatePicker from "react-datepicker";

type EditCommitmentProps = {};

type EditCommitmentState = {
    contentPage: ContentPage,
    themesOption: OptionTheme[],
    isConfirmBox: boolean;
};
type Theme = {
    id:  number,
    name: string
}
type FilesGathering = {
    id: number,
    community_gathering: number,
    file: string,
    file_name: string,
    url: string
}
type OptionTheme = {
    label: string,
    value: number,
}
type CommunityGathering = {
    id: number,
    attendees: string,
    date: string,
    files: FilesGathering[],
    community: number
}
type ContentPage = {
    id: number;
    en_title: string,
    title?: string,
    en_resources: string,
    en_sub_activities: string,
    sw_title: string,
    sw_resources: string,
    sw_sub_activities: string,
    due_date: string,
    responsible_party: string,
    responsible_party_type: string,
    monitored_by: string,
 //   status: string,
 //   community_gatherings: CommunityGathering[] | null,
    theme: number
};

class EditCommitment extends React.Component
<
    EditCommitmentProps &
    WithTranslation &
    RouteComponentProps<{}>,
    EditCommitmentState
> {
    themes: Theme[] | null;
    idCommunity: number;
    idPlan: number;
    arrayPartyType: any[];
    defaultTheme: OptionTheme | null;

    constructor(props: any) {
		super(props);
        this.state = {
            contentPage: {
                id: 0,
                en_title: '',
                en_resources: '',
                title:'',
                en_sub_activities: '',
                sw_title: '',
                sw_resources: '',
                sw_sub_activities: '',
                due_date: moment(new Date()).format('YYYY-MM-DD'),
                responsible_party: '',
                responsible_party_type: '',
                monitored_by: '',
              //  status: '',
              //  community_gatherings: [],
                theme: 0
            },
            themesOption: [],
            isConfirmBox: false
        };
        this.themes = null;
        this.idCommunity = 0;
        this.idPlan = 0;
        this.arrayPartyType = [];
        this.defaultTheme = null;

        this.setContentPage = this.setContentPage.bind(this);
        this.createRadioGroup = this.createRadioGroup.bind(this);
        this.onChangeRadioButtons = this.onChangeRadioButtons.bind(this);
        this.selectedTheme = this.selectedTheme.bind(this);
        this.createOptionThemes = this.createOptionThemes.bind(this);
        this.backToPrevoius = this.backToPrevoius.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.getActionInfo = this.getActionInfo.bind(this);
        this.getCommunityActionPlan = this.getCommunityActionPlan.bind(this);
        this.deletePlan = this.deletePlan.bind(this);
        this.processConfirm = this.processConfirm.bind(this);
    }

    async componentDidMount() {
        const param = Object.create(this.props.match.params);
        this.idCommunity = Number(param.idCommunity);
        this.idPlan = Number(param.idPlan);

        await this.getCommunityActionPlan(this.idCommunity,this.idPlan);
        await this.getActionInfo();

        try {
            const {data} = await getThemes();
            this.themes = [...data];
            this.createOptionThemes(this.themes);
        } catch (error: any) {
            console.log(`getThemes error status: ${error}`);
        }
    }

    async getCommunityActionPlan(idCommunity: number, idPlan: number) {
        try {
            const {data} = await getActionPlanById(idCommunity,idPlan);
            this.setContentPage({
                ...data,
                en_title: data.en_title? data.en_title:"",
                en_resources: data.en_resources? data.en_resources:"",
                en_sub_activities: data.en_sub_activities? data.en_sub_activities:"",
                sw_title: data.sw_title? data.sw_title:"",
                sw_resources: data.sw_resources? data.sw_resources:"",
                sw_sub_activities: data.sw_sub_activities? data.sw_sub_activities:"",
                responsible_party: data.responsible_party? data.responsible_party:"",
                responsible_party_type: data.responsible_party_type? data.responsible_party_type:"",
                monitored_by: data.monitored_by? data.monitored_by:""
            });
        } catch (error: any) {
            console.log(`getActionPlanById error status: ${error}`);
        }
    }

    processConfirm(confirm: string) {
        this.setState({isConfirmBox:false});
        if (confirm === 'Delete') {
            this.deletePlan();
        }
    }

    async getActionInfo() {
        try {
            const {data} = await getActionPlanInfo();
            this.arrayPartyType = [...data.responsible_party_type];
            if (this.arrayPartyType.length != 0) {
                const keys = Object.keys(this.arrayPartyType[0]);
                const type_party: string = keys[0];
                if (this.state.contentPage.responsible_party_type != '')
                    this.onChangeRadioButtons(this.state.contentPage.responsible_party_type);
                else
                    this.onChangeRadioButtons(type_party);
            }
        } catch (error: any) {
            console.log(`getActionInfo error status: ${error}`);
        }
    }

    createOptionThemes(arrayThemes:Theme[]) {
       const themesOption: OptionTheme[] = arrayThemes.map((item)=>{
            if (this.state.contentPage.theme != 0) {
                if (this.state.contentPage.theme === item.id) {
                    this.defaultTheme = {
                        label: item.name,
                        value: item.id
                    };
                }
            }
            return(
                {
                    label:item.name,
                    value:item.id
                }
            );
        });
        this.setState({themesOption:themesOption});
    }

    setContentPage(content:any) {
        this.setState({contentPage:content});
    }

    onChangeRadioButtons(value: string) {
        const content:ContentPage = {...this.state.contentPage,responsible_party_type:value};
        this.setContentPage(content);
    }

    selectedTheme(option:any) {

        this.setContentPage({...this.state.contentPage,theme:option.value});
    }

    backToPrevoius() {
        this.props.history.goBack();
    }

    async deletePlan() {
        try {
            const {data} = await deleteActionPlanById(this.idCommunity,this.idPlan);
        } catch (error: any) {
            console.log(`deleteActionPlanById error status: ${error}`);
        }

    }

    async saveChanges() {
        try {
            const {data} = await updateActionPlanById(this.idCommunity,this.idPlan, this.state.contentPage);
            console.log('response update',data);
            this.props.history.goBack();
        } catch (error: any) {
            console.log(`updateActionPlanById error status: ${error}`);
        }

    }

    createRadioGroup(arrayParty:any[], onChangeHadler: (inputText: string) => void):JSX.Element[] {
        const { t } = this.props;
        return arrayParty.map((item:any,index:number)=>{
            let keys = Object.keys(item);
            let party_type: string = keys[0];
            return(
                <div key={index++} className="community-update-row custom-input-radio-row">
                    <div className='custom-input-radio-wrap cusom-input-props'>
                        <CustomRadioButton
                            id={party_type}
                            value={party_type}
                            cssSize="custom-input-radio-size"
                            checked={this.state.contentPage.responsible_party_type === party_type?true:false}
                            onChange={
                                (inputText:string) => {
                                    onChangeHadler(inputText);
                                }
                            }
                        />
                        <label
                            className="community-update-label-in-row"
                            htmlFor={party_type}
                        >
                            {t(item[party_type])}
                        </label>
                    </div>
                </div>
            );
        });
    }

    render() {
        const { t } = this.props;
        const content:ContentPage = {...this.state.contentPage};
        const add_resource_css: string = ' custom-tetxtarea-add-commitment';
        const group_radio:JSX.Element[] = this.createRadioGroup(
            this.arrayPartyType,
            this.onChangeRadioButtons
            );
        const buttonSave: JSX.Element = (
                <ButtonResource id="saveCommitment" onClickHandler={this.saveChanges} text_color="white">
                  {t("save")}
                </ButtonResource>
              );

        const buttonDelete: JSX.Element = (
                <ButtonResource
                  id="cancelCommitment"
                  color="light"
                  text_color="rgb(105, 113, 126)"
                  onClickHandler={()=>this.setState({isConfirmBox:true})}
                >
                  {t("delete")}
                </ButtonResource>
              );

        return(
            <IonPage>
                <IonContent>
                    <SmallHeader rightText={t("edit")} />
                    <TitlePageTextTheme>
                        <div className="community-update-header-text">
                            {/*t("individualActionPlanCommitment")*/t("commitmentDetails")}
                        </div>
                    </TitlePageTextTheme>
                    <WrapperOnTheForm
                        title={t("engdetails")}
                        description={t("provideAClearTitleAndOptionally")}
                    >
                        <div className="community-update-row">
                            <label
                                className="community-update-label-in-row"
                                htmlFor="englishTitle"
                            >
                            {t("title")}
                            </label>
                            <CustomInput
                                cssInput="cusom-input-props"
                                id="englishTitle"
                                value={this.state.contentPage.en_title}
                                onChange={
                                    (inputText:string) => {
                                       this.setContentPage({...content,en_title:inputText});
                                    }
                                }
                                isShowCountLetter
                            />
                        </div>
                        <div className="community-update-row">
                            <label
                                className="community-update-label-in-row"
                                htmlFor="englishResource"
                            >
                            {t("resources")}
                            </label>
                            <CustomTextAre
                                value={this.state.contentPage.en_resources}
                                id="englishResource"
                                isShowCountLetter
                                onChange={
                                    (inputText:string) => {
                                        this.setContentPage({...content,en_resources:inputText});
                                    }
                                }
                                cssTextAre={"cusom-input-props" + add_resource_css}
                            />
                        </div>
                        <div className="community-update-row">
                            <label
                                className="community-update-label-in-row"
                                htmlFor="englishActivitis"
                            >
                            {t("subaAtivities")}
                            </label>
                            <CustomTextAre
                                value={this.state.contentPage.en_sub_activities}
                                id="englishActivitis"
                                isShowMarkdown
                                onChange={
                                    (inputText:string) => {
                                        this.setContentPage({...content,en_sub_activities:inputText});
                                    }
                                }
                                cssTextAre={"cusom-input-props" + add_resource_css}
                            />
                        </div>
                    </WrapperOnTheForm>

                    <WrapperOnTheForm
                        title={t("swahiliTranslation")}
                        description={t("optionalIfAvailable")}
                    >
                        <div className="community-update-row">
                            <label
                                className="community-update-label-in-row"
                                htmlFor="swTitle"
                            >
                            {t("title")}
                            </label>
                            <CustomInput
                                cssInput="cusom-input-props"
                                id="swTitle"
                                value={this.state.contentPage.sw_title}
                                onChange={
                                    (inputText:string) => {
                                        this.setContentPage({...content,sw_title:inputText});
                                    }
                                }
                                isShowCountLetter
                            />
                        </div>
                        <div className="community-update-row">
                            <label
                                className="community-update-label-in-row"
                                htmlFor="swResources"
                            >
                            {t("resources")}
                            </label>
                            <CustomTextAre
                                value={this.state.contentPage.sw_resources}
                                id="swResources"
                                isShowCountLetter
                                onChange={
                                    (inputText:string) => {
                                        this.setContentPage({...content,sw_resources:inputText});
                                    }
                                }
                                cssTextAre={"cusom-input-props" + add_resource_css}
                            />
                        </div>
                        <div className="community-update-row">
                        <label
                            className="community-update-label-in-row"
                            htmlFor="swActivitis"
                        >
                        {t("subaAtivities")}
                        </label>
                        <CustomTextAre
                            value={this.state.contentPage.sw_sub_activities}
                            id="swActivitis"
                            isShowMarkdown
                            onChange={
                                (inputText:string) => {
                                    this.setContentPage({...content,sw_sub_activities:inputText});
                                }
                            }
                            cssTextAre={"cusom-input-props" + add_resource_css}
                        />
                        </div>
                    </WrapperOnTheForm>

                    <WrapperOnTheForm
                        title={t("attributes")}
                        description={t("specifyCommitmentDueCompleted")}
                    >
                        <div className="community-update-row">
                            <label
                                className="community-update-label-in-row"
                                htmlFor="englishDate"
                            >
                            {t("dueDate")}
                            </label>
                            <section className="section-custom-input">
                                <div>
                                    <DatePicker className={"custom-date-picker update-picker"}
                                                value={ this.state.contentPage.due_date}
                                                onChange={(date:Date) =>  this.setContentPage({...content,due_date:date.toLocaleDateString('en-CA')})}
                                                popperClassName="some-custom-class"
                                                popperPlacement="top-end"
                                                popperModifiers={[
                                                    {
                                                        name: "offset",
                                                        options: {
                                                            offset: [5, 10],
                                                        },
                                                    },
                                                    {
                                                        name: "preventOverflow",
                                                        options: {
                                                            rootBoundary: "viewport",
                                                            tether: false,
                                                            altAxis: true,
                                                        },
                                                    },
                                                ]}
                                    />
                                </div>
                            </section>
                        </div>
                        <div className="community-update-row">
                            <label
                                className="community-update-label-in-row"
                                htmlFor="englishParty"
                            >
                            {t("responsibleParty")}
                            </label>
                            <CustomInput
                                cssInput="cusom-input-props"
                                id="englishParty"
                                value={this.state.contentPage.responsible_party}
                                onChange={
                                    (inputText:string) => {
                                        this.setContentPage({...content,responsible_party:inputText});
                                    }
                                }
                            />
                        </div>
                        {group_radio}

                        <div className="community-update-row">
                            <label
                                className="community-update-label-in-row"
                                htmlFor="englishMonitor"
                            >
                            {t("monitoredBy")}
                            </label>
                            <CustomInput
                                cssInput="cusom-input-props"
                                id="englishMonitor"
                                value={this.state.contentPage.monitored_by}
                                onChange={
                                    (inputText:string) => {
                                        this.setContentPage({...content,monitored_by:inputText});
                                    }
                                }
                            />
                        </div>
                    </WrapperOnTheForm>

                    <WrapperOnTheForm
                        title={t("categorisation")}
                        description={t("toHelpUsCompareSentimentFrom")}
                    >
                        <div className="community-update-row">
                            <label
                                className="community-update-label-in-row"
                                htmlFor="englishTheme"
                            >
                            {t("theme")}
                            </label>
                            <CustomDropDown
                                defaultOption={this.defaultTheme !== null?this.defaultTheme as any:''}
                                setValue={(option) => this.selectedTheme(option)}
                                cssControl="cusom-input-props"
                                options={this.state.themesOption as any[]}
                            />
                        </div>
                    </WrapperOnTheForm>
                    <div className="section-add-resource-button commitment-button-control">
                        <div className="resource-button-cancel-container">
                        {buttonDelete}
                        </div>
                        <div className="resource-button-add-container">
                        {buttonSave}
                        </div>
                    </div>
                </IonContent>
                {this.state.isConfirmBox ? (
                <ConfirmBox
                    effectIn="rubberBand"
                    effectOut="zoomOutDown"
                    effectOutDel="rotateOut"
                    actionRender={(state: string) => {
                    this.processConfirm(state);
                    }}
                >
                    {t("willDeleteResource")}
                </ConfirmBox>
                ) : null}
            </IonPage>
        );
    }
}
const decoratedComponent = withTranslation("common")(EditCommitment);
export { decoratedComponent as EditCommitment };
