import React, {useEffect, useState} from "react";
import { RouteComponentProps } from "react-router-dom";
import ContentAll from "../components/content/ContentAll";
import "../components/header/Header.css";
import { useDispatch } from "react-redux";
import { setHeaderActiveTab} from "../reducers/headerReducer";
import {useIonViewWillEnter} from "@ionic/react";

const Browse: React.FC<RouteComponentProps> = (props) => {
  const [updateHome, setUpdateHome] = useState<boolean>(false);
  const dispatch = useDispatch();

  useIonViewWillEnter(() => {
    dispatch(setHeaderActiveTab(0))
  });

  useEffect(() => {
    setUpdateHome(props.history.location.pathname === "/browse")
  },[ props.history])

  return (
      <ContentAll updateHome={updateHome} />
  );
};

export default Browse;
