import React, {useEffect, useState} from "react";
import "./MyCommunity.css";
import {useTranslation} from "react-i18next";
import TitlePageTextTheme from "../../../components/titlePageTextTheme/titlePageTextTheme";
import NoBookmarkBlock from "../../../components/noBookmarkBlock/NoBookmarkBlock";
import {AppState} from "../../../reducers";
import {useDispatch, useSelector} from "react-redux";
import {PageTreeProvider} from "contexts/pageTreeContext";
import {
    NewsT,
    PaginationT,
} from "../../../types/types";
import CommunityDataHeader from "../../../components/communityDataHeader/CommunityDataHeader";
import NewsWall from "../../../components/newsWall/NewsWall";
import { setHeaderActiveTab } from "reducers/headerReducer";
import {useIonViewWillEnter} from "@ionic/react";
import TabComponent from "../../../components/tabsControl/TabsControl";
import {ThemesProvider} from "../../../contexts/themesChartsContext";
import BrowseChartsContainer from "../../../components/browseCharts/container";
import {getOlderUpdatesSummaryBrowse} from "../../../services/Community";

let isCommunityPage = false;
const MyCommunity: React.FC = () => {
    const {t} = useTranslation("common");
    const [isNoCommunity, setIsNoCommunity] = useState(true);
    const [levelURL, setLevelUrl] = useState("4");
    const [idURL, setIdURL] = useState("1");
    const [country, setCountry] = useState<string>("");
    const localCommunity = useSelector((state: AppState) => state.user.community);
    const pageTree = useSelector((state: AppState) => state.community.myPageTree);
    const [isRefreshComponent, setIsRefreshComponent] = useState(false);
    const [pagination, setPagination] = useState<PaginationT>({
        page: 1,
        pages:0,
        pageLen: 10,
    });
    const [news, setNews] = useState<NewsT[]>([]);

   /* const news = pageTree && pageTree.data && pageTree.data.updates && pageTree.data.updates.map((item: any) => {
        return {
            id: item.id,
            community: item.community,
            title: item.title,
            date: item.date,
            img: item.photos[0] && item.photos[0].url,
            text: item.content,
        };
    }) || [];
    console.log('page tree upds', pageTree.data.updates)*/


    const dispatch = useDispatch();

    useIonViewWillEnter(() => {
        getMyCommunity();
        dispatch(setHeaderActiveTab(1))
    });
    useEffect(() => {
        getMyCommunity();
    }, [localCommunity]);

    const updateNews = (update: any[]) => {
        if(Array.isArray(update)&&update.length){
            const newsForUpdate = update.map((item: any) => {
                return {
                    id: item.id,
                    community: item.community,
                    title: item.title,
                    date: item.date,
                    img: item.photos[0] && item.photos[0].url,
                    text: item.content,
                };
            })

            setNews([...news, ...newsForUpdate])
        }
    }

    const getMyCommunity = async () => {
        setIsNoCommunity(false);
        try {
            const id = localCommunity?.toString();
            const level = "4";

            if (id) {
                const {data:communityUpdates} = await getOlderUpdatesSummaryBrowse(id, pagination.page.toString())
                setLevelUrl(level.toString());
                setIdURL(id.toString());
                setIsRefreshComponent(!isRefreshComponent);
                if(communityUpdates){
                    setPagination({...pagination,pages:communityUpdates.pages})
                    updateNews(communityUpdates.data)
                }else{
                    updateNews(pageTree && pageTree.data && pageTree.data.updates||[])
                }

            } else {
                setIsNoCommunity(true);
            }
        }
        catch (error) {
            console.error(error);
        }
    };

    const loadOlderUpdates = async () => {
        const page = pagination.page + 1
        const {data:communityUpdates} = await getOlderUpdatesSummaryBrowse(idURL, page.toString() )
        if(communityUpdates){
            setPagination({...pagination,pages:communityUpdates.pages, page})
            updateNews(communityUpdates.data)
        }
    };

    const tabs =  ['updates', 'standards', 'sentiment', 'action']
    const [activeTab, setActiveTab] = useState(tabs[0]);

    const isShowBtnOlderUpdate = pagination.pages
        ? pagination.page < pagination.pages
        : true;
    return (
        <PageTreeProvider myCommunity={true}>
            <TitlePageTextTheme
                cssTitleScreenCommunity={`${
                    isNoCommunity && `align-bottom-text-my-community`
                } ${!isNoCommunity && `css-props-community-browse-context`}`}
            >
                {!isNoCommunity && (
                    <CommunityDataHeader
                        isGoBrowse
                        levelURL={levelURL}
                        idURL={idURL}
                        pageTreeProps={pageTree}
                        setNewsResponce={() => {}}
                        isCommunityProps={(isCommunity) =>
                            (isCommunityPage = isCommunity)
                        }
                        setCountry={(countryParams: string) => setCountry(countryParams)}
                    />
                )}

                {isNoCommunity && <div>{t("myCommunity")}</div>}
            </TitlePageTextTheme>
            {isNoCommunity && (
                <section className="align-center-my-cimmunity">
                    <NoBookmarkBlock/>
                </section>
            )}

            {!isNoCommunity && (
                <>
                    <div className="browse-charts">
                        <TabComponent tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab}/>
                        <div className="main-browse-chart">
                            <div className={ activeTab === 'updates' ? 'myCommunityNewsBlock': 'hidden'}>
                                <NewsWall
                                    levelURL={levelURL}
                                    noNews={t('noNews')}
                                    news={news}
                                    loadOlderUpdates={loadOlderUpdates}
                                    isShowBtnLoadOlderUpdates={isShowBtnOlderUpdate}
                                    isCommunity={isCommunityPage}
                                    idCommunity={idURL}
                                    country={country}
                                />
                            </div>
                            <div className={ activeTab !== 'updates' ? 'w-full': 'hidden'}>
								<ThemesProvider activeParentTab={activeTab} level={levelURL} id={idURL} myCommunity={true}>
									<BrowseChartsContainer>
									</BrowseChartsContainer>
								</ThemesProvider>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </PageTreeProvider>
    );
};

export default MyCommunity;
