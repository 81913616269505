import {
  postAuthToken,
  putResetPassword,
  getCountryIreland,
  getCountryTanzania,
  putCountryIreland,
  putCountryTanzania,
  getAccountMe,
  putAccountMe,
  postAccountSetPasswordMe,
  postFeedback,
  putAvatar,
} from "../User";
import { createErrorApi } from "../../helpers/helperApi";

export const middlewarePostAuthToken = async (
  email: string,
  password: string
) => {
  try {
    const { data } = await postAuthToken(email, password);
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewarPutResetPassword = async (email: string) => {
  try {
    const { data } = await putResetPassword(email);
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewarGetCountryTanzania = async () => {
  try {
    const { data } = await getCountryTanzania();
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewarGetCountryIreland = async () => {
  try {
    const { data } = await getCountryIreland();
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewarPutCountryIreland = async (dataSend: any) => {
  try {
    const { data } = await putCountryIreland(dataSend);
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewarPutCountryTanzania = async (dataSend: any) => {
  try {
    const { data } = await putCountryTanzania(dataSend);
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewarGetAccountMe = async () => {
  try {
    const { data } = await getAccountMe();
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewarPutAccountMe = async (sendData: any) => {
  try {
    const { data } = await putAccountMe(sendData);
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewarPutAvatar = async (userId:string, dataToSend: any) => {
  try {
    const { data } = await putAvatar(userId, dataToSend);
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewarPostAccountSetPasswordMe = async (
  sendPassword: string
) => {
  try {
    const { data } = await postAccountSetPasswordMe(sendPassword);
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

export const middlewarPostFeedback = async (sendData: any) => {
  try {
    const { data } = await postFeedback(sendData);
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};

// postFeedback
