import React, {useEffect, useState} from "react";
import TitlePageTextTheme from "../../../components/titlePageTextTheme/titlePageTextTheme";
import ContainerPosts from "../../../components/containerPosts/ContainerPosts";
import ShallPost from "../../../components/shallPost/ShallPost";
import logoImage from "../../../assets/images/logo.png";
import "./Reset.css";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {AppState} from "../../../reducers";
import {useDispatch, useSelector} from "react-redux";
import IonLoadingPage from "../../../components/ionLoadingPage/IonLoadingPage";
import {middlewarPutResetPassword} from "../../../services/middleware/MiddlewateUser";
import {setHeaderActiveTab} from "../../../reducers/headerReducer";
import {useIonViewWillEnter} from "@ionic/react";


const Reset: React.FC = () => {
    const history = useHistory();
    const {t} = useTranslation("common");
    const email = useSelector((state: AppState) => state.user.email);
    const [isLoading, setIsLoading] = useState(false);
    const THEME_PAGE = useSelector((state:any) => state.theme.theme )
    const dispatch = useDispatch();

    useIonViewWillEnter(() => {
        dispatch(setHeaderActiveTab(3))
    }, []);
    const backToMain = () => {
        history.push("/resources"); // TODO: set normal link
    };

    const cancelBtn = () => {
        history.push("/signin");
    };

    const resetBtn = async () => {
        setIsLoading(true);
        const responce = await middlewarPutResetPassword(email);
        console.log(responce);
        setIsLoading(false);
        history.push("/signin/reset/password");
    };

    return (

        <>
            <TitlePageTextTheme
                theme={THEME_PAGE}
                cssTitleScreenCommunity="sing-in-title-page-text"
            >
                <>
                    <div className="show-logo-in-table">
                        <img
                            onClick={backToMain}
                            className="sing-in-logo-image"
                            src={logoImage}
                            alt="logoImage"/>
                    </div>
                    <div>{t("myAccount")}</div>
                </>
            </TitlePageTextTheme><ContainerPosts
            theme={THEME_PAGE}
            text={t("eligibleParticipantsCanLogInHere")}
        >
            <section>
                {/* sign-in-css-section-wall */}
                <ShallPost cssSectionWall="community-page-css-section-wall sign-in-css-section-wall">
                    <>
                        <div className="text-align-left">
                            <div className="reset-form-title">{t("passwordReset")}</div>
                        </div>
                        <div className="row-form-sing-in">
                            <div className="reset-form-text">{t("weCanSendAnEmail")}</div>
                        </div>
                        <div className="sing-in-border"></div>
                        <div className="sing-in-container-for-btn">
                            <div
                                className="sing-in-reset-password-btn"
                                onClick={cancelBtn}
                            >
                                {t("cancel")}
                            </div>
                            <div className="sing-in-login-btn" onClick={resetBtn}>
                                {t("reset")}
                            </div>
                        </div>
                    </>
                </ShallPost>
            </section>
            <IonLoadingPage isLoading={isLoading}/>
        </ContainerPosts>
        </>

    );
};

export default Reset;
