import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./CustomTextAre.css";

type Props = {
  isShowMarkdown?: boolean;
  isShowCountLetter?: boolean;
  cssCounterLetter?: string;
  maxCountLetter?: number;
  value: string;
  id?: string;
  cssTextAre?: string;
  cssMarkdown?: string;
  cols?: number;
  rows?: number;
  downBlock?: React.ReactNode;
  onChange: (inputText: string) => void;
};

const CustomTextAre: React.FC<Props> = ({
  isShowMarkdown = false,
  isShowCountLetter = false,
  cssCounterLetter,
  maxCountLetter = 250,
  cols = 30,
  rows = 10,
  onChange,
  value,
  id,
  cssTextAre,
  cssMarkdown,
  downBlock,
}) => {
  const { t } = useTranslation("common");
  const [countLetter, setCountLetter] = useState(0);
  const [maxLetter] = useState(maxCountLetter);
  const hendlerOnChange = (e: string) => {
    onChange(e);    
    if (isShowCountLetter) setCountLetter(e.length);
  };

  return (
    <section className="section-custom-textarea">
      <div>
        <textarea
          onChange={(e) => hendlerOnChange(e.target.value)}
          className={`custom-textarea ${cssTextAre && cssTextAre}`}
          value={value}
          name=""
          id={id}
          cols={cols}
          rows={rows}
        />
      </div>
      {isShowMarkdown && (
        <div
          className={`custom-textarea-markdown ${cssMarkdown && cssMarkdown}`}
        >
          {t("youCanUse")}{" "}
          <span className="markdown-text">{t("markdown")}</span>{" "}
          {t("forStyling")}
        </div>
      )}
      {isShowCountLetter && (
        <div
          className={`custom-textare-count-letter  ${
            cssCounterLetter && cssCounterLetter
          }`}
        >
          {countLetter} / {maxLetter}
        </div>
      )}
      {downBlock}
    </section>
  );
};

export default CustomTextAre;
