import React, {useState, useEffect} from "react";
import "./CommunitySentimentBarChart.css";
import IonLoadingPage from "../../../components/ionLoadingPage/IonLoadingPage";
import BarchartCanvas from "../../../components/barchart/BarchartCanvas";
import {useTranslation} from "react-i18next";
import TitlePageTextTheme from "../../../components/titlePageTextTheme/titlePageTextTheme";
import GoBack from "../../../components/goBack/GoBack";
import ArrowLeftAndRight from "../../../components/arrowLeftAndRight/ArrowLeftAndRight";
import WrapperCard from "../../../components/wrapperCard/WrapperCard";
import CustomDropDown from "../../../components/customDropDown/CustomDropDown";
import {
    middlewareGetCommunitySentimentIdUpdates,
    middlewareGetCommunitySentimentId,
    middlewareGetCommunitySentiment,
} from "../../../services/middleware/MiddlewareCommunity";
import {THEMES} from "../../../mocks/layout";
import {useParams, useHistory} from "react-router-dom";
import {CommunityGatheringT} from "../../../types/types";
import {
    BOYS,
    GIRLS,
    MEN,
    WOMEN,
    UNDEFINED_PERSON,
} from "../../../mocks/layout";
import {normalizeText} from "../../../helpers/functions";
import faceSorrowfulImage from "../../../assets/images/indicators-color/face-sorrowful.svg";
import faceJubilantImage from "../../../assets/images/indicators-color/face-jubilant.svg";
import facePositiveImage from "../../../assets/images/indicators-color/face-positive.svg";
import faceNegativeImage from "../../../assets/images/indicators-color/face-negative.svg";
import faceNeutralImage from "../../../assets/images/indicators-color/face-neutral.svg";
import faceSorrowfulImageNoColor from "../../../assets/images/indicators/face-sorrowful.svg";
import faceJubilantImageNoColor from "../../../assets/images/indicators/face-jubilant.svg";
import facePositiveImageNoColor from "../../../assets/images/indicators/face-positive.svg";
import faceNegativeImageNoColor from "../../../assets/images/indicators/face-negative.svg";
import faceNeutralImageNoColor from "../../../assets/images/indicators/face-neutral.svg";
import CustomButtonNavLink from "../../../components/customButton/customButtonNavLink/CustomButtonNavLink";
import moment from "moment";
import "moment/min/locales.min";
import "moment/locale/sw";
import {v4 as uuidv4} from "uuid";
import {
    getAccessUserToCommunity,
    getObjectsValue,
} from "../../../helpers/functions";
import { setHeaderActiveTab } from "reducers/headerReducer";
import {useDispatch, useSelector} from "react-redux";
import {useIonViewWillEnter} from "@ionic/react";
import {setSentiments, setSentimentsDescriptions, setSentimentsDetails} from "../../../reducers/sentimentsReducer";
import {AppState} from "../../../reducers";


const momentFormat = "Do.MM.YYYY";
const SIZE_FOR_CHANGE_BARCHART_STEP_ONE = 520;
const SIZE_FOR_CHANGE_BARCHART_STEP_TWO = 380;
const FIRST_DATE_GENERATION_ON_PAGE = 0;
const PAGE_THEME = THEMES.GREEN;
const CommunitySentimentBarChart: React.FC = () => {
    const {t} = useTranslation("common");
    const [barChart, setBarChart] = useState({
        width: "420", // on mobile - 288, 376 desktop
        series: [
            {
                name: "Boys",
                data: [44, 55, 41, 65, 22],
                color: "rgb(103, 189, 33)",
            },
            {
                name: "Girls",
                data: [13, 23, 20, 8, 13],
                color: "rgb(180, 222, 147)",
            },
            {
                name: "Man",
                data: [11, 17, 15, 15, 21],
                color: "rgb(254, 176, 25)",
            },
            {
                name: "Woman",
                data: [21, 7, 25, 13, 22],
                color: "rgb(254, 210, 122)",
            },
        ],
        remarks: [],
        proposals: [],
        xaxis: {
            categories: ["", "", "", "", ""], // ["Very bad", "Bad", "Okay", "Good", "Very good"
        },
    });
    const {idCommunity, idSentiment} =
        useParams<{ idCommunity: string; idSentiment: string }>();
    const [dateOptions, setDateOptions] = useState<any[]>([]);
    const history = useHistory();
    const [currentDate, setCurrentDate] = useState<any>({});
    const [firstColumn, setFirstColumn] = useState([{}]);
    const [secondColumn, setSecondColumn] = useState([{}]);
    const [isUserCanEdit, setIsUserCanEdit] = useState<boolean>(false);
    const [isUserCanUpdate, setIsUserCanUpdate] = useState<boolean>(false);
    const [sentimentList, setSentimentList] = useState<any>();
    const SentimentsList = useSelector((state: AppState) =>  state.sentiments.sentimentsData)
    const Sentiment = useSelector((state: AppState) =>  state.sentiments.sentimentsDescriptions[idSentiment])
    const SentimentGatherings = useSelector((state: AppState) =>  state.sentiments.sentimentsDetails[idSentiment]&&state.sentiments.sentimentsDetails[idSentiment])
    const [statusPage, setStatusPage] = useState({
        image: "",
        text: "",
        color: "",
    });
    const [pageText, setPageText] = useState<any>({
        name: "",
        description: "",
    });

    const dispatch = useDispatch();

    useIonViewWillEnter(() => {
        dispatch(setHeaderActiveTab(1))
    }, []);

    useEffect(() => {
        getNormalSizeBarChart();
        getAllDate();
    }, [idSentiment]);

    useEffect(() => {
        prepareData();
    },[Sentiment, SentimentsList, SentimentGatherings])

    const fetchSentimentsDescriptions = async () => {
        try {
            const responceCommunitySentiment = await middlewareGetCommunitySentimentId(
                idCommunity,
                idSentiment
            );

            if (responceCommunitySentiment.cached && Sentiment) {
                return;
            }


            dispatch(setSentimentsDescriptions( { id:idSentiment,...responceCommunitySentiment}))

        } catch (err) {
            console.error(err);
        }

    }
    const fetchSentimentsList = async () => {
        try {
            const responceCommunitySentimentList = await middlewareGetCommunitySentiment(idCommunity);

            if (responceCommunitySentimentList.cached && SentimentsList && !SentimentsList.def) {
                return;
            }

            dispatch(setSentiments(responceCommunitySentimentList))
        } catch (err) {
            console.error(err);
        }

    }
    const fetchSentimentsDetails = async () => {
        try {
            const responceSentimentGatherings = await middlewareGetCommunitySentimentIdUpdates(
                idCommunity,
                idSentiment
            );

            if (responceSentimentGatherings.cached && SentimentGatherings) {
                return;
            }

            dispatch(setSentimentsDetails({id:idSentiment,updates:responceSentimentGatherings.community_gatherings}))

        } catch (err) {
            console.error(err);
        }

    }

    const getAllDate = async () => {
        try {
            const isAdminCountry = await getAccessUserToCommunity(idCommunity);
            setIsUserCanEdit(isAdminCountry);
            setIsUserCanUpdate(isAdminCountry);

            await Promise.all([fetchSentimentsDescriptions(),fetchSentimentsList(),fetchSentimentsDetails()])
        } catch (err) {
            console.error(err);
        }
    };

    const prepareData = async () => {

        if (!SentimentGatherings || !SentimentGatherings.updates) {
            return;
        }

        let newDateOptions;

        if (!SentimentGatherings.updates.length) {
            const emptyGathering = createEmptyGathering();
            setGatheringToBarchart(0, emptyGathering);
            newDateOptions = generationDateOptions(emptyGathering);
        } else {
            setGatheringToBarchart(
                SentimentGatherings.updates[FIRST_DATE_GENERATION_ON_PAGE]
                    .id,
                SentimentGatherings.updates
            );

            newDateOptions = generationDateOptions(
                SentimentGatherings.updates
            );
        }

        if (Sentiment && Sentiment.state) {
            const newImageStatus = getNormalImage(
                getObjectsValue(Sentiment.state)
            );
            const newTextStatus = getObjectsValue(Sentiment.state);
            const newTextColorStatus = getColorText(
                getObjectsValue(Sentiment.state)
            );
            setStatusPage({
                ...statusPage,
                color: newTextColorStatus,
                image: newImageStatus || "",
                text: newTextStatus,
            });
        }



        if (!SentimentsList) {
            history.goBack();
        }
        const {sentiments} = SentimentsList;
        const indexSentiment = sentiments.findIndex(
            (item: any) => item.id === +idSentiment
        );
        if (indexSentiment >= 0) {
            setPageText({
                name: sentiments[indexSentiment].name,
                description: sentiments[indexSentiment].description,
            });
        }

        setSentimentList(sentiments);
        setDateOptions(newDateOptions);

    };

    const createEmptyGathering = () => {
        const emptyCommunityGathering = [
            {
                attendees: "",
                community: 0,
                date: "",
                id: 0,
                score_cards: [
                    {
                        rating_very_bad: 0,
                        rating_bad: 0,
                        rating_ok: 0,
                        rating_good: 0,
                        rating_very_good: 0,
                        remarks: "",
                        proposals: "",
                        respondent_type: "",
                        id: 0,
                    },
                ],
            },
        ];

        return emptyCommunityGathering;
    };

    const setGatheringToBarchart = (
        id: number,
        communityGath: CommunityGatheringT[] | [] = []
    ) => {
        const findGathering = communityGath.find(
            (item: CommunityGatheringT) => item.id === id
        );

        let newRemarks: any = [];
        let newProposals: any = [];
        let newSeries: any = [];
        try {
            if (findGathering) {
                if (findGathering.score_cards) {
                    newSeries = findGathering.score_cards.map((item: any) => {
                        newRemarks.push(item.remarks);
                        newProposals.push(item.proposals);
                        return {
                            name: normalizeText(getTextAndColor(item.respondent_type).text),
                            data: [
                                item.rating_very_bad,
                                item.rating_bad,
                                item.rating_ok,
                                item.rating_good,
                                item.rating_very_good,
                            ],
                            color: getTextAndColor(item.respondent_type).color,
                        };
                    });
                }
            }
        }
        catch (error) {
            console.log(error);
        }

        let newFirstColumn: any = [];
        let newSecondColumn: any = [];
        findGathering?.score_cards.forEach((item: any, index: number) => {
            const newObject = {
                text: getTranslateText(getTextAndColor(item.respondent_type).text),
                color:
                    "barchart-legend-cirle-" +
                    getTextAndColor(item.respondent_type).key.toLocaleLowerCase(), // background-color-person-cirle-
            };
            if (index % 2) {
                newFirstColumn.push(newObject);
            } else {
                newSecondColumn.push(newObject);
            }
        });
        setFirstColumn(newFirstColumn);
        setSecondColumn(newSecondColumn);

        setBarChart({
            ...barChart,
            series: newSeries,
            remarks: newRemarks,
            proposals: newProposals,
        });
    };

    const getTranslateText = (name: string | undefined) => {
        switch (name) {
            case BOYS.text:
                return t("boys");
            case GIRLS.text:
                return t("girls");
            case MEN.text:
                return t("men");
            case WOMEN.text:
                return t("women");
        }
    };

    const getNormalImage = (state: string) => {
        switch (state.toLowerCase()) {
            case "Very Good".toLowerCase():
                return faceJubilantImage;
            case "Good".toLowerCase():
                return facePositiveImage;
            case "Ok".toLowerCase():
                return faceNeutralImage;
            case "Bad".toLowerCase():
                return faceNegativeImage;
            case "Very Bad".toLowerCase():
                return faceSorrowfulImage;
        }
    };

    const getColorText = (state: string) => {
        switch (state.toLowerCase()) {
            case "Very Good".toLowerCase():
                return "state-text-color-jubilant";
            case "Good".toLowerCase():
                return "state-text-color-positive";
            case "Ok".toLowerCase():
                return "state-text-color-neutral";
            case "Bad".toLowerCase():
                return "state-text-color-negative";
            case "Very Bad".toLowerCase():
                return "state-text-color-sorrowful";
            default:
                return "";
        }
    };

    const generationDateOptions = (responceSentiment: any) => {
        let newDateOptions: any = [];
        try {
            newDateOptions = responceSentiment.map((item: any) => {
                return {
                    label: moment(item.date).format(momentFormat),
                    value: item.id,
                };
            });
        }
        catch (error) {
            console.log(error);
        }
        setCurrentDate({
            label: moment(
                responceSentiment[FIRST_DATE_GENERATION_ON_PAGE].date
            ).format(momentFormat),
            value: responceSentiment[FIRST_DATE_GENERATION_ON_PAGE].id,
        });
        return newDateOptions;
    };

    const getTextAndColor = (name: string) => {
        switch (name) {
            case BOYS.text:
                return BOYS;
            case GIRLS.text:
                return GIRLS;
            case MEN.text:
                return MEN;
            case WOMEN.text:
                return WOMEN;
            default:
                return UNDEFINED_PERSON;
        }
    };

    const getNormalSizeBarChart = () => {
        if (window.screen.width <= SIZE_FOR_CHANGE_BARCHART_STEP_ONE) {
            setBarChart({
                ...barChart,
                width: "340",
            });
        }
        if (window.screen.width <= SIZE_FOR_CHANGE_BARCHART_STEP_TWO) {
            setBarChart({
                ...barChart,
                width: "288",
            });
        }
    };

    const onClickFilterBtn = (id: number, text: string) => {
        console.log(id);
    };

    const onChangeDate = (newDate: any) => {
        setGatheringToBarchart(newDate.value, SentimentGatherings.updates);
    };

    const moveOtherSentiment = (moveRight: boolean) => {
        if (sentimentList.length > 1) {
            const findIndex = sentimentList.findIndex(
                (item: any) => item.id === +idSentiment
            );
            if (moveRight) {
                if (sentimentList[findIndex + 1]) {
                    history.push(
                        `/community/${idCommunity}/sentiments/${
                            sentimentList[findIndex + 1].id
                        }`
                    );
                } else {
                    history.push(
                        `/community/${idCommunity}/sentiments/${sentimentList[0].id}`
                    );
                }
            } else {
                if (sentimentList[findIndex - 1]) {
                    history.push(
                        `/community/${idCommunity}/sentiments/${
                            sentimentList[findIndex - 1].id
                        }`
                    );
                } else {
                    history.push(
                        `/community/${idCommunity}/sentiments/${
                            sentimentList[sentimentList.length - 1].id
                        }`
                    );
                }
            }
        }
    };

    const LINK_TO_UPDATE = `/community/${idCommunity}/sentiments/${idSentiment}/update`;
    const LINK_TO_EDIT = `/community/${idCommunity}/sentiments/${idSentiment}/perfomance`; // /community/${idCommunity}/sentiments/${idSentiment}/edit
    return (

        <>
            <div className="community-gathering-compliance-title-page-wrap">
                <div className="community-gathering-compliance-title-page-wrap-upper">
                    <GoBack urlMove={`/community/${idCommunity}/sentiments`} text={"My Community Sentiments"}/>
                    <div className="community-sentiment-arrow-left-and-right">
                        <ArrowLeftAndRight
                            onClick={(direct) => moveOtherSentiment(direct)}/>
                    </div>
                </div>
                <div className="community-gathering-compliance-title-page-wrap-down">
                    <div className="community-gathering-compliance-title-page-wrap-down-name-standard">
                        {pageText.name}
                    </div>
                </div>
            </div>
            <section className="community-stabdards-align">
                <div className="align-status-list-media">
                    <div className="community-sentiment-barchart-left-text">
                        {/* {t("performanceMeasureDescriptionShould")}  */}
                        {pageText.description}
                    </div>
                    <div className="community-sentiment-barchart-top-margin-status-list">
                    </div>
                </div>
                <WrapperCard>
                    <div className="community-sentiment-row-barchart-data">
                        <div className="community-sentiment-row-left-title-text">
                            <div className="community-sentiment-barchart-block">
                                {statusPage.image && (
                                    <img
                                        className="community-sentiment-barchart-status-image"
                                        src={statusPage.image}
                                        alt="facePositiveImage"/>
                                )}
                                {statusPage.text && (
                                    <div
                                        className={`community-sentiment-barchart-status-text ${statusPage.color}`}
                                    >
                                        {statusPage.text}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="max-width-right-section"></div>
                    </div>
                    <div className="community-sentiment-row-barchart-data community-sentiment-row-barchart-adaptation">
                        <div className="community-sentiment-row-left-title-text"></div>
                        <div className="max-width-right-section-barchart ">
                            <section className="section-community-sentimnet-barchart-legends">
                                <div className="community-sentiment-container-for-barchart">
                                    <BarchartCanvas
                                        series={barChart.series}
                                        xaxisCategories={barChart.xaxis.categories}
                                        width={barChart.width}/>
                                </div>
                                <div className="community-sentiment-barchart-container-legends-image">
                                    <div className="margin-left-legend-block">
                                        <img
                                            className="community-sentiment-barchart-container-legend-image"
                                            src={faceSorrowfulImageNoColor}
                                            alt="faceJubilantImageNoColor"/>
                                    </div>
                                    <div>
                                        <img
                                            className="community-sentiment-barchart-container-legend-image"
                                            src={faceNegativeImageNoColor}
                                            alt="faceJubilantImageNoColor"/>
                                    </div>
                                    <div>
                                        <img
                                            className="community-sentiment-barchart-container-legend-image"
                                            src={faceNeutralImageNoColor}
                                            alt="faceJubilantImageNoColor"/>
                                    </div>
                                    <div>
                                        <img
                                            className="community-sentiment-barchart-container-legend-image"
                                            src={facePositiveImageNoColor}
                                            alt="faceJubilantImageNoColor"/>
                                    </div>
                                    <div>
                                        <img
                                            className="community-sentiment-barchart-container-legend-image"
                                            src={faceJubilantImageNoColor}
                                            alt="faceJubilantImageNoColor"/>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    <div className="community-sentimnet-horizontal-line"></div>
                    <div className="community-sentiment-row-barchart-data community-sentiment-row-addaptation-mobile">
                        <div className="community-sentiment-row-left-title-text">
                            {t("keys")}
                        </div>
                        <div className="max-width-right-section">
                            <div className="container-barchart-legend-cirle-block">
                                <div className="barchart-legend-cirle-block barchart-legend-cirle-block--margin">
                                    {firstColumn.length > 0 && (
                                        <>
                                            {firstColumn.map((item: any) => {
                                                return (
                                                    <div className="legend-cirle-flex" key={uuidv4()}>
                                                        <div
                                                            className={`barchart-legend-cirle ${item.color}`}
                                                        ></div>
                                                        <div
                                                            className="barchart-legend-cirle-text barchart-legend-cirle-text--margin">
                                                            {item.text}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    )}
                                </div>
                                <div className="barchart-legend-cirle-block">
                                    {secondColumn.length > 0 && (
                                        <>
                                            {secondColumn.map((item: any) => {
                                                return (
                                                    <div className="legend-cirle-flex" key={uuidv4()}>
                                                        <div
                                                            className={`barchart-legend-cirle ${item.color}`}
                                                        ></div>
                                                        <div
                                                            className="barchart-legend-cirle-text barchart-legend-cirle-text--margin">
                                                            {item.text}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="community-sentimnet-horizontal-line"></div>
                    <div className="community-sentiment-row-barchart-data">
                        <div className="community-sentiment-row-left-title-text">
                            {t("date")}
                        </div>
                        <div className="max-width-right-section">
                            {dateOptions.length && (
                                <>
                                    {dateOptions[0].label !== "Invalid date" && (
                                        <CustomDropDown
                                            isShowBorder={false}
                                            defaultOption={currentDate}
                                            options={dateOptions}
                                            setValue={(option: any) => onChangeDate(option)}/>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    <div className="community-sentimnet-horizontal-line"></div>
                    <div className="community-sentiment-row-barchart-data community-sentiment-row-addaptation-mobile">
                        <div className="community-sentiment-row-left-title-text">
                            {t("remarks")}
                        </div>
                        <div
                            className="max-width-right-section community-sentiment-row-right-text community-sentiment-row-right-text--margin">
                            {barChart.remarks.length > 0 && (
                                <>
                                    {barChart.remarks.map((item: any) => {
                                        return (
                                            <div key={uuidv4()}>
                                                <div>{item}</div>
                                                <div className="community-sentiment-row-right-down-text">
                                                    {/* Prospect Hill, Girls */}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </>
                            )}
                        </div>
                    </div>
                    <div className="community-sentimnet-horizontal-line"></div>
                    <div className="community-sentiment-row-barchart-data community-sentiment-row-addaptation-mobile">
                        <div className="community-sentiment-row-left-title-text">
                            {t("proposals")}
                        </div>
                        <div
                            className="max-width-right-section community-sentiment-row-right-text community-sentiment-row-right-text--margin">
                            {barChart.proposals.length > 0 && (
                                <>
                                    {barChart.proposals.map((item: any) => {
                                        return (
                                            <div key={uuidv4()}>
                                                <div>{item}</div>
                                                <div className="community-sentiment-row-right-down-text">
                                                    {/* Prospect Hill, Girls */}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </>
                            )}
                        </div>
                    </div>
                    <div className="community-sentimnet-horizontal-line"></div>
                    <div className="community-sentiment-row-barchart-data">
                        <div className="community-sentiment-row-left-title-text"></div>
                        <div className="community-sentiment-row-right-btn-block">
                            <div className="community-sentimnet-edit--margin-right display-flex">
                                {isUserCanEdit && (
                                    <CustomButtonNavLink to={LINK_TO_EDIT} text="Edit"/>
                                )}
                            </div>
                            <div className="community-sentimnet-update-btn-block">
                                {isUserCanUpdate && (
                                    <CustomButtonNavLink
                                        to={LINK_TO_UPDATE}
                                        text={t("update")}
                                        theme={PAGE_THEME}/>
                                )}
                            </div>
                        </div>
                    </div>
                </WrapperCard>
            </section>
        </>

    );
};

export default CommunitySentimentBarChart;
