import React from "react";
import "./StatusList.css";
import { v4 as uuidv4 } from "uuid";

type statusListT = {
  text: string;
  image?: string;
};

type Props = {
  list: statusListT[];
  active?: number;
  onClick?: (id: number, text: string) => void;
};

const StatusList: React.FC<Props> = ({ list, active, onClick }) => {
  const onHanlderClick = (id: number, text: string) => {
    if (onClick) onClick(id, text);
  };
  return (
    <section className="status-list-container">
      {list.map((item: statusListT, index) => {
        return (
          <div
            className={`status-list-btn ${
              active === index && `status-list-btn-active`
            }`}
            key={uuidv4()}
            onClick={() => onHanlderClick(index, item.text)}
          >
            {item.image && (
              <img
                src={item.image}
                alt="crossImage"
                className="status-list-image"
              />
            )}
            <div
              className={`status-list-text ${
                item.image && `status-list-text-margin-left`
              }`}
            >
              {item.text}
            </div>
          </div>
        );
      })}
      {/* <div className="status-list-btn">
        <img src={crossImage} alt="crossImage" className="status-list-image" />
        <div className="status-list-text">Pass</div>
      </div>
      <div className="status-list-btn status-list-btn-active">
        <img src={crossImage} alt="crossImage" className="status-list-image" />
        <div className="status-list-text">Pass</div>
      </div>
      <div className="status-list-btn">
        <img src={crossImage} alt="crossImage" className="status-list-image" />
        <div className="status-list-text">Pass</div>
      </div> */}
    </section>
  );
};

export default StatusList;
