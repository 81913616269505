import { getThemes } from "../Theme";
import { createErrorApi } from "../../helpers/helperApi";
import { PerformanceMeasureT } from "../../types/types";

export const middlewareGetThemes = async () => {
  try {
    const data = await getThemes();
    return data;
  } catch (error: any) {
    return createErrorApi(error);
  }
};
