import React from "react";
import Dropdown from "react-dropdown";
import "./CustomDropDown.css";
import "react-dropdown/style.css";
import { Option, Group } from "../../types/types";

type Props = {
  cssControl?: string;
  cssMyMenuClassName?: string;
  defaultOption?: Option | string;
  options: (Group | Option | string)[];
  setValue: (option: (Group | Option | string)[]) => void;
  isShowBorder?: boolean;
};

const CustomDropDown: React.FC<Props> = ({
  cssControl,
  options,
  defaultOption,
  setValue,
  cssMyMenuClassName,
  isShowBorder = true,
}) => {
  const handleChange = (option: any) => {
    setValue(option);
  };

  return (
    <>
      <Dropdown
        controlClassName={`custom-drop-down-control ${
          cssControl && cssControl
        } ${!isShowBorder && `custom-drop-down-control-none-border`}`}
        menuClassName={`custom-drop-down-menu ${
          cssMyMenuClassName && cssMyMenuClassName
        }`}
        options={options}
        value={defaultOption && defaultOption}
        onChange={handleChange}
        placeholder="Select"
      />
    </>
  );
};

export default CustomDropDown;
