import React, {useCallback, useEffect, useState} from "react";
import {IonSelectOption, IonSelect,  IonIcon} from "@ionic/react";
import { Link } from "react-router-dom";
import { languagesObjects, LANG, DEFAULT_LANG } from "../../lang";
import { useTranslation } from "react-i18next";
import { changeLang } from "../../services/User";
import {v4 as uuidv4} from "uuid"

import "./Footer.css";
import {IStats} from "../../models";
import {getStats} from "../../services/Stats";
import { barChartOutline, earthOutline, newspaperOutline, personOutline, ribbonOutline, speedometerOutline, peopleOutline } from "ionicons/icons";

const statsIcons:any = {
  "active communities": earthOutline,
  "community gatherings": peopleOutline,
  "blog posts": newspaperOutline,
  standards: ribbonOutline,
  sentiments: barChartOutline,
  "action plans": speedometerOutline,
  accounts: personOutline
}

const Footer: React.FC = () => {
  const { t } = useTranslation("common");
  const changeLanguageBtn = async (e: any) => {
    const langValue = e.detail.value;
    await changeLang(langValue);
    window.localStorage.setItem(LANG, langValue);
    window.location.reload(); // TODO: Try to not refresh page
  };

  const [stats, setStats] = useState<any>({
    "active communities": 0,
    "community gatherings": 0,
    "blog posts": 0,
    standards: 0,
    sentiments: 0,
    "action plans": 0,
    accounts: 0,
  });



  const fetchStats = useCallback(async () => {
    const statsData:IStats = await getStats();
    setStats(statsData);
  }, []);

  useEffect(() => {
    fetchStats();
  }, []);



  const langInPage = window.localStorage.getItem(LANG) || DEFAULT_LANG;
  return (
        <div className="container_footer ">
          <div className="stats_container" >
            {
              Object.keys(stats).map((key) => {
                 return (
                     <div key={uuidv4()} className="stat_block">
                       <IonIcon size="large" icon={statsIcons[key]} />
                       <div className="count-info">
                         {stats[key]}
                       </div>
                       <span className="tooltip-text">{key}</span>
                     </div>
                 )

              })
            }
          </div>
          <div className="block_footer_elements">
            <div className="block_footer_menu">
              <div className="about_link">
                <Link to="/browse/about_us">
                  <span>{t("aboutUs")}</span>
                </Link>
              </div>
              <div className="legal_link">
                <Link to="/browse/termscondions">
                  <span>{t("legal")}</span>
                </Link>
              </div>
            </div>
            <div className="block_select">
              <IonSelect
                  value={langInPage}
                  interface="popover"
                  placeholder="English"
                  onIonChange={(e) => changeLanguageBtn(e)}
              >
                {languagesObjects.map((lang, key) => {
                  return (
                      <IonSelectOption value={lang.abbreviation} key={key}>
                        {lang.text}
                      </IonSelectOption>
                  );
                })}
                {/* <IonSelectOption value="english">English</IonSelectOption>
            <IonSelectOption value="franch">Franch</IonSelectOption> */}
              </IonSelect>
            </div>
          </div>
        </div>

  );

};

export default Footer;
