import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { THEMES } from "../../mocks/layout";
import Moment from "react-moment";
import { LANG, DEFAULT_LANG } from "../../lang";
import CustomButtonNavLink from "../customButton/customButtonNavLink/CustomButtonNavLink";
import {
  getAccessUserToCommunity,
} from "../../helpers/functions";
import "./CommitmentCard.css";

const PAGE_THEME = THEMES.GREEN;
const PAGE_LANG = window.localStorage.getItem(LANG) || DEFAULT_LANG;

type Props = {
  headerText?: string;
  theme?: string;
  due_date?: string;
  status_text?: string;
  responsible_party_text?: string;
  resources?: string;
  sub_activities?: string;
  monitored_by?: string;
  idCommunity?: string;
  idActionPlan?: string;
};

const CommitmentCard: React.FC<Props> = ({
  headerText,
  due_date,
  theme = PAGE_THEME,
  status_text,
  responsible_party_text,
  resources,
  sub_activities,
  monitored_by,
  idCommunity = "0",
  idActionPlan = "0",
}) => {
  const { t } = useTranslation("common");
  const [isShowBtns, setIsShowBtns] = useState<boolean>(false);

  useEffect(() => {
    setAccessBtn();
  });

  const setAccessBtn = async () => {
    const responce = await getAccessUserToCommunity(idCommunity);
    setIsShowBtns(responce);
  };

  const NAV_LINK_TO_EDIT = `/community/${idCommunity}/action_plan/${idActionPlan}/edit`;
  const NAV_LINK_TO_UPDATE = `/community/${idCommunity}/action_plan/${idActionPlan}/update`;
  return (
    <>
      <div className="community-commitment-shell-with-text">
        {headerText && (
          <div className="commitment-city-header-card">{headerText}</div>
        )}
        <div
          className={`community-commitment-shell-border ${
            headerText && "shell-top-none-radius"
          }`}
        >
          <div className="commitment-card-status-text">{status_text || ""}</div>
          <div className="commitment-card-date-block">
            <div className="commitment-card-normal-text">
              <Moment fromNow ago locale={PAGE_LANG}>
                {due_date}
              </Moment>
            </div>
            <div className="commitment-card-normal-text">
              <Moment format="YYYY.MM.DD" locale={PAGE_LANG} date={due_date} />
            </div>
          </div>
          <div className="commitment-card-hr commitment-card-hr-top-margin"></div>
          <div className="commitment-card-row">
            <div className="commitment-card-left-text-in-row commitment-card-normal-text">
              {t("responsible")}
            </div>
            <div>
              <div className="commitment-card-normal-text right-main-text-in-row">
                Action focus group
              </div>
              <div className="commitment-card-additional-text">
                {responsible_party_text}
              </div>
            </div>
          </div>
          <div className="commitment-card-row last-row-margin-bottom">
            <div className="commitment-card-left-text-in-row commitment-card-normal-text">
              {t("monitoring")}
            </div>
            <div>
              <div className="commitment-card-normal-text">{monitored_by}</div>
            </div>
          </div>
          <div className="commitment-card-hr commitment-card-hr-middle-margin"></div>
          <div className="commitment-card-row ">
            <div className="commitment-card-left-text-in-row commitment-card-normal-text">
              {t("resource")}
            </div>
            <div>
              <div className="commitment-card-normal-text">{resources}</div>
            </div>
          </div>
          <div className="commitment-card-row last-row-margin-bottom">
            <div className="commitment-card-left-text-in-row commitment-card-normal-text">
              {t("subActivity")}
            </div>
            <div>
              <div className="commitment-card-normal-text">
                {sub_activities}
              </div>
            </div>
          </div>
          <div className="commitment-card-hr commitment-card-hr-bottom-margin"></div>
          <div className="commitment-card-row-btns">
            {isShowBtns && (
              <CustomButtonNavLink to={NAV_LINK_TO_EDIT} text={t("edit")} />
            )}

            {isShowBtns && (
              <div className="right-btn-in-row-btns display-flex">
                <CustomButtonNavLink
                  to={NAV_LINK_TO_UPDATE}
                  theme={theme}
                  text={t("update")}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CommitmentCard;
