import React from 'react';
import "./content.css";
import LinearDiagram from './LinearDiagram'

type ChartDescriptionProps = {
  children?: JSX.Element,
  commitments?: string,
  wrap_container: string,
  state_santiments?: {
    evaluation: any,
    linear_points: number[]
  }
};

class ChartDescription extends React.Component<ChartDescriptionProps,{}> {
  constructor(props: any) {
    super(props);
  }

  render() {
    var commitments:JSX.Element|null = null;
    var linear_chart_el:JSX.Element|null = null;
    var evaluation:JSX.Element|null = null; 
    let progress:string = '';

    if (this.props.commitments)
      commitments = <div className="decript_commit"><span className="str_align">{this.props.commitments}</span></div>;
    else {
      commitments = null;
      if (this.props.state_santiments) {
        
        var length:number = this.props.state_santiments.linear_points.length;
        var linear_points:any = this.props.state_santiments.linear_points.map((point,index)=> {
          var control:boolean = (index===length-1)?true:false;
          
          return {level:point,control_point:control};
        });
        var line_color:string = 'blue';
        var class_str:string = 'evaluate_str1';
          
        if (this.props.state_santiments.evaluation != null) {
            
            const keys:string[] = Object.keys(this.props.state_santiments.evaluation);
            if (keys.length != 0) {

                if (keys[0].toLowerCase() === 'improving' ||
                    keys[0].toLowerCase() === 'accelerating' ||
                    keys[0].toLowerCase() === 'good') {

                    line_color = 'rgb(62, 165, 81)';
                    class_str += ' improving';

                }else
                if (keys[0].toLowerCase() === 'stalled') {

                    line_color = 'rgb(231, 181, 0)';
                    class_str += ' stalled';

                }
                else if (keys[0].toLowerCase() === 'declining' ||
                         keys[0].toLowerCase() === 'bad' ||
                         keys[0].toLowerCase() === 'poor' ) {

                    line_color = 'rgb(219, 128, 46)';
                    class_str += ' declining';//orange

                }
                let key_progress = keys[0];
                progress = this.props.state_santiments.evaluation[key_progress];
                
            }
        }

        var state_santiments:any = {
          evaluation: this.props.state_santiments.evaluation,
          linear_points: linear_points,
          line_color: line_color,
          line_width: 2,
          point_color: 'blue',
          background: 'transparent'
        };      
        this.props.state_santiments.linear_points.length > 1?
        linear_chart_el = <div className='evaluate_linear_chart1'><LinearDiagram state_santiments={state_santiments}/></div>:
        linear_chart_el = null;
        
        evaluation = <div className={class_str}><span className="str_align">{progress}</span></div>;
      }
    }
    return (
      <div className={this.props.wrap_container}>
        {linear_chart_el}
        {evaluation}
        {commitments}
        {this.props.children?this.props.children:null}
      </div>
    );
  }
}

export default ChartDescription;