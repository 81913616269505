import React from "react";
import "./News.css";
import africaTmpImage from "../../assets/images/africa-tmp.jpg";
import shareImage from "../../assets/images/share.png";
import ShareSocial from "../shareSocial/ShareSocial";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import { LANG, DEFAULT_LANG } from "../../lang";

const PAGE_LANG = window.localStorage.getItem(LANG) || DEFAULT_LANG;

type Props = {
  title: string;
  date: string;
  img?: string;
  text: React.ReactNode;
  id: number;
  community: number;
  isCommunity?: boolean;
};

const News: React.FC<Props> = ({
  title,
  date,
  img,
  text,
  isCommunity,
  id,
  community,
}) => {
  const history = useHistory();
  const onHanlderClick = () => {
    const URL_TO_COMMUNITY_ID = `/community/${community}/show/${id}`;
    history.push(URL_TO_COMMUNITY_ID);
  };

  const formatingContent = (text: any)=> {
        let content: JSX.Element[] = [];
        let start: number = 0;
        let key = 0;

        while (start < text.length) {
            let end: number = text.indexOf("\n", start);
            if (end !== -1) {
                content.push(
                    <span key={key++}>
            {text.slice(start, end)}
                        <br/>
          </span>
                );
            } else {
                content.push(<span key={key++}>{text.slice(start)}</span>);
                break;
            }
            start = end + 1;
        }
        return (
            <div className="content-resource-summary-detail-subcontainer-text">
                {content}
            </div>
        );
    }


  return (
    <div>
      <div
        className={`news-title item-cursor-pointer`}
        onClick={onHanlderClick}
      >
        {title}
      </div>
      <div className="news-date">
        <Moment fromNow ago locale={PAGE_LANG}>
          {date}
        </Moment>
      </div>
      {img && (
        <div>
          <img className="news-image" src={img} alt="africaTmpImage" />
        </div>
      )}
      <div className="news-child-text">
        {formatingContent(text)}
        {/* Lusanga has joined SAUTI-Youth. Check back for updates (or some other piece of boilerplate copy). */}
      </div>
      <div className="news-share">
        <ShareSocial />
        {/* <img className="news-share-image" src={shareImage} alt="shareImage" />
        Share */}
      </div>
    </div>
  );
};

export default News;
