import React from "react";
import {RouteComponentProps} from "react-router-dom";
import { IonButton, IonIcon} from "@ionic/react";
import {chevronBackOutline} from "ionicons/icons";
import ButtonResource from "../../../components/content/ButtonResource";
import {THEMES} from "../../../mocks/layout";
import CustomInput from "../../../components/customInput/CustomInput";
import CustomTextAre from "../../../components/customTextAre/CustomTextAre";
import CustomCheckbox from "../../../components/customCheckbox/CustomCheckbox";
import {withTranslation, WithTranslation} from "react-i18next";
import {
    getCommunityId,
    updateCommunityId,
    addCommunityPhoto,
    getCommunityPhotos,
    updateCommunityPhoto,
    deleteCommunityPhoto,
} from "../../../services/Community";
import "../../resource.css";
import "./CommunityManage.css";
import {persistor} from "../../../reducers";

const THEME_PAGE = THEMES.BLUE;

type CommunityManageProps = {};

type Content = {
    group_name: string;
    name: string;
    welcome_message: string;
    respondent_types: string[];
};

type CommunityManageState = {
    respondentsGroups: JSX.Element[] | null;
    contentPage: Content;
    photo: PhotoCommunity;
};

type CommunityMedia = {
    id: number;
    photo: string;
    rank: number;
    community: number;
    url: string;
};

type PhotoCommunity = {
    file: File | null;
    url: string;
    id: number;
    name: string;
};

type Respondent = {
    type: string;
    value: boolean;
};

class CommunityManage extends React.Component<CommunityManageProps & WithTranslation & RouteComponentProps<{}>,
    CommunityManageState> {
    idCommunity: number;
    respondents: Respondent[];

    constructor(props: any) {
        super(props);

        this.state = {
            respondentsGroups: null,
            contentPage: {
                group_name: "",
                name: "",
                welcome_message: "",
                respondent_types: [],
            },
            photo: {
                file: null,
                url: "",
                id: 0,
                name: "",
            },
        };
        this.idCommunity = 0;
        this.respondents = [
            {
                type: "Boys",
                value: false,
            },
            {
                type: "Girls",
                value: false,
            },
            {
                type: "Men",
                value: false,
            },
            {
                type: "Women",
                value: false,
            },
            {
                type: "Disabled Boys & Men",
                value: false,
            },
            {
                type: "Disabled Women & Girls",
                value: false,
            },
        ];

        this.backToResources = this.backToResources.bind(this);
        this.addPhoto = this.addPhoto.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.setGroupRespondents = this.setGroupRespondents.bind(this);
        this.setRespondent = this.setRespondent.bind(this);
        this.createArrayRespondents = this.createArrayRespondents.bind(this);
        this.setContentPage = this.setContentPage.bind(this);
        this.updateCommunity = this.updateCommunity.bind(this);
        this.addPhotoToServer = this.addPhotoToServer.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.setValueRespondents = this.setValueRespondents.bind(this);
        this.getMediaCommunity = this.getMediaCommunity.bind(this);
    }

    async componentDidMount() {
        const param = Object.create(this.props.match.params);
        this.idCommunity = Number(param.idCommunity);
        try {
            const {data} = await getCommunityId(`${this.idCommunity}`);
            this.setContentPage({
                ...data,
                group_name: data.group_name ? data.group_name : "",
                name: data.name ? data.name : "",
                welcome_message: data.welcome_message ? data.welcome_message : "",
            });
            this.createArrayRespondents(data.respondent_types as string[]);
            await this.getPhotos();
        }
        catch (error) {
            console.log(`getCommunityId error status: ${error}`);
        }
    }

    async getPhotos() {
        try {
            const {data} = await getCommunityPhotos(this.idCommunity.toString());
            this.getMediaCommunity(data);
        }
        catch (error: any) {
            console.log(`getCommunityPhotos error status: ${error}`);
        }
    }

    backToResources() {
        this.props.history.goBack();
    }

    addPhoto(e: React.ChangeEvent<HTMLInputElement>) {
        const files = e.target.files;

        if (files && files.length > 0) {
            const filePhoto = files[0];
            const photoUrl = URL.createObjectURL(filePhoto);
            const photo: PhotoCommunity = {
                ...this.state.photo,
                file: filePhoto,
                url: photoUrl,
                name: filePhoto.name,
            };
            this.setState({photo: photo});
        }
    }

    removeFile() {
        const photo: PhotoCommunity = {
            ...this.state.photo,
            file: null,
            url: "",
            name: "",
        };
        this.setState({photo: photo});
    }

    getMediaCommunity(arrayList: CommunityMedia[]) {
        let photoUrl: string = "";
        let photoId: number = 0;
        let photoName: string = "";
        console.log(arrayList);

        for (let j = 0; j <= arrayList.length - 1; j++) {
            photoUrl = arrayList[j].url;
            photoId = arrayList[j].id;
            const i = photoUrl.lastIndexOf("/");
            if (i !== -1 && i + 1 !== photoUrl.length) {
                photoName = photoUrl.slice(i + 1);
            }
            const photo: PhotoCommunity = {
                ...this.state.photo,
                url: photoUrl,
                id: photoId,
                name: photoName,
            };
            this.setState({photo: photo});
            break;
        }
    }

    setRespondent(respondent: boolean, index: number) {
        this.respondents[index].value = respondent;
        if (this.respondents[index].type === "Disabled Boys & Men") {
            const set_value: boolean = !this.respondents[index].value;
            this.setValueRespondents(set_value, "Boys", "Men");
        } else if (this.respondents[index].type === "Disabled Women & Girls") {
            const set_value: boolean = !this.respondents[index].value;
            this.setValueRespondents(set_value, "Girls", "Women");
        }
        this.setGroupRespondents(this.respondents);
    }

    setValueRespondents(value: boolean, start_type: string, end_type: string) {
        for (let j = 0; j <= this.respondents.length - 1; j++) {
            if (this.respondents[j].type === start_type)
                this.respondents[j].value = value;
            else if (this.respondents[j].type === end_type) {
                this.respondents[j].value = value;
                break;
            }
        }
    }

    createArrayRespondents(arrayRespondents: string[]) {
        arrayRespondents.forEach((item) => {
            let str: string = item.slice(1).toLowerCase();
            let res: string = item[0] + str;
            this.respondents.find((item) => {
                if (res === item.type) {
                    item.value = true;
                }
                return res === item.type;
            });
        });
        this.setGroupRespondents(this.respondents);
    }

    setContentPage(content: any) {
        this.setState({contentPage: content});
    }

    setGroupRespondents(arrayRespondents: Respondent[]) {
        let respondentsGroups: JSX.Element[] = arrayRespondents.map(
            (item, index) => {
                let css_last =
                    index === arrayRespondents.length - 1
                        ? " community-management-content-group-checks-row-last"
                        : "";
                return (
                    <div
                        key={index}
                        className={
                            "community-management-content-wrap-row community-management-content-group-checks-row" +
                            css_last
                        }
                    >
                        <CustomCheckbox
                            id={"checkboxUser" + index}
                            textAfter={item.type}
                            checked={item.value}
                            onChange={(change) => {
                                this.setRespondent(change, index);
                            }}
                        />
                    </div>
                );
            }
        );
        this.setState({respondentsGroups: respondentsGroups});
    }

    async saveChanges() {
        const respondent_types: string[] = [];
        this.respondents.forEach((item) => {
            if (item.value && item.type.indexOf("Disabled") === -1)
                respondent_types.push(item.type.toUpperCase());
        });
        const content: Content = {
            ...this.state.contentPage,
            respondent_types: respondent_types,
        };
        await this.updateCommunity(content);
        await this.addPhotoToServer();
        await persistor.flush();
        window.location.reload();
    }

    async updateCommunity(content: any) {
        try {
            const {data} = await updateCommunityId(this.idCommunity, content);
        }
        catch (error: any) {
            console.log(`updateCommunityId error status: ${error}`);
        }
    }

    async addPhotoToServer() {
        if (this.state.photo.file === null && !this.state.photo.id) return;
        try {
            let response;
            if (this.state.photo.file) {
                var formData = new FormData();
                formData.append("photo", this.state.photo.file);
                formData.append("rank", "0");
                formData.append("community", this.idCommunity.toString());
                if (this.state.photo.id) {
                    response = updateCommunityPhoto(
                        this.idCommunity,
                        this.state.photo.id,
                        formData
                    );
                } else response = addCommunityPhoto(this.idCommunity, formData);
            } else
                response = await deleteCommunityPhoto(
                    this.idCommunity,
                    this.state.photo.id
                );
            console.log("response Photo", response);
        }
        catch (error: any) {
            console.log(`error status: ${error}`);
        }
    }

    render() {
        const {t} = this.props;
        const content: Content = {...this.state.contentPage};


        return (

            <>
                <div className="community-management-title-wrap">
                    <div className="community-management-title-wrap-content">
                        <div>
                            <IonButton
                                color="light"
                                id="back_to_resources_button"
                                onClick={this.backToResources}
                            >
                                <IonIcon slot="start" icon={chevronBackOutline}/>
                                <span style={{color: "rgb(22, 124, 187)"}}>
                    {t("myAccount")}
                                    {/* {this.state.contentPage.name} */}
                  </span>
                            </IonButton>
                        </div>
                        <div className="community-management-title-wrap-content-name">
                            {t("communityDetails")}
                        </div>
                    </div>
                </div>
                <div className="wrap-content-resource">
                    <div className="content-resource community-management-content-resource">
                        <div className="description_resource community-management-content-description">
                            {t("ManageCommunityDetails")}
                        </div>
                        <div className="content-resource-summary community-management-content-summary">
                            <div className="content-resource-summary-detail">
                                <div className="content-resource-summary-detail-subcontainer">
                                    <div className="community-management-content-wrap">
                                        <div
                                            className="community-management-content-wrap-row
                                      community-management-content-group-name-row"
                                        >
                                            <div className="community-management-content-group-label">
                                                <div className="community-management-content-group-label-name">
                                                    {t("groupName")}
                                                </div>
                                            </div>
                                            <CustomInput
                                                cssInput="community-management-content-group"
                                                readOnly
                                                id="englishGroupName"
                                                value={this.state.contentPage.group_name}
                                                onChange={(inputText: string) => {
                                                    this.setContentPage({
                                                        ...content,
                                                        group_name: inputText,
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div
                                            className="community-management-content-wrap-row
                                      community-management-content-group-welcome-row"
                                        >
                                            <div className="community-management-content-group-label">
                                                <div className="community-management-content-group-label-welcome">
                                                    {t("welcomeMessage")}
                                                </div>
                                            </div>
                                            <CustomTextAre
                                                value={this.state.contentPage.welcome_message}
                                                id="englishWelcome"
                                                isShowCountLetter
                                                onChange={(inputText: string) => {
                                                    this.setContentPage({
                                                        ...content,
                                                        welcome_message: inputText,
                                                    });
                                                }}
                                                cssTextAre="community-management-content-group-welcome-input"
                                            />
                                        </div>
                                        <div className="community-management-content-line"/>

                                        <div
                                            className="community-management-content-wrap-row
                                    community-management-content-group-photos-descript-row"
                                        >
                                            <div className="community-management-content-group-label">
                                                <div className="community-management-content-group-label-photos">
                                                    {t("photos")}
                                                </div>
                                            </div>
                                            <div
                                                className="community-management-content-group
                                        community-management-content-group-descript-photos-text"
                                            >
                                                {t("photosShouldRepresent")}
                                            </div>
                                        </div>
                                        <div
                                            className="community-management-content-wrap-row
                                      community-management-content-group-photos-button-row"
                                        >
                                            <label>
                                                <div
                                                    className="community-management-content-add-photo-btn
                                          community-management-content-add-photo-size-btn"
                                                >
                                                    {t("addAPhoto")}
                                                </div>
                                                <input
                                                    className="community-management-content-hide-input-button"
                                                    type="file"
                                                    accept=".jpg, .png"
                                                    onChange={this.addPhoto}
                                                />
                                            </label>
                                        </div>
                                        {this.state.photo.url !== "" ? (
                                            <div
                                                className="community-management-content-wrap-row
                                      community-management-content-wrap-row-remove-file
                                      community-management-content-group-photos-preview-row"
                                            >
                                                <div className="community-management-content-group-photo">
                                                    <img
                                                        src={this.state.photo.url}
                                                        alt="ImageCommunity"
                                                        className="community-management-content-preview-image"
                                                    />
                                                </div>
                                                <div className="community-management-content-upload-block">
                                                    <div className="community-management-content-upload-name-file1">
                                                        {this.state.photo.name}
                                                    </div>
                                                    <div className="community-management-content-upload-remove1">
                                                        <span onClick={this.removeFile}>Remove</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                        <div className="community-management-content-line"/>

                                        <div
                                            className="community-management-content-wrap-row
                                    community-management-content-group-photos-descript-row"
                                        >
                                            <div className="community-management-content-group-label">
                                                <div className="community-management-content-group-label-reporting">
                                                    {t("reportingGroups")}
                                                </div>
                                            </div>
                                            <div
                                                className="community-management-content-group
                                        community-management-content-group-descript-photos-text"
                                            >
                                                {t("deselectAnyReportingGroups")}
                                            </div>
                                        </div>
                                        {this.state.respondentsGroups}
                                        <div className="community-management-content-line"/>
                                        <div
                                            className="community-management-content-wrap-row
                                      community-management-content-button-save-row"
                                        >
                                            <ButtonResource
                                                onClickHandler={this.saveChanges}
                                                text_color="white"
                                                id="community-management-conten-button-save"
                                            >
                                                {t("saveChanges")}
                                            </ButtonResource>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        );
    }
}

const decoratedComponent = withTranslation("common")(CommunityManage);
export {decoratedComponent as CommunityManage};
