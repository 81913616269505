import React from 'react';
import './WrapperOnTheForm.css';

type Props = {
    title: string,
    description: string,
    children: React.ReactNode
  }
  

const WrapperOnTheForm: React.FC<Props> = ({
    title,
    description,
    children
}) => {
  return (
    <>
        <section className="section-community-update">
            <div className="block-community-update-left">
                <div className="community-update-title">{title}</div>
                <div className="community-update-text">{description}</div>
            </div>
            <div className="block-community-update-right">
                {children}
            </div>
        </section>
    </>
  );
};

export default WrapperOnTheForm;