import React, {useState, useEffect} from "react";
import IonLoadingPage from "../../../../components/ionLoadingPage/IonLoadingPage";
import "./CommunityBrowseStandards.css";
import TitlePageTextTheme from "../../../../components/titlePageTextTheme/titlePageTextTheme";
import GoBack from "../../../../components/goBack/GoBack";
import StatusList from "../../../../components/statusList/StatusList";
import crossImage from "../../../../assets/images/indicators-color/cross.svg";
import pendingImage from "../../../../assets/images/indicators-color/pending.svg";
import tickImage from "../../../../assets/images/indicators-color/tick.svg";
import crossImageNoColor from "../../../../assets/images/indicators/cross.svg";
import pendingImageNoColor from "../../../../assets/images/indicators/pending.svg";
import tickImageNoClor from "../../../../assets/images/indicators/tick.svg";
import ListLinkStatus from "../../../../components/listLinkStatus/ListLinkStatus";
import {middlewareGetStandards} from "../../../../services/middleware/MiddlewareCommunity";
import {useHistory, useParams} from "react-router-dom";
import {StandardStatusT} from "../../../../types/types";
import {useTranslation} from "react-i18next";
import {getObjectsValue} from "../../../../helpers/functions";
import { setHeaderActiveTab } from "reducers/headerReducer";
import {useDispatch, useSelector} from "react-redux";
import {useIonViewWillEnter} from "@ionic/react";
import CommunityChart from "../../../../components/communityCharts/communityChart";
import {AppState} from "../../../../reducers";
import {setStandards} from "../../../../reducers/standardsReducer";

let statusArray = [
    {
        text: "All",
    },
    {
        text: "Pass",
        image: tickImageNoClor,
    },
    {
        text: "Fail",
        image: crossImageNoColor,
    },
    {
        text: "Unrated",
        image: pendingImageNoColor,
    },
];

const miniDiagrams = {
    chart: {
        type: 1,
        colorInRing: "RGB(57, 150, 74)",
        colorRing: "silver",
        colorProgress: "white",
        widthRing: 4,
        percent: 50,
        fontSizeText: "0px",
        colorText: "white",
    },
    lineaDiagram: {
        evaluation: "Improving",
        linear_points: [
            {level: 10.2, control_point: false},
            {level: 50.1, control_point: false},
            {level: 30.1, control_point: false},
            {level: 70.4, control_point: false},
        ],
        line_color: "rgba(256, 256, 256, 0.5)",
        line_width: 4,
        point_color: "black",
        background: "transparent",
    },
};

let chartDiagram = {
    type: 1,
    colorInRing: "RGBA(63, 166, 82, 1)",
    colorRing: "silver",
    colorProgress: "white",
    widthRing: 50,
    percent: 84,
    fontSizeText: "24px",
    colorText: "white",
};

const CommunityBrowseStandards: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [listLinks, setListLinks] = useState<StandardStatusT[]>([]);
    const [allListLinks, setAllListLinks] = useState<StandardStatusT[]>([]);
    const {idCommunity} = useParams<{ idCommunity: string }>();
    const [statusIndex, setStatusIndex] = useState(0);
    const {t} = useTranslation("common");
    const history = useHistory();
    const dispatch = useDispatch();
    const chartData = useSelector((state: AppState) => state.standards.standardsData);
    const [chartInfo, setChartInfo]= useState({completed:0,active:0})

    statusArray[0].text = t("all");
    statusArray[1].text = t("pass");
    statusArray[2].text = t("fail");
    statusArray[3].text = t("unrated");

    useIonViewWillEnter(() => {
        dispatch(setHeaderActiveTab(1))
    }, []);

    useEffect(() => {
        fetchStandards();
    }, []);

    useEffect(() => {
        getStandards();
    }, [chartData]);

    const fetchStandards = async () => {
        setIsLoading(true);

        const responce: any = await middlewareGetStandards(idCommunity);

        setIsLoading(false);

        if (responce.cached && !chartData.def) {
            return;
        }

        return dispatch(setStandards(responce));
    }

    const getStandards = () => {
        const responce = chartData;

        if (!responce || !responce.trend) {
            return;
        }

        chartDiagram.percent = Math.floor(responce.rate);
        miniDiagrams.lineaDiagram.linear_points = [];
        responce.trend.forEach((element: number, index: number) => {
            if (index + 1 !== responce.trend.length) {
                miniDiagrams.lineaDiagram.linear_points.push({
                    level: element,
                    control_point: false,
                });
            } else {
                miniDiagrams.lineaDiagram.linear_points.push({
                    level: element,
                    control_point: true,
                });
            }
        });

        const completedStandarts =  responce.standards.filter((standart:any) => standart&&standart.rate>0).length
        setChartInfo({completed:completedStandarts, active: responce.standards.length})
        setStandardstList(responce.standards);
    };

    const setStandardstList = async (standards: any[]) => {
        const listSentiment: any = standards.map((item: any) => {
            return {
                id: item.id,
                name: item.name,
                nameCss: getColorText(getObjectsValue(item.state)),
                description: item.description,
                rate: item.rate,
                state: getObjectsValue(item.state),
                img: getNormalImage(getObjectsValue(item.state)),
            };
        });
        setAllListLinks(listSentiment);
        setListLinks(listSentiment);
    };

    const getColorText = (state: string) => {
        switch (state) {
            case t("fail"):
                return "state-fail-text";
            case t("pass"):
                return "state-pass-text";
            case t("unrated"):
                return "state-not-rated-text";
            case t("notRated"):
                return "state-not-rated-text";
            default:
                return "";
        }
    };

    const getNormalImage = (state: string) => {
        switch (state) {
            case t("fail"):
                return crossImage;
            case t("pass"):
                return tickImage;
            case t("unrated"):
                return pendingImage;
            case t("notRated"):
                return pendingImage;
            default:
                return "";
        }
    };

    const onClickListItem = (id: number) => {
        // const URL_TO_STANDARDS = `/community/${idCommunity}/standards/${id}/update`;
        const URL_TO_STANDARDS = `/community/${idCommunity}/standards/${id}`;
        history.push(URL_TO_STANDARDS);
    };

    const onClickFilterBtn = (id: number, text: string) => {
        if (!id) {
            setListLinks(allListLinks);
        } else {
            let newList: any = [];
            if (id === 3)
                newList = allListLinks.filter(
                    (item) => item.state === t("unrated") || item.state === t("notRated")
                );
            else newList = allListLinks.filter((item) => item.state === text);
            setListLinks(newList);
        }
        setStatusIndex(id);
    };

    return (

        <>
            <div className={"list-action-plans-title-page-wrap" }>
                <div
                    className={"list-action-plans-title-left-block" }
                >
                    <div className="display-flex">
                        <GoBack isGoBack text={"My Community"}/>
                    </div>
                    <div
                        className={"list-action-plans-title-left-block-name"}
                    >
                        {t("standardsCompliance")}
                    </div>
                </div>
                <CommunityChart
                    data={chartData }
                    info={chartInfo}
                    showSentiment={false}
                />
            </div>
            <div className="mini-diagram-bottom-size"></div>
            <section className="community-stabdards-align">
                <div className="align-status-list-media">
                    <StatusList
                        list={statusArray}
                        active={statusIndex}
                        onClick={onClickFilterBtn}/>
                </div>
                <ListLinkStatus listLinks={listLinks} onClick={onClickListItem}/>
            </section>
            <IonLoadingPage isLoading={isLoading}/>
        </>

    );
};

export default CommunityBrowseStandards;
