import { createContext, useContext, FC, useState, useEffect } from 'react';

import { IPageTree } from 'models';

export interface IPageTreeContext {
  isFetching: boolean;
  setIsFetching(state: boolean): void;
  pageTree: any;
  setPageTree(pageTree: IPageTree): void;
  pageChildren: any[];
  myCommunity?: boolean;
}

const PageTreeContext = createContext<IPageTreeContext | undefined>(undefined);
const usePageTreeContext = (): IPageTreeContext => {
  const context = useContext(PageTreeContext);

  if (!context) {
    throw new Error(
      'usePageTreeContext must be used within a PageTreeProvider'
    );
  }

  return context;
};

type Props = {
  myCommunity?: boolean;
}

const PageTreeProvider: FC<Props> = (props) => {
  const [isFetching, setIsFetching] = useState(false);
  const [pageTree, setPageTree] = useState<IPageTree>();
  const [pageChildren, setPageChildren] = useState<any>();

  useEffect(() => {
    const { children = [] }: any = pageTree || {};
    setPageChildren(children );
  }, [pageTree]);

  const ThemeContextValue: IPageTreeContext = {
    isFetching,
    setIsFetching,
    pageTree,
    setPageTree,
    pageChildren,
    myCommunity: props.myCommunity,
  };

  return <PageTreeContext.Provider value={ThemeContextValue} {...props} />;
};

export { PageTreeProvider, usePageTreeContext };
