import React, {useEffect, useState} from "react";
import TitlePageTextTheme from "../../../components/titlePageTextTheme/titlePageTextTheme";
import GoBack from "../../../components/goBack/GoBack";
import ShallPost from "../../../components/shallPost/ShallPost";
import CommunityPost from "../../../components/communityBlock/CommunityPost";
import ContainerPosts from "../../../components/containerPosts/ContainerPosts";
import SimpleListOfLink from "../../../components/simpleListOfLink/SimpleListOfLink";
import {middlewareGetResource} from "../../../services/middleware/MiddlewareResurces";
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

import "./ResourcesId.css";
import {useDispatch, useSelector} from "react-redux";
import {setHeaderActiveTab} from "../../../reducers/headerReducer";
import {useIonViewWillEnter} from "@ionic/react";



const post = {
    isEditPost: false,
    isShare: false,
    files: [
        {
            name: "File download name",
            url: "File download name",
        },
        {
            name: "A longer file name for a downloadable file",
            url: "A longer file name for a downloadable file",
        },
    ],
    text: (
        <>
            <div>
                Ultrices dui sapien eget mi proin sed libero enim sed. Ut pharetra sit
                amet aliquam id diam. Ac tortor dignissim convallis aenean et tortor at
                risus. Sed vulputate mi sit amet mauris commodo quis imperdiet. At
                volutpat diam ut venenatis tellus in metus.
            </div>
            <div>
                <br/>
                <div>
                    Nisi scelerisque eu ultrices vitae auctor. Pellentesque elit
                    ullamcorper dignissim cras tincidunt lobortis feugiat vivamus. At
                    lectus urna duis convallis convallis tellus id interdum velit. Sem
                    fringilla ut morbi tincidunt augue interdum velit euismod in.
                </div>
                <br/>
            </div>
            <div>
                Nisl tincidunt eget nullam non nisi. Aliquet enim tortor at auctor.
                Sapien et ligula ullamcorper malesuada proin. At risus viverra
                adipiscing at in tellus integer feugiat scelerisque.
            </div>
        </>
    ),
};

const ResourcesId: React.FC = () => {
    const {idResourse} = useParams<{ idResourse?: string }>();
    const history = useHistory();
    const [title, setTitle] = useState("");
    const {t} = useTranslation("common");
    const THEME_PAGE = useSelector((state:any) => state.theme.theme )
    const dispatch = useDispatch();

    useIonViewWillEnter(() => {
        dispatch(setHeaderActiveTab(2))
    }, []);

    const listOfLinks = {
        title: t("alsoSee"),
        links: [
            {
                text: "Another Tanzanian standard",
                url: "/",
            },
            {
                text: "A Tanzanian standard that has a slightly longer name",
                url: "/",
            },
            {
                text: "A Tanzanian standard that has a much longer name that wraps over two or more lines",
                url: "/",
            },
            {
                text: "Another Tanzanian standard",
                url: "/",
            },
            {
                text: "A Tanzanian standard that has a slightly longer name",
                url: "/",
            },
        ],
    };

    useEffect(() => {
        getResourseId();
    }, []);

    const getResourseId = async () => {
        const response = await middlewareGetResource(idResourse);
        console.log(response);
        if (!response.isError) {
            console.log("No error");
        } else {
            console.log("Error");
        }
    };
    return (

        <>
            <TitlePageTextTheme
                theme={THEME_PAGE}
                cssTitleScreenCommunity="resourse-title-page-text-green"
            >
                <>
                    <div>
                        <GoBack theme={THEME_PAGE} text="Tanzanian Standards"/>
                    </div>
                    <div>{t("resourcesHeader")}</div>
                </>
            </TitlePageTextTheme><ContainerPosts theme={THEME_PAGE} text={t("shortSummary")}>
            <section>
                <ShallPost cssSectionWall="community-page-css-section-wall">
                    <CommunityPost
                        {...post}
                        title={title}
                        theme={THEME_PAGE}
                        downBtn={<div className="new-post-in-wall-btn">
                            {t("editResource")}
                        </div>}/>
                </ShallPost>
                <ShallPost cssSectionWall="simple-list-of-link-section-shall-post">
                    <SimpleListOfLink {...listOfLinks} />
                </ShallPost>
            </section>
        </ContainerPosts>
        </>


    );
};

export default ResourcesId;
