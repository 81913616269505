import React, { useState } from "react";
import faceNeutralImage from "../../assets/images/indicators-color/face-neutral.svg";
import CustomInput from "../customInput/CustomInput";

import "./FaceInput.css";

type Props = {
  image?: string;
  value: number;
  onChange: (count: number) => void;
};

const FaceInput: React.FC<Props> = ({
  image = faceNeutralImage,
  value,
  onChange,
}) => {
  const changeNumber = (isIncrease: boolean) => {
    if (isIncrease) {
      onChange(value + 1);
    } else {
      if (value <= 0) onChange(value);
      else onChange(value - 1);
    }
  };

  const handlerChange = (count: number) => {
    if (count < 0) onChange(0);
    else onChange(count);
  };
  return (
    <section className="face-input-section">
      <div className="face-image-block">
        <img className="face-image" src={image} alt="faceNeutralImage" />
      </div>
      <div className="face-input-block">
        <div
          className="side-block-face-input"
          onClick={() => changeNumber(false)}
        >
          -
        </div>
        <CustomInput
          type="number"
          cssInput="size-face-input"
          value={value.toString()}
          onChange={(inputText) => handlerChange(+inputText)}
        />
        <div
          className="side-block-face-input"
          onClick={() => changeNumber(true)}
        >
          +
        </div>
      </div>
    </section>
  );
};

export default FaceInput;
