import API from "../api/api";
import { TOKEN } from "../reducers/userReducer";

export const addActionPlan = async (
  idCommunity: number,
  idPlan: number,
  body: any
) => {
  const url = `/community/${idCommunity}/action_plan/${idPlan}`;

  return await API.post(url, body, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      "Content-Type": "application/json",
    },
  });
};

export const getActionPlanInfo = async () => {
  const url = `/action_plan/info`;

  return await API.get(url);
};

export const getActionPlanById = async (
  idCommunity: number,
  idPlan: number
) => {
  const url = `/community/${idCommunity}/action_plan/${idPlan}`;

  return await API.get(url);
};

export const getActionPlans = async (idCommunity: number) => {
  const url = `/community/${idCommunity}/action_plans`;

  return await API.get(url);
};

export const deleteActionPlanById = async (
  idCommunity: number,
  idPlan: number
) => {
  const url = `/community/${idCommunity}/action_plan/${idPlan}`;

  return await API.delete(url, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      "Content-Type": "application/json",
    },
  });
};

export const updateActionPlanById = async (
  idCommunity: number,
  idPlan: number,
  body: any
) => {
  const url = `/community/${idCommunity}/action_plan/${idPlan}`;

  return await API.put(url, body, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      "Content-Type": "application/json",
    },
  });
};

export const putActionPlanIdUpdateId = async (
  idCommunity: string,
  idPlan: string,
  idUpdate: any,
  body: any
) => {
  const url = `/community/${idCommunity}/action_plan/${idPlan}/update/${idUpdate}`;
  return await API.put(url, body, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      "Content-Type": "application/json",
    },
  });
};

export const postActionPlanIdUpdate = async (
  idCommunity: string,
  idPlan: string,
  body: any
) => {
  const url = `/community/${idCommunity}/action_plan/${idPlan}/update`;
  return await API.post(url, body, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      "Content-Type": "application/json",
    },
  });
};

export const postActionPlanIdUpdateNew = async (
  idCommunity: string,
  idPlan: string,
  body: any
) => {
  const url = `/community/${idCommunity}/action_plan/${idPlan}/update/new`;
  return await API.post(url, body, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      "Content-Type": "application/json",
    },
  });
};

export const getActionPlanUpdates = async (
  idCommunity: number,
  idPlan: number
) => {
  const url = `/community/${idCommunity}/action_plan/${idPlan}/updates`;

  return await API.get(url);
};

export const getActionPlansCurrent = async (idCommunity: number) => {
  const url = `/community/${idCommunity}/action_plans`;

  return await API.get(url, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      "Content-Type": "application/json",
    },
    params: {
      status: "active",
    },
  });
};

export const getActionPlansArchive = async (idCommunity: number) => {
  const url = `/community/${idCommunity}/action_plans`;

  return await API.get(url, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      "Content-Type": "application/json",
    },
    params: {
      status: "archive",
    },
  });
};
