export const ROLE_GLOBAL_USER = "global";
export const ROLE_BASIK_USER = "basic user";
export const ROLE_ADMIN_IRELAND = "ireland";
export const ROLE_ADMIN_TANZANIA = "tanzania";
export const ROLE_NOT_REGISTERED_USER = "not_registered_user";

export const URL_COMMUNITY_MY = "/community/my";
export const URL_COMMYNUTY_SENTIMENT_EDIT =
  "/community/:idCommunity/sentiments/:idSentiment/edit";
export const URL_BROWSE = "/browse";
export const URL_COMMUNITY_BROWSE_LEVEL_ID =
  "/community/browse/level/:levelURL/id/:idURL";
export const URL_COMMUNITY_STANDARDS = "/community/:idCommunity/standards";
export const URL_COMMUNITY_SENTIMENTS = "/community/:idCommunity/sentiments";
export const URL_BROWSE_ABOUT_US = "/browse/about_us";
export const URL_COMMUNITY_STANDARDS_UPDATE =
  "/community/:idCommunity/standards/:idStandard";
export const URL_COMMUNITY_SENTIMENTS_ID =
  "/community/:idCommunity/sentiments/:idSentiment";
export const URL_COMMUNITY_SENTIMENT_UPDATE =
  "/community/:idCommunity/sentiments/:idSentiment/update";
export const URL_COMMUNITY_ID = "/community/:idCommunity/show/:idUpdate";
export const URL_COMMUNITY_LISTACTIONPLAN =
  "/community/:idCommunity/ListActionPlans";
export const URL_COMMUNITY_ACTION_PLAN_ID =
  "/community/:idCommunity/action_plan/:idActionPlan";
export const URL_COMMUNITY_ACTION_PLAN_ID_UPDATE =
  "/community/:idCommunity/action_plan/:idActionPlan/update";
export const URL_COMMUNITY_ACTION_PLAN_ID_EDIT =
  "/community/:idCommunity/action_plan/:idPlan/edit";
export const URL_RESOURCE = "/resources";
export const URL_RESOURCE_ADDGROUP = "/resources/addgroup";
export const URL_COMMUNITY_UPDATE_ID =
  "/community/:idCommunity/update/:idUpdate";
export const URL_SIGNIN = "/signin";
export const URL_WELCOME = "/welcome";
export const URL_SIGNIN_RESET = "/signin/reset";
export const URL_SIGNIN_RESET_PASSWORD = "/signin/reset/password";
export const URL_COMMUNITY_PERFOMANCE_ID =
  "/community/perfomance/:idPerfomanceMeasure";
export const URL_COMMUNITY_PERFOMANCE_SENTIMENT =
  "/community/:idCommunity/sentiments/:idPerfomanceMeasure/perfomance";
export const URL_COUNTRY_MANAGEMENT = "/account/country/management";
export const URL_ACCOUNT = "/account";
export const URL_ACCOUNT_DETAILS = "/account/details";
export const URL_USERS_MANAGEMENT = "/account/UserManagement";
export const URL_USER_CREATE = "/account/new_user";
export const URL_ACCOUNT_FEEDBACK = "/account/feedback";
export const URL_ACCOUNT_STANDARDS = "/account/standards";
export const URL_ACCOUNT_COMMUNITY_MANAGE = "/account/community/:idCommunity";
export const URL_RESOURCE_ADD_NEW = "/resources/group/:idGroup/addresource";
export const URL_RESOURCE_EDIT_GROUP = "/resources/group/:idGroup/editgroup";
export const URL_RESOURCE_EDIT = "/resource/:idResource/editresource";
export const URL_ADD_COMMITMENT = "/community/:idCommunity/action_plan";
export const URL_ACCOUNT_RESET_USER = "/UserManagement/resetuser/:idUser";

export const MAP_ROUTER = [
  // {
  //   url: URL_COMMUNITY_MY,
  //   access: [
  //     ROLE_NOT_REGISTERED_USER,
  //     ROLE_ADMIN_TANZANIA,
  //     ROLE_ADMIN_IRELAND,
  //     ROLE_GLOBAL_USER,
  //   ],
  //   redirect: URL_BROWSE,
  // },
  {
    url: URL_COMMYNUTY_SENTIMENT_EDIT,
    access: [
      ROLE_ADMIN_TANZANIA,
      ROLE_ADMIN_IRELAND,
      ROLE_GLOBAL_USER,
      ROLE_BASIK_USER,
    ],
    redirect: URL_BROWSE,
  },
  {
    url: URL_COMMUNITY_ACTION_PLAN_ID_UPDATE,
    access: [
      ROLE_ADMIN_TANZANIA,
      ROLE_ADMIN_IRELAND,
      ROLE_GLOBAL_USER,
      ROLE_BASIK_USER,
      ROLE_BASIK_USER,
    ],
    redirect: URL_BROWSE,
  },
  {
    url: URL_COMMUNITY_ACTION_PLAN_ID_EDIT,
    access: [
      ROLE_ADMIN_TANZANIA,
      ROLE_ADMIN_IRELAND,
      ROLE_GLOBAL_USER,
      ROLE_BASIK_USER,
    ],
    redirect: URL_BROWSE,
  },
  {
    url: URL_RESOURCE_ADDGROUP,
    access: [ROLE_ADMIN_TANZANIA, ROLE_ADMIN_IRELAND, ROLE_GLOBAL_USER],
    redirect: URL_BROWSE,
  },
  {
    url: URL_COMMUNITY_UPDATE_ID,
    access: [
      ROLE_ADMIN_TANZANIA,
      ROLE_ADMIN_IRELAND,
      ROLE_GLOBAL_USER,
      ROLE_BASIK_USER,
    ],
    redirect: URL_BROWSE,
  },
  {
    url: URL_USERS_MANAGEMENT,
    access: [ROLE_ADMIN_TANZANIA, ROLE_ADMIN_IRELAND, ROLE_GLOBAL_USER],
    redirect: URL_BROWSE,
  },
  {
    url: URL_USER_CREATE,
    access: [ROLE_ADMIN_TANZANIA, ROLE_ADMIN_IRELAND, ROLE_GLOBAL_USER],
    redirect: URL_BROWSE,
  },
  {
    url: URL_ACCOUNT,
    access: [
      ROLE_ADMIN_TANZANIA,
      ROLE_ADMIN_IRELAND,
      ROLE_GLOBAL_USER,
      ROLE_BASIK_USER,
    ],
    redirect: URL_SIGNIN, // change to list after finsished task SAUTI-43
  },
  {
    url: URL_COUNTRY_MANAGEMENT,
    access: [ROLE_GLOBAL_USER],
    redirect: URL_SIGNIN, // change to list after finsished task SAUTI-43
  },
  {
    url: URL_ACCOUNT_DETAILS,
    access: [
      ROLE_ADMIN_TANZANIA,
      ROLE_ADMIN_IRELAND,
      ROLE_GLOBAL_USER,
      ROLE_BASIK_USER,
    ],
    redirect: URL_SIGNIN, // change to list after finsished task SAUTI-43
  },
  {
    url: URL_RESOURCE_ADD_NEW,
    access: [ROLE_GLOBAL_USER],
    redirect: URL_BROWSE,
  },
  {
    url: URL_RESOURCE_EDIT_GROUP,
    access: [ROLE_GLOBAL_USER],
    redirect: URL_BROWSE,
  },
  {
    url: URL_RESOURCE_EDIT,
    access: [ROLE_GLOBAL_USER],
    redirect: URL_BROWSE,
  },
  {
    url: URL_ADD_COMMITMENT,
    access: [
      ROLE_ADMIN_TANZANIA,
      ROLE_ADMIN_IRELAND,
      ROLE_GLOBAL_USER,
      ROLE_BASIK_USER,
    ],
    redirect: URL_BROWSE,
  },
  {
    url: URL_ACCOUNT_RESET_USER,
    access: [ROLE_GLOBAL_USER],
    redirect: URL_BROWSE,
  },
];
