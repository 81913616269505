import React, {useEffect, useState} from "react";
import TitlePageTextTheme from "../../../components/titlePageTextTheme/titlePageTextTheme";
import GoBack from "../../../components/goBack/GoBack";
import {useTranslation} from "react-i18next";
import IonLoadingPage from "../../../components/ionLoadingPage/IonLoadingPage";
import {
    middlewareGetCommunityActionPlanUpdatesOffline
} from "../../../services/middleware/MiddlewareCommunity";
import {useParams} from "react-router-dom";
import {THEMES} from "../../../mocks/layout";
import {useHistory} from "react-router-dom";
import WrapperOnTheForm from "../../../components/wrapperOnTheForm/WrapperOnTheForm";
import CustomRadioButton from "../../../components/customRadioButton/customRadioButton";
import CustomDropDown from "../../../components/customDropDown/CustomDropDown";
import {getObjectsValue, normalizeText} from "../../../helpers/functions";
import {ActionPLanUpdateT} from "../../../types/types";
import {getActionPlanInfo} from "../../../services/actionPlan";
import {MOMENT_FORMAT_DAY_MONTH_TEXT_YEAR} from "../../../mocks/layout";
import CustomButton from "../../../components/customButton/CustomButton";
import {
    middlewatePutActionPlanIdUpdateId,
    middlewatePostActionPlanIdUpdateNew,
} from "../../../services/middleware/MiddlewareActionPlan";
import moment from "moment";
import { setHeaderActiveTab } from "reducers/headerReducer";
import {useDispatch} from "react-redux";
import {useIonViewWillEnter} from "@ionic/react";
import DatePicker from "react-datepicker";

const FIRST_START_ELEMENT_ID = 0;
const PAGE_THEME = THEMES.GREEN;
const CommunityActionPlanUpdate: React.FC = () => {
    const {t} = useTranslation("common");
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [localResponce, setLocalResponce] = useState<any>();
    const {idCommunity, idActionPlan} =
        useParams<{ idCommunity: string; idActionPlan: string }>();
    const [actionPlanUpdate, setActionPlanUpdate] = useState<ActionPLanUpdateT>({
        id: 0,
        isAddNew: false,
        newDate: new Date().toLocaleDateString('en-CA'),
        listDate: [],
        status: "",
        listStatus: [],
        activeDate: {
            label: "",
            value: "",
        },
    });

    const dispatch = useDispatch();

    useIonViewWillEnter(() => {
        dispatch(setHeaderActiveTab(1))
    }, []);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setIsLoading(true);
        let newStatus = "";
        let newListOfStatus = [];
        let newListDate: any = [];
        let newId: number = 0;

        const responce:any = await middlewareGetCommunityActionPlanUpdatesOffline(
            idCommunity,
            idActionPlan
        );
        setLocalResponce(responce);
        if (responce.isError) {
            history.goBack();
        } else {
            newListDate = responce.map((item: any) => {
                return {
                    label:item.community_gathering.date,
                    value: item.community_gathering.id,
                };
            });
        }

        newStatus =
            responce.length > 0
                ? normalizeText(responce[FIRST_START_ELEMENT_ID].status)
                : "";
        newId = responce.length > 0 ? responce[FIRST_START_ELEMENT_ID].id : 0;

        try {
            const actionPlanInfo = await getActionPlanInfo();
            newListOfStatus = actionPlanInfo.data.status.map((item: any) => {
                return {
                    label: getObjectsValue(item),
                    value: getObjectsValue(item),
                };
            });
        }
        catch (error) {
            history.goBack();
        }

        setActionPlanUpdate({
            ...actionPlanUpdate,
            listStatus: newListOfStatus,
            status: newStatus,
            listDate: newListDate,
            id: newId,
            activeDate: newListDate[FIRST_START_ELEMENT_ID],
        });

        setIsLoading(false);
    };

    const setDateByIndex = (index: number, newActiveDate: any) => {
        const newStatus = normalizeText(localResponce[index].status);
        const newId = localResponce[index].id;

        setActionPlanUpdate({
            ...actionPlanUpdate,
            status: newStatus,
            id: newId,
            activeDate: newActiveDate,
        });
    };

    const handlerDateDropDown = (option: any) => {
        const findIndex = actionPlanUpdate.listDate.findIndex(
            (item: any) => item.value === option.value
        );
        setDateByIndex(findIndex, option);
    };

    const setStatus = (option: any) => {
        setActionPlanUpdate({
            ...actionPlanUpdate,
            status: option.value,
        });
    };

    const saveBtn = async () => {
        if (actionPlanUpdate.isAddNew) {
            const dateObject = {
                date: actionPlanUpdate.newDate,
                status: actionPlanUpdate.status.toUpperCase(),
            };
            await middlewatePostActionPlanIdUpdateNew(
                idCommunity,
                idActionPlan,
                dateObject
            );
            getData();
        } else {
            const sendDate = {
                id: actionPlanUpdate.id,
                status: actionPlanUpdate.status.toUpperCase(),
                community_gathering: actionPlanUpdate.activeDate.value,
                action_plan: idActionPlan,
            };
            await middlewatePutActionPlanIdUpdateId(
                idCommunity,
                idActionPlan,
                sendDate.id,
                sendDate
            );

        }

        history.goBack()
    };

    return (

        <>
            <TitlePageTextTheme cssTitleScreenCommunity="resourse-title-page-text-green">
                <>
                    <div>
                        <GoBack isGoBack text={t("exit")}/>
                    </div>
                    <div className="community-update-header-text">{t("resourcesHeader")}</div>
                </>
            </TitlePageTextTheme><WrapperOnTheForm
            title={t("communityGathering")}
            description={t("makeSureThisUpdate")}
        >
            <div className="community-update-row margin-top-label-unset">
                <label
                    className="community-update-label-in-row"
                    htmlFor="englishTitle"
                >
                    {t("date")}
                </label>
                <div className="community-measure-radio-block cusom-input-props">
                    <label className="community-measure-radio-block margin-right-custom-radion-btn">
                        <CustomRadioButton
                            id={"1"}
                            value={"1"}
                            cssSize="custom-input-radio-size"
                            checked={actionPlanUpdate.isAddNew}
                            onChange={(inputText: string) => setActionPlanUpdate({
                                ...actionPlanUpdate, isAddNew: true
                            })}/>
                        <div>{t("addNew")}</div>
                    </label>
                    <label className="community-measure-radio-block">
                        <CustomRadioButton
                            id={"2"}
                            value={"2"}
                            cssSize="custom-input-radio-size"
                            checked={!actionPlanUpdate.isAddNew}
                            onChange={(inputText: string) => setActionPlanUpdate({
                                ...actionPlanUpdate,
                                isAddNew: false,
                            })}/>
                        <div>{t("editExisting")}</div>
                    </label>
                </div>
            </div>
            <div className="community-update-row">
                <label className="community-update-label-in-row"></label>
                {actionPlanUpdate.isAddNew && (
                    <DatePicker className={"custom-date-picker update-picker"}
                    value={ actionPlanUpdate.newDate}
                    onChange={(date:Date) =>  setActionPlanUpdate({...actionPlanUpdate,newDate:date.toLocaleDateString('en-CA')})}
                    popperClassName="some-custom-class"
                    popperPlacement="top-end"
                    popperModifiers={[
                            {
                                name: "offset",
                                options: {
                                offset: [5, 10],
                            },
                            },
                            {
                                name: "preventOverflow",
                                options: {
                                rootBoundary: "viewport",
                                tether: false,
                                altAxis: true,
                            },
                            },
                    ]}
                    />
                )}
                {!actionPlanUpdate.isAddNew && (
                    <CustomDropDown
                        setValue={(option) => handlerDateDropDown(option)}
                        defaultOption={actionPlanUpdate.activeDate}
                        options={actionPlanUpdate.listDate}
                        cssControl="cusom-input-props"/>
                )}
            </div>
        </WrapperOnTheForm><WrapperOnTheForm
            title={t("currentStatus")}
            description={t("ifTheCommitmentHasBeen")}
        >
            <div className="community-update-row">
                <label className="community-update-label-in-row">
                    {t("status")}
                </label>
                <CustomDropDown
                    setValue={(option) => setStatus(option)}
                    defaultOption={actionPlanUpdate.status}
                    options={actionPlanUpdate.listStatus}
                    cssControl="cusom-input-props"/>
            </div>
        </WrapperOnTheForm>
            <div className="container-post-update-btn margin-bottom-in-tablet">
                <div></div>
                <div className="community-sentiment-id-container-btn">
                    <div className="community-sentiment-id-send-next-btn-block">
                        <CustomButton text={t("saveNext")}/>
                    </div>
                    <CustomButton
                        theme={PAGE_THEME}
                        text={t("save")}
                        onClick={saveBtn}/>
                </div>
            </div>
            <IonLoadingPage isLoading={isLoading}/>
        </>

    );
};

export default CommunityActionPlanUpdate;
