import {useCallback, useEffect, useState} from 'react';
import { useDispatch, useSelector } from "react-redux";

import { useThemesContext } from './provider';
import {usePageTreeContext} from '../pageTreeContext/provider'
import { setTrends } from 'reducers/communityTrendsReducer'
import { ITheme } from 'models';
import { getThemes } from 'services/Theme';
import {getSummaryChartsByTheme, getTrendCommunityChartsByType} from 'services/Community';
import {middlewareGetCommunityTrends} from 'services/middleware/MiddlewareCommunity';

const mapTabToChart: any = {
  'standards': 'standards',
  'action': 'action-plans',
  'sentiment': 'sentiments'
};
const mapTrendToChart: any = {
  'standards': 'standards',
  'action': 'action_plans',
  'sentiment': 'sentiments'
};
const mapTabToApiType: any = {
  'standards': 'standard_trends',
  'action': 'action_plan_trends',
  'sentiment': 'sentiment_trends'
}

let initialThemesState = [{ name: 'allThemes', id: 0 }];

export default () => {
  const {
    id,
    level,
    activeParentTab,
    myCommunity
  } = useThemesContext();

  const {
    setPageTree,
  } = usePageTreeContext();

  const dispatch = useDispatch();

  const isCommunity  = parseFloat(level) === 4
  const [themes, setThemes] = useState<any[]>( initialThemesState);
  const [themeCharts, setThemesCharts] = useState<any>([]);


  let [trendCharts, setTrendCharts] = useState<any>({});

  if (myCommunity) {
    trendCharts = useSelector((state: any) => state.communityTrends);
  }
  const [activeTheme, setActiveTheme] = useState<ITheme>(themes[0]);
  const [charts, setCharts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [firstRun, setFirstRun] = useState(true)

  const fetchThemes = useCallback(async () => {
    const themesData:ITheme[] = await getThemes();

    setThemes(initialThemesState.concat(themesData));
  }, []);

  useEffect(() => {
    fetchThemes();
  }, []);

  const setActiveThemeId = (themeId: any) => {
    const selectedTheme: any = themes.find(el => el.id === themeId);
    setActiveTheme(selectedTheme);
  }

  const fetchThemesCharts = useCallback(async () => {

    if(!isCommunity){
      const { data: chartsThemeRes } = await getSummaryChartsByTheme(level, id, '' + activeTheme.id);
      setPageTree(chartsThemeRes)
      const charts = chartsThemeRes.children;

      setThemesCharts(charts);
    } else {
      if (!activeTheme.id){
        const { data:standards, cached }: any  = await  middlewareGetCommunityTrends( id, 'standard_trends');
        const { data:action_plans }: any  = await  middlewareGetCommunityTrends( id, 'action_plan_trends');
        const { data:sentiments }: any  = await  middlewareGetCommunityTrends( id, 'sentiment_trends');

        if (myCommunity) {
          if (cached && !trendCharts.def) {
            return;
          }
          dispatch(setTrends({standards, sentiments, action_plans}));
          return;
        }

        setTrendCharts({standards, sentiments, action_plans});
      }else {
        setThemesCharts([]);
      }
    }
    setFirstRun(false)
  }, [id, level, activeTheme, firstRun]);

  useEffect(() => {
    fetchThemesCharts();
    setActiveTheme(themes[0])
  }, [id, level]);

  useEffect(() => {
    fetchThemesCharts();
  }, [activeTheme]);


  useEffect(() => {
    let charts:any = []
    if(!isCommunity) {
       charts = themeCharts.map((chartTheme: any) => {
        return {
          data: chartTheme.data && chartTheme.data[mapTabToChart[activeParentTab]], name: chartTheme.name,
          id: chartTheme.id
        };
      });
    } else {
      const trendChart = trendCharts[mapTrendToChart[activeParentTab]];
      charts = trendChart && trendChart.trends.filter((chartTheme: any) => {
        if (activeTheme.id) {
          return chartTheme.theme_id === activeTheme.id;
        }

        return chartTheme;

      }).map((chartTheme: any) => {
        const data = {...chartTheme}
        data.rate = activeParentTab !== 'sentiment' ?  chartTheme.trend[chartTheme.trend.length - 1] : null
        data.rate_all = activeParentTab === 'sentiment' ?  chartTheme.trend_all[chartTheme.trend_all.length - 1] : []

        return {
          data,
          name: chartTheme.name,
          id: chartTheme.id
        };
      }) || [];

    }
    setCharts(charts);
  }, [activeParentTab, activeTheme, themeCharts, trendCharts, firstRun]);

  return {
    setActiveThemeId,
    themes,
    activeTheme,
    themeCharts: charts,
    activeParentTab,
    isLoading,
    myCommunity,
    id,
    level,
  };
};
