import * as React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { AppState } from "../reducers";
import {
  isCheckConvertUrlEqual,
} from "../helpers/functions";
import * as access from "./Access";
import  "./AccessRights.css";
import Footer from "../components/footer/Footer";
import {IonContent, IonPage, isPlatform} from "@ionic/react";


export function requiresAuthentication(
  Component:
    | React.StatelessComponent<any>
    | (new (props?: any) => React.Component<any, any>),
  OfflineComponent?:
      | React.StatelessComponent<any>
      | (new (props?: any) => React.Component<any, any>)

) {
  class RequiresAuthentication extends React.Component<any> {
    checkAccess() {
      const { role, community, match: { params: { idCommunity } } } = this.props;
      const { pathname } = this.props.location;

      let isRedirect = false;
      let redirectUrl = "";
      access.MAP_ROUTER.forEach((element) => {
        const isCheck = isCheckConvertUrlEqual(pathname, element.url);
        if (isCheck) {
          if (!this.isUserCanSeePage(role, element.access)) {
            isRedirect = true;
            redirectUrl = element.redirect;
          }
        }
      });

      const componentToRender = community && +idCommunity === community && OfflineComponent ? <OfflineComponent {...this.props} /> : <Component {...this.props} />;

      return isRedirect ? (
        <Redirect to={redirectUrl} />
      ) : (
          <IonPage className={isPlatform('iphone')?"main-ios-page":"main-page"}>
          <IonContent className={"router-page"}>
            { componentToRender }
            <Footer/>
          </IonContent>
          </IonPage>
    );
    }

    isUserCanSeePage(role: string, accessRole: any[]) {
      return accessRole.some((item: string) => item === role);
    }

    render() {
      return this.checkAccess();
    }
  }
  return connect((state: AppState) => {
    return { role: state.user.role, community: state.user.community };
  })(RequiresAuthentication);
}
