import React, { useState, useEffect } from "react";
import { IonContent, IonPage } from "@ionic/react";
import "./CommunityUpdate.css";
import CustomInput from "../../../components/customInput/CustomInput";
import CustomTextAre from "../../../components/customTextAre/CustomTextAre";
import WrapperOnTheForm from "../../../components/wrapperOnTheForm/WrapperOnTheForm";
import CustomDropDown from "../../../components/customDropDown/CustomDropDown";
import CustomCheckbox from "../../../components/customCheckbox/CustomCheckbox";
import SmallHeader from "../../../components/smallHeader/SmallHeader";
import { useParams } from "react-router-dom";
import IonLoadingPage from "../../../components/ionLoadingPage/IonLoadingPage";
import fileImage from "../../../assets/images/file.png";
import { useHistory } from "react-router-dom";
import {
  middlewareGetCommunityUpdatePost,
  middlewateGetCommunityUpdatePostImages,
  middlewareDeleteCommunityUpdatePhoto,
  middlewarePostCommunityUpdatePhoto,
  middlewarePostCommunityGathingFile,
  middlewareDeleteCommunityGathingFile,
  middlewarePutCommunityUpdate,
  middlewarePutCommunityGathering,
  middlewarePostCommunityUpdate,
  middlewareGetCommunityGatherings,
} from "../../../services/middleware/MiddlewareCommunity";
import { CommunityUpdatePostT } from "../../../types/types";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../components/customButton/CustomButton";
import moment from "moment";
import TitlePageTextTheme from "../../../components/titlePageTextTheme/titlePageTextTheme";
import {persistor} from "../../../reducers";

const NEW_IN_URL = "new";
const CommunityUpdate: React.FC = () => {
  const [pageContent, setPageContent] = useState<CommunityUpdatePostT>();
  const [cancelPageContent, setCancelPageContent] =
    useState<CommunityUpdatePostT>();
  const [localPhoto, setLocalPhoto] = useState<File>();
  const [localFiles, setLocalFiles] = useState<File[]>([]);
  const [copeFilesFromOtherGathering, setCopeFilesFromOtherGathering] =
    useState<any[]>([]);
  const [meetingFileInput, setMeetingFileInput] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [optionsDateOfGathering, setOptionsDateOfGathering] = useState<any>([]);
  const [valueDate, setValueDate] = useState<any>();

  const { t } = useTranslation("common");
  const { idCommunity, idUpdate } =
    useParams<{ idCommunity: string; idUpdate: string }>();
  const history = useHistory();

  useEffect(() => {
    if (idUpdate === NEW_IN_URL) {
      setGatheringDefaultFroCommunit();
    } else {
      getCommunityUpdate();
    }
  }, [idUpdate]);

  const getCommunityUpdate = async () => {
    const data = await middlewareGetCommunityUpdatePost(idCommunity, idUpdate);
    const isCommunityGathering = data.community_gathering ? true : false;
    const dataImages = await middlewateGetCommunityUpdatePostImages(
      idCommunity,
      idUpdate
    );

    let setData = {};
    if (dataImages) {
      setData = {
        image: { ...dataImages[dataImages.length - 1] },
        isCommunityGathering: isCommunityGathering,
        ...data,
      };
    } else {
      setData = {
        isCommunityGathering: isCommunityGathering,
        ...data,
      };
    }

    const newsOptionsDateOfGathering = data?.community_gatherings?.map(
        (item: any) => {
          return {
            label: item.date,
            value: item.date,
          };
        }
    );

    try {
      setValueDate({
        label: data.community_gathering.date,
        value: data.community_gathering.date,
      });
    } catch (error) {}

    await setOptionsDateOfGathering(newsOptionsDateOfGathering);
    await setPageContent(setData);
    await setCancelPageContent(setData);
    setIsLoading(false);
  };

  const setGatheringDefaultFroCommunit = async () => {
    const responceGathering = await middlewareGetCommunityGatherings(
      idCommunity
    );
    console.log(responceGathering);
    if (responceGathering.length > 0) {
      const newsOptionsDateOfGathering = await responceGathering.map(
        (item: any) => {
          return {
            label: item.date,
            value: item.date,
          };
        }
      );

      try {
        setValueDate({
          label: responceGathering[0].date,
          value: responceGathering[0].date,
        });
      } catch (error) {}

      await setOptionsDateOfGathering(newsOptionsDateOfGathering);
    }
    setIsLoading(false);
  };

  const handlerImagePost = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    const files = e.target.files;
    if (files && files.length > 0) {
      setLocalPhoto(files[0]);
    }
    setIsLoading(false);
  };

  const handlerImgePut = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      setLocalPhoto(files[0]);
      setPageContent({ ...pageContent, image: undefined });
    }
  };

  const addFileToGathering = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      setLocalFiles([...localFiles, files[0]]);
    }
  };

  const clearImage = async (idPhoto: number) => {
    if (pageContent?.image?.id === idPhoto) {
      setPageContent({
        ...pageContent,
        image: undefined,
      });
    }
  };

  const clearFileIndex = async (idFile: number) => {
    setIsLoading(true);
    const newFiles = pageContent?.community_gathering?.files?.filter(
      (file) => file.id !== idFile
    );
    setPageContent({
      ...pageContent,
      community_gathering: {
        ...pageContent?.community_gathering,
        files: newFiles,
      },
    });
    setIsLoading(false);
  };

  const clearLocalImage = () => {
    setLocalPhoto(undefined);
  };

  const getUniqueElementArray = (a: any[], b: any[]) => {
    // A comparer used to determine if two entries are equal.
    const isSameUser = (a: any, b: any) => a.id === b.id && a.id === b.id;

    // Get items that only occur in the left array,
    // using the compareFunction to determine equality.
    const onlyInLeft = (left: any, right: any, compareFunction: any) =>
      left.filter(
        (leftValue: any) =>
          !right.some((rightValue: any) =>
            compareFunction(leftValue, rightValue)
          )
      );

    const onlyInA = onlyInLeft(a, b, isSameUser);
    const onlyInB = onlyInLeft(b, a, isSameUser);

    const result = [...onlyInA, ...onlyInB];

    return result;
  };

  const clearPageContent = () => {
    setIsLoading(true);
    setPageContent(cancelPageContent); // clear data page
    setIsLoading(false);
  };

  const postUpdate = async () => {
    setIsLoading(true);
    const updateData = {
      date: pageContent?.date,
      en_title: pageContent?.en_title,
      en_content: pageContent?.en_content,
      sw_title: pageContent?.sw_title,
      sw_content: pageContent?.sw_content,
      community: pageContent?.community,
      id: pageContent?.id,
    };
    await middlewarePutCommunityUpdate(idCommunity, idUpdate, updateData);
    /// post image
    if (localPhoto) {
      let formData = new FormData();
      formData.append("photo", localPhoto);
      formData.append("photo_name", localPhoto.name);
      formData.append("community_update", (pageContent?.id || 0).toString());
      const responce = await middlewarePostCommunityUpdatePhoto(
        idCommunity,
        idUpdate,
        0,
        formData
      );
    } else {
      const isDeleteImage = !pageContent?.image && cancelPageContent?.image; // when u delete image but not add local
      if (isDeleteImage) {
        const deleteImageId = cancelPageContent?.image?.id || 0;
        await middlewareDeleteCommunityUpdatePhoto(
          idCommunity,
          idUpdate,
          deleteImageId
        );
      }
    }

    if (pageContent?.community_gathering) {
      const responceGathering = await middlewarePutCommunityGathering(
        idCommunity,
        pageContent?.community_gathering?.id || 0,
        pageContent?.community_gathering
      );
    }

    // delete serve file
    const startFiles =
      copeFilesFromOtherGathering.length > 0
        ? copeFilesFromOtherGathering
        : cancelPageContent?.community_gathering?.files;
    const resultUnique = getUniqueElementArray(
      pageContent?.community_gathering?.files || [],
      startFiles || []
    );
    resultUnique.forEach(async (file: any) => {
      await middlewareDeleteCommunityGathingFile(
        idCommunity,
        pageContent?.community_gathering?.id || 0,
        file.id
      );
    });

    // psot to server file
    localFiles.forEach(async (file: any) => {
      let formData = new FormData();
      const idGathering = pageContent?.community_gathering?.id;
      formData.append("file", file);
      formData.append("file_name", file.name);
      formData.append("community_gathering", (idGathering || 0).toString());
      await middlewarePostCommunityGathingFile(
        idCommunity,
        idGathering || 0,
        formData
      );
    });
    setIsLoading(false);
    // await persistor.flush();
    // window.location.reload();
  };

  const changeValueDate = (option: any) => {
    setValueDate(option);
    setLocalFiles([]);
    const findGathering = pageContent?.community_gatherings?.find(
      (item: any) => item.date === option.value
    );
    setPageContent({
      ...pageContent,
      community_gathering: findGathering,
    });
    setCopeFilesFromOtherGathering(findGathering?.files || []);
  };

  const clearLocalFile = (id: number) => {
    const newLocalFiles = localFiles.filter((file, index) => index !== id);
    setLocalFiles(newLocalFiles);
  };

  const createNewPost = async () => {
    setIsLoading(true);

    const updateData = {
      date: moment().format("YYYY-MM-DD"),
      en_title: pageContent?.en_title || '',
      en_content: pageContent?.en_content || '',
      sw_title: pageContent?.sw_title || '',
      sw_content: pageContent?.sw_content || '',
      community: idCommunity,
    };

    const responce = await middlewarePostCommunityUpdate(
      idCommunity,
      updateData
    );
    const newPostId = responce.id;

    if (localPhoto) {
      let formData = new FormData();
      formData.append("photo", localPhoto);
      formData.append("photo_name", localPhoto.name);
      formData.append("community_update", newPostId.toString());
      await middlewarePostCommunityUpdatePhoto(
        idCommunity,
        newPostId,
        0,
        formData
      );
    }
    setIsLoading(false);
    //await persistor.flush();

    // window.location.reload();
    // setIsLoading(false);
  };

  const validationContent = () => {
    return true;
  };

  const delegationButton = () => {
    if (idUpdate === NEW_IN_URL) {
      createNewPost();
    } else {
      postUpdate();
    }
  };

  return (
    <IonPage>
      <IonContent>
        <SmallHeader rightText={t("addNew")} />
        <TitlePageTextTheme>
          <div className="community-update-header-text">
            {t("communityUpdate")}
          </div>
        </TitlePageTextTheme>
        <WrapperOnTheForm
          title={t("englishContent")}
          description={t("addAConciseTitleForTheUpdate")}
        >
          <div className="community-update-row">
            <label
              className="community-update-label-in-row"
              htmlFor="englishTitle"
            >
              {t("title")}
            </label>
            <CustomInput
              cssInput="cusom-input-props"
              id="englishTitle"
              value={pageContent?.en_title || ""}
              onChange={(inputText) =>
                setPageContent({ ...pageContent, en_title: inputText })
              }
              isShowCountLetter
            />
          </div>
          <div className="community-update-row">
            <label
              className="community-update-label-in-row"
              htmlFor="englishUpdate"
            >
              {t("update")}
            </label>
            <CustomTextAre
              value={pageContent?.en_content || ""}
              id="englishUpdate"
              isShowMarkdown
              onChange={(inputText) =>
                setPageContent({ ...pageContent, en_content: inputText })
              }
              cssTextAre="cusom-input-props"
            />
          </div>
        </WrapperOnTheForm>
        <WrapperOnTheForm
          title={t("swahiliTranslation")}
          description={t("optionalIfAvailable")}
        >
          <div className="community-update-row">
            <label
              className="community-update-label-in-row"
              htmlFor="swahiliTitle"
            >
              {t("title")}
            </label>
            <CustomInput
              cssInput="cusom-input-props"
              id="swahiliTitle"
              value={pageContent?.sw_title || ""}
              onChange={(inputText) =>
                setPageContent({
                  ...pageContent,
                  sw_title: inputText,
                })
              }
              isShowCountLetter
            />
          </div>
          <div className="community-update-row">
            <label
              className="community-update-label-in-row"
              htmlFor="swahiliUpdate"
            >
              {t("update")}
            </label>
            <CustomTextAre
              value={pageContent?.sw_content || ""}
              id="swahiliUpdate"
              isShowMarkdown
              onChange={(inputText) =>
                setPageContent({ ...pageContent, sw_content: inputText })
              }
              cssTextAre="cusom-input-props"
            />
          </div>
        </WrapperOnTheForm>
        <WrapperOnTheForm title={t("photos")} description={t("youCanAddUpToX")}>
          <div className="community-update-image-row">
            {/* When image not selected */}
            {!pageContent?.image && !localPhoto && (
              <div className="centered-btn-add-photo">
                <label>
                  <div className="add-photo-btn-community">
                    {t("addAPhoto")}
                  </div>
                  <input
                    className="hidde-input-image-community"
                    type="file"
                    accept="image/*"
                    onChange={handlerImagePost}
                  />
                </label>
              </div>
            )}
            {/* When image selected */}
            {pageContent?.image && !localPhoto && (
              <>
                <div className="upper-block-image-preview">
                  <div className="under-line-in-image-preview">
                    <div>
                      <img
                        src={pageContent.image.photo}
                        alt="tmpImageUrl"
                        className="preview-image-community"
                      />
                    </div>
                    <div className="right-side-image-preview-community">
                      <div className="image-preview-name-community">
                        {pageContent.image.photo_name}
                      </div>
                      <div
                        onClick={() => clearImage(pageContent.image?.id || 0)}
                        className="image-preview-remove-community"
                      >
                        {t("remove")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="add-another-photo-community">
                  <label>
                    <div className="add-photo-btn-community">
                      {t("addAnotherPhoto")}
                    </div>
                    <input
                      className="hidde-input-image-community"
                      type="file"
                      accept="image/*"
                      onChange={handlerImgePut}
                    />
                  </label>
                </div>
              </>
            )}
          </div>
          {/* When local image selected */}
          {localPhoto && (
            <>
              <div className="upper-block-image-preview">
                <div className="under-line-in-image-preview">
                  <div>
                    <img
                      src={URL.createObjectURL(localPhoto)}
                      alt="tmpImageUrl"
                      className="preview-image-community"
                    />
                  </div>
                  <div className="right-side-image-preview-community">
                    <div className="image-preview-name-community">
                      {localPhoto.name}
                    </div>
                    <div
                      onClick={clearLocalImage}
                      className="image-preview-remove-community"
                    >
                      {t("remove")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="add-another-photo-community">
                <label>
                  <div className="add-photo-btn-community">
                    {t("addAnotherPhoto")}
                  </div>
                  <input
                    className="hidde-input-image-community"
                    type="file"
                    accept="image/*"
                    onChange={handlerImagePost}
                  />
                </label>
              </div>
            </>
          )}
        </WrapperOnTheForm>
        <WrapperOnTheForm
          title={t("relatedGathering")}
          description={t("youCanDocumentACommunityGathering")}
        >
          <div className="community-update-image-row">
            <div className="community-update-row">
              <label className="community-update-label-in-row"></label>
              <CustomCheckbox
                id="checkbox-link"
                textAfter={t("linkToACommunityGathering")}
                checked={pageContent?.isCommunityGathering}
                onChange={(change) =>
                  setPageContent({
                    ...pageContent,
                    isCommunityGathering: change,
                  })
                }
              />
            </div>
          </div>

          {pageContent?.isCommunityGathering && (
            <>
              <div className="community-update-image-row">
                <div className="community-update-row">
                  <label
                    className="community-update-label-in-row"
                    htmlFor="dateFfGathering"
                  >
                    {t("dateOfFathering")}
                  </label>
                  <CustomDropDown
                    setValue={(option) => changeValueDate(option)}
                    defaultOption={valueDate}
                    options={optionsDateOfGathering}
                    cssControl="cusom-input-props"
                  />
                </div>
              </div>
              <div className="community-update-row">
                <label
                  className="community-update-label-in-row"
                  htmlFor="attendees"
                >
                  {t("attendees")}
                </label>
                <CustomTextAre
                  value={pageContent.community_gathering?.attendees || ""}
                  id="attendees"
                  onChange={(inputText) =>
                    setPageContent({
                      ...pageContent,
                      community_gathering: {
                        ...pageContent.community_gathering,
                        attendees: inputText,
                      },
                    })
                  }
                  cssTextAre="cusom-input-props"
                  downBlock={
                    <div className={`custom-textarea-markdown`}>
                      {t("separateEachName")}
                    </div>
                  }
                />
              </div>
              <div className="community-update-row">
                <label className="community-update-label-in-row">
                  {t("documentation")}
                </label>
                <div className="documentation-text-community cusom-input-props">
                  {t("attachMeetingDocumentation")}
                </div>
              </div>
              {/* Files */}
              {pageContent.community_gathering?.files?.map((file, index) => {
                return (
                  <div className="community-update-row" key={index}>
                    <label className="community-update-label-in-row"></label>
                    <div>
                      <div className="upperblock-file-row-community">
                        <div className="fileimage-img-name-file">
                          <img
                            className="fileimage-community"
                            src={fileImage}
                            alt="fileImage"
                          />
                          <a href={file.file} className="name-file-community">
                            {file.file_name}
                          </a>
                        </div>
                        <div
                          className="remove-file-text"
                          onClick={() => clearFileIndex(file.id || index)}
                        >
                          {t("removeFile")}
                        </div>
                      </div>
                      <div>
                        <CustomInput
                          cssInput="cusom-input-props"
                          id="englishTitle"
                          value={meetingFileInput}
                          onChange={(inputText) =>
                            setMeetingFileInput(inputText)
                          }
                          placeholder={t("meetingMinutes")}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}

              {/* Local Files */}
              {localFiles?.map((file: any, index) => {
                return (
                  <div className="community-update-row" key={index}>
                    <label className="community-update-label-in-row"></label>
                    <div>
                      <div className="upperblock-file-row-community">
                        <div className="fileimage-img-name-file">
                          <img
                            className="fileimage-community"
                            src={fileImage}
                            alt="fileImage"
                          />
                          <a href={file.file} className="name-file-community">
                            {file.name}
                          </a>
                        </div>
                        <div
                          className="remove-file-text"
                          onClick={() => clearLocalFile(index)}
                        >
                          {t("removeFile")}
                        </div>
                      </div>
                      <div>
                        <CustomInput
                          cssInput="cusom-input-props"
                          id="englishTitle"
                          value={meetingFileInput}
                          onChange={(inputText) =>
                            setMeetingFileInput(inputText)
                          }
                          placeholder={t("meetingMinutes")}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="community-update-row">
                <label className="community-update-label-in-row"></label>
                <div>
                  <label>
                    <div className="size-btn-file">
                      <CustomButton text={t("addFile")} />
                    </div>
                    <input
                      className="hidde-input-image-community"
                      type="file"
                      accept=".doc, .docx, .pdf"
                      onChange={addFileToGathering}
                    />
                  </label>
                </div>
              </div>
            </>
          )}
        </WrapperOnTheForm>
        <div className="container-post-update-btn">
          <div className="delete-update-btn" onClick={clearPageContent}>
            {t("cancel")}
          </div>
          <div className="post-update-btn" onClick={delegationButton}>
            {t("postUpdate")}
          </div>
        </div>

        <IonLoadingPage isLoading={isLoading} />
      </IonContent>
    </IonPage>
  );
};

export default CommunityUpdate;
