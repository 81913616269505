import { globeOutline, person, book, location } from "ionicons/icons";
import { URL_ACCOUNT } from "../accessRghts/Access";

export const NAV_LINKS = [
  { text: "Browse", link: "/browse", icon: globeOutline },
  { text: "Community", link: "/community/my", icon: location }, // /community/190/update/1
  { text: "Resources", link: "/resources", icon: book },
  { text: "Account", link: URL_ACCOUNT, icon: person },
];

export const THEMES = {
  GREEN: "green",
  BLUE: "blue",
};

export const ALL = "All";
export const VERY_GOOD = "Very Good";
export const GOOD = "Good";
export const OKAY = "Okay";
export const BAD = "Bad";
export const VERY_BAD = "Very Bad";

export const BOYS = {
  text: "BOYS",
  color: "rgb(103, 189, 33)",
  key: "BOYS",
};
export const GIRLS = {
  text: "GIRLS",
  color: "rgb(180, 222, 147)",
  key: "GIRLS",
};
export const MAN = {
  text: "MAN",
  color: "rgb(254, 176, 25)",
  key: "MAN",
};
export const WOMAN = {
  text: "WOMAN",
  color: "rgb(254, 210, 122)",
  key: "WOMAN",
};
export const MEN = {
  text: "MEN",
  color: "rgb(254, 176, 25)",
  key: "MEN",
};
export const WOMEN = {
  text: "WOMEN",
  color: "rgb(254, 210, 122)",
  key: "WOMEN",
};
export const UNDEFINED_PERSON = {
  text: "UNDEFINED PERSON",
  color: "rgb(122, 101, 55)",
  key: "UNDEFINED_PERSON",
};

export const MOMENT_FORMAT_DAY_MONTH_TEXT_YEAR = "Do MMMM YYYY";
