import { createContext, useContext, FC } from 'react';
export interface IThemesContext {
  activeParentTab: string;
  id: string;
  level: string;
  myCommunity?: boolean;
}

const ThemesContext = createContext<IThemesContext | undefined>(undefined);
const useThemesContext = (): IThemesContext => {
  const context = useContext(ThemesContext);

  if (!context) {
    throw new Error(
      'useThemesContext must be used within a ThemesProvider'
    );
  }

  return context;
};

type Props = {
  activeParentTab: string;
  level: string;
  id: string;
  myCommunity?: boolean;
}

const ThemesProvider: FC<Props> = (props) => {
  const ThemeContextValue: IThemesContext = {
    activeParentTab: props.activeParentTab,
    level: props.level,
    id: props.id,
    myCommunity: props.myCommunity,
  };

  return <ThemesContext.Provider value={ThemeContextValue} {...props} />;
};

export { ThemesProvider, useThemesContext };
