import { DEFAULT_LANG, LANG } from "../lang";
import { UserReducerT } from "../types/types";
import { ROLE_NOT_REGISTERED_USER } from "../accessRghts/Access";

export const TOKEN = "Token";
export const USER = "USER";
export const ROLE = "role";
export const EMAIL = "email";
export const COMMUNITY = "community";
export const AVATAR = "avatar";
export const FIRST_NAME = "first_name";

const SET_COUNT = "SET_COUNT";
const SET_TOKEN = "SET_TOKEN";
const SET_LANG = "SET_LANG";
const SET_ROLE = "SET_ROLE";
const SET_EMAIL = "SET_EMAIL";
const SET_AVATAR = "SET_AVATAR";
const LOG_OUT = "LOG_OUT";
const SET_COMMUNITY = "SET_COMMUNITY";
const SET_USERNAME = "SET_USERNAME";

export interface UserState {
  count: number;
  token: string;
  lang: string;
  role: string | null;
  email: string;
  community: string | null;
  avatar: string | undefined;
  first_name: string | undefined;
}

const initialState: UserState = {
  count: 0,
  token: "",
  lang: DEFAULT_LANG,
  role: ROLE_NOT_REGISTERED_USER,
  email: "",
  community: '',
  avatar: "",
  first_name: "",
};

export const getUserToken = () => {
  return window.localStorage.getItem(TOKEN);
};

export default function userReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_COUNT:
      return {
        ...state,
        count: action.payload,
      };
    case SET_TOKEN:
      window.localStorage.setItem(TOKEN, action.payload);
      return {
        ...state,
        token: action.payload,
      };
    case SET_LANG:
      return {
        ...state,
        lang: action.payload,
      };
    case SET_ROLE:
      return {
        ...state,
        role: action.payload,
      };
    case SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case SET_AVATAR:
      return {
        ...state,
        avatar: action.payload,
      };
    case SET_USERNAME:
      return {
        ...state,
        first_name: action.payload,
      };
    case LOG_OUT:
      return {
        ...initialState,
        lang: state.lang,
      };
    case SET_COMMUNITY:
      return {
        ...state,
        community: action.payload,
      };
    default:
      return state;
  }
}

export const setCount = (count: number) => ({
  type: SET_COUNT,
  payload: count,
});

export const setToken = (token: string) => ({
  type: SET_TOKEN,
  payload: token,
});

export const setLang = (lang: string) => ({
  type: SET_LANG,
  payload: lang,
});

export const setRole = (role: string | null) => ({
  type: SET_ROLE,
  payload: role,
});

export const setEmail = (email: string) => ({
  type: SET_EMAIL,
  payload: email,
});
export const setAvatar = (avatar: string | undefined) => ({
  type: SET_AVATAR,
  payload: avatar,
});
export const setUsername = (first_name: string | undefined) => ({
  type: SET_USERNAME,
  payload: first_name,
});

export const setLogOut = () => ({
  type: LOG_OUT,
});

export const setCommunity = (community: string) => ({
  type: SET_COMMUNITY,
  payload: community,
});
