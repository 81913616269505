import React, {FormEvent, useState} from "react";
import TitlePageTextTheme from "../../components/titlePageTextTheme/titlePageTextTheme";
import ContainerPosts from "../../components/containerPosts/ContainerPosts";
import ShallPost from "../../components/shallPost/ShallPost";
import CustomInput from "../../components/customInput/CustomInput";
import logoImage from "../../assets/images/logo.png";
import "./SignIn.css";
import {useHistory} from "react-router-dom";
import {middlewarePostAuthToken} from "../../services/middleware/MiddlewateUser";
import {useDispatch, useSelector} from "react-redux";
import {
    setToken,
    setRole,
    setEmail,
    setCommunity, setAvatar, setUsername,
} from "../../reducers/userReducer";
import {useTranslation} from "react-i18next";
import {URL_BROWSE} from "../../accessRghts/Access";
import CustomInputWithError from "../../components/customInput/customInputWithError/СustomInputWithError";
import {middlewarGetAccountMe} from "../../services/middleware/MiddlewateUser";
import * as access from "../../accessRghts/Access";
import {setHeaderActiveTab} from "reducers/headerReducer";
import {persistor} from "reducers";
import prepareCacheState from "prepareCacheState";
import {useIonViewWillEnter} from "@ionic/react";


const SignIn: React.FC = () => {
    const [email, setEmailLocal] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    const history = useHistory();
    const {t} = useTranslation("common");
    const [error, setError] = useState({
        isError: false,
        textError: "",
    });
    const THEME_PAGE = useSelector((state: any) => state.theme.theme)

    useIonViewWillEnter(() => {
        dispatch(setHeaderActiveTab(3))
    }, []);


    const backToMain = () => {
        history.push("/resources"); // TODO: set normal link
    };

    const singIn = async (e: FormEvent) => {
        e.preventDefault();
        const responce = await middlewarePostAuthToken(email, password);
        if (responce.isError) {
            alert(t("incorrectPasswordOrEmail"));
        } else {
            dispatch(setToken(responce.token));
            dispatch(setEmail(email));
            if (!responce.admin_scope) {
                dispatch(setRole(access.ROLE_BASIK_USER));
            } else {
                dispatch(setRole(responce.admin_scope));
            }
            const responceAccountMe = await middlewarGetAccountMe();
            const {community, avatar, first_name} = responceAccountMe;
            dispatch(setCommunity(community));
            dispatch(setAvatar(avatar || ''))
            dispatch(setUsername(first_name || ''))

            await persistor.flush();
            history.push("/");

            window.location.reload();
        }
    };

    const resetPassword = async (e: FormEvent) => {
        e.preventDefault()
        const validEmail: any = checkEmailValid();
        setError({...validEmail});

        if (!validEmail.isError) {
            dispatch({
                type: "SET_EMAIL",
                payload: email,
            });

            history.push("/signin/reset");
        }
        // dispatch({
        //   type: "SET_EMAIL",
        //   payload: email,
        // });
        // history.push("/signin/reset");
    };

    const checkEmailValid = () => {
        if (email.length === 0) {
            return {
                isError: true,
                textError: t("mailIsTooShort"),
            };
        }

        if (!email.includes("@")) {
            return {
                isError: true,
                textError: t("thisIsNotMail"),
            };
        }

        return {
            isError: false,
            textError: "",
        };
    };

    return (

        <>
            <TitlePageTextTheme
                theme={THEME_PAGE}
                cssTitleScreenCommunity="sing-in-title-page-text"
            >
                <>
                    <div className="show-logo-in-table">
                        <img
                            onClick={backToMain}
                            className="sing-in-logo-image"
                            src={logoImage}
                            alt="logoImage"/>
                    </div>
                    <div>{t("myAccount")}</div>
                </>
            </TitlePageTextTheme>
            <ContainerPosts
                theme={THEME_PAGE}
                text={t("eligibleParticipantsCanLogInHere")}
            >
                <form>
                    {/* sign-in-css-section-wall */}
                    <ShallPost cssSectionWall="community-page-css-section-wall sign-in-css-section-wall">
                        <>
                            <div className="row-form-sing-in">
                                <label className="sing-in-label-form" htmlFor="email">
                                    {t("emailAddress")}
                                </label>
                                <CustomInputWithError
                                    cssInput="cusom-input-props"
                                    id="email"
                                    value={email}
                                    isError={error.isError}
                                    textError={error.textError}
                                    onChange={(inputText) => setEmailLocal(inputText)}/>
                            </div>
                            <div className="row-form-sing-in">
                                <label className="sing-in-label-form" htmlFor="password">
                                    {t("password")}
                                </label>
                                <CustomInput
                                    cssInput="cusom-input-props"
                                    id="password"
                                    value={password}
                                    type="password"
                                    onChange={(inputText) => setPassword(inputText)}/>
                            </div>
                            <div className="sing-in-border"></div>
                            <div className="sing-in-container-for-btn">
                                <button
                                    className="sing-in-reset-password-btn"
                                    onClick={resetPassword}
                                    type="reset"
                                >
                                    {t("resetPassword")}
                                </button>
                                <button className="sing-in-login-btn" type="submit" onClick={singIn}>
                                    {t("logIn")}
                                </button>
                            </div>
                        </>
                    </ShallPost>
                </form>
            </ContainerPosts>
        </>

    );
};

export default SignIn;
