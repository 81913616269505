import API from "../api/api";
import { TOKEN } from "../reducers/userReducer";

export const testUser = async () => {
  return await API.post(
    "/auth-token",
    {
      username: "asd",
      password: "asd",
    },
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      },
    }
  );
};

export const postAuthToken = async (email: string, password: string) => {
  return await API.post("/auth-token", {
    username: email,
    password: password,
  });
};

export const changeLang = async (lang: string) => {
  const url = `/activate/${lang}`;
  return await API.get(url, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
    },
  });
};

export const putResetPassword = async (email: string) => {
  const url = `/account/reset_password/set`;
  return await API.put(url, {
    email: email,
  });
};

export const getCountryTanzania = async () => {
  const url = `/country/tanzania`;
  return await API.get(url);
};

export const getCountryIreland = async () => {
  const url = `/country/ireland`;
  return await API.get(url);
};

export const putCountryTanzania = async (data: any) => {
  const url = `/country/tanzania`;
  return await API.put(url, data, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
    },
  });
};

export const putCountryIreland = async (data: any) => {
  const url = `/country/ireland`;
  return await API.put(url, data, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
    },
  });
};

export const getAccountMe = async () => {
  const url = `/account/me`;
  return await API.get(url, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
    },
  });
};

export const selfDelete = async () => {
  const url = `/account/me`;
  return await API.delete(url, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
    },
  });
};

export const putAccountMe = async (data: any) => {
  const url = `/account/me`;
  return await API.put(url, data, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
    },
  });
};

export const postAccountSetPasswordMe = async (password: string) => {
  const url = `/account/set_password/me`;
  return await API.post(
    url,
    {
      password: password,
    },
    {
      headers: {
        Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      },
    }
  );
};

export const postFeedback = async (data: any) => {
  const url = `/feedback`;
  return await API.post(url, data, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
    },
  });
};


export const putAvatar = async (userId: string, dataToSend: any) => {
  const url = `/user/${userId}/avatar/add`;
  return await API.put(url, dataToSend,
      {
        headers: {
          Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
        },
      });
};

