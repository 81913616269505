import React from "react";
import { getTextColor } from "../../theme/CombineThemes";
import { THEMES } from "../../mocks/layout";

type Props = {
  text: string;
  children: React.ReactNode;
  theme?: string;
  class_section?: string;
};
const ContainerPosts: React.FC<Props> = ({
  text,
  children,
  theme = THEMES.GREEN,
  class_section = "align-browse-news-wall",
}) => {
  // css in: "../../pages/Community/browseContext/CommunityBrowseContext.css"
  const textColor = () => {
    return getTextColor(theme);
  };

  return (
    <>
      <section className={class_section}>
        <div className={`left-text-community-browse ${textColor()}`}>
          {text}
        </div>
        {children}
      </section>
    </>
  );
};

export default ContainerPosts;
