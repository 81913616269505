
import { setStandardsTrends } from 'reducers/communityTrendsReducer'
import recalculateStandardsTrends from 'helpers/offline/recalculateTrends'
import {setCommunityMyPageTree} from "../communityReducer";
import {setStandards} from "../standardsReducer";

export const standardsTrendsUpdateThunk = function standardsTrendsUpdateThunk(gatheringUpdate: any) {
    return (dispatch: any, getState: any) => {

        const community_gathering_date = gatheringUpdate.community_gathering.date;
        const standard_update_rate = gatheringUpdate.rate;
        const standard_id = gatheringUpdate.standard.id;

        const { communityTrends: { standards }, community: { myPageTree }, standards: { standardsData }  } = getState()

        const { trends } = standards;

        const standardTrend = trends.find((trend: any) => trend.id === standard_id);

        if (!standardTrend) {
            return;
        }

        let standardTrendUpdateIndex = standardTrend.dates.findIndex((date: string) => date === community_gathering_date);

        if (!~standardTrendUpdateIndex) {
            const olderDates = standardTrend.dates.filter((el: string) => el < community_gathering_date);

            standardTrendUpdateIndex = olderDates.length;
            standardTrend.dates.splice(standardTrendUpdateIndex, 0, community_gathering_date);
            standardTrend.trend.splice(standardTrendUpdateIndex, 0, standard_update_rate);
        } else {
            standardTrend.trend[standardTrendUpdateIndex] = standard_update_rate;
        }


        dispatch(setStandardsTrends(standards));

        const newTrends = recalculateStandardsTrends(trends);

        const { data: myPageTreeData } = myPageTree;

        myPageTreeData.standards = newTrends;

        myPageTreeData.all_rate_list[0] = newTrends.rate;
        myPageTreeData.rate = myPageTreeData.all_rate_list.reduce((acc: number, el: number) => acc + el,0)/myPageTreeData.all_rate_list.length;

        dispatch(setCommunityMyPageTree(myPageTree));

        // update list

        const { standards: standartsListData } = standardsData;

        const standartsListDataItem = standartsListData.find((el: any) => el.id === standard_id) || {};

        standartsListDataItem.rate = standard_update_rate;
        standartsListDataItem.state = gatheringUpdate.state;

        standardsData.trend = newTrends.trend;
        standardsData.rate = newTrends.rate;
        standardsData.trend_direction = newTrends.trend_direction;

        dispatch(setStandards(standardsData));

    }
}
