import React from "react";
import {RouteComponentProps} from "react-router-dom";
import {IonContent, IonPage} from "@ionic/react";
import GoBack from "../../components/goBack/GoBack";
import ButtonResource from "../../components/content/ButtonResource";
import {THEMES} from "../../mocks/layout";
import Header from "../../components/header/Header";
import CustomInput from "../../components/customInput/CustomInput";
import {withTranslation, WithTranslation} from "react-i18next";
import Footer from "../../components/footer/Footer";
import CustomDropDown from "../../components/customDropDown/CustomDropDown";
import {
    getUsersRightList,
    addNewUser
} from "../../services/users";
import {
    isUserGlobalIrelandTanzaniaAdmin,
    isUserGlobalAdmin,
    isUserGlobalIrelandAdmin,
    isUserGlobalTanzaniaAdmin
} from "../../helpers/functions";
import {
    getCommunitiesList,
    getCommunitiesListCountry
} from "../../services/Community";
import "../resource.css";
import "../Community/CommunityManage/CommunityManage.css";
import "./UserManagement.css"
import "./CreateUser.css"
import {useDispatch} from "react-redux";
import {setHeaderActiveTab} from "../../reducers/headerReducer";

const THEME_PAGE = THEMES.BLUE;

type CreateUserProps = {};

type Community = {
    group_name: string,
    id: number,
    name: string,
    welcome_message: string,
    community: number
};

type OptionRight = {
    label: string
};

type OptionCommunity = {
    label: string,
    value: number
};

type OptionCountries = {
    label: string
};

type Content = {
    first_name: string,
    last_name: string,
    email: string,
    admin_scope: string,
    community: number
};

type CreateUserState = {
    contentPage: Content,
    listRight: OptionRight[],
    communitiesCountry: JSX.Element | null,
    countries: JSX.Element | null
};

class CreateUser extends React.Component<CreateUserProps & WithTranslation & RouteComponentProps<{}>,
    CreateUserState> {
    globalAdmin: boolean;
    levelCountrysAdmin: boolean;
    adminTanzania: boolean;
    adminIreland: boolean;
    communitiesListTanzania: Community[];
    communitiesListIreland: Community[];
    listOptionCountries: OptionCountries[];
    listOptionCommunity: OptionCommunity[];

    constructor(props: any) {
        super(props);
        this.state = {
            contentPage: {
                first_name: "",
                last_name: "",
                email: "",
                admin_scope: "",
                community: 0
            },
            listRight: [],
            communitiesCountry: null,
            countries: null,
        };

        this.globalAdmin = false;
        this.levelCountrysAdmin = false;
        this.adminTanzania = false;
        this.adminIreland = false;
        this.communitiesListTanzania = [];
        this.communitiesListIreland = [];
        //   this.listOptionTanzania = [];
        this.listOptionCommunity = [];
        this.listOptionCountries = [{
            label: "Ireland"
        }, {
            label: "Tanzania"
        }];
        this.createAccount = this.createAccount.bind(this);
        this.createListRight = this.createListRight.bind(this);
        this.selectOptionRight = this.selectOptionRight.bind(this);
        this.setContentPage = this.setContentPage.bind(this);
        this.getCommunitiesList = this.getCommunitiesList.bind(this);
        this.getCommunities = this.getCommunities.bind(this);
        this.makeListCommunities = this.makeListCommunities.bind(this);
        this.selectOptionCommunity = this.selectOptionCommunity.bind(this);
        this.makeListCountry = this.makeListCountry.bind(this);
        this.selectCountry = this.selectCountry.bind(this);
    }

    async componentDidMount() {
        if (isUserGlobalAdmin())
            this.globalAdmin = true;
        else if (isUserGlobalIrelandTanzaniaAdmin())
            this.levelCountrysAdmin = true;
        else if (isUserGlobalIrelandAdmin())
            this.adminIreland = true;
        else if (isUserGlobalTanzaniaAdmin())
            this.adminTanzania = true;

        try {
            const {data} = await getUsersRightList();
            await this.getCommunities("ireland");
            await this.getCommunities("tanzania");

            this.createListRight(data.admin_scopes);
        }
        catch (error: any) {
            console.log(`getUsersRightList error status: ${error}`);
        }
    }

    async getCommunities(country: string) {
        try {
            const {data} = await getCommunitiesListCountry(country);
            country === "tanzania" ?
                this.communitiesListTanzania = [...data] :
                this.communitiesListIreland = [...data];
        }
        catch (error: any) {
            console.log(`getCommunitiesListCountry error status: ${error}`);
        }
    }

    makeListCommunities(arrayCommunities: Community[], country: string) {
        const {t} = this.props;
        this.listOptionCommunity = arrayCommunities.map((item) => {
            return ({
                    label: item.name,
                    value: item.id
                }
            );
        });

        const communitiesDropList: JSX.Element =
            <div className="community-management-content-wrap-row
                    community-management-content-group-name-row
                    create-user-admin-scope-row"
            >
                <div className="community-management-content-group-label">
                    <div className="create-user-group-label-email">
                        {country.toLocaleLowerCase() === "ireland" ? t("communitiesIreland") : t("communitiesTanzania")}
                    </div>
                </div>
                <CustomDropDown
                    setValue={(option) => this.selectOptionCommunity(option, country)}
                    options={this.listOptionCommunity as any[]}
                    cssControl="create-user-select-dropdown"
                />
            </div>;
        this.setState({communitiesCountry: communitiesDropList});
    }

    makeListCountry() {
        const {t} = this.props;
        const countriesDropList: JSX.Element =
            <div className="community-management-content-wrap-row
                    community-management-content-group-name-row
                    create-user-admin-scope-row"
            >
                <div className="community-management-content-group-label">
                    <div className="create-user-group-label-email">
                        {t("chooseCountry")}
                    </div>
                </div>
                <CustomDropDown
                    setValue={(option) => this.selectCountry(option)}
                    options={this.listOptionCountries as any[]}
                    cssControl="create-user-select-dropdown"
                />
            </div>;
        this.setState({countries: countriesDropList});
        this.setState({communitiesCountry: null});
    }

    async getCommunitiesList() {
        try {
            const {data} = await getCommunitiesList();
        }
        catch (error: any) {
            console.log(`getCommunitiesList error status: ${error}`);
        }
    }

    createListRight(araryRights: string[]) {
        let listRight: OptionRight[] = [{
            label: "Basic User"
        }];
        araryRights.forEach((item) => {
            if (this.globalAdmin ||
                (this.levelCountrysAdmin && ((item.toLocaleLowerCase() === "tanzania") ||
                    (item.toLocaleLowerCase() === "ireland"))) ||
                (this.adminIreland && item.toLocaleLowerCase() === "ireland") ||
                (this.adminTanzania && item.toLocaleLowerCase() === "tanzania")
            ) {
                listRight.push({
                        label: item
                    }
                );
            }
        });
        this.setState({listRight: listRight});
    }

    setContentPage(content: any) {
        this.setState({contentPage: content});
    }

    selectOptionRight(option: any) {
        const content = {...this.state.contentPage, admin_scope: option.value};
        this.setState({contentPage: content});
        if (option.value === "Basic User") {
            this.makeListCountry();
        } else if (option.value === "tanzania") {
            this.setState({countries: null});
            this.makeListCommunities(this.communitiesListTanzania, option.value);
        } else if (option.value === "ireland") {
            this.setState({countries: null});
            this.makeListCommunities(this.communitiesListIreland, option.value)
        }
    }

    selectCountry(option: any) {
        option.value === "Ireland" ?
            this.makeListCommunities(this.communitiesListIreland, option.value) :
            this.makeListCommunities(this.communitiesListTanzania, option.value);
    }

    selectOptionCommunity(option: any, country: string) {
        const content = {...this.state.contentPage, community: option.value};
        this.setState({contentPage: content});
    }

    backToResources() {
        this.props.history.goBack();
    }

    async createAccount() {
        const {t} = this.props;
        if (this.state.contentPage.first_name.length === 0 ||
            this.state.contentPage.last_name.length === 0 ||
            this.state.contentPage.email.length === 0 ||
            this.state.contentPage.admin_scope.length === 0 ||
            (
                this.state.contentPage.admin_scope.toLocaleLowerCase() !== "global" &&
                this.state.contentPage.community === 0)) {

            alert(t("allFieldsMissing"));
            return;
        }
        let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (reg.test(this.state.contentPage.email) == false) {
            alert(t("wrongEmailAddress"));
            return;
        }
        try {
            const response = addNewUser({...this.state.contentPage});
        }
        catch (error: any) {
            console.log(`addNewUser error status: ${error}`);
        }
    }


    render() {
        const {t} = this.props;
        const content: Content = {...this.state.contentPage};

        return (
            <>
                <div className="community-management-title-wrap">
                    <div className="community-management-title-wrap-content">
                        <div className="display-flex">
                            <GoBack isGoBack text={t("userManagement")}/>
                        </div>
                        <div className="community-management-title-wrap-content-name">
                            {t("newAdminAccount")}
                        </div>
                    </div>
                </div>
                <div className="wrap-content-resource">
                    <div className="content-resource community-management-content-resource">
                        <div className='description_resource community-management-content-description'>
                            {t("usersWillEmailedLinkCompleteRegistration")}
                        </div>
                        <div className="content-resource-summary community-management-content-summary">
                            <div className="content-resource-summary-detail">
                                <div className="content-resource-summary-detail-subcontainer">
                                    <div className="community-management-content-wrap">
                                        <div className="community-management-content-wrap-row
                                      community-management-content-group-name-row"
                                        >
                                            <div className="community-management-content-group-label">
                                                <div className="create-user-group-label-name">
                                                    {t("name")}
                                                </div>
                                            </div>
                                            <CustomInput
                                                cssInput="create-user-first-name"
                                                id="englishFirstName"
                                                value={this.state.contentPage.first_name}
                                                placeholder="First Name"
                                                onChange={(inputText: string) => {
                                                    this.setContentPage({...content, first_name: inputText});
                                                }}
                                            />
                                            <CustomInput
                                                cssInput="create-user-last-name"
                                                id="englishLastName"
                                                placeholder="Last Name"
                                                value={this.state.contentPage.last_name}
                                                onChange={(inputText: string) => {
                                                    this.setContentPage({...content, last_name: inputText});
                                                }}
                                            />

                                        </div>
                                        <div className="community-management-content-wrap-row
                                      community-management-content-group-name-row"
                                        >
                                            <div className="community-management-content-group-label">
                                                <div className="create-user-group-label-email">
                                                    {t("emailAddress")}
                                                </div>
                                            </div>
                                            <CustomInput
                                                cssInput="create-user-email-address"
                                                id="englishEmail"
                                                placeholder="account@domain.net"
                                                value={this.state.contentPage.email}
                                                onChange={(inputText: string) => {
                                                    this.setContentPage({...content, email: inputText});
                                                }}
                                                isShowCountLetter
                                            />
                                        </div>
                                        <div className="community-management-content-wrap-row
                                      community-management-content-group-name-row
                                      create-user-admin-scope-row"

                                        >
                                            <div className="community-management-content-group-label">
                                                <div className="create-user-group-label-email">
                                                    {t("adminScope")}
                                                </div>
                                            </div>
                                            <CustomDropDown
                                                setValue={(option) => this.selectOptionRight(option)}
                                                options={this.state.listRight as any[]}
                                                cssControl="create-user-select-dropdown"
                                            />
                                        </div>
                                        {this.state.countries}
                                        {this.state.communitiesCountry}
                                        <div className="community-management-content-line"/>
                                        <div className="community-management-content-wrap-row
                                      community-management-content-button-save-row"
                                        >
                                            <ButtonResource
                                                onClickHandler={this.createAccount}
                                                text_color="white"
                                                id="community-management-conten-button-save"
                                            >
                                                {t("createAccount")}
                                            </ButtonResource>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>


        );
    }
}

const decoratedComponent = withTranslation("common")(CreateUser);
export {decoratedComponent as CreateUser};
