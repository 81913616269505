import React, { useEffect, useState } from "react";
import { IonContent, IonPage } from "@ionic/react";
import "./PerformanceMeasure.css";
import IonLoadingPage from "../../../components/ionLoadingPage/IonLoadingPage";
import { useTranslation } from "react-i18next";
import TitlePageTextTheme from "../../../components/titlePageTextTheme/titlePageTextTheme";
import WrapperOnTheForm from "../../../components/wrapperOnTheForm/WrapperOnTheForm";
import CustomInput from "../../../components/customInput/CustomInput";
import CustomTextAre from "../../../components/customTextAre/CustomTextAre";
import { PerformanceMeasureT } from "../../../types/types";
import { THEMES } from "../../../mocks/layout";
import { useHistory, useParams } from "react-router-dom";
import CustomButton from "../../../components/customButton/CustomButton";
import {
  middlewareGetPerformanceMeasure,
  middlewarePutPerformanceMeasure,
  middlewarePostPerformanceMeasure,
} from "../../../services/middleware/MiddlewareCommunity";
import { middlewareGetThemes } from "../../../services/middleware/MiddlewareTheme";
import CustomDropDown from "../../../components/customDropDown/CustomDropDown";
import SmallHeader from "../../../components/smallHeader/SmallHeader";

const URL_PARAMS_NEW = "new";
const PAGE_THEME = THEMES.GREEN;
const PerformanceMeasure: React.FC = () => {
  const { t } = useTranslation("common");
  const [perfomanceMeasure, setPerfomanceMeasure] =
    useState<PerformanceMeasureT>({
      id: 0,
      en_name: "",
      en_description: "",
      sw_name: "",
      sw_description: "",
      theme: 1,
      community: 0,
    });
  const [isLoading, setIsLoading] = useState(false);
  const { idPerfomanceMeasure, idCommunity } =
    useParams<{ idPerfomanceMeasure: string; idCommunity: string }>();
  const [defaultValueDropDown, setDefaultValueDropDown] = useState();
  const [optionsThemes, setOptionsThemes] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (checkNewPerfomance()) {
      getDataPerfomanceMeasure();
    } else {
      setPerfomanceMeasure({ ...perfomanceMeasure, community: +idCommunity });
      getThemes();
    }
  }, []);

  const checkNewPerfomance = () => {
    return idPerfomanceMeasure !== URL_PARAMS_NEW ? true : false;
  };

  const getDataPerfomanceMeasure = async () => {
    setIsLoading(true);
    const optionsThemesLocal = await getThemes();
    const responce = await middlewareGetPerformanceMeasure(idPerfomanceMeasure);
    if (responce.isError) {
      history.goBack();
    } else {
      setPerfomanceMeasure({
        ...responce,
      });
    }
    setDefaultValueDropDown(
      optionsThemesLocal.find((item: any) => item.value === responce.theme)
    );
    setIsLoading(false);
  };

  const getThemes = async () => {
    const responce:any = await middlewareGetThemes();
    const optionsThemesLocal = responce.map((item: any) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
    setOptionsThemes(optionsThemesLocal);
    return optionsThemesLocal;
  };

  const addScorecard = async () => {
    setIsLoading(true);
    if (!checkNewPerfomance()) {
      const responce = await middlewarePostPerformanceMeasure(
        perfomanceMeasure
      );
      console.log(responce);
      // create
    } else {
      // update
      await middlewarePutPerformanceMeasure(
        idPerfomanceMeasure,
        perfomanceMeasure
      );
    }
    setIsLoading(false);
    window.location.reload();
  };

  const changeValueDate = (option: any) => {
    const { value } = option;
    setPerfomanceMeasure({
      ...perfomanceMeasure,
      theme: value,
    });
    setDefaultValueDropDown(option);
  };

  const textBtn = checkNewPerfomance() ? t("save") : t("addScorecard");

  return (
    <IonPage>
      <IonContent>
        <SmallHeader isGoBack rightText={t("scorecard")} />
        <TitlePageTextTheme cssTitleScreenCommunity="align-bottom-text-my-community">
          <div>{t("performanceMeasure")}</div>
        </TitlePageTextTheme>{" "}
        <WrapperOnTheForm
          title={t("details")}
          description={t("provideAClearNameAndConciseDescription")}
        >
          <div className="community-update-row">
            <label
              className="community-update-label-in-row"
              htmlFor="englishName"
            >
              {t("name")}
            </label>
            <CustomInput
              cssInput="cusom-input-props"
              id="englishName"
              value={perfomanceMeasure.en_name}
              onChange={(inputText) =>
                setPerfomanceMeasure({
                  ...perfomanceMeasure,
                  en_name: inputText,
                })
              }
              isShowCountLetter
            />
          </div>
          <div className="community-update-row">
            <label
              className="community-update-label-in-row"
              htmlFor="englishUpdate"
            >
              {t("update")}
            </label>
            <CustomTextAre
              value={perfomanceMeasure.en_description}
              id="englishUpdate"
              isShowCountLetter
              onChange={(inputText) =>
                setPerfomanceMeasure({
                  ...perfomanceMeasure,
                  en_description: inputText,
                })
              }
              cssTextAre="cusom-input-props"
            />
          </div>
        </WrapperOnTheForm>
        <WrapperOnTheForm
          title={t("swahiliTranslation")}
          description={t("optionalIfAvailable")}
        >
          <div className="community-update-row">
            <label
              className="community-update-label-in-row"
              htmlFor="swahiliTitle"
            >
              {t("name")}
            </label>
            <CustomInput
              cssInput="cusom-input-props"
              id="swahiliTitle"
              value={perfomanceMeasure.sw_name}
              onChange={(inputText) =>
                setPerfomanceMeasure({
                  ...perfomanceMeasure,
                  sw_name: inputText,
                })
              }
              isShowCountLetter
            />
          </div>
          <div className="community-update-row">
            <label
              className="community-update-label-in-row"
              htmlFor="swahiliUpdate"
            >
              {t("update")}
            </label>
            <CustomTextAre
              value={perfomanceMeasure.sw_description}
              id="swahiliUpdate"
              isShowCountLetter
              onChange={(inputText) =>
                setPerfomanceMeasure({
                  ...perfomanceMeasure,
                  sw_description: inputText,
                })
              }
              cssTextAre="cusom-input-props"
            />
          </div>
        </WrapperOnTheForm>
        <WrapperOnTheForm
          title={t("categorisation")}
          description={t("toHelpUsCompareSentimentFrom")}
        >
          <div className="community-update-row">
            <label
              className="community-update-label-in-row"
              htmlFor="swahiliTitle"
            >
              {t("theme")}
            </label>
            <CustomDropDown
              setValue={(option) => changeValueDate(option)}
              defaultOption={defaultValueDropDown}
              options={optionsThemes}
              cssControl="cusom-input-props"
            />
          </div>
        </WrapperOnTheForm>
        <div className="container-post-update-btn margin-bottom-in-tablet">
          <div></div>
          <div>
            <CustomButton
              theme={PAGE_THEME}
              onClick={addScorecard}
              text={textBtn}
            />
          </div>
        </div>
        <IonLoadingPage isLoading={isLoading} />
      </IonContent>
    </IonPage>
  );
};

export default PerformanceMeasure;
