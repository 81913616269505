import React, {useEffect, useState} from "react";
import TitlePageTextTheme from "../../../components/titlePageTextTheme/titlePageTextTheme";
import ContainerPosts from "../../../components/containerPosts/ContainerPosts";
import ShallPost from "../../../components/shallPost/ShallPost";
import "./FeedBack.css";
import { useTranslation } from "react-i18next";
import CustomDropDown from "../../../components/customDropDown/CustomDropDown";
import CustomButton from "../../../components/customButton/CustomButton";
import GoBack from "../../../components/goBack/GoBack";
import CustomTextAre from "../../../components/customTextAre/CustomTextAre";
import { middlewarPostFeedback } from "../../../services/middleware/MiddlewateUser";
import { FeedbackI } from "../../../types/types";
import {useDispatch, useSelector} from "react-redux";
import {setHeaderActiveTab} from "../../../reducers/headerReducer";
import {useIonViewWillEnter} from "@ionic/react";

const FeedBack: React.FC = () => {
  const THEME_PAGE = useSelector((state:any) => state.theme.theme )
  const { t } = useTranslation("common");
  const [feedback, setFeedback] = useState<FeedbackI>({
    subject: "",
    message: "",
  });

  const optionsLang = [
    {
      label: t("iHaveAProblem"),
      value: "I have a problem",
    },
    {
      label: t("iFoundABug"),
      value: "I found a bug",
    },
  ];

  const dispatch = useDispatch();

  useIonViewWillEnter(() => {
    dispatch(setHeaderActiveTab(3))
  }, []);

  const save = async () => {
    if (feedback.message.length > 0) {
      await middlewarPostFeedback(feedback);
      alert(t("feedbackWasSend"));
    } else {
      alert(t("pleaseFillInTheMessage"));
    }
  };

  return (
      <>
        <TitlePageTextTheme
          theme={THEME_PAGE}
          cssTitleScreenCommunity="resourse-title-page-text-green"
      >
        <>
          <GoBack theme={THEME_PAGE} isGoBack text={t("myAccount")}/>
          <div>{t("sendFeedback")}</div>
        </>
      </TitlePageTextTheme><ContainerPosts theme={THEME_PAGE} text="">
        <ShallPost cssSectionWall="community-page-css-section-wall sign-in-css-section-wall">
          <div className="account-country-manamenet-country-text text-center">
            {t("sendAnonymousFeedbck")}
          </div>
          <section className="vertical-line vertical-line-top-bottom"></section>
          <section>
            <div className="row-account-details row-margin-bottom-account-details">
              <div className="row-account-details-left">{t("subject")}</div>
              <div className="row-account-details-right">
                <CustomDropDown
                    setValue={(option: any) => setFeedback({...feedback, subject: option.value})}
                    defaultOption={optionsLang[0]}
                    options={optionsLang}
                    cssControl="cusom-large-input-props"/>
              </div>
            </div>
            <div className="row-account-details">
              <div className="row-account-details-left">
                {t("yourFeedback")}
              </div>
              <div className="row-account-details-right">
                <CustomTextAre
                    value={feedback.message}
                    id="message"
                    isShowCountLetter
                    onChange={(inputText: string) => {
                      setFeedback({...feedback, message: inputText});
                    }}
                    cssTextAre={"cusom-large-input-props"}/>
              </div>
            </div>
          </section>
          <section className="vertical-line vertical-line-top-bottom"></section>
          <div className="container-post-update-btn margin-bottom-in-tablet">
            <div></div>
            <div className="down-button-country-management">
              <CustomButton
                  theme={THEME_PAGE}
                  onClick={save}
                  text={t("sendFeedback")}/>
            </div>
          </div>
        </ShallPost>
      </ContainerPosts>
      </>
  );
};

export default FeedBack;
