import React from "react";
import "./TitlePageTextGreen.css";

type Props = {
  children: React.ReactNode;
  cssTitleScreenCommunity?: string;
};

const TitlePageTextGreen: React.FC<Props> = ({
  children,
  cssTitleScreenCommunity,
}) => {
  return (
    <>
      <div
        className={`title-screen-community-green ${
          cssTitleScreenCommunity && cssTitleScreenCommunity
        }`}
      >
        {children}
      </div>
    </>
  );
};

export default TitlePageTextGreen;
