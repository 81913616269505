import React from 'react';
import '../../pages/resource.css'

type Props = {
    children: React.ReactNode;
};

const DescriptionResource: React.FC<Props> = ({
    children
  }
) => {

    return (
        <div className='description_resource'>
            {children}
        </div>
    );
}
export default DescriptionResource;