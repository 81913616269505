import React, {useEffect, useState} from "react";
import TitlePageTextTheme from "../../../../components/titlePageTextTheme/titlePageTextTheme";
import ContainerPosts from "../../../../components/containerPosts/ContainerPosts";
import ShallPost from "../../../../components/shallPost/ShallPost";
import logoImage from "../../../../assets/images/logo.png";
import CustomInputWithError from "../../../../components/customInput/customInputWithError/СustomInputWithError";
import "./ResetPassword.css";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {setHeaderActiveTab} from "../../../../reducers/headerReducer";
import {useIonViewWillEnter} from "@ionic/react";

const MIN_LENGTH_PASSWORD = 10;
const ResetPassword: React.FC = () => {
    const [password, setPassword] = useState("");
    const [repPassword, setRepPassword] = useState("");
    const history = useHistory();
    const {t} = useTranslation("common");
    const [error, setError] = useState({
        isError: false,
        isErrorRep: false,
        textError: "",
    });
    const dispatch = useDispatch();

    useIonViewWillEnter(() => {
        dispatch(setHeaderActiveTab(3))
    }, []);
    const THEME_PAGE = useSelector((state:any) => state.theme.theme )

    const backToMain = () => {
        history.push("/resources"); // TODO: set normal link
    };

    const setNewPassword = () => {
        const errorObject = isPasswordValid();
        setError(errorObject);
        if (!errorObject.isError && !errorObject.isErrorRep) {
            console.log("send password");
        }
    };

    const isPasswordValid = () => {
        if (password.length < MIN_LENGTH_PASSWORD) {
            return {
                isError: true,
                isErrorRep: false,
                textError: t("passwordMustBeAt"),
            };
        }
        const isContainNumber = /\d/.test(password);
        if (!isContainNumber) {
            return {
                isError: true,
                isErrorRep: false,
                textError: t("includeAtLeastOneNumber"),
            };
        }

        if (password !== repPassword) {
            return {
                isError: false,
                isErrorRep: true,
                textError: t("passwordsMustBeTheSame"),
            };
        }

        return {
            isError: false,
            isErrorRep: false,
            textError: "",
        };
    };

    return (

        <>
            <TitlePageTextTheme
                theme={THEME_PAGE}
                cssTitleScreenCommunity="sing-in-title-page-text"
            >
                <>
                    <div className="show-logo-in-table">
                        <img
                            onClick={backToMain}
                            className="sing-in-logo-image"
                            src={logoImage}
                            alt="logoImage"/>
                    </div>
                    <div>{t("myAccount")}</div>
                </>
            </TitlePageTextTheme><ContainerPosts
            theme={THEME_PAGE}
            text={t("eligibleParticipantsCanLogInHere")}
        >
            <section>
                {/* sign-in-css-section-wall */}
                <ShallPost cssSectionWall="community-page-css-section-wall sign-in-css-section-wall">
                    <>
                        <div className="text-align-left reset-password-margin-title">
                            <div className="reset-form-title">
                                {t("createAccountPassword")}
                            </div>
                        </div>
                        <div>
                            <div className="reset-form-text reset-password-margin-text">
                                {t("useAMinimumOfLettersr")}
                            </div>
                        </div>
                        <div className="sing-in-border reset-form-text reset-password-margin-line-top"></div>
                        <div className="row-form-sing-in reset-form-text reset-password-margin-new-password">
                            <label
                                className="sing-in-label-form reset-password--text-left"
                                htmlFor="newPassword"
                            >
                                {t("newPassword")}
                            </label>
                            <CustomInputWithError
                                cssInput="cusom-input-props"
                                id="newPassword"
                                isError={error.isError}
                                value={password}
                                textError={error.textError}
                                type="password"
                                onChange={(inputText) => setPassword(inputText)}/>
                        </div>
                        <div className="row-form-sing-in reset-password-margin-rep-password">
                            <label
                                className="sing-in-label-form reset-password--text-left"
                                htmlFor="confirmPassword"
                            >
                                {t("confirmPassword")}
                            </label>
                            <CustomInputWithError
                                cssInput="cusom-input-props"
                                id="confirmPassword"
                                value={repPassword}
                                isError={error.isErrorRep}
                                textError={error.textError}
                                type="password"
                                onChange={(inputText) => setRepPassword(inputText)}/>
                        </div>
                        <div className="sing-in-border"></div>
                        <div className="sing-in-container-for-btn">
                            <div className="sing-in-login-btn" onClick={setNewPassword}>
                                {t("savePassword")}
                            </div>
                        </div>
                    </>
                </ShallPost>
            </section>
        </ContainerPosts>
        </>

    );
};

export default ResetPassword;
