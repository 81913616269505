import React, {ComponentProps} from "react";
import Axios from "axios";
import fileDownload from "js-file-download";
import { IonButton, IonIcon} from "@ionic/react";
import {chevronBackOutline, chevronForwardOutline} from "ionicons/icons";
import ButtonResource from "../components/content/ButtonResource";
import {THEMES} from "../mocks/layout";
import TitlePageTextTheme from "../components/titlePageTextTheme/titlePageTextTheme";
import {withTranslation, WithTranslation} from "react-i18next";
import {
    getResourcesById,
    getResourceGroup,
    getResourcesOfGroup,
} from "../services/Resources";
import {
    isUserGlobalIrelandTanzaniaAdmin,
    isUserGlobalAdmin,
    isUserGlobalIrelandAdmin,
    isUserGlobalTanzaniaAdmin
} from "../helpers/functions";
import DescriptionResource from "../components/content/DescriptionResource";
import fileImage from "../assets/images/file-download.svg";
import "./resource.css";
import {connect} from "react-redux";
import {setHeaderActiveTab} from "../reducers/headerReducer";

import { withIonLifeCycle } from '@ionic/react';

const THEME_PAGE = THEMES.BLUE;

type ResourcePageProps = {};

type ResourcePageState = {
    contentPage: JSX.Element | null;
};
type Resources = {
    title: string;
    text: string;
    id: number;
    url: string;
};

export type Resource = {
    summary: string;
    title: string;
    content: string;
    en_summary: string;
    en_title: string;
    en_content: string;
    id: number;
    sw_summary: string;
    sw_title: string;
    sw_content: string;
    resource_group: number;
    standard: number | null;
    files: any[];
};

class ResourcePage extends React.Component<ComponentProps<any>, ResourcePageState> {
    idResource: number;
    idGroup: number;
    resource: Resource;
    nameOfGroup: string;
    array_other_resources: any[];
    blockSeeAlso: JSX.Element | null;
    globalAdmin: boolean;
    levelCountrysAdmin: boolean;
    adminTanzania: boolean;
    adminIreland: boolean;

    constructor(props: any) {
        super(props);

        this.idGroup = 0;
        this.idResource = 0;
        this.resource = {
            summary: "",
            title: "",
            content: "",
            en_summary: "",
            en_title: "",
            en_content: "",
            id: 0,
            sw_summary: "",
            sw_title: "",
            sw_content: "",
            resource_group: 0,
            standard: 0,
            files: [],
        };
        this.array_other_resources = [];
        this.blockSeeAlso = null;
        this.nameOfGroup = "";
        this.state = {contentPage: null};
        this.globalAdmin = false;
        this.levelCountrysAdmin = false;
        this.adminTanzania = false;
        this.adminIreland = false;
        this.getResource = this.getResource.bind(this);
        this.getNameOfGroup = this.getNameOfGroup.bind(this);
        this.backToResources = this.backToResources.bind(this);
        this.setResource = this.setResource.bind(this);
        this.makeBlockFiles = this.makeBlockFiles.bind(this);
        this.editResource = this.editResource.bind(this);
        this.getListOfOtherResources = this.getListOfOtherResources.bind(this);
        this.makeListOfOtherResources = this.makeListOfOtherResources.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.formatingContent = this.formatingContent.bind(this);
        this.updatePage = this.updatePage.bind(this);
        this.hanlderClickItemList = this.hanlderClickItemList.bind(this);
    }

    componentDidMount() {
        if (isUserGlobalAdmin())
            this.globalAdmin = true;
        else if (isUserGlobalIrelandTanzaniaAdmin())
            this.levelCountrysAdmin = true;
        else if (isUserGlobalIrelandAdmin())
            this.adminIreland = true;
        else if (isUserGlobalTanzaniaAdmin())
            this.adminTanzania = true;
        var param = Object.create(this.props.match.params);
        this.getResource(param.idResource);
        this.idResource = Number(param.idResource);
        //  this.idGroup = Number(param.idGroup);

        this.props.history.listen((location: any, action: any) => {
            if (location.pathname.indexOf("/resource/") !== -1 && action === "POP")
                this.getResource(this.idResource);
        });
    }

    async getResource(id: number) {


        await getResourcesById(`${id}`)
            .then(async ({data}) => {
                this.resource = {...data};
                this.idGroup = this.resource.resource_group;
                console.clear();
                //console.log({resource: this.resource});
                await this.getNameOfGroup(this.resource.resource_group);
            })
            .catch((error: any) => {
                console.log(`getResourcesById error status: ${error.response.status}`);
                if (error.response.status == 404)
                    this.props.history.goBack();
            });
    }

    async getNameOfGroup(idGroup: number) {
        try {
            const {data} = await getResourceGroup(`${idGroup}`);
            //this.nameOfGroup = data.en_title;
            this.nameOfGroup = data.title;
            await this.getListOfOtherResources(`${idGroup}`);
        }
        catch (error: any) {
            console.log(`getNameOfGroup error status: ${error.response.status}`);
        }
    }

    async getListOfOtherResources(idGroup: string) {
        try {
            const {data} = await getResourcesOfGroup(idGroup);
            this.makeListOfOtherResources(data);
        }
        catch (error: any) {
            console.log(
                `getListOfOtherResources error status: ${error.response.status}`
            );
        }
    }

    editResource() {
        //  this.props.history.push(`/resources/group/${this.idGroup}/resource/${this.idResource}/editresource`); //add 05-01-22
        this.props.history.push(`/resource/${this.idResource}/editresource`);
    }

    updatePage() {
        this.getResource(this.idResource);
    }

    async hanlderClickItemList(id: number) {
        await this.getResource(id);
        this.props.history.push("/resource/" + id);
    }

    makeListOfOtherResources(list_resources: any) {
        this.array_other_resources = [];
        list_resources.forEach((item: any) => {
            //if (item.en_title != this.resource.en_title)
            if (item.title != this.resource.title)
                this.array_other_resources.push({...item});
        });

        const listOfResources: JSX.Element[] = this.array_other_resources.map(
            (item, index) => {
                return (
                    <li
                        key={index}
                        onClick={() => {
                            this.hanlderClickItemList(item.id);
                        }}
                    >
                        <div className="content-resource-summary-list_resources-name">
                            {/*item.en_title*/item.title}
                        </div>
                        <div className="content-resource-summary-list_resources-name-forward">
                            <IonIcon icon={chevronForwardOutline} className="forward_arrow"/>
                        </div>
                    </li>
                );
            }
        );
        this.blockSeeAlso = (
            <ul className="content-resource-summary-list_resources">
                {listOfResources}
            </ul>
        );

        this.setResource();
    }

    downloadFile(url: string, file: string) {
        Axios.get(url, {
            responseType: "blob",
        }).then((res) => {
            fileDownload(res.data, file);
        });
    }

    makeBlockFiles(): JSX.Element {
        const len = this.resource.files.length;
        const block_files: JSX.Element[] = this.resource.files.map(
            (itemFile: any, index: number) => {
                let sufix: string = index === len - 1 ? "-last" : "";

                let start = itemFile.file.lastIndexOf("/");
                let url: string = itemFile.file.slice(0, start);
                let end = itemFile.file.lastIndexOf("_");
                let fileName: string = itemFile.file.slice(start + 1, end);
                start = itemFile.file.lastIndexOf(".");
                let extFile: string = itemFile.file.slice(start);
                fileName += extFile;

                return (
                    <div
                        key={index}
                        className={
                            "content-resource-summary-detail-subcontainer-downloads-files-row" +
                            sufix
                        }
                    >
                        <div className="content-resource-summary-detail-subcontainer-downloads-files-col-img">
                            <img src={fileImage} alt="img"/>
                        </div>
                        <div className="content-resource-summary-detail-subcontainer-downloads-files-col-file">
              <span
                  onClick={() => {
                      this.downloadFile(itemFile.file, fileName);
                  }}
              >
                {fileName}
              </span>
                        </div>
                    </div>
                );
            }
        );
        return (
            <div className="content-resource-summary-detail-subcontainer-downloads-files">
                {block_files}
            </div>
        );
    }

    formatingContent(text: string) {
        let content: JSX.Element[] = [];
        let start: number = 0;
        let key = 0;

        while (start < text.length) {
            let end: number = text.indexOf("\n", start);
            if (end !== -1) {
                content.push(
                    <span key={key++}>
            {text.slice(start, end)}
                        <br/>
          </span>
                );
            } else {
                content.push(<span key={key++}>{text.slice(start)}</span>);
                break;
            }
            start = end + 1;
        }
        return (
            <div className="content-resource-summary-detail-subcontainer-text">
                {content}
            </div>
        );
    }

    backToResources() {
        this.props.history.goBack();
    }
    ionViewWillEnter () {
        const {dispatch} = this.props;
        dispatch(setHeaderActiveTab(2))
    }

    setResource() {

        const {t} = this.props;

        const block_files: JSX.Element = this.makeBlockFiles();
        const content: JSX.Element = this.formatingContent(
            // this.resource.en_content
            this.resource.content
        );
        const contentPage: JSX.Element = (

            <>
                <TitlePageTextTheme
                    theme={THEME_PAGE}
                    cssTitleScreenCommunity="resourse-title-page-text-green"
                >
                    <>
                        <div>
                            <IonButton
                                color="light"
                                id="back_to_resources_button"
                                onClick={this.backToResources}
                            >
                                <IonIcon slot="start" icon={chevronBackOutline}/>
                                <span>{this.nameOfGroup}</span>
                            </IonButton>
                        </div>
                        <div>{/*this.resource.en_title*/this.resource.title}</div>
                    </>
                </TitlePageTextTheme>
                <div className="wrap-content-resource">
                    <div className="content-resource">
                        <DescriptionResource>
                            {/*this.resource.en_summary*/this.resource.summary}
                        </DescriptionResource>
                        <div className="content-resource-summary">
                            <div className="content-resource-summary-detail">
                                <div className="content-resource-summary-detail-subcontainer">
                                    <div className="content-resource-summary-detail-subcontainer-name">
                                        {t("summary")}
                                    </div>
                                    {content}
                                    <div className="content-resource-summary-detail-line"/>
                                    <div className="content-resource-summary-detail-subcontainer-downloads">
                                        {t("downloads")}
                                    </div>
                                    {block_files}
                                    {this.globalAdmin ?
                                        <div>
                                            <div className="content-resource-summary-detail-line"/>
                                            <div className="content-resource-summary-detail-subcontainer-button">
                                                <ButtonResource
                                                    color="light"
                                                    text_color="rgb(105, 113, 126)"
                                                    onClickHandler={this.editResource}
                                                >
                                                    {t("editResource")}
                                                </ButtonResource>
                                            </div>
                                        </div> :
                                        null}
                                </div>
                            </div>
                            <div className="content-resource-summary-other-list">
                                <div className="content-resource-summary-other-list-title">
                                    {t("alsoSee")}
                                </div>
                                {this.blockSeeAlso}
                            </div>
                        </div>
                    </div>
                </div>
            </>


        );
        this.setState({contentPage: contentPage});
    }

    render() {
        return <div>{this.state.contentPage}</div>;
    }
}

const decoratedComponent = connect()(withTranslation("common")(withIonLifeCycle(ResourcePage)));
export {decoratedComponent as ResourcePage};
