import API from "../api/api";
import {TOKEN} from "../reducers/userReducer";

export const getStandardsList = async () => {
  const url = `/standards`;
  return await API.get(url, {
    headers: {
        Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
        "Content-Type": "application/json",
    },
  });
};

export const getStandardsThemes = async () => {
  const url = `/standards/themes`;
  return await API.get(url, {
    headers: {
        Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
        "Content-Type": "application/json",
    },
  });
};

export const getStandardById = async (id: number) => {
    const url = `/standard/${id}`;
    return await API.get(url, {
      headers: {
          Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
          "Content-Type": "application/json",
      },
    });
  };

  
export const getStandardsOfCountry = async (country: string) => {
    const url = `/standards/${country}`;
    return await API.get(url, {
      headers: {
          Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
          "Content-Type": "application/json",
      },
    });
  };

  export const getFreeStandardsOfCountry = async (country: string) => {
    const url = `/standards`;
    return await API.get(url, {
      headers: {
          Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
          "Content-Type": "application/json",
      },
      params: {
          country:country,
          free: true
      }
    });
  };

export const getCountriesList = async () => {
    const url = `/countries`;
    return await API.get(url, {
      headers: {
          Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
          "Content-Type": "application/json",
      },
    });
  };