const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";

export interface HeaderState {
    activeTab: number;
}

const initialState: HeaderState = {
    activeTab: 0,
};


export default function headerReducer(state = initialState, action: any) {
    switch (action.type) {
        case SET_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.payload,
            };

        default:
            return state;
    }
}

export const setHeaderActiveTab = (activeTab: any) => ({
    type: SET_ACTIVE_TAB,
    payload: activeTab,
});

