import API from "../api/api";
import TestAPI from "../api/postmanApi";
import axios from "axios";
import { getUserToken } from "../reducers/userReducer";
import { PerformanceMeasureT } from "../types/types";
import { TOKEN } from "../reducers/userReducer";

export const getCommunityUpdates = async (idCommunity?: string) => {
  const url = `/community/${idCommunity}/updates`;
  return await API.get(url);
};

export const getCommunityUpdatePost = async (
  idCommunity: string,
  idPost: string
) => {
  const url = `/community/${idCommunity}/update/${idPost}`;
  return await API.get(url);
};

export const getCommunityUpdatePostImages = async (
  idCommunity: string,
  idPost: string
) => {
  const url = `/community/${idCommunity}/update/${idPost}/photos`;
  return await API.get(url);
};

export const deleteCommunityUpdatePhoto = async (
  idCommunity: string,
  idPost: string,
  idDelete: number
) => {
  const url = `/community/${idCommunity}/update/${idPost}/photo/${idDelete}`;
  return await API.delete(url, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
    },
  });
};

export const postCommunityUpdatePhoto = async (
  idCommunity: string,
  idUpdate: string,
  idPost: number,
  formData: FormData
) => {
  const url = `/community/${idCommunity}/update/${idUpdate}/photo/${idPost}`;
  return await API.post(url, formData, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const putCommunityUpdatePhoto = async (
  idCommunity: string,
  idUpdate: string,
  idPhoto: number,
  formData: FormData
) => {
  const url = `/community/${idCommunity}/update/${idUpdate}/photo/${idPhoto}`;
  return await API.post(url, formData, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getCommunityGatheringFiles = async (
  idCommunity: string,
  idGathering: number
) => {
  const url = `/community/${idCommunity}/gathering/${idGathering}/files`;
  return await API.get(url);
};

export const postCommunityGathingFile = async (
  idCommunity: string,
  idGathering: number,
  formData: FormData
) => {
  const url = `/community/${idCommunity}/gathering/${idGathering}/file/0`;
  return await API.post(url, formData, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteCommunityGathingFile = async (
  idCommunity: string,
  idGathering: number,
  idFile: number
) => {
  const url = `/community/${idCommunity}/gathering/${idGathering}/file/${idFile}`;
  return await API.delete(url, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const testFilesFormAppend = async () => {
  let formData = new FormData();
  const testArray = [
    {
      name: "qwe",
      id: 1,
    },
    {
      name: "asd",
      id: 123,
    },
  ];
  formData.append("files", JSON.stringify(testArray));
  formData.append("file", "Bohdan file");

  return await TestAPI.post("/files", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  // return await axios({
  //   method: "post",
  //   url: "http://localhost:3001/files",
  //   data: formData,
  // });
};

export const getSummaryDataBrowse = async (level: string, id: string) => {
  const url = `/summary/data/level/${level}/id/${id}`;
  return await API.get(url);
};
export const getSummaryChartsByTheme = async (level: string, id: string, themeId: string) => {
  const url = `/summary/data/level/${level}/id/${id}/theme/${themeId}`;
  return await API.get(url);
};


export const getTrendCommunityChartsByType = async (id: string, type: string) => {
  const url = `/community/${id}/${type}`;
  return await API.get(url);
};

export const getCommunityActionPlan = async (
  idCommunity: string,
  idActionPlan: string
) => {
  const url = `/community/${idCommunity}/action_plan/${idActionPlan}`;
  return await API.get(url);
};

export const getCommunityActionPlanUpdates = async (
  idCommunity: string,
  idActionPlan: string
) => {
  const url = `/community/${idCommunity}/action_plan/${idActionPlan}/updates`;
  return await API.get(url);
};

export const getCommunityActionPlanUpdateId = async (
  idCommunity: string,
  idActionPlan: string,
  idAtionPlanId: string
) => {
  const url = `/community/${idCommunity}/action_plan/${idActionPlan}/update/${idAtionPlanId}`;
  return await API.get(url);
};

export const putCommunityUpdate = async (
  idCommunity: string,
  idUpdate: string,
  data: any
) => {
  const url = `/community/${idCommunity}/update/${idUpdate}`;
  console.log(data);
  return await API.put(
    url,
    {
      ...data,
    },
    {
      headers: {
        Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      },
    }
  );
};

export const putCommunityGathering = async (
  idCommunity: string,
  idGathering: number,
  data: any
) => {
  const url = `/community/${idCommunity}/gathering/${idGathering}`;
  return await API.put(
    url,
    {
      ...data,
    },
    {
      headers: {
        Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      },
    }
  );
};

export const getStandards = async (idCommunity: string) => {
  const url = `/community/${idCommunity}/standards`;
  return await API.get(url);
};

export const getOlderUpdatesSummaryBrowse = async (
  idCommunity: string,
  page: string,
  pageLen?: string
) => {
  const url = `/community/${idCommunity}/updates1?page=${page}&page_len=${pageLen||"10"}`;
  return await API.get(url);
};

export const getPerformanceMeasure = async (id: string) => {
  const url = `/performance_measure/${id}`;
  return await API.get(url);
};

export const putPerformanceMeasure = async (
  id: string,
  objectData: PerformanceMeasureT
) => {
  const url = `/performance_measure/${id}`;
  return await API.put(url, objectData, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
    },
  });
};

export const postPerformanceMeasure = async (
  objectData: PerformanceMeasureT
) => {
  const url = `/performance_measure/new`;
  return await API.post(url, objectData, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
    },
  });
};

export const getCommunitySentiment = async (id: string) => {
  const url = `/community/${id}/sentiments`;
  return await API.get(url);
};

export const getCommunitySentimentId = async (
  id: string,
  idSentiment: string
) => {
  const url = `/community/${id}/sentiment/${idSentiment}`;
  return await API.get(url);
};

export const getCommunitySentimentIdUpdates = async (
  id: string,
  idSentiment: string
) => {
  const url = `/community/${id}/sentiment/${idSentiment}/updates`;
  return await API.get(url);
};

export const getCommunityId = async (id: string) => {
  const url = `/community/${id}`;
  return await API.get(url);
};

export const updateCommunityId = async (idCommunity: number, data: any) => {
  const url = `/community/${idCommunity}`;

  return await API.put(
    url,
    {
      ...data,
    },
    {
      headers: {
        Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      },
    }
  );
};

export const putCommunitySentimentIdUpdates = async (
  idCommunity: string,
  idSentiment: string,
  idUpdate: number,
  dataObject: any
) => {
  const url = `/community/${idCommunity}/sentiment/${idSentiment}/update/${idUpdate}`;
  return await API.put(url, dataObject, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
    },
  });
};

export const postCommunitySentimentIdUpdates = async (
  idCommunity: string,
  idSentiment: string,
  dataObject: any
) => {
  const url = `/community/${idCommunity}/sentiment/${idSentiment}/update/new`;
  return await API.post(url, dataObject, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
    },
  });
};

export const getStandardsOfCommunity = async (idCommunity: number) => {
  const url = `/community/${idCommunity}/standards`;
  return await API.get(url);
};

export const postCopmlianceStandard = async (
  idCommunity: number,
  idStandard: number,
  data: any
) => {
  const url = `/community/${idCommunity}/standard/${idStandard}/update/new`;

  return await API.post(
    url,
    {
      ...data,
    },
    {
      headers: {
        Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      },
    }
  );
};

export const updateCopmlianceStandard = async (
  idCommunity: number,
  idStandard: number,
  idUpdate: number,
  data: any
) => {
  const url = `/community/${idCommunity}/standard/${idStandard}/update/${idUpdate}`;

  return await API.put(
    url,
    {
      ...data,
    },
    {
      headers: {
        Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      },
    }
  );
};

export const getCopmlianceStandard = async (
  idCommunity: number,
  idStandard: number,
  idUpdate: number
) => {
  const url = `/community/${idCommunity}/standard/${idStandard}/update/${idUpdate}`;
  return await API.get(url);
};

export const getCopmlianceStandardAll = async (
  idCommunity: number,
  idStandard: number
) => {
  const url = `/community/${idCommunity}/standard/${idStandard}/updates`;
  return await API.get(url);
};

export const getCopmlianceStandardUpdates = async (
  idCommunity: number,
  idStandard: number
) => {
  const url = `/community/${idCommunity}/standard/${idStandard}`;
  return await API.get(url);
};

export const addCommunityPhoto = async (idCommunity: number, body: any) => {
  const url = `/community/${idCommunity}/photo/new`;

  return await API.post(url, body, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getCommunityPhotos = async (idCommunity: string) => {
  const url = `/community/${idCommunity}/photos`;
  return await API.get(url);
};

export const postCommunityUpdate = async (idCommunity: string, data: any) => {
  const url = `/community/${idCommunity}/update/0`;
  return await API.post(
    url,
    {
      ...data,
    },
    {
      headers: {
        Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      },
    }
  );
};

export const getCommunitiesListCountry = async (country: string) => {
  const url = `/communities`;

  return await API.get(url, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      "Content-Type": "application/json",
    },
    params: {
      country: country,
    },
  });
};

export const updateCommunityPhoto = async (
  idCommunity: number,
  idPhoto: number,
  body: any
) => {
  const url = `/community/${idCommunity}/photo/${idPhoto}`;

  return await API.put(url, body, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteCommunityPhoto = async (
  idCommunity: number,
  idPhoto: number
) => {
  const url = `/community/${idCommunity}/photo/${idPhoto}`;

  return await API.delete(url, {
    headers: {
      Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getCommunitiesList = async () => {
  const url = `/communities`;

  return await API.get(url);
};

export const getThemes = async () => {
  const url = `/themes`;
  return await API.get(url);
};

export const getCommunityGatherings = async (idCommunity: string) => {
  const url = `/community/${idCommunity}/gatherings`;
  return await API.get(url);
};

export const postCommunityGatheringsGet = async (
    idCommunity: string,
    date: string
) => {
  const url = `/community/${idCommunity}/gatherings/get`;
  return await API.post(
      url,
      {
        date: date,
      },
      {
        headers: {
          Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
        },
      }
  );
};

export const postCommunityGatheringsGetOffline = async (
  idCommunity: string,
  date: string,
  id: number,
) => {
  const url = `/community/${idCommunity}/gatherings/get`;
  return await API.post(
    url,
    {
      date,
      id,
    },
    {
      headers: {
        Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      },
    }
  );
};
