import React, { useEffect, useState } from "react";
import "./NewsWall.css";
import News from "../news/News";
import { NewsT } from "../../types/types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  isUserGlobalAdmin,
  isUserAdminCountryByCommunity,
  getAccessUserToCommunity,
} from "../../helpers/functions";
import { ROLE } from "../../reducers/userReducer";
import {useSelector} from "react-redux";

type Props = {
  news: NewsT[];
  loadOlderUpdates?: () => void;
  isShowBtnLoadOlderUpdates?: boolean;
  noNews?: string;
  isCommunity?: boolean;
  idCommunity?: string;
  country?: string;
  levelURL: string;
};

const NewsWall: React.FC<Props> = ({
  news,
  loadOlderUpdates,
  noNews = "No news",
  isShowBtnLoadOlderUpdates = true,
  isCommunity,
  idCommunity,
  country,
  levelURL,
}) => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const isConnected = useSelector((state:any) => state.offline.isConnected);
  const [isShowBtnNewPost, setIsShowBtnNewPost] = useState<boolean>(false);

  useEffect(() => {
    getAccess();
  }, [idCommunity, country, levelURL, isConnected]);

  const getAccess = async () => {
    if (!isConnected) {
      setIsShowBtnNewPost(false);
      return;
    }
    if (+levelURL > 3) {
      const responceIsAccessToNewPostBtn = await getAccessUserToCommunity(
        idCommunity || "0"
      );
      setIsShowBtnNewPost(responceIsAccessToNewPostBtn);
    } else {
      const isUserGlobal = await isUserGlobalAdmin();
      if (isUserGlobal) {
        setIsShowBtnNewPost(isUserGlobal);
      } else {
        const userRole = window.localStorage.getItem(ROLE);
        const isShowBtnTmp = country === userRole ? true : false;
        setIsShowBtnNewPost(isShowBtnTmp);
      }
    }

  };

  const URL_COMMUNITY_ID_UPDATE_NEW = `/community/${idCommunity}/update/new`;
  return (
    <section className={ isCommunity ? 'section-news-wall':' section-news-wall browse'}>
      <div className="new-post-in-wall-block">
        {isShowBtnNewPost && (
          <div
            className="new-post-in-wall-btn"
            onClick={() => history.push(URL_COMMUNITY_ID_UPDATE_NEW)}
          >
            {t("newPost")}
          </div>
        )}
      </div>
      {news.map((item, index) => {
        return <News {...item} key={index} isCommunity={isCommunity} />;
      })}
      {news.length <= 0 && (
        <div className="news-wall-no-news-center">{noNews}</div>
      )}
      {isShowBtnLoadOlderUpdates && (
        <div className="loader-older-updates-in-wall-block">
          <div
            className="loader-older-updates-in-wall-btn"
            onClick={loadOlderUpdates}
          >
            {t("loadOlderUpdates")}
          </div>
        </div>
      )}
    </section>
  );
};

export default NewsWall;
