import API from "../api/api";
import { TOKEN } from "../reducers/userReducer";
export const getUsersListAdmin = async () => {
    const url = `/admin-users`;
    return await API.get(url, {
      headers: {
        Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      },
    });
  };

  export const getUsersList = async () => {
    const url = `/users`;
    return await API.get(url, {
      headers: {
        Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      },
    });
  };

  export const getUsersRightList = async () => {
    const url = `/admin-scopes`;
    return await API.get(url, {
      headers: {
        Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      },
    });
  };

  export const addNewUser = async (body: any) => {
    const url = `/account/new_user`;
  
    return await API.post(url, body, {
      headers: {
        Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
        "Content-Type": 'application/json',
      },
    });
  };

  export const getUser = async (id: string) => {
    const url = `/user/${id}`;
    return await API.get(url, {
      headers: {
        Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      },
    });
  };

  export const updateUser = async (id: string, body: any) => {
    const url = `/user/${id}`;
    return await API.put(url, body, {
      headers: {
        Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
      },
    });
  };

  export const deleteUserId = async (id: string) => {
    const url = `/account/delete_user/${id}`;
    return await API.get(url, {
      headers: {
        Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
        "Content-Type": 'application/json',
      },
    });
  };

  export const resetPassword = async (body: any) => {
    const url = `/account/reset_password/set`;
  
    return await API.put(url, body, {
      headers: {
        Authorization: `Token ${window.localStorage.getItem(TOKEN)}`,
        "Content-Type": 'application/json',
      },
    });
  };