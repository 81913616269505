import React, {useEffect, useState} from "react";
import TitlePageTextTheme from "../../../components/titlePageTextTheme/titlePageTextTheme";
import "./CommunityPage.css";
import africaImage from "../../../assets/images/africa-tmp2.jpg";
import CommunityPost from "../../../components/communityBlock/CommunityPost";
import ShallPost from "../../../components/shallPost/ShallPost";
import SimpleListOfLink from "../../../components/simpleListOfLink/SimpleListOfLink";
import {useTranslation} from "react-i18next";
import {useParams, useHistory} from "react-router-dom";
import {middlewareGetCommunityUpdatePost} from "../../../services/middleware/MiddlewareCommunity";
import GoBack from "../../../components/goBack/GoBack";
import { useDispatch } from "react-redux";
import {setHeaderActiveTab} from "../../../reducers/headerReducer";
import {useIonViewWillEnter} from "@ionic/react";

const post = {
    date: "2 Days Ago",
    image: africaImage,
    isEditPost: true,
    attendees: [
        "Conn Barrett",
        "Éanna MacGilfoyle",
        "Reece O'Gara",
        "Eric Kinsella",
        "Mark O'Boland",
    ],
    files: [
        {
            name: "File download name",
            url: "File download name",
        },
    ],
    text: (
        <>
            <div>
                Ultrices dui sapien eget mi proin sed libero enim sed. Ut pharetra sit
                amet aliquam id diam. Ac tortor dignissim convallis aenean et tortor at
                risus. Sed vulputate mi sit amet mauris commodo quis imperdiet. At
                volutpat diam ut venenatis tellus in metus.
            </div>
            <div>
                <br/>
                <div>
                    Nisi scelerisque eu ultrices vitae auctor. Pellentesque elit
                    ullamcorper dignissim cras tincidunt lobortis feugiat vivamus. At
                    lectus urna duis convallis convallis tellus id interdum velit. Sem
                    fringilla ut morbi tincidunt augue interdum velit euismod in.
                </div>
                <br/>
            </div>
            <div>
                Nisl tincidunt eget nullam non nisi. Aliquet enim tortor at auctor.
                Sapien et ligula ullamcorper malesuada proin. At risus viverra
                adipiscing at in tellus integer feugiat scelerisque.
            </div>
        </>
    ),
};

const CommunityPage: React.FC = () => {
    const {t} = useTranslation("common");
    const {idCommunity, idUpdate} =
        useParams<{ idCommunity: string; idUpdate: string }>();
    const history = useHistory();
    const [pageContent, setPageContent] = useState<any>({});
    const dispatch = useDispatch();

    useIonViewWillEnter(() => {
        dispatch(setHeaderActiveTab(1))
    }, []);

    useEffect(() => {
        getCommunityUpdate();
    }, []);

    const getCommunityUpdate = async () => {
        const responce = await middlewareGetCommunityUpdatePost(
            idCommunity,
            idUpdate
        );

        if (responce.isError) {
            history.goBack();
        }

        let newImage = null;
        if (responce.photos) {
            if (responce.photos.length > 0) {
                newImage = responce.photos[0].url;
            }
        }
        const newPageContent = {
            date: responce.date,
            content: responce.content,
            photos: responce.photos,
            files: responce.community_gathering && responce.community_gathering.files,
            attendeeText:
                responce.community_gathering && responce.community_gathering.attendees,
            image: newImage,
            text: <div>{responce.content}</div>,
            title: responce.title
        };

        setPageContent({...newPageContent});

        // date?: string;
        // content?: string;
        // photos?: PhotoT[];
        // files?: FileT;
        // attendees?: string;
        // image?: string;
    };

    const listOfLinks = {
        title: t("previousEntries"),
        links: [
            {
                text: "Photos from our factory protest",
                url: "/",
            },
            {
                text: "We’ve been featured in the local newspaper",
                url: "/",
            },
        ],
    };

    const getImageAndIndexByUrl = (url: string) => {
        const {photos} = pageContent;
        const findImage = photos.find((item: any) => item.url === url);
        const findIndexImage = photos.findIndex((item: any) => item.url === url);
        return {
            image: findImage,
            index: findIndexImage,
        };
    };

    const goNextPhoto = (urlImage: string) => {
        const {photos} = pageContent;
        const imageObject = getImageAndIndexByUrl(urlImage);
        let newUrlimage = imageObject.image.url;
        if (imageObject.index + 1 >= photos.length) {
            newUrlimage = photos[0].url;
        } else {
            newUrlimage = photos[imageObject.index + 1].url;
        }
        setPageContent({...pageContent, image: newUrlimage});
    };

    const goBackPhoto = (urlImge: string) => {
        const {photos} = pageContent;
        const imageObject = getImageAndIndexByUrl(urlImge);
        let newUrlimage = imageObject.image.url;
        if (imageObject.index - 1 <= 0) {
            newUrlimage = photos[photos.length - 1].url;
        } else {
            newUrlimage = photos[imageObject.index - 1].url;
        }
        setPageContent({...pageContent, image: newUrlimage});
    };

    const changeImage = (direct: number, imageUrl: string) => {
        if (direct < 0) {
            goBackPhoto(imageUrl);
        } else {
            goNextPhoto(imageUrl);
        }
    };

    return (

        <>
            <TitlePageTextTheme cssTitleScreenCommunity="community-sentiment-barchart-header">
                <>
                    <GoBack isGoBack/>
                    <div className="community-update-header-text">
                        {pageContent.title||''}
                    </div>
                </>
            </TitlePageTextTheme>
            <section className="align-section-community-page">
                <ShallPost cssSectionWall="community-page-css-section-wall">
                    <CommunityPost
                        {...pageContent}
                        idUpdate={idUpdate}
                        idCommunity={idCommunity}
                        changeImage={(direct, imageUrl) => changeImage(direct, imageUrl)}/>
                </ShallPost>
                <ShallPost cssSectionWall="simple-list-of-link-section-shall-post">
                    <SimpleListOfLink {...listOfLinks} />
                </ShallPost>
            </section>
        </>

    );
};

export default CommunityPage;
