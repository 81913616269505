import React from "react";

type Props = {
  children: React.ReactNode;
  cssSectionWall?: string;
};

// path to css in this file is a: ../newsWall/NewsWall.css
const ShallPost: React.FC<Props> = ({ children, cssSectionWall }) => {
  return (
    <section
      className={`section-news-wall ${cssSectionWall && cssSectionWall}`}
    >
      {children}
    </section>
  );
};

export default ShallPost;
