import React from "react";
import "./ListLinks.css";
import chevronRightImage from "../../assets/images/functional/chevron-right.svg";
import menuImage from "../../assets/images/functional/menu.svg";
import { v4 as uuidv4 } from "uuid";

type Props = {
  listLinks: any[];
  onClick?: (id: number) => void;
  titleCss?: string;
};

const ListLinks: React.FC<Props> = ({ listLinks, onClick, titleCss }) => {
  const onHandler = (id: number) => {
    if (onClick) onClick(id);
  };
  return (
    <>
      <section className="list-links-status-section">
        <div className="list-links-status-section-header-text"></div>
        {listLinks.length > 0 && (
          <>
            {listLinks.map((item, index) => {
              return (
                <div
                  className="list-status-block"
                  key={uuidv4()}
                  onClick={() => onHandler(index)}
                >
                  <div className="list-status-img-text">
                    {/* {item.img && (
                      <img
                        className="list-link-status-image"
                        src={item.img}
                        alt="pendingImage"
                      />
                    )} */}
                    <div
                      className={`${
                        !item.description &&
                        `align-text-status-list-header-text-link`
                      }`}
                    >
                      <div
                        className={`list-status-align-vertical list-status-text-normal ${
                          titleCss && titleCss
                        }`}
                      >
                        {item.name}
                      </div>
                      {item.description && (
                        <div className="list-status-description-text">
                          {item.description}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="list-status-align-vertical">
                    <img
                      className="list-status-chevron-right-image"
                      src={chevronRightImage}
                      alt="chevronRightImage"
                    />
                  </div>
                </div>
              );
            })}
          </>
        )}
      </section>
    </>
  );
};

export default ListLinks;
