import React from "react";
import "./SmallHeader.css";
import { useHistory } from "react-router-dom";
import arrowGreenLeftImage from "../../assets/images/arrow-green-left.png";
import GoBack from "../../components/goBack/GoBack";
import { useTranslation } from "react-i18next";

type Props = {
  isGoBack?: boolean;
  leftText?: string;
  rightText?: string;
  rightLink?: string;
  buttonText?: string;
};

const SmallHeader: React.FC<Props> = ({
  isGoBack = true,
  leftText = "Exit",
  rightText,
  rightLink,
  buttonText,
}) => {
  const history = useHistory();
  const { t } = useTranslation("common");

  //   const leftBtn = () => {
  //     if(isGoBack) history.goBack();
  //     else history.push('/');
  //   }

  const rightBtn = () => {
    history.push(rightLink || "/");
  };

  return (
    <>
      <section className="small-header-section">
        {/* <a href="/" className="small-header-exit-btn">
          <img
            className="arrow-left-green-image-in-small-header"
            src={arrowGreenLeftImage}
            alt="arrowGreenLeftImage"
          />
          Exit
        </a> */}
        <GoBack
          isGoBack={isGoBack}
          text={buttonText ? buttonText : t("exit")}
        />

        {/* <div className="small-header-exit-btn" onClick={leftBtn}>
                <img className="arrow-left-green-image-in-small-header" src={arrowGreenLeftImage} alt="arrowGreenLeftImage" />
                {leftText}
            </div> */}
        <div className="small-header-right-block" onClick={rightBtn}>
          {rightText}
        </div>
      </section>
    </>
  );
};

export default SmallHeader;
