import _ from 'lodash';

const findAvg = function(arrVals: any) {
    const sum = arrVals.reduce((a: number, b: number) => a + b, 0);
    const avg: number = (sum / arrVals.length) || 0;

    return Math.round(avg);
}

const recalculateTrends = (data: any) => {
    const trends = data;

    const dates: any[] = _.sortBy(_.uniq(_.flatten(trends.map((el: any) => el.dates))));

    const datesObj:any = {};

    dates.map((date, index) => {
        const prevDate = datesObj[dates[index - 1]] || {};

        datesObj[date] = {};
        const filtered_trend_dates = trends.filter((trend: any) => trend.dates.includes(date));


        filtered_trend_dates.forEach((filtered_trend_date: any) => {
            const dateIndex =  filtered_trend_date.dates.findIndex((stDate: string) => stDate === date);

            const value = filtered_trend_date.trend[dateIndex];

            datesObj[date][filtered_trend_date.id] = value;
        });

        for (const stId of Object.keys(prevDate)) {
            if (!(stId in datesObj[date])) {
                datesObj[date][stId] = prevDate[stId];
            }
        }

    });

    return Object.values(datesObj).map((el: any) => findAvg(Object.values(el)));
}

const makeDirection = (trends: any) => {
    let trend_direction: {} = { Good: 'Good' };

    if (trends.length > 2) {
        trend_direction = trends[trends.length - 1] >= trends[trends.length - 2] ? { Improving: 'Improving' } : { Declining: 'Declining' };
    }
    if (trends.length === 1) {
        trend_direction = trends[trends.length - 1] >= 50 ? { Good: 'Good' } : { Bad: 'Bad' };
    }

    return trend_direction;
}

const LEVELS = [17, 42, 58, 83];

const LEVELS_NAMES = ['Very Bad', 'Bad', 'Ok', 'Good', 'Very Good'];
const makeTrendState = (trends: any) => {


    return trends.map((trend: any) => {
        const levels = LEVELS.filter(level => trend >= level);
        const levelIndex = levels.length - 1 < 0 ? levels.length : levels.length - 1;

        const levelName = LEVELS_NAMES[levelIndex];

        return { [levelName]: levelName };
    });

}
const resultsMaker = (data: any) => {
    const trend = recalculateTrends(data);
    const trends_state = makeTrendState(trend);

    return {
        trend_direction: makeDirection(trend),
        rate: trend[trend.length - 1] || 0,
        trend,
        trends_state,
        state: trends_state[trends_state.length - 1]
    };
}

export default resultsMaker;