import React from "react";
import view_data from "./view_data"
import "./content.css";
import CommunitySentiment from "./CommunitySentiment";
import ActionPlan from "./ActionPlan";
import HeaderCharts from "./HeaderCharts";
import {StandartsComplience} from "./StandartsComplience";
import {getDataHomePage, getUpdatesHomePage} from '../../services/Landing';
import { withTranslation, WithTranslation } from "react-i18next";
import TabComponent from "../tabsControl/TabsControl";
import NewsWall from "../newsWall/NewsWall";
import {t} from "i18next";

type SectionChart = {
  section_name: string;
  type_chart: number /*1-круговая,2-столбчатая*/;
  community_sentiment: {
    badly: number;
    unsatisfactory: number;
    satisfactory: number;
    good: number;
    excelent: number;
  } /* для столбиков*/;
  level: number /*% - for circle*/;
  commitments: number;
  state_santiments: {
    evaluation: any;
    linear_points: number[];
  };
};
type ViewChart = {
  country: string;
  section_charts: SectionChart[];
};
type ChartContentProps = {
  updateHome: boolean
}

type ChartContentState = {
  charts_blocks:JSX.Element[]|null,
  updates_block:JSX.Element[]|null,
  activeTab: string,
  page:number,
  news:any[]
};

class ChartContent extends React.Component<WithTranslation & ChartContentProps, ChartContentState> {
  arrayViewChart:any;
  updateHome: boolean
  tabs:string[];
  page:number;
  pages:number;
  pageLen:number

  constructor(props: any) {

    super(props);
    this.arrayViewChart = view_data;
    this.updateHome = false;
    this.page = 1
    this.pages = 0;
    this.pageLen =10
    this.tabs = ["standards","updates"];
    this.state = {charts_blocks: null, updates_block: null, activeTab:"standards", page:1, news:[] };
    this.isEmpty = this.isEmpty.bind(this);
    this.getDataAPI = this.getDataAPI.bind(this);
    this.fillDataView = this.fillDataView.bind(this);
    this.fillUpdates = this.fillUpdates.bind(this);
    this.fillUpdatesData = this.fillUpdatesData.bind(this);
    this.getCountrySections = this.getCountrySections.bind(this);
    this.fillSection = this.fillSection.bind(this);
    this.findSection = this.findSection.bind(this);
    this.copyDataFromAPI = this.copyDataFromAPI.bind(this);
    this.fillBlockCharts = this.fillBlockCharts.bind(this);
    this.getMaxValue = this.getMaxValue.bind(this);
    this.setActiveTab = this.setActiveTab.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
  }

  componentDidMount() {
     this.getDataAPI();
  }

  componentDidUpdate(
    prevProps: ChartContentProps,
    prevState: ChartContentState
  ): void {

    if (prevProps.updateHome === false)
        this.updateHome = false;
    if (prevProps.updateHome && !this.updateHome) {
        this.updateHome = true;
        this.setState({charts_blocks: null });
        this.getDataAPI();
    }
    if(prevState.page !== this.page){
      this.getDataAPI();
    }
  }

  async getDataAPI() {
      try {
        const { data } = await getDataHomePage();
        const  updatesData = await getUpdatesHomePage(this.state.page)
        this.fillDataView(data);
        this.fillBlockCharts();
        this.fillUpdatesData(updatesData)
        this.fillUpdates()

      } catch (error: any) {
        console.log(error);
      }
  }

  fillUpdatesData({data}:any){
    const updatedNews = data.data.map((item: any) => {
      return{
        ...item,
        img: item.photos[0] && item.photos[0].url,
        text: item.content,
      }
    })

    this.setState({news:[...this.state.news, ...updatedNews]})
    this.pages = data.pages
  }

  fillDataView(dataAPI:any) {
      var obj_charts:any[];

      for (let key in dataAPI) {
        obj_charts = this.getCountrySections(key);
        if (obj_charts.length != 0) {

          this.fillSection(dataAPI[key],obj_charts);
        }
      }
  }

  fillSection(sectionsAPI:any,fill_sections:any[]) {
      var section:any;
      var j:number;

      for (let key in sectionsAPI) {
        var arrayWords:string[];

        j = fill_sections.findIndex((item)=>{
          arrayWords = item.section_name.trim().split(" ");

          return this.findSection(arrayWords,key);
        });
        if (j != -1)
          this.copyDataFromAPI(sectionsAPI[key],fill_sections[j]);
      }
  }

  copyDataFromAPI(sectionAPI:any,sectionDest:any) {
      for (let key in sectionAPI) {

        if (key == 'rate' && sectionAPI[key] != null) {//круговая - проценты

          sectionDest.level = sectionAPI[key];
        }
        if (key == 'trend' && sectionAPI[key].length !=0) {//линейная диграмма- массив точек в процентах

          sectionDest.state_santiments.linear_points = sectionAPI[key];
        }
        if (key == 'trend_direction' && sectionAPI[key] != null) {//уровень прогресса

          sectionDest.state_santiments.evaluation = sectionAPI[key];
        }
        if (key == 'rate_all' && sectionAPI[key].length !=0) {//массив для столбиков - проценты
          var array_sentimets:number[] = sectionAPI[key];
          var max_value:number = this.getMaxValue (sectionAPI[key]);
          sectionDest.rate_all = array_sentimets
          for (var i = 0; i <= array_sentimets.length-1; i++) {
            switch(i) {
              case 0:
                sectionDest.community_sentiment.badly = (array_sentimets[i]*100)/max_value;
              break;
              case 1:
                sectionDest.community_sentiment.unsatisfactory = (array_sentimets[i]*100)/max_value;
              break;
              case 2:
                sectionDest.community_sentiment.satisfactory = (array_sentimets[i]*100)/max_value;
              break;
              case 3:
                sectionDest.community_sentiment.good = (array_sentimets[i]*100)/max_value;
              break;
              case 4:
                sectionDest.community_sentiment.excelent = (array_sentimets[i]*100)/max_value;
              break;
            }
          }
        }
      }
  }

  getMaxValue(arrayValue:number[]):number {
    var value:number = 0;

    arrayValue.forEach((item)=> {
      if (item > value)
        value = item;
    });
    return value;
  }

  findSection(arrayStr:string[],name_sectionAPI:string):boolean {

      var result:string|undefined = arrayStr.find((item)=> {
        return name_sectionAPI.toLowerCase().indexOf(item.toLowerCase())!=-1;
      });
      if (typeof result != 'undefined')
        return true;
      return false;
  }

  getCountrySections(key:string):any[] {
      var j:number = this.arrayViewChart.findIndex((item:any)=> {
        return item.country===key;
      });
      if (j!=-1) {
        return this.arrayViewChart[j].section_charts;
      }
      return [];
  }

  isEmpty(obj: any) {
    for (let key in obj) {
      return false;
    }
    return true;
  }

  fillBlockCharts() {
    const { t } = this.props;
      const items_block:JSX.Element[] = this.arrayViewChart.map((block:any,index:number) => {
        var count_no_data_section:number = 0;

        const items_section:any[] = block.section_charts.map((section:any,index:number) => {
          if (section.section_name==='Standards Compliance') {
            return (<StandartsComplience key={index} country={block.country} progress={section.level} state_santiments={section.state_santiments}>
                      <span>{t("standardsCompliance")}</span>
                    </StandartsComplience>);
          }
          if (section.section_name==='Community Sentiment') {
            var isData:boolean;
            var no_data:string = "";

            if (this.isEmpty(section.community_sentiment)) {

              no_data = t("noPerformanceMeasuresYetCreated");
              isData = false;//данных нет , вывод укороченный
            } else {

              isData = true;
            }

            if (!isData)
              ++count_no_data_section;

            return (<CommunitySentiment key={index}
                                        isData={isData}
                                        sentiments={section.community_sentiment}
                                        country={block.country}
                                        rate_all={section.rate_all}
                                        state_santiments={section.state_santiments}
                                        description={no_data}>
                       <div>{t("communitySentiment")}</div>
                    </CommunitySentiment>);
          }
          if (section.section_name==='Action Plan Progress') {
            var isData:boolean;
            var no_data:string = "";

            if (!section.level) {
              if (!section.commitments) {
                isData = false;//данных нет , вывод укороченный
                no_data = t("noCommitmentsCreated");
                ++count_no_data_section;
              }
              else
                isData = true;
            } else
              isData = true;

            return (<ActionPlan commitments={section.commitments} key={index} isData={isData} country={block.country}
                      progress={section.level} state_santiments={section.state_santiments} description={no_data}>
                      <div>{t("actionPlanProgress")}</div>
                    </ActionPlan>);

          }
        });
        //block_of_charts1,subblock_of_charts1-1 секция без данных
        //block_of_charts1,subblock_of_charts1-2 секции без данных
        var str_no_data:string = '';
        if (count_no_data_section)
          str_no_data += count_no_data_section;

        return(
          <div className={"charts_block1"+str_no_data} key={index}>
            <HeaderCharts country_name={block.country} link={block.link}/>
            <div className={"block_of_charts"+str_no_data}>
              <div className={"subblock_of_charts"+str_no_data}>
              {items_section}
              </div>
            </div>
          </div>
        );

      });
      this.setState ({charts_blocks:items_block});
  }

  fillUpdates(){
    const updates_block:JSX.Element[] =[this.state.news].map((data) => {
      return (
          <div className="browse-container">
            <NewsWall
                levelURL={'0'}
                noNews={t('noNews')}
                news={data}
                isShowBtnLoadOlderUpdates = {this.state.page < this.pages }
                loadOlderUpdates = {this.setCurrentPage}
            />
          </div>

      )
    })

    this.setState ({updates_block});
  }

  setActiveTab(value:string){
    this.setState({activeTab:value});
  }

  setCurrentPage(){

    const prevPage = this.state.page
    if(prevPage<=this.pages){
      this.page = prevPage + 1
      this.setState({page:prevPage+1})
    }

  }

  render() {
    return(
        <div className="container_control_chart_block">
          {/*<ChartControls handler={this.buttonHandler} />*/}
          <TabComponent tabs={this.tabs} activeTab={this.state.activeTab} setActiveTab={this.setActiveTab} theme={'blue'}/>
            { this.state.activeTab === 'updates' &&
                this.state.updates_block
            }
            { this.state.activeTab === 'standards'&&
                this.state.charts_blocks
            }
        </div>
    );
  }
}

const decoratedComponent = withTranslation("common")(ChartContent);
export { decoratedComponent as ChartContent };
