import React from "react";
import "./CustomCheckbox.css";

type Props = {
  onChange: (change: boolean) => void;
  textAfter?: string;
  id?: string;
  checked?: boolean;
  cssContainerProps?: string;
};

const CustomCheckbox: React.FC<Props> = ({
  onChange,
  textAfter,
  id,
  checked = false,
  cssContainerProps,
}) => {
  return (
    <>
      <div
        className={`cusom-input-props ${
          cssContainerProps && cssContainerProps
        }`}
      >
        <input
          id={id}
          type="checkbox"
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
        />
        <label htmlFor={id}>
          <div className="checkbox-community-text">{textAfter}</div>
        </label>
      </div>
    </>
  );
};

export default CustomCheckbox;
