import React, { useEffect } from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  onHandlerOpen: (eOpen: boolean) => void;
  contentLabel?: string;
};

const ModalWindow: React.FC<Props> = ({
  children,
  isOpen,
  onHandlerOpen,
  contentLabel = "",
}) => {
  useEffect(() => {
    Modal.setAppElement("body");
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onHandlerOpen(false)}
      style={customStyles}
      contentLabel={contentLabel}
    >
      {children}
    </Modal>
  );
};

export default ModalWindow;
