import React from "react";
import "./BarchartCanvas.css";
import Chart from "react-apexcharts";

// https://apexcharts.com/docs/react-charts/
// https://apexcharts.com/react-chart-demos/column-charts/stacked/

// Props
// props.series - array object items
// props.isShowDataLabels - is show number inside bar
// props.xaxis - {
//   type: "text",
//   categories: ["Very bad", "Bad", "Okay", "Good", "Very good"],
// }
// props.width  - width barchart - default - "500"

class BarchartCanvas extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: props.series,
      //   series: [
      //     {
      //       name: "Boys",
      //       data: [44, 55, 41, 67, 22],
      //       //   color: "#008000",
      //     },
      //     {
      //       name: "Girls",
      //       data: [13, 23, 20, 8, 13],
      //     },
      //     {
      //       name: "Man",
      //       data: [11, 17, 15, 15, 21],
      //     },
      //     {
      //       name: "Woman",
      //       data: [21, 7, 25, 13, 22],
      //     },
      //   ],
      options: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: props.isShowDataLabels ? props.isShowDataLabels : false,
        },
        // responsive: [
        //   {
        //     breakpoint: 480,
        //     options: {
        //       legend: {
        //         position: "bottom",
        //         offsetX: -10,
        //         offsetY: 0,
        //       },
        //     },
        //   },
        // ],
        // plotOptions: {
        //   bar: {
        //     horizontal: false,
        //     borderRadius: 10,
        //   },
        // },
        xaxis: {
          type: "text",
          categories: props.xaxisCategories ? props.xaxisCategories : [],
        },
        legend: {
          show: props.isShowlegend ? props.isShowlegend : false,
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
      },
    };
  }

  render() {
    return (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={this.state.options}
              series={this.props.series}
              type="bar"
              width={this.props.width || "500"}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default BarchartCanvas;
