import React from "react";
import {RouteComponentProps} from "react-router-dom";
import {IonContent, IonPage, IonButton, IonIcon} from "@ionic/react";
import {chevronBackOutline, chevronForwardOutline} from "ionicons/icons";
import ButtonResource from "../../components/content/ButtonResource";
import {THEMES} from "../../mocks/layout";
import Header from "../../components/header/Header";
import {withTranslation, WithTranslation} from "react-i18next";
import GoBack from "../../components/goBack/GoBack";
import {
    getUsersListAdmin,
    getUsersList
} from "../../services/users";
import Footer from "../../components/footer/Footer";
import {
    isUserGlobalIrelandTanzaniaAdmin,
    isUserGlobalAdmin,
    isUserGlobalIrelandAdmin,
    isUserGlobalTanzaniaAdmin
} from "../../helpers/functions";
import "../resource.css";
import "../Community/CommunityManage/CommunityManage";
import "../ActionPlan/ListActionPlans.css";
import "./UserManagement.css"

const THEME_PAGE = THEMES.BLUE;

type UserManagementProps = {};

type UserManagementState = {
    usersList: JSX.Element[] | null
};

type AdminScope = {
    name: string,
    slug: string,
    _global: boolean
};

type AdminAccount = {
    admin_scope: AdminScope,
    email: string,
    first_name: string,
    last_name: string,
    url: string
};

export type UserAccount = {
    admin_scope: string | null,
    community: number | null,
    email: string,
    first_name: string,
    last_name: string,
    url: string
};

class UserManagement extends React.Component<UserManagementProps & WithTranslation & RouteComponentProps<{}>,
    UserManagementState> {
    adminUsers: AdminAccount[];
    allUsers: UserAccount[];
    globalAdmin: boolean;
    levelCountrysAdmin: boolean;
    adminTanzania: boolean;
    adminIreland: boolean;

    constructor(props: any) {
        super(props);

        this.state = {
            usersList: null
        };
        this.adminUsers = [];
        this.allUsers = [];
        this.globalAdmin = false;
        this.levelCountrysAdmin = false;
        this.adminTanzania = false;
        this.adminIreland = false;
        this.addNewUser = this.addNewUser.bind(this);
        this.getUsersAdmin = this.getUsersAdmin.bind(this);
        this.createUsersList = this.createUsersList.bind(this);
        this.createAllUsersList = this.createAllUsersList.bind(this);
    }

    async componentDidMount() {
        this.props.history.listen((location: any, action: any) => {
            if (location.pathname.indexOf('/account/UserManagement') !== -1 && action === 'POP')
                this.getUsers();
        });

        if (isUserGlobalAdmin())
            this.globalAdmin = true;
        else if (isUserGlobalIrelandTanzaniaAdmin())
            this.levelCountrysAdmin = true;
        else if (isUserGlobalIrelandAdmin())
            this.adminIreland = true;
        else if (isUserGlobalTanzaniaAdmin())
            this.adminTanzania = true;
        try {
            //    await this.getUsersAdmin();
            await this.getUsers();
        }
        catch (error: any) {
            console.log(`error status: ${error}`);
        }
    }

    async getUsersAdmin() {
        try {
            const {data} = await getUsersListAdmin();
            console.log("admins", data);
            this.createUsersList(data);
        }
        catch (error: any) {
            console.log(`getUsers error status: ${error}`);
        }
    }

    async getUsers() {
        try {
            const {data} = await getUsersList();
            console.log("users", data);
            this.createAllUsersList(data);
        }
        catch (error: any) {
            console.log(`getUsersList error status: ${error}`);
        }
    }

    backToResources() {
        this.props.history.goBack();
    }

    selectUser(id: string) {

        this.props.history.push(`/UserManagement/resetuser/${id}`);
    }

    createAllUsersList(arrayUsers: UserAccount[]) {
        this.allUsers = [];
        let arrayList: JSX.Element[] = [];
        arrayUsers.forEach((item, index) => {
            if (this.globalAdmin /*||
         (this.levelCountrysAdmin &&
        ((item.admin_scope !== null && item.admin_scope.toLowerCase() === "tanzania") ||
         (item.admin_scope !== null && item.admin_scope.toLowerCase() === "ireland"))) ||
         (this.adminIreland &&
         (item.admin_scope !== null && item.admin_scope.toLowerCase() === "ireland")) ||
         (this.adminTanzania &&
         (item.admin_scope !== null && item.admin_scope.toLowerCase() === "tanzania"))*/
            ) {
                this.allUsers.push(item);
                let id_user: string = item.url.slice(item.url.lastIndexOf("/") + 1);
                arrayList.push(
                    <div key={index}>
                        {
                            index ? (
                                    <div className="community-gathering-compliance-line"/>
                                ) :
                                null
                        }
                        <div
                            className="list-action-plans-block-standard-link"
                            onClick={() => this.selectUser(id_user)}
                        >
                            <div className="list-action-plans-content-link">
                                <div className="list-action-plans-content-link-row-standard
                                usermanagement-user-name">
                                    {item.first_name}
                                    {" "}
                                    {item.last_name}
                                </div>
                                <div className="list-action-plans-content-link-row-date
                                usermanagement-user-adminscope">
                                    {item.admin_scope ? item.admin_scope : "basic user"}
                                </div>
                            </div>
                            <div className="list-action-plans-block-forward-icon">
                                <IonIcon icon={chevronForwardOutline}/>
                            </div>
                        </div>
                    </div>
                );
            }
        });
        this.setState({usersList: arrayList});
    }

    createUsersList(arrayUsers: AdminAccount[]) {
        this.adminUsers = [];
        let arrayList: JSX.Element[] = [];
        arrayUsers.forEach((item, index) => {
            if (this.globalAdmin /* ||
         (this.levelCountrysAdmin && ((item.admin_scope.slug.toLocaleLowerCase() === "tanzania") ||
         (item.admin_scope.slug.toLocaleLowerCase() === "ireland"))) ||
         (this.adminIreland && item.admin_scope.slug.toLocaleLowerCase() === "ireland") ||
         (this.adminTanzania && item.admin_scope.slug.toLocaleLowerCase() === "tanzania")*/
            ) {
                this.adminUsers.push(item);
                let id_user: string = item.url.slice(item.url.lastIndexOf("/") + 1);
                arrayList.push(
                    <div key={index}>
                        {
                            index ? (
                                    <div className="community-gathering-compliance-line"/>
                                ) :
                                null
                        }
                        <div
                            className="list-action-plans-block-standard-link"
                            onClick={() => this.selectUser(id_user)}
                        >
                            <div className="list-action-plans-content-link">
                                <div className="list-action-plans-content-link-row-standard
                                    usermanagement-user-name">
                                    {item.first_name}
                                    {" "}
                                    {item.last_name}
                                </div>
                                <div className="list-action-plans-content-link-row-date
                                    usermanagement-user-adminscope">
                                    {item.admin_scope.name}
                                </div>
                            </div>
                            <div className="list-action-plans-block-forward-icon">
                                <IonIcon icon={chevronForwardOutline}/>
                            </div>
                        </div>
                    </div>
                );
            }
        });
        this.setState({usersList: arrayList});
    }

    addNewUser() {
        this.props.history.push("/account/new_user");
    }

    render() {
        const {t} = this.props;

        return (
            <>
                <div className="community-management-title-wrap">
                    <div className="community-management-title-wrap-content">
                        <div className="display-flex">
                            <GoBack isGoBack text={t("userManagement")}/>
                        </div>
                        <div className="community-management-title-wrap-content-name">
                            {t("youth")}
                        </div>
                    </div>
                </div>
                <div className="wrap-content-resource">
                    <div className="content-resource community-management-content-resource">
                        <div className='description_resource community-management-content-description'>
                            {t("allAccounts")}
                        </div>
                        <div className="content-resource-summary community-management-content-summary">
                            <div className="content-resource-summary-detail">
                                <div className="content-resource-summary-detail-subcontainer">

                                    {this.state.usersList}
                                    <div className="community-management-content-line"/>
                                    <div className="community-management-content-wrap-row
                                      community-management-content-button-save-row
                                      usermanagement-content-button-add-user-row"
                                    >
                                        <ButtonResource
                                            onClickHandler={this.addNewUser}
                                            text_color="rgb(107, 114, 128)"
                                            id="user-management-conten-button-add-user"
                                        >
                                            {t("addNewUser")}
                                        </ButtonResource>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        );
    }
}

const decoratedComponent = withTranslation("common")(UserManagement);
export {decoratedComponent as UserManagement};
