import React from "react";
import "./chart.css";

type MakeChartProps = {
  setOption: {
    type: number /*1-circle,2-столбик*/;
    colorInRing?: string;
    colorRing?: string;
    colorProgress?: string;
    widthRing?: number;
    percent?: number;
    fontSizeText?: string;
    colorText?: string;
    optionSentiment?: {
      barWidth: number;
      community_sentiment: {
        excelent?: number;
        good?: number;
        satisfactory?: number;
        unsatisfactory?: number;
        badly?: number;
      };
      colorBar?: string;
      backColor?: string;
      backgroundColorSvg?: string;
    };
  };
};
type MakeChartState = {
  showChart: boolean;
  barElements: any[];
  ringElements: any[];
};
class MakeChart extends React.Component<MakeChartProps, MakeChartState> {
  style_ring: any;
  style_percent: any;
  deg: number;
  colorProgress: any;
  text_percent: string;
  option: any;
  container: any;
  setChart: boolean;
  sentiments: any;
  loadDOM: boolean;

  constructor(props: any) {
    super(props);

    this.text_percent = "";
    this.style_percent = {};
    this.deg = 0;
    this.container = null;
    this.setChart = false;
    this.loadDOM = false;

    this.setOptions();

    this.setRing = this.setRing.bind(this);
    this.MakeBars = this.MakeBars.bind(this);
    this.checkDOM = this.checkDOM.bind(this);
    this.emodji = this.emodji.bind(this);
    this.onResize = this.onResize.bind(this);
  }

  setOptions(){
    const { setOption } = this.props;

    this.option = setOption;

    setOption.optionSentiment
        ? (this.sentiments = setOption.optionSentiment)
        : (this.sentiments = {});

    setOption.widthRing
        ? (this.style_ring = {
          border: setOption.widthRing * 2 + "px solid transparent",
          borderTop:
              setOption.widthRing * 2 + "px solid " + setOption.colorRing,
          borderLeft:
              setOption.widthRing * 2 + "px solid " + setOption.colorRing,
          borderRight:
              setOption.widthRing * 2 + "px solid " + setOption.colorRing,
        })
        : (this.style_ring = {});

    setOption.percent
        ? (this.deg = (270 * setOption.percent) / 100)
        : (this.deg = 0);

    setOption.colorProgress
        ? (this.colorProgress = {
          backgroundImage:
              "conic-gradient(" +
              setOption.colorProgress +
              " 0deg, " +
              setOption.colorProgress +
              " " +
              this.deg +
              "deg, rgba(0,0,0,0) " +
              this.deg +
              "deg)",
        })
        : (this.colorProgress = {});


    this.state = { showChart: false, barElements: [], ringElements: [] };
  }
  onResize(event:any) {

    this.checkDOM();

  }

  componentDidUpdate(
    prevProps: MakeChartProps,
    prevState: MakeChartState
  ): void {
    if (prevProps.setOption.type !== this.props.setOption.type) {
      this.setOptions();
      this.checkDOM();
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.onResize);
    this.checkDOM();
  }

  checkDOM() {
    if (this.container===null)
      return;
    if (!this.container.clientWidth)
      setTimeout(() => {
        this.checkDOM();
      }, 1000);
    else {
      if (this.option.type === 2 && this.sentiments) {
        this.MakeBars(
          this.sentiments.barWidth,
          this.sentiments.community_sentiment
        );
      }
      if (this.option.type === 1) {
        this.setRing();
      }
    }
  }

  MakeBars(width: number, sentiments: any /*,totalSentiments:number*/) {
    var arrayBars: JSX.Element[] = [];
    var index: number = 0;

    for (let key in sentiments) {
      var percent: number = sentiments[key];
      var emodji_wraper: JSX.Element = (
        <div
          className="emodji_wraper"
          style={{ height: width + "px", top: -width + "px" }}
        >
          {this.emodji(key)}
        </div>
      );
      var bar: JSX.Element = (
        <div
          className="progress_bar"
          style={{
            height: percent + "%",
            backgroundColor: this.sentiments.colorBar,
          }}
        >
          {emodji_wraper}
        </div>
      );
      var bar_element: JSX.Element = (
        <div
          className="bar_wraper"
          style={{ height: this.container.clientHeight - width + "px" }}
        >
          {bar}
        </div>
      );

      arrayBars.push(
        <div
          key={index++}
          className="bar_section"
          style={{ width: width + "px", height: "100%" }}
        >
          {bar_element}
        </div>
      );
    }
    this.setState({ barElements: arrayBars });
  }

  setRing() {
    var arrayElements: JSX.Element[] = [];

    if (this.option.fontSizeText && this.option.colorText) {
      this.style_percent = {
        fontSize: this.option.fontSizeText,
        color: this.option.colorText,
      };
      if (this.option.percent) {
        this.text_percent = this.option.percent + "%";
      } else {
        this.text_percent = "0%";
        this.option.colorRing
          ? (this.style_percent.color = this.option.colorRing)
          : (this.style_percent.color = "rgb(156, 163, 175)");
      }
    }

    arrayElements.push(
      <div key={0} className="circle" style={this.style_ring} />
    );
    arrayElements.push(
      <div key={1} className="percent" style={this.colorProgress} />
    );
    arrayElements.push(
      <div key={2} className="percent_txt" style={this.style_percent}>
        {this.text_percent}
      </div>
    );

    var styleElem = document.head.appendChild(document.createElement("style"));
    var width_container: number = this.container.clientWidth;

    var ring_props: string =
      ".percent::after { left:" +
      this.option.widthRing +
      "px;top:" +
      this.option.widthRing +
      "px;background-color:" +
      this.option.colorInRing +
      ";width:" +
      (width_container - this.option.widthRing * 2) +
      "px;height:" +
      (width_container - this.option.widthRing * 2) +
      "px;}";

    styleElem.insertAdjacentHTML("beforeend", ring_props);
    this.setState({ ringElements: arrayElements });
  }

  emodji(key: any): JSX.Element {
    var a: JSX.Element = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: this.sentiments.backgroundColorSvg,
        }}
        strokeWidth="1.5"
        viewBox="0 0 40 40"
        fill={this.sentiments.colorBar}
      >
        <defs></defs>
        <g>
          <rect style={{ width: "100%", height: "100%" }} fill="none" />
          <g>
            <path d="M20,36A16,16,0,1,1,36,20,16.01833,16.01833,0,0,1,20,36ZM20,6A14,14,0,1,0,34,20,14.01572,14.01572,0,0,0,20,6Z" />
            <path d="M20,30a7.15683,7.15683,0,0,1-6.98047-5.80371A1,1,0,0,1,14,23H26a1,1,0,0,1,.98047,1.19629A7.15683,7.15683,0,0,1,20,30Zm-4.59766-5a5.02982,5.02982,0,0,0,9.20411,0Z" />
            <circle cx="25.5" cy="17.5" r="1.5" />
            <circle cx="14.5" cy="17.5" r="1.5" />
          </g>
        </g>
      </svg>
    );
    var b: JSX.Element = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: this.sentiments.backgroundColorSvg,
        }}
        strokeWidth="1.5"
        viewBox="0 0 40 40"
        fill={this.sentiments.colorBar}
      >
        <defs></defs>
        <g>
          <rect
            style={{
              width: "100%",
              height: "100%",
            }}
            fill="none"
          />
          <g>
            <path d="M20,36A16,16,0,1,1,36,20,16.01833,16.01833,0,0,1,20,36ZM20,6A14,14,0,1,0,34,20,14.01572,14.01572,0,0,0,20,6Z" />
            <path d="M20,29a7.74227,7.74227,0,0,1-6.44287-3.44336,1.00025,1.00025,0,0,1,1.66211-1.11328,5.74818,5.74818,0,0,0,9.56152,0,1.00025,1.00025,0,0,1,1.66211,1.11328A7.74227,7.74227,0,0,1,20,29Z" />
            <circle cx="25.5" cy="17.5" r="1.5" />
            <circle cx="14.5" cy="17.5" r="1.5" />
          </g>
        </g>
      </svg>
    );
    var c: JSX.Element = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: this.sentiments.backgroundColorSvg,
        }}
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke={this.sentiments.colorBar}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <circle cx="12" cy="12" r="9" />
        <line x1="9" y1="10" x2="9.01" y2="10" />
        <line x1="15" y1="10" x2="15.01" y2="10" />
        <line x1="9" y1="15" x2="15" y2="15" />
      </svg>
    );
    var d: JSX.Element = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: this.sentiments.backgroundColorSvg,
        }}
        strokeWidth="1.5"
        viewBox="0 0 40 40"
        fill={this.sentiments.colorBar}
      >
        <defs></defs>
        <g>
          <rect
            style={{
              width: "100%",
              height: "100%",
            }}
            fill="none"
          />
          <g>
            <path d="M20,36A16,16,0,1,1,36,20,16.01833,16.01833,0,0,1,20,36ZM20,6A14,14,0,1,0,34,20,14.01572,14.01572,0,0,0,20,6Z" />
            <path d="M25.61279,29a.9985.9985,0,0,1-.832-.44336,5.74818,5.74818,0,0,0-9.56152,0,1.00025,1.00025,0,0,1-1.66211-1.11328,7.74931,7.74931,0,0,1,12.88574,0A1.00022,1.00022,0,0,1,25.61279,29Z" />
            <circle cx="25.5" cy="17.5" r="1.5" />
            <circle cx="14.5" cy="17.5" r="1.5" />
          </g>
        </g>
      </svg>
    );
    var e: JSX.Element = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: this.sentiments.backgroundColorSvg,
        }}
        strokeWidth="1.5"
        viewBox="0 0 40 40"
        fill={this.sentiments.colorBar}
      >
        <defs></defs>
        <g>
          <rect
            style={{
              width: "100%",
              height: "100%",
            }}
            fill="none"
          />
          <g>
            <path d="M20,36A16,16,0,1,1,36,20,16.01833,16.01833,0,0,1,20,36ZM20,6A14,14,0,1,0,34,20,14.01572,14.01572,0,0,0,20,6Z" />
            <path d="M26.332,21.44531a1.03785,1.03785,0,0,0-1.66406,0,15.74845,15.74845,0,0,0-1.9906,4.25659,5.48578,5.48578,0,0,0-7.24964,1.74146,1.00025,1.00025,0,0,0,1.66211,1.11328,3.49861,3.49861,0,0,1,5.82032,0,1.025,1.025,0,0,0,.0625.083l.34472.41406c.00421.00507.01074.00653.01508.01154.01684.01757.02869.03857.046.05584A3,3,0,0,0,28.5,27C28.5,24.76172,26.55371,21.77832,26.332,21.44531ZM26.20654,27.707a1.019,1.019,0,0,1-1.40234.007l-.0354-.0426A.98488.98488,0,0,1,24.5,27a7.702,7.702,0,0,1,1-3.0127A7.702,7.702,0,0,1,26.5,27,.98992.98992,0,0,1,26.20654,27.707Z" />
            <circle cx="25.5" cy="17.5" r="1.5" />
            <circle cx="14.5" cy="17.5" r="1.5" />
          </g>
        </g>
      </svg>
    );
    var emodji_objs: any = {
      excelent: a,
      good: b,
      satisfactory: c,
      unsatisfactory: d,
      badly: e,
    };
    return emodji_objs[key];
  }

  render() {
    if (this.option.type === 1) {
      return (
        <div
          className="containerChart"
          ref={(div) => {
            this.container = div as HTMLDivElement;
          }}
        >
          {this.state.ringElements}
        </div>
      );
    }
    if (this.option.type === 2 && this.sentiments) {
      var back_color: any = this.sentiments.backColor
        ? { backgroundColor: this.sentiments.backColor }
        : { backgroundColor: "white" };
      return (
        <div
          className="containerBar"
          style={back_color}
          ref={(div) => {
            this.container = div as HTMLDivElement;
          }}
        >
          {this.state.barElements}
        </div>
      );
    }
  }
}

export default MakeChart;

/*
		  		var totalSentiments:number = 0;
		  		for (let key in this.sentiments.community_sentiment) {
		  			totalSentiments += this.sentiments.community_sentiment[key];

	  		}*/
