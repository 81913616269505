import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import MapCommunity from "../mapCommunity/MapCommunity";
import GoBack from "../goBack/GoBack";
import {
  ResponceCommunityBrowseLevelIdT,
  ResponceChildrenT,
} from "../../types/types";
import "./CommunityDataHeader.css";
import Favorite from "../../components/favorite/Favorite";
import CustomDropDown from "../customDropDown/CustomDropDown";
import arrowRightImage from "../../assets/images/arrow-right.png";
import MakeChart from "../content/MakeChart";
import { useTranslation } from "react-i18next";
import LinearDiagram from "../content/LinearDiagram";
import { useHistory } from "react-router-dom";
import {
  middlewareGetSummaryDataBrowse,
  middlewareGetCommunityPhotos,
} from "../../services/middleware/MiddlewareCommunity";

import { usePageTree } from 'contexts/pageTreeContext';
import { useSelector, useDispatch } from "react-redux";
import {AppState, persistor} from "../../reducers";
import {
  setCommunityBrowseWithSub, setCommunityMyPageTree,
  setLevelAndId,
} from "../../reducers/communityReducer";
import { getObjectsValue } from "../../helpers/functions";
import { NavLink } from "react-router-dom";
import arrowGreenLeftImage from "../../assets/images/arrow-green-left.png";
import faceSorrowfulImage from "../../assets/images/indicators-color/face-sorrowful.svg";
import faceJubilantImage from "../../assets/images/indicators-color/face-jubilant.svg";
import facePositiveImage from "../../assets/images/indicators-color/face-positive.svg";
import faceNegativeImage from "../../assets/images/indicators-color/face-negative.svg";
import faceNeutralImage from "../../assets/images/indicators-color/face-neutral.svg";
import faceNoneGrayImage from "../../assets/images/indicators-color/face-none-gray.svg";
import {useIonViewWillEnter} from "@ionic/react";
import PieChartComponent from "../chartsComponents/pieChart";
import TinyBarChartComponent from "../chartsComponents/tinyBarChart";
import {setCommunity, setLogOut} from "../../reducers/userReducer";
import {middlewarPutAccountMe} from "../../services/middleware/MiddlewateUser";


const WHAT_TYPE_SHOW_BOOKMARK = ["Community"];
const MAX_LEVEL = 4;
const MIN_LEVEL = 0;
const TYPE_COMMUNITY = "Community";

let browseDateChart = [
  {
    name: "", // t("standartCompliance")
    noData: "",
    chart: {
      type: 1,
      colorInRing: "rgb(68, 180, 64)",
      colorRing: "silver",
      colorProgress: "white",
      widthRing: 25,
      percent: 0,
      fontSizeText: "24px",
      colorText: "white",
    },
    lineaDiagram: {
      evaluation: "Improving",
      linear_points: [
        { level: 10.2, control_point: false },
        { level: 90.1, control_point: false },
        { level: 60.4, control_point: true },
      ],
      line_color: "rgba(256, 256, 256, 0.5)",
      line_width: 2,
      point_color: "black",
      background: "transparent",
    },
  },
  {
    name: "", // t("communitySentiment")
    noData: "",
    chart: {
      type: 2,
      optionSentiment: {
        barWidth: 24,
        community_sentiment: {
          badly: 0,
          unsatisfactory: 0,
          satisfactory: 0,
          good: 0,
          excelent: 0,
        },
        commitments: 0,
        level: 0,
        type_chart: 2,
        section_name: "", // t("communitySentiment")
        backColor: "#3ea551",
        colorBar: "white",
        backgroundColorSvg: "#3ea551",
      },
      rate_all:[]
    },
    lineaDiagram: {
      evaluation: "Improving",
      linear_points: [
        { level: 10.2, control_point: false },
        { level: 90.1, control_point: false },
        { level: 60.4, control_point: true },
      ],
      line_color: "rgba(256, 256, 256, 0.5)",
      line_width: 2,
      point_color: "black",
      background: "transparent",
    },
  },
  {
    name: "", // t("actionPlanProgress")
    noData: "",
    chart: {
      type: 1 /*1-circle,2-столбик*/,
      colorInRing: "rgb(68, 180, 64)",
      colorRing: "silver",
      colorProgress: "white",
      widthRing: 25,
      percent: 0,
      fontSizeText: "24px",
      colorText: "white",
    },
    lineaDiagram: {
      evaluation: "Improving",
      linear_points: [
        { level: 10.2, control_point: false },
        { level: 90.1, control_point: false },
        { level: 60.4, control_point: true },
      ],
      line_color: "rgba(256, 256, 256, 0.5)",
      line_width: 2,
      point_color: "black",
      background: "transparent",
    },
  },
];

type Props = {
  levelURL: string;
  idURL: string;
  pageTreeProps?: ResponceCommunityBrowseLevelIdT;
  setNewsResponce: (updates: any) => void;
  refreshComponent?: boolean;
  isCommunityProps?: (isCommunity: boolean) => void;
  isGoBrowse?: boolean;
  setCountry?: (countyParams: string) => void;
  setActiveTab?:(tab:string) => void;
};

const CommunityDataHeader: React.FC<Props> = ({
  levelURL,
  idURL,
  setNewsResponce,
  refreshComponent = false,
  isCommunityProps,
  isGoBrowse = false,
  setCountry,
  setActiveTab,
}) => {
  const { t } = useTranslation("common");
  const { pageTree, fetchPageTree } = usePageTree();
  const userData: any = useSelector((state: AppState) => state.user);
  const { first_name, last_name, community: localCommunity } = userData;
  const history = useHistory();
  const [optionsDateOfGathering, setOptionsDateOfGathering] = useState([]);
  const dispatch = useDispatch();
  const [isBookMarkActive, setIsBookMarkActive] = useState(false);
  const [isChangeBranch, setIsChangeBranch] = useState(false);
  const [isShowBookmarkBlock, setIsShowBookmarkBlock] = useState(false);
  const [photoCommunityUrl, setPhotoCommunityUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const isConnected = useSelector((state:any) => state.offline.isConnected);
  useEffect(() => {
    getAllData(levelURL, idURL);
  }, [refreshComponent, levelURL, idURL]);
  useIonViewWillEnter(() => {
    getAllData(levelURL, idURL);
  }, [refreshComponent, levelURL, idURL]);
  useEffect( () => {
    async function processData () {
      // set photo
      const responcePhotos: any = await middlewareGetCommunityPhotos(
          pageTree.me.id + ''
      );
      if (responcePhotos&& responcePhotos.length) {
        setPhotoCommunityUrl(responcePhotos[0].url);
      } else {
        setPhotoCommunityUrl("");
      }
      const dropDownList: any = (pageTree.children as any).map((child: any) => ({
        label: child.name,
        value: child.level + "-" + child.id,
      }));
      setOptionsDateOfGathering(dropDownList);
    }

    if (!pageTree || !pageTree.me) {
      return;
    }
    isShowBookmark(pageTree.me.type);
    setBrowseDateChart(pageTree.data);
    setNewsFromResponce(pageTree.data.updates);

    processData().catch(console.error)

  }, [pageTree]);
  const getAllData = async (level: string, id: string) => {
    setIsLoading(true);
    checkIsMookmarkActive(level, id);
    dispatch(
      setLevelAndId({
        level: level,
        id: id,
      })
    );
    if (level && id) {
      await fetchPageTree(level, id);
    } else {
      history.goBack();
    }
    setIsLoading(false);
  };

  const setNewsFromResponce = (updates: any = []) => {
    const newsUpdates = updates.map((item: any) => {
      return {
        ...item,
        img: item.photos[0] && item.photos[0].url,
        text: item.content,
      };
    });
    setNewsResponce(newsUpdates);
  };

  const getNewTree = async (level: string, id: string) => {
    let responce;
    if (+level < MIN_LEVEL) history.goBack();
    else if (+level >= MIN_LEVEL && +level <= MAX_LEVEL) {
      responce = await middlewareGetSummaryDataBrowse(level, id);
    } else if (+level === MAX_LEVEL + 1) {
      responce = await middlewareGetSummaryDataBrowse(level, id);
    } else {
      history.goBack();
    }
    if (setCountry) setCountry(responce.me.country);
    return responce;
  };

  const checkIsMookmarkActive = (level: string, id: string) => {
    if (localCommunity) {
      if (+level === 4 && +id === +localCommunity) {
        setIsBookMarkActive(true);
      } else {
        setIsBookMarkActive(false);
      }
    } else {
      setIsBookMarkActive(false);
    }
  };

  const changeBranchParams = async (
    levelParams: string | undefined,
    idParams: string | undefined
  ) => {
    const level = levelParams || "0";
    const id = idParams || "0";
    getAllData(level, id);
    setIsChangeBranch(!isChangeBranch);
    history.push("/community/browse/level/" + level + "/id/" + id);
    if(level == "4" && setActiveTab){
      setActiveTab('updates')
    }
  };

  const isShowBookmark = (type: string) => {
    const isShow = WHAT_TYPE_SHOW_BOOKMARK.includes(type);
    setIsShowBookmarkBlock(isShow);
  };

  const setBrowseDateChart = (data: any) => {
    /// First diagram: Standart Compliance
    const { standards } = data;
    browseDateChart[0].name = t("standartCompliance");
    browseDateChart[0].chart.percent = standards.rate;
    browseDateChart[0].lineaDiagram.linear_points = generationLinearPoints(
      standards.trend
    );
    if (standards.trend_direction) {
      browseDateChart[0].lineaDiagram.evaluation =
        standards.trend_direction[Object.keys(standards.trend_direction)[0]];
    }
    browseDateChart[0].noData = t("noPerformanceMeasuresYetCreated");

    /// Second diagram: Community Sentiment t("communitySentiment")
    const { sentiments } = data;

    browseDateChart[1].name = t("communitySentiment");
    if (
      browseDateChart[1].chart.optionSentiment &&
      sentiments.rate_all &&
      sentiments.rate_all.length > 0
    ) {
      browseDateChart[1].chart.optionSentiment.community_sentiment =
        generationCommunitySentiment(sentiments.rate_all);
      browseDateChart[1].chart.rate_all = sentiments.rate_all
    }
    browseDateChart[1].lineaDiagram.linear_points = generationLinearPoints(
      sentiments.trend
    );
    if (sentiments.trend_direction) {
      browseDateChart[1].lineaDiagram.evaluation =
        sentiments.trend_direction[Object.keys(sentiments.trend_direction)[0]];
    }
    browseDateChart[1].noData = t("noPerformanceMeasuresYetCreated");

    /// Third diagram: Action Plan Progress t("actйionPlanProgress")
    const actionPlans = data["action-plans"];
    browseDateChart[2].name = t("actionPlanProgress");
    browseDateChart[2].chart.percent = actionPlans.rate;
    browseDateChart[2].lineaDiagram.linear_points = generationLinearPoints(
      actionPlans.trend
    );
    if (actionPlans.trend_direction) {
      browseDateChart[2].lineaDiagram.evaluation =
        actionPlans.trend_direction[
          Object.keys(actionPlans.trend_direction)[0]
        ];
    }
    browseDateChart[2].noData = t("noCommitmentsCreated");
  };

  const generationLinearPoints = (array: number[]) => {
    return array.map((item, index) => {
      return {
        level: item,
        control_point: index + 1 === array.length ? true : false,
      };
    });
  };

  const generationCommunitySentiment = (array: number[]) => {
    array = normalizationNumberArray(array);
    let objectSentiment = {
      badly: 0,
      unsatisfactory: 0,
      satisfactory: 0,
      good: 0,
      excelent: 0,
    };
    objectSentiment.badly = array[0];
    objectSentiment.unsatisfactory = array[1];
    objectSentiment.satisfactory = array[2];
    objectSentiment.good = array[3];
    objectSentiment.excelent = array[4];
    return objectSentiment;
  };

  const normalizationNumberArray = (array: number[]) => {
    const maxNumber = Math.max(...array);
    const normalizeArrayNumber = array.map((item) => {
      return (item * 100) / maxNumber;
    });
    return normalizeArrayNumber;
  };

  const saveBrowseToLocalstorage = async () => {


     setIsBookMarkActive(!isBookMarkActive);

     const pageTreeId = !isBookMarkActive ? pageTree?.me.id : '';

    await middlewarPutAccountMe({
      ...userData,
      last_name: userData.last_name || '',
      community: pageTreeId,
    });
     dispatch(setCommunity(pageTreeId));
     dispatch(setCommunityMyPageTree({}));
      caches && caches.delete('dynamic_cache_sauti');
      localStorage.removeItem('TIMESTAMP');
      await persistor.purge();
  };

  const checkIsShowMap = (
    responce: ResponceCommunityBrowseLevelIdT | undefined
  ) => {
    let isShowMap = true;
    let points: any[] = [];

    if (responce) {
      if (responce.children && responce.children.length > 0) {
        points = createMapPoints(responce.children);
        if (points.length <= 0) {
          isShowMap = false;
        }
      } else {
        isShowMap = false;
      }
    } else {
      isShowMap = false;
    }

    if (isCommunity()) {
      isShowMap = !photoCommunityUrl ? true : false;

    }

    return isShowMap;
  };

  const createMapPoints = (children: ResponceChildrenT[]) => {
    const childrenNotNull = children.filter((item) => item.lng && item.lat);
    const points = childrenNotNull.map((item) => {
      return {
        lng: item.lng,
        lat: item.lat,
        id: item.id,
        level: item.level,
        state: item.data.state,
        image: item.data.state
          ? getNormalImage(getObjectsValue(item.data.state))
          : faceNoneGrayImage,
      };
    });
    return points;
  };

  const createFakePoint = (pageTreeLocal: any) => {
    const points = [
      {
        lng: pageTreeLocal.me.coordinates.center
          ? pageTreeLocal.me.coordinates.center[0]
          : 0,
        lat: pageTreeLocal.me.coordinates.center
          ? pageTreeLocal.me.coordinates.center[1]
          : 0,
        id: pageTreeLocal.me.id,
        level: pageTreeLocal.me.level,
        state: pageTreeLocal.data.state,
        type: pageTreeLocal.me.type,
        name: pageTreeLocal.me.name,
        data: pageTreeLocal.data,
        image: pageTreeLocal.data.state
          ? getNormalImage(getObjectsValue(pageTreeLocal.data.state))
          : faceNoneGrayImage,
      },
    ];

    return points;
  };

  const getNormalImage = (state: string) => {
    switch (state) {
      case t("veryGood"):
        return faceJubilantImage;
      case t("good"):
        return facePositiveImage;
      case t("okay"):
        return faceNeutralImage;
      case t("bad"):
        return faceNegativeImage;
      case t("veryBad"):
        return faceSorrowfulImage;
      default:
        return faceNoneGrayImage;
    }
  };

  const settingMapViewport = (children: ResponceChildrenT[]) => {
    let defaultViewport: any;

    if (children.length > 0) {
      // const points = createMapPoints(children);
      const coordinates = pageTree?.me?.coordinates;

      defaultViewport = {
        latitude: coordinates.center ? coordinates.center[0] : 0,
        longitude: coordinates.center ? coordinates.center[1] : 0,
        zoom: coordinates.zoom ? coordinates.zoom : 1, // MAX_ZOOM / coordinates.zoom - MAX_ZOOM * coordinates.zoom
      };

    } else {
      if (pageTree?.me?.coordinates.center) {
        defaultViewport = {
          latitude: pageTree?.me?.coordinates.center[0],
          longitude: pageTree?.me?.coordinates.center[1],
          zoom: pageTree?.me?.coordinates.zoom
            ? pageTree?.me?.coordinates.zoom
            : 1,
        };
      } else {
        defaultViewport = {
          latitude: 0,
          longitude: 0,
          zoom: 1,
        };
      }
    }

    return defaultViewport;
  };

  const changeBranch = async (option: any) => {
    const [level, id] = option.value.split("-");
    changeBranchParams(level, id);
    // setIsUseEffect(!isUseEffect);
  };

  const isShowMakeChart = (item: any) => {
    if (item.chart.type === 1) {
      return item.chart.percent ? true : false;
    } else if (item.chart.type === 2) {
      return item.lineaDiagram.linear_points.length > 0 ? true : false;
    }
  };

  const onClickMarker = (level: number, id: number) => {
    changeBranchParams(level.toString(), id.toString());
  };

  const isCommunity = () => {
    const isCommuni = pageTree?.me?.type === TYPE_COMMUNITY ? true : false;
    if (isCommunityProps) isCommunityProps(isCommuni);
    return isCommuni;
  };

  const moveToCorrectUrl = (index: number) => {
    if (isCommunity()) {
      index === 0 && history.push(`/community/${pageTree?.me?.id}/standards`);
      index === 1 && history.push(`/community/${pageTree?.me?.id}/sentiments`);
      index === 2 &&
        history.push(`/community/${pageTree?.me?.id}/ListActionPlans`);
      // /community/:idCommunity/sentiment
    }
  };

  const sendCorrectPointForMap = () => {
    return createMapPoints(pageTree?.children || []);
  };

  const IS_SHOW_GO_BACK = !isGoBrowse && +levelURL > MIN_LEVEL;
  return (
    <div className="community-browse-header-with-map">
      <div className="grid-upper-favorite-map-name-block">
        <div className="left-nav-section-browse-header">
          <div className="back-favorite-browse-block">
            <div>
              {IS_SHOW_GO_BACK && (
                <GoBack
                  eventAfterClick={() =>
                    changeBranchParams(
                      pageTree?.parent?.level.toString(),
                      pageTree?.parent?.id.toString()
                    )
                  }
                  urlMove={`/community/browse/level/${pageTree?.parent?.level}/id/${pageTree?.parent?.id}`}
                  text={pageTree?.parent?.name}
                />
              )}
              {!(+levelURL > MIN_LEVEL) && (
                <NavLink
                  to="/"
                  className="small-header-exit-btn color-text-data-header-green"
                >
                  <img
                    className="arrow-left-green-image-in-small-header"
                    src={arrowGreenLeftImage}
                    alt="arrowGreenLeftImage"
                  />
                  {t("exit")}
                </NavLink>
                // <GoBack urlMove="/browse" text="Exit" />
              )}
              {isGoBrowse && (
                <NavLink
                  to="/"
                  className="small-header-exit-btn color-text-data-header-green"
                >
                  <img
                    className="arrow-left-green-image-in-small-header"
                    src={arrowGreenLeftImage}
                    alt="arrowGreenLeftImage"
                  />
                  {t("exit")}
                </NavLink>
              )}
            </div>
            <div>
              {isShowBookmarkBlock && isConnected && (
                <Favorite
                  isCheckBookmark={isBookMarkActive}
                  onClick={saveBrowseToLocalstorage}
                />
              )}
            </div>
          </div>
          <div className="adaptive-map-header-browse-show-mobile">
            {/* {() => shaowMapOrImage(pageTree)} */}
            {/* {mapOrImagePart} */}
            <div className="map-community-browse-block">
              <div className="map-community-browse-block">
                {!checkIsShowMap(pageTree) && photoCommunityUrl && (
                  <img
                    className="africa-image-browse"
                    src={photoCommunityUrl}
                    alt="africaTmpImage"
                  />
                )}
                {checkIsShowMap(pageTree) && (
                  <MapCommunity
                    onClickMarker={onClickMarker}
                    points={sendCorrectPointForMap()}
                    defaultViewport={settingMapViewport(
                      pageTree?.children || []
                    )}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="header-browse-dropdown-community">
            <div className="name-community-browse-header">
              {pageTree?.me?.name}
            </div>
            <div className="community-browse-header-dropdown-class-name-is-open">
              {optionsDateOfGathering.length > 0 && (
                <CustomDropDown
                  setValue={(option) => changeBranch(option)}
                  options={optionsDateOfGathering}
                  defaultOption={({label:"Select", value:'-1'})}
                  cssControl="community-browse-header-dropdown"
                  cssMyMenuClassName="community-browse-header-dropdown-menu"
                />
              )}
              {optionsDateOfGathering.length <= 0 && (
                <div className="community-welcome-message-sit-amet">
                  {/* {t("communityWelcomeMessageSitAmet")} */}
                  {welcomeMessage}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="adaptive-map-header-browse-hidden-mobile">
          <div className="map-community-browse-block">
            <div className="map-community-browse-block">
              {!checkIsShowMap(pageTree) && photoCommunityUrl && (
                <img
                  className="africa-image-browse"
                  src={photoCommunityUrl}
                  alt="africaTmpImage"
                />
              )}
              {checkIsShowMap(pageTree) && (
                <MapCommunity
                  points={sendCorrectPointForMap()}
                  defaultViewport={settingMapViewport(pageTree?.children || [])}
                  onClickMarker={onClickMarker}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="diagrams-block-browse">
        {browseDateChart.map((item: any, index: number) => {
          return (
            <section
              onClick={() => moveToCorrectUrl(index)}
              className={`diagram-section-make-chart ${
                isCommunity() && `item-cursor-pointer`
              } ${!isShowMakeChart(item) && `is-show-makechart-width`}`}
              key={uuidv4()}
            >
              <div
                className={`diagram-text-section-browse ${
                  !isShowMakeChart(item) &&
                  `is-show-makechart-no-rigth-margin-width`
                }`}
              >
                <div className="name-diagram-upper-block">
                  <div className="name-diagram-text">
                    {isCommunity() && (
                      <img
                        className="browse-diagram-arrow-image"
                        src={arrowRightImage}
                        alt="arrowRightImage"
                      />
                    )}
                    <div
                      className={`name-diagram-browse ${
                        !isShowMakeChart(item) && `unset-width`
                      }`}
                    >
                      {item.name}
                    </div>
                  </div>
                  {isShowMakeChart(item) && (
                    <div className="make-chart-block diagram-browse-show-tablet">
                     {/* <MakeChart setOption={item.chart} />*/}
                      {item.chart.type === 1
                          ?
                          <PieChartComponent color={"white"} backgroundColor={"rgb(57, 150,74)"} data={item.chart.percent||0} />
                        :
                          < TinyBarChartComponent color={"white"} data={item.chart} options={{height:"100%"}} />
                      }
                    </div>
                  )}
                </div>
                <div className="bottom-border-white-browse-diagram"></div>
                <div className="small-diagram-down-browse">
                  {isShowMakeChart(item) && (
                    <>
                      <div className="linear-diagrama-css">
                        <LinearDiagram state_santiments={item.lineaDiagram} />
                      </div>
                      <div className="linea-diagram-evalation">
                        {item.lineaDiagram.evaluation}
                      </div>
                    </>
                  )}
                  {!isShowMakeChart(item) && (
                    <div className="community-browse-no-data-text">
                      {item.noData}
                    </div>
                  )}
                </div>
              </div>
              {isShowMakeChart(item) && (
                <div className="make-chart-block diagram-browse-hidden-tablet">
                  {item.chart.type === 1
                      ?
                      <PieChartComponent color={"white"} backgroundColor={"rgb(57, 150,74)"} data={item.chart.percent||0} />
                      :
                      < TinyBarChartComponent color={"white"} data={item.chart} options={{height:"100%"}} />
                  }
                </div>
              )}
            </section>
          );
        })}
      </div>
    </div>
  );
};

export default CommunityDataHeader;
