import React from "react";
import "./SimpleListOfLink.css";
import arrowRoghtBlackShort from "../../assets/images/arrow-right-black-short.png";
import { useHistory } from "react-router-dom";
import { THEMES } from "../../mocks/layout";
import { getTextColor } from "../../theme/CombineThemes";
import { LinkT } from "../../types/types";

interface Func {
    (indexLink: number): void;
}

type Props = {
  title?: string;
  links: LinkT[];
  theme?: string;
};

const SimpleListOfLink: React.FC<Props> = ({
  title,
  links,
  theme = THEMES.GREEN
}) => {
  const history = useHistory();

  const movePage = (e:any,link: LinkT) => {
    if (link.url) {
      history.push(link.url);
    } else {
      console.log("/");
    }
  };

  const textColor = () => {
    return getTextColor(theme);
  };

  return (
    <section className="simple-list-of-link-section">
      {title && <div className="simple-list-of-link-title">{title}</div>}
      {links &&
        links.map((link, index) => {
          return (
            <div
              className="simple-list-of-link-row"
              key={index}
              onClick={(e) => movePage(e,link)}
            >
              {link.title && (
                <div
                  className={`simple-list-of-link-title-link ${textColor()}`}
                >
                  {link.title}
                </div>
              )}
              <div className="simple-list-of-link-block-down-info">
                <div className="simple-list-of-link-text">{link.text}</div>
                <div>
                  <img
                    className="arrow-right-black-short-list-link"
                    src={arrowRoghtBlackShort}
                    alt="arrowRoghtBlackShort"
                  />
                </div>
              </div>
            </div>
          );
        })}
    </section>
  );
};

export default SimpleListOfLink;
