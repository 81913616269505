import React from "react";
import {RouteComponentProps} from "react-router-dom";
import {IonContent, IonPage} from "@ionic/react";
import GoBack from "../../components/goBack/GoBack";
import ButtonResource from "../../components/content/ButtonResource";
import {THEMES} from "../../mocks/layout";
import Header from "../../components/header/Header";
import CustomInput from "../../components/customInput/CustomInput";
import {withTranslation, WithTranslation} from "react-i18next";
import Footer from "../../components/footer/Footer";
import CustomDropDown from "../../components/customDropDown/CustomDropDown";
import {
    getUser,
    deleteUserId,
    resetPassword,
    updateUser
} from "../../services/users";
import {
    isUserGlobalIrelandTanzaniaAdmin,
    isUserGlobalAdmin,
    isUserGlobalIrelandAdmin,
    isUserGlobalTanzaniaAdmin
} from "../../helpers/functions";
import {
    getCommunitiesList,
    getCommunitiesListCountry,
    getCommunityId
} from "../../services/Community";
import {ConfirmBox} from '../../components/content/ConfirmBox';
import "../resource.css";
import "../Community/CommunityManage/CommunityManage.css";
import "./UserManagement.css"
import "./CreateUser.css";
import "./ResetUser.css";
import {UserAccount} from "./UserManagement";

const THEME_PAGE = THEMES.BLUE;

type ResetUserProps = {};

type Community = {
    group_name: string,
    id: number,
    name: string,
    welcome_message: string,
    community: number
};

type UserCommunity = {
    country: string,
    group_name: string,
    id: number,
    name: string,
    respondent_types: string[],
    welcome_message: string | null,
};

type OptionRight = {
    label: string
};

type OptionCommunity = {
    label: string,
    value: number
};

type OptionCountries = {
    label: string
};

type Content = {
    first_name: string,
    last_name: string,
    email: string,
    admin_scope: string | null,
    community: number | null
};

type ResetUserState = {
    contentPage: Content,
    communitiesCountry: JSX.Element | null,
    isConfirm: {
        message: string,
        acionButtonText: string | null
    } | null
};

class ResetUser extends React.Component<ResetUserProps & WithTranslation & RouteComponentProps<{}>,
    ResetUserState> {
    globalAdmin: boolean;
    levelCountrysAdmin: boolean;
    adminTanzania: boolean;
    adminIreland: boolean;
    communitiesListTanzania: Community[];
    communitiesListIreland: Community[];
    listOptionCommunity: OptionCommunity[];
    idUser: string;
    userInfo: UserAccount | null;
    userCommunity: UserCommunity | null;

    constructor(props: any) {
        super(props);
        this.state = {
            contentPage: {
                first_name: "",
                last_name: "",
                email: "",
                admin_scope: null,
                community: null
            },
            communitiesCountry: null,
            isConfirm: null
        };

        this.globalAdmin = false;
        this.levelCountrysAdmin = false;
        this.adminTanzania = false;
        this.adminIreland = false;
        this.communitiesListTanzania = [];
        this.communitiesListIreland = [];
        //   this.listOptionTanzania = [];
        this.listOptionCommunity = [];
        this.idUser = "";
        this.userInfo = null;
        this.userCommunity = null;
        this.createOptionCommunity = this.createOptionCommunity.bind(this);
        this.setContentPage = this.setContentPage.bind(this);
        this.getCommunitiesList = this.getCommunitiesList.bind(this);
        this.getCommunities = this.getCommunities.bind(this);
        this.makeListCommunities = this.makeListCommunities.bind(this);
        this.selectOptionCommunity = this.selectOptionCommunity.bind(this);
        this.resetPasswordUser = this.resetPasswordUser.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.deleteUsers = this.deleteUsers.bind(this);
        this.processConfirm = this.processConfirm.bind(this);
        this.getUserInfo = this.getUserInfo.bind(this);
        this.Confirm = this.Confirm.bind(this);
        this.updateUserId = this.updateUserId.bind(this);
    }

    async componentDidMount() {
        var param = Object.create(this.props.match.params);
        this.idUser = param.idUser;

        if (isUserGlobalAdmin())
            this.globalAdmin = true;
        else if (isUserGlobalIrelandTanzaniaAdmin())
            this.levelCountrysAdmin = true;
        else if (isUserGlobalIrelandAdmin())
            this.adminIreland = true;
        else if (isUserGlobalTanzaniaAdmin())
            this.adminTanzania = true;

        try {
            await this.getUserInfo(this.idUser);
            //  await this.getCommunities("ireland");
            //  await this.getCommunities("tanzania");
        }
        catch (error: any) {
            console.log(`getUsersRightList error status: ${error}`);
        }
    }

    async getUserInfo(id: string) {
        try {
            const {data} = await getUser(id);
            this.userInfo = {...data};
            const content: Content = {...this.state.contentPage};
            this.setContentPage({
                ...content,
                first_name: this.userInfo ? this.userInfo.first_name : "",
                last_name: this.userInfo ? this.userInfo.last_name : "",
                email: this.userInfo ? this.userInfo.email : "",
                admin_scope: this.userInfo ? this.userInfo.admin_scope : null,
                community: this.userInfo ? this.userInfo.community : null
            });
            if (this.userInfo && this.userInfo.community) {
                await this.getCommunityById(this.userInfo.community);
            }

        }
        catch (error: any) {
            console.log(`getUser error status: ${error}`);
        }
    }

    async updateUserId(id: string, account: UserAccount) {
        try {
            const response = updateUser(id, {...account});
        }
        catch (error: any) {
            console.log(`updateUser error status: ${error}`);
        }
    }

    async getCommunityById(id: number) {
        try {
            const {data} = await getCommunityId(`${id}`);
            this.userCommunity = {...data};
            if (this.userCommunity) {
                await this.getCommunities(this.userCommunity.country);
            }
        }
        catch (error) {
            console.log(`getCommunityId error status: ${error}`);
        }
    }

    async getCommunities(country: string) {
        try {
            const {data} = await getCommunitiesListCountry(country);
            country === "tanzania" ?
                this.communitiesListTanzania = [...data] :
                this.communitiesListIreland = [...data];
            this.createOptionCommunity(country);
        }
        catch (error: any) {
            console.log(`getCommunitiesListCountry error status: ${error}`);
        }
    }

    makeListCommunities(arrayCommunities: Community[], country: string) {
        const {t} = this.props;
        let user_community: OptionCommunity | null = null;
        this.listOptionCommunity = arrayCommunities.map((item) => {
            if (this.userInfo) {
                if (item.id === this.userInfo.community)
                    user_community = {
                        label: item.name,
                        value: item.id
                    }
            }

            return ({
                    label: item.name,
                    value: item.id
                }
            );
        });

        const communitiesDropList: JSX.Element =
            <div className="community-management-content-wrap-row
                    community-management-content-group-name-row
                    create-user-admin-scope-row"
            >
                <div className="community-management-content-group-label">
                    <div className="create-user-group-label-email">
                        {country.toLocaleLowerCase() === "ireland" ? t("communitiesIreland") : t("communitiesTanzania")}
                    </div>
                </div>
                <CustomDropDown
                    setValue={(option) => this.selectOptionCommunity(option, country)}
                    options={this.listOptionCommunity as any[]}
                    cssControl="create-user-select-dropdown"
                    defaultOption={user_community ? user_community as any : ""}
                />
            </div>;
        this.setState({communitiesCountry: communitiesDropList});
    }

    async getCommunitiesList() {
        try {
            const {data} = await getCommunitiesList();
        }
        catch (error: any) {
            console.log(`getCommunitiesList error status: ${error}`);
        }
    }


    setContentPage(content: any) {
        this.setState({contentPage: content});
    }

    createOptionCommunity(country: string) {
        if (country.toLocaleLowerCase() === "tanzania") {
            this.makeListCommunities(this.communitiesListTanzania, country);
        } else if (country.toLocaleLowerCase() === "ireland") {
            this.makeListCommunities(this.communitiesListIreland, country)
        }
    }

    selectOptionCommunity(option: any, country: string) {
        const content = {...this.state.contentPage, community: option.value};
        this.setState({contentPage: content});
    }

    backToResources() {
        this.props.history.goBack();
    }

    async resetPasswordUser() {
        try {
            if (this.userInfo) {
                const {data} = await resetPassword({"email": this.userInfo.email});
            }
        }
        catch (error: any) {
            console.log(`resetPassword error status: ${error}`);
        }
    }

    saveChanges() {
        const {t} = this.props;
        if (this.state.contentPage.first_name.length === 0 ||
            this.state.contentPage.last_name.length === 0 ||
            this.state.contentPage.email.length === 0 ||
            (
                !this.globalAdmin &&
                this.state.contentPage.community === 0)) {

            alert(t("allFieldsMissing"));
            return;
        }
        let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (reg.test(this.state.contentPage.email) == false) {
            alert(t("wrongEmailAddress"));
            return;
        }
        if (this.userInfo) {
            const user_account: UserAccount = {
                ...this.state.contentPage,
                url: this.userInfo.url
            };
            this.updateUserId(this.idUser, user_account);
        }
    }

    Confirm(message: string, actionButtonText?: string) {
        const actionButton: string | null = actionButtonText ? actionButtonText : null;
        this.setState({
            isConfirm: {
                message: message,
                acionButtonText: actionButton
            }
        });
    }

    processConfirm(confirm: string) {
        this.setState({isConfirm: null});
        if (confirm === 'Delete') {
            this.deleteUsers();
        } else if (confirm === 'Reset Password') {
            this.resetPasswordUser();
        }
    }

    async deleteUsers() {
        try {
            const response = await deleteUserId(this.idUser);
            this.backToResources();
        }
        catch (error: any) {
            console.log(`deleteUser error status: ${error}`);
        }
    }


    render() {
        const {t} = this.props;
        const content: Content = {...this.state.contentPage};

        return (
            <>
                <div className="community-management-title-wrap">
                    <div className="community-management-title-wrap-content">
                        <div className="display-flex">
                            <GoBack isGoBack text={t("userManagement")}/>
                        </div>
                        <div className="community-management-title-wrap-content-name">
                            {t("editAccount")}
                        </div>
                    </div>
                </div>
                <div className="wrap-content-resource">
                    <div className="content-resource community-management-content-resource">
                        <div className='description_resource community-management-content-description'>
                            {t("updateUserDetailsSendPasswordResetEmail")}
                        </div>
                        <div className="content-resource-summary community-management-content-summary">
                            <div className="content-resource-summary-detail">
                                <div className="content-resource-summary-detail-subcontainer">
                                    <div className="community-management-content-wrap">
                                        <div className="community-management-content-wrap-row
                                      community-management-content-group-name-row"
                                        >
                                            <div className="community-management-content-group-label">
                                                <div className="create-user-group-label-name">
                                                    {t("name")}
                                                </div>
                                            </div>
                                            <CustomInput
                                                cssInput="create-user-first-name"
                                                id="englishFirstName"
                                                value={this.state.contentPage.first_name}
                                                placeholder="First Name"
                                                onChange={(inputText: string) => {
                                                    this.setContentPage({...content, first_name: inputText});
                                                }}
                                            />
                                            <CustomInput
                                                cssInput="create-user-last-name"
                                                id="englishLastName"
                                                placeholder="Last Name"
                                                value={this.state.contentPage.last_name}
                                                onChange={(inputText: string) => {
                                                    this.setContentPage({...content, last_name: inputText});
                                                }}
                                            />

                                        </div>
                                        <div className="community-management-content-wrap-row
                                      community-management-content-group-name-row"
                                        >
                                            <div className="community-management-content-group-label">
                                                <div className="create-user-group-label-email">
                                                    {t("emailAddress")}
                                                </div>
                                            </div>
                                            <CustomInput
                                                cssInput="create-user-email-address"
                                                id="englishEmail"
                                                placeholder="account@domain.net"
                                                value={this.state.contentPage.email}
                                                onChange={(inputText: string) => {
                                                    this.setContentPage({...content, email: inputText});
                                                }}
                                                isShowCountLetter
                                            />
                                        </div>
                                        {this.state.communitiesCountry}
                                        <div className="community-management-content-line"/>
                                        <div className="community-management-content-button-save-row
                                      user-management-content-buttons-row"
                                        >
                                            <div className="user-management-conten-delete">
                                                <ButtonResource
                                                    onClickHandler={
                                                        () => {
                                                            this.Confirm("theUserWillbeDeletedAreYouSure");
                                                        }
                                                    }
                                                    text_color="rgb(107, 114, 128)"
                                                    id="user-management-conten-button-delete"
                                                >
                                                    {t("delete")}
                                                </ButtonResource>
                                            </div>
                                            <div className="user-management-conten-save-reset">
                                                <ButtonResource
                                                    onClickHandler={
                                                        () => {
                                                            this.Confirm("thePasswordWillBeReset", "Reset Password");
                                                        }
                                                    }
                                                    text_color="rgb(107, 114, 128)"
                                                    id="user-management-conten-button-reset"
                                                >
                                                    {t("resetPassword")}
                                                </ButtonResource>
                                                <ButtonResource
                                                    onClickHandler={this.saveChanges}
                                                    text_color="white"
                                                    id="user-management-conten-button-save"
                                                >
                                                    {t("save")}
                                                </ButtonResource>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {
                    this.state.isConfirm ?
                        <ConfirmBox
                            effectIn="rubberBand"
                            effectOut="zoomOutDown"
                            effectOutDel="rotateOut"
                            actionButtonText={this.state.isConfirm.acionButtonText}
                            actionRender={(state: string) => {
                                this.processConfirm(state);
                            }}
                        >
                            {t(this.state.isConfirm.message)}
                        </ConfirmBox> :
                        null
                }

            </>
        );
    }
}

const decoratedComponent = withTranslation("common")(ResetUser);
export {decoratedComponent as ResetUser};
