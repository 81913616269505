import React, {useEffect} from "react";
import ContentAboutUs from "../components/content/ContentAboutUs";
import {useDispatch} from "react-redux";
import { setHeaderActiveTab} from "../reducers/headerReducer";
import {useIonViewWillEnter} from "@ionic/react";

const AboutUs: React.FC = () => {
    const dispatch = useDispatch();

    useIonViewWillEnter(() => {
        dispatch(setHeaderActiveTab(2))
    }, []);
    return (
        <ContentAboutUs/>
    );
};

export default AboutUs;
