import { Network } from '@capacitor/network';
import { OFFLINE, ONLINE } from 'redux-offline-queue'
import { store } from './reducers';

Network.addListener('networkStatusChange', (status: any) => {
    if (status.connected) {
        store.dispatch({ type: ONLINE })
    } else {
        store.dispatch({ type: OFFLINE })
    }
});