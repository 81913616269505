import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import CommunityBrowseContext from "./pages/Community/browseContext/CommunityBrowseContext";
import CommunityUpdate from "./pages/Community/CommunityUpdate/CommunityUpdate";
import Browse from "./pages/Browse";
import CommunityPage from "./pages/Community/communityPage/CommunityPage";
import ResourcesId from "./pages/Resources/resoursesId/ResoursesId";
//import Resources from "./pages/Resources/Resources";
import { Resources } from "./pages/Resources";
import { ResourcesOfGroup } from "./pages/ResourcesOfGroup";
import { ResourcePage } from "./pages/Resource";
import { AddResourceGroupPage } from "./pages/AddResourceGroupPage";
import { AddNewResource } from "./pages/AddNewResourcePage";
import { EditGroup } from "./pages/EditGroup";
import { EditResource } from "./pages/EditResource";
import { AddCommitment } from "./pages/ActionPlan/AddCommitment";
import { OfflineAddCommitment } from "./pages/ActionPlan/OfflineAddCommitment";
import { NewCompliance } from "./pages/Compliance/NewCompliance";
import { OfflineNewCompliance } from "./pages/Compliance/OfflineNewCompliance";
import { EditCommitment } from "./pages/ActionPlan/EditCommitment";
import { OfflineEditCommitment } from "./pages/ActionPlan/OfflineEditCommitment";
import CommunityCommitment from "./pages/Community/communityCommitment/CommunityCommitment";
import OfflineCommunityCommitment from "./pages/Community/communityCommitment/OfflineCommunityCommitment";
import Reset from "./pages/SignIn/Reset/Reset";
import SingIn from "./pages/SignIn/SignIn";
import ResetPassword from "./pages/SignIn/Reset/ResetPassword/ResetPassword";
import AboutUs from "./pages/AboutUs";
import CommunityBrowseStandards from "./pages/Community/browseContext/CommunityBrowseStandards/CommunityBrowseStandards";
import MyCommunity from "./pages/Community/myCommunity/MyCommunity";
import PerformanceMeasure from "./pages/Community/performanceMeasure/PerformanceMeasure";
import OfflinePerformanceMeasure from "./pages/Community/performanceMeasure/OfflinePerformanceMeasure";
import CommunitySentimentId from "./pages/Community/communitySentimentId/CommunitySentimentId";
import OfflineCommunitySentimentId from "./pages/Community/communitySentimentId/OfflineCommunitySentimentId";
import CommunitySentiment from "./pages/Community/communitySentiment/CommunitySentiment";
import OfflineCommunitySentiment from "./pages/Community/communitySentiment/offlineCommunitySentiment";
import CommunitySentimentBarChart from "./pages/Community/communitySentimentBarChart/CommunitySentimentBarChart";
import OfflineCommunitySentimentBarChart from "./pages/Community/communitySentimentBarChart/OfflineCommunitySentimentBarChart";
import CommunitySentimentEdit from "./pages/Community/communitySentimentEdit/CommunitySentimentEdit";
import { UpdateStandardGathering } from "./pages/UpdateStandardGathering/UpdateStandardGathering";
import { OfflineUpdateStandardGathering } from "./pages/UpdateStandardGathering/OfflineUpdateStandardGathering";
import { ListActionPlans } from "./pages/ActionPlan/ListActionPlans";
import {OfflineListActionPlans} from "./pages/ActionPlan/OfflineListActionPlans";
import CommunityActionPlanUpdate from "./pages/Community/communityActionPlanUpdate/CommunityActionPlanUpdate";
import OfflineCommunityActionPlanUpdate from "./pages/Community/communityActionPlanUpdate/OfflineCommunityActionPlanUpdate";
import { CommunityManage } from "./pages/Community/CommunityManage/CommunityManage";
import { UserManagement } from "./pages/UserManagement/UserManagement";
import { CreateUser } from "./pages/UserManagement/CreateUser";
import { ResetUser } from "./pages/UserManagement/ResetUser";
import { requiresAuthentication } from "./accessRghts/AccessRights";
import CountyManagement from "./pages/CountryManagement/CountyManagement";
import AccountMenu from "./pages/Account/AccountMenu/AccountMenu";
import AccountDetails from "./pages/Account/AccountDetails/AccountDetails";
import FeedBack from "./pages/Account/FeedBack/FeedBack";
import { Standards } from "./pages/Standards/Standards";
import * as access from "./accessRghts/Access";

//
import "./assets/css/fonts.css";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import "index.css"
import Header from "./components/header/Header";
import * as React from "react";
import OfflineCommunityBrowseStandards
  from "./pages/Community/browseContext/CommunityBrowseStandards/OfflineCommunityBrowseStandards";

/* Theme variables */
// import './theme/variables.css';

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <Header/>
      <IonRouterOutlet>
        <Route exact path="/resources/:idResourse">
          <ResourcesId />
        </Route>
        <Route
          exact
          path={access.URL_COMMUNITY_BROWSE_LEVEL_ID}
          component={requiresAuthentication(CommunityBrowseContext)}
        >
          {/* <CommunityBrowseContext /> */}
        </Route>
        <Route
          exact
          path={access.URL_COMMUNITY_STANDARDS}
          component={requiresAuthentication(CommunityBrowseStandards, OfflineCommunityBrowseStandards)}
        >
          {/* <CommunityBrowseStandards /> */}
        </Route>
        <Route
          exact
          path={access.URL_COMMUNITY_ID}
          component={requiresAuthentication(CommunityPage)}
        >
          {/* <CommunityPage /> */}
        </Route>
        <Route
          exact
          path={access.URL_COMMUNITY_ACTION_PLAN_ID}
          component={requiresAuthentication(CommunityCommitment,OfflineCommunityCommitment)}
        >
          {/* <CommunityCommitment /> */}
        </Route>
        <Route
          exact
          path={access.URL_COMMUNITY_UPDATE_ID}
          component={requiresAuthentication(CommunityUpdate)}
        >
          {/* <CommunityUpdate /> */}
        </Route>
        <Route
          exact
          path={access.URL_COMMUNITY_PERFOMANCE_ID}
          component={requiresAuthentication(PerformanceMeasure)}
        >
          {/* <PerformanceMeasure /> */}
        </Route>
        <Route
          exact
          path={access.URL_COMMUNITY_PERFOMANCE_SENTIMENT}
          component={requiresAuthentication(PerformanceMeasure, OfflinePerformanceMeasure)}
        />
        <Route
          exact
          path={access.URL_COMMUNITY_MY}
          component={requiresAuthentication(MyCommunity)}
        >
          {/* <MyCommunity /> */}
        </Route>
        <Route
          exact
          path={access.URL_COMMUNITY_SENTIMENT_UPDATE}
          component={requiresAuthentication(CommunitySentimentId,OfflineCommunitySentimentId)}
        >
          {/* <CommunitySentimentId /> */}
        </Route>
        <Route
          exact
          path={access.URL_COMMUNITY_SENTIMENTS_ID}
          component={requiresAuthentication(CommunitySentimentBarChart, OfflineCommunitySentimentBarChart)}
        >
          {/* <CommunitySentimentBarChart /> */}
        </Route>
        <Route
          exact
          path={access.URL_COMMYNUTY_SENTIMENT_EDIT}
          component={requiresAuthentication(CommunitySentimentEdit)}
        >
          {/* <CommunitySentimentEdit /> */}
        </Route>
        <Route
          exact
          path={access.URL_COMMUNITY_SENTIMENTS}
          component={requiresAuthentication(CommunitySentiment, OfflineCommunitySentiment)}
        >
          {/* <CommunitySentiment /> */}
        </Route>
        <Route
        exact
          path={access.URL_RESOURCE}
          component={requiresAuthentication(Resources)}
          // render={(props) => <Resources {...props} />} //группы(список)
        />
        <Route
          exact
          path={access.URL_RESOURCE_ADDGROUP}
          component={requiresAuthentication(AddResourceGroupPage)}
          //path="/resource/addgroup"
          // render={(props) => <AddResourceGroupPage {...props} />} //добавить группу
        />
        <Route
          exact
          path={access.URL_COUNTRY_MANAGEMENT}
          component={requiresAuthentication(CountyManagement)}
          //path="/resource/addgroup"
          // render={(props) => <AddResourceGroupPage {...props} />} //добавить группу
        />
        <Route
          exact
          path={access.URL_ACCOUNT}
          component={requiresAuthentication(AccountMenu)}
          //path="/resource/addgroup"
          // render={(props) => <AddResourceGroupPage {...props} />} //добавить группу
        />
        <Route
          exact
          path={access.URL_ACCOUNT_FEEDBACK}
          component={requiresAuthentication(FeedBack)}
          //path="/resource/addgroup"
          // render={(props) => <AddResourceGroupPage {...props} />} //добавить группу
        />
        <Route
          exact
          path="/resource/group/:idResourse/resources"
          // path="/resources/group/:idGroup/resources"
          component={requiresAuthentication(ResourcesOfGroup)} //ресурсы группы(список)
        />
        <Route
          exact
          path="/resource/:idResource"
          //   path="/resources/group/:idGroup/resource/:idResource"
          component={requiresAuthentication(ResourcePage)} //отдельный ресурс из группы
        />
        <Route
          exact
          path={access.URL_RESOURCE_ADD_NEW}
          component={requiresAuthentication(AddNewResource)}
          // path="/resources/group/:idGroup/addresource" // add 05-01-22 добавить ресурс
          //  render={(props) => <AddNewResource {...props} />}
        />
        <Route
          exact
          path={access.URL_RESOURCE_EDIT_GROUP}
          component={requiresAuthentication(EditGroup)}
          // path="/resources/group/:idGroup/editgroup" // add 05-01-22 редактировать группу
          // render={(props) => <EditGroup {...props} />}
        />
        <Route
          exact
          path={access.URL_RESOURCE_EDIT}
          component={requiresAuthentication(EditResource)}
          //  path="/resource/:idResource/editresource"
          //   path="/resources/group/:idGroup/resource/:idResource/editresource"  // add 05-01-22 редактировать ресурс
          //  render={(props) => <EditResource {...props} />}
        />
        <Route
          exact
          path={access.URL_ADD_COMMITMENT}
          component={requiresAuthentication(AddCommitment, OfflineAddCommitment)}
          // path="/community/:idCommunity/action_plan"
          // render={(props) => <AddCommitment {...props} />}
        />
        <Route
          exact
          path={access.URL_COMMUNITY_ACTION_PLAN_ID_EDIT}
          component={requiresAuthentication(EditCommitment, OfflineEditCommitment)}
          // render={(props) => <EditCommitment {...props} />}
        />
        <Route
          exact
          path={access.URL_COMMUNITY_ACTION_PLAN_ID_UPDATE}
          component={requiresAuthentication(CommunityActionPlanUpdate,OfflineCommunityActionPlanUpdate)}
        >
          {/* <CommunityActionPlanUpdate /> */}
        </Route>
        <Route
          exact
          path="/community/:idCommunity/standard/:idStandard/updateCompliance/:idUpdate"
          /*render={(props) => <NewCompliance {...props} />}*/
          component={requiresAuthentication(NewCompliance, OfflineNewCompliance)}
        />
        <Route
          exact
          path={access.URL_COMMUNITY_STANDARDS_UPDATE}
          component={requiresAuthentication(UpdateStandardGathering, OfflineUpdateStandardGathering)}
          // render={(props) => <UpdateStandardGathering {...props} />}
        />
        <Route
          exact
          path={access.URL_COMMUNITY_LISTACTIONPLAN}
          component={requiresAuthentication(ListActionPlans,OfflineListActionPlans)}
        />
        {/*
        <Route
          exact
          path="/community/:idCommunity/standard/:idStandard/UpdateStandardGathering"
          render={(props) => <UpdateStandardGathering {...props} />}
        />*/}
        {/*
        <Route
          exact
          path="/community/:idCommunity/ListActionPlans"
          render={(props) => <ListActionPlans {...props} />}
        />*/}
        <Route
          exact
          path={access.URL_ACCOUNT_COMMUNITY_MANAGE}
          component={requiresAuthentication(CommunityManage)}
          // render={(props) => <CommunityManage {...props} />}
        />
        <Route
          exact
          path={access.URL_USERS_MANAGEMENT}
          component={requiresAuthentication(UserManagement)}
        />
        <Route
          exact
          path={access.URL_USER_CREATE}
          component={requiresAuthentication(CreateUser)}
        />
        <Route
          exact
          path={access.URL_SIGNIN}
          component={requiresAuthentication(SingIn)}
        />
        <Route
          exact
          path={access.URL_WELCOME}
          component={requiresAuthentication(SingIn)}
        />
          {/* <SingIn /> */}
        <Route
          exact
          path={access.URL_SIGNIN_RESET}
          component={requiresAuthentication(Reset)}
        >
          {/* <Reset /> */}
        </Route>
        <Route
          exact
          path={access.URL_ACCOUNT_DETAILS}
          component={requiresAuthentication(AccountDetails)}
        />
        <Route
          exact
          path={access.URL_SIGNIN_RESET_PASSWORD}
          component={requiresAuthentication(ResetPassword)}
        >
          {/* <ResetPassword /> */}
        </Route>
        {/* <Route exact path="/community/browse">
          <CommunityBrowseContext />
        </Route> */}
        {/* <Route exact path="/community/commitment">
          <CommunityCommitment />
        </Route> */}
        <Route
          exact
          path={access.URL_ACCOUNT_STANDARDS}
          component={requiresAuthentication(Standards)}
          // render={(props) => <Standards {...props} />}
        />
        <Route
          exact
          path={access.URL_BROWSE}
          component={requiresAuthentication(Browse)}
        />
        <Route
          exact
          path={access.URL_ACCOUNT_RESET_USER}
          component={requiresAuthentication(ResetUser)}
        />
        {/* <Route exact path={access.URL_BROWSE} render={(props) => <Browse {...props} />} /> */}
        <Route
          path={access.URL_BROWSE_ABOUT_US}
          component={requiresAuthentication(AboutUs)}
        >
          {/* <AboutUs /> */}
        </Route>

        <Route
          exact
          path="/"
          render={() => <Redirect to={access.URL_BROWSE} />}
        >
          <Redirect to={access.URL_BROWSE} />
        </Route>
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
